import moment from 'moment/moment'; 
import { UNITS } from '../../utils/units.enum';

(function() {
'use strict';

angular.module('agronicwebApp')


//TODO - check moment
.constant("moment", moment)

.controller('historyLogicsController',historyLogicsController)

historyLogicsController.$inject = ['$log' ,'$rootScope', '$scope','$state','Restangular','$mdDialog','UserData','historyFactory', 'sensorsFactory', '$filter','uiGridConstants'];

function historyLogicsController($log ,$rootScope, $scope, $state, Restangular,$mdDialog, UserData, historyFactory, sensorsFactory,$filter,uiGridConstants) {
    
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate(){
        vm.currentState = $state.includes;
        
        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;
            vm.from = moment().subtract(7,'day')._d;
            vm.to = moment()._d;    
            vm.checkDates10min = checkDates10min;
            if(!UserData.profesional){
                vm.minDate = moment().subtract(7,'day')._d;
            }else{
                vm.minDate = null;
            }
            vm.loadHistory = loadHistory;     
            vm.exportXLS = exportXLS;
            vm.getHistory = getHistory;
            loaded = false;           
            vm.pagination = {
                page:1,
                limit:15
            } 
            
            vm.groupBy = [
                {text: '10 ' + $filter('translate')('history.minutes'), value: '0'},
                {text:  $filter('translate')('history.hour'), value: '1'},
                {text:  $filter('translate')('history.day'), value: '2'},
                {text:  $filter('translate')('history.week'), value: '3'},
                {text:  $filter('translate')('history.month'), value: '4'},
                {text:  $filter('translate')('history.dates'), value: '5'},
            ];
            vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0'}];
            
            initGrid();            
            loadHistory();        
        }else{
            $state.go('units');
        }       
    }

    function initGrid(){
        vm.filter.groupBy = '2';
        vm.filter.sensor = '0';
        vm.gridOptions = {
            enableSorting: true,
            enableColumnMenus: false,
            useExternalPagination: false,
            paginationPageSizes: [15, 20, 25],
            paginationPageSize: 15,
            columnDefs: getColumnDefinition(vm.currentUnit.type),
            onRegisterApi: function(gridApi){
              vm.gridApi = gridApi;
            }
        }
    }
    function getColumnDefinition(type){
        switch(type){
            case UNITS.A_4500:
                return [
                    { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '4' , displayName: 'history.sensor' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '5' , displayName: 'history.sensorname' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '6' , displayName: 'history.avg' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '7' , displayName: 'history.max' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '8' , displayName: 'history.min' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '10' , displayName: 'sensors.error' ,  width:'**' , headerCellFilter: 'translate' },
                  ];
            default:
                return [];
        }
    }

    function checkDates(){
        if(this.from === undefined){
           return this["2"] + " - " + this["3"];      
        }else{
           return this.from  + " - " + this.to;
           
        }          
    }
    function checkDates10min(){
        if(vm.filter.groupBy === '0'){      
            if(vm.filter.sensor === 0){
                vm.filter.sensor = 1;
            }
            vm.maxDate = moment(vm.from).add(1,'day')._d;
            vm.to = moment(vm.from).add(1,'day')._d;
        }else{
           vm.maxDate = null;
        }
    }
    function loadHistory(clean){
        if(clean !== undefined){
            vm.pagination.page = 1;
        }
        var params = {
            from:moment(vm.from).format("DD-MM-YYYY"),
            to:moment(vm.to).add(1, 'd').format("DD-MM-YYYY"),
            type:vm.currentUnit.type,
            filter:vm.filter,
            page:vm.pagination.page,
            limit:vm.pagination.limit
        }
        historyFactory.logics(vm.currentUnit.id, params).then(data =>{
            vm.gridOptions.totalItems = data.total;
            vm.gridOptions.data =  data.content;
            _.forEach(vm.gridOptions.data , row => {
                row.loaded = false;
                row.checkDates = checkDates;
            });
            
        });
    }
    /*function exportXLS(){
        var name = moment(vm.from).format("DD-MM-YYYY") + "_" + moment(vm.to).format("DD-MM-YYYY") + ".xlsx";
        historyFactory.export(vm.gridApi.grid,name);
    }*/

    function exportXLS(){
        /*vm.loaded = false; 
        var params = {
            from:moment(vm.from).format("DD-MM-YYYY"),
            to:moment(vm.to).add(1, 'd').format("DD-MM-YYYY"),
            type:vm.currentUnit.type,
            filter:vm.filter,
            page:1,
            limit:10000
        }
        historyFactory.analogsExport(vm.currentUnit.id, params).then( ()=>{
           vm.loaded = true; 
        });*/
    }

    $scope.$on('refresh',function(event,args){
        
    });

    function getHistory(){
        historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    $scope.$on('VeggaSelectChange', (_e, data) => {
        vm.filter.groupBy = data.value;
        vm.checkDates10min();
    })
}

    
    
})();