import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    //TODO - check moment
    .constant('moment', moment)
    .controller('unitsController', unitsController)
    .filter(
      'includedIn',
      () => (unitsSource, unitTypes) => ((unitTypes && unitTypes.length) && unitsSource.filter((u) => unitTypes.includes(u.device.type))) || unitsSource
    );

  unitsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'NgTableParams',
    'progFactory',
    'sectorFactory',
    'userFactory',
    '$confirm',
    '$translate',
    'unitFactory',
    '$filter',
    'veggaModalConfirmService',
  ];

  function unitsController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    NgTableParams,
    progFactory,
    sectorFactory,
    userFactory,
    $confirm,
    $translate,
    unitFactory,
    $filter,
    veggaModalConfirmService
  ) {
    var vm = this;
    var order, ordersplit;
    vm.sort = {
      column: '',
      descending: false,
    };
    vm.devicesArray;
    vm.UNITS = UNITS;
    vm.checkStatus;
    vm.getType;
    vm.selectUnit;
    vm.openPrograms;
    vm.openSectors;
    vm.subusers = null;
    vm.unitTimeFormatter;
    let editing_time;
    let backup_name;

    activate();

    vm.unitsType = [
      { id: 2, name: 'A-4000' },
      { id: 3, name: 'A-2500' },
      { id: 4, name: 'A-BIT' },
      { id: 5, name: 'A-7000' },
      { id: 6, name: 'A-5500' },
      { id: 7, name: 'A-4500' },
    ];

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.units !== null) {
        order = userFactory.getOrderStorage(UserData.id);
        if (order) {
          ordersplit = _.split(order, ',');
          vm.sort.column = ordersplit[0];
          if (ordersplit[1] === 'true') {
            vm.sort.descending = true;
          } else {
            vm.sort.descending = false;
          }
        }

        vm.devicesArray = $state.params.units;
        vm.checkStatus = unitFactory.checkStatus;
        vm.getType = unitFactory.getType;
        vm.unitTimeFormatter = unitFactory.unitTimeFormatter;
        vm.selectUnit = selectUnit;
        // vm.openPrograms = openPrograms;
        // vm.openSectors = openSectors;
        // vm.editName = editName;
        // vm.cancelEditing = cancelEditing;
        // vm.updateName = updateName;
        vm.showUserUnits = showUserUnits;
        // vm.changeSorting = changeSorting;
        // checkIrrigation();
        if (UserData.master) {
          loadMasterUsers();
        }
      } else {
        //TODO - Verify if good solution, add devices array in the $rootScope
        if ($rootScope.devicesArray) {
          $state.params.units = $rootScope.devicesArray;
          activate();
        }
      }
    }

    function loadMasterUsers() {
      userFactory.masterUsers(UserData.id).then((result) => {
        vm.subusers = result.plain();
      });
    }

    // function editName(reg){
    //     editing_time = true;
    //     reg.editing = true;
    //     backup_name = reg.device.name;
    // }

    // function cancelEditing(reg){
    //     editing_time = false;
    //     reg.editing = false;
    //     reg.device.name = backup_name;
    // }

    // function updateName(reg){
    //     userFactory.updateName(reg.device.id,reg.device.name).then( result => {
    //         $rootScope.toast({
    //             type:"success",
    //             title:"Guardado correcto"
    //         });
    //         editing_time = false;
    //         reg.editing = false;
    //         $rootScope.$broadcast("reload",{});
    //     });
    // }

    // function checkIrrigation(){
    //initTable();
    /* if(vm.devicesArray.length < 21){
            
            _.forEach(vm.devicesArray, (obj) => {           
                switch(obj.device.type){
                    case 2://A4000
                    case 3://A2500
                    case 4://ABIT
                    case 6://A5500
                        progFactory.programs(obj.device.id,obj.device.type,true).then(function(data){
                            var activeList = progFactory.activePrograms();
                            var irrig = _.filter(activeList, (prog) => {return prog.irrigation;}); 
                            obj.device.irrigation = (irrig.length > 0);
                            obj.device.nprogr = activeList.length;
                            if(activeList.length > 0 || (activeList.length = 0 && obj.device.type !== 2)){
                                sectorFactory.sector(obj.device.id).then(function(data){
                                   var sectors = data.plain();
                                   var irrig2 = _.filter(sectors, (prog) => {return prog.xManual === 1 && prog.xStartStop === 1;}); 
                                   obj.device.irrigation = (irrig2.length > 0 || (irrig.length > 0)); 
                                   obj.device.nsect = sectors.length;
                                   initTable();
                                },function(error){
                                    obj.device.nsect = 0;
                                    initTable();
                                });
                            }else{
                                obj.device.nsect = 0;
                                initTable();
                            }                

                        });  
                        break;
                }

            });
        } */

    // }

    function selectUnit(obj) {
      if (
        $rootScope.env.FLAGS_ENABLED_REDIRECT_TO_AGRONIC &&
        obj.device.type !== vm.UNITS.A_2500 &&
        obj.device.type !== vm.UNITS.A_4000
      ) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('units.not_migrated.title'),
            content: $filter('translate')('units.not_migrated.text'),
            cancelText: $filter('translate')('units.not_migrated.actions.cancel'),
            confirmText: $filter('translate')('units.not_migrated.actions.accept'),
          })
          .then((isConfirmed) => {
            //TODO - There is a lack of information from AgronicWeb on how users who come from here want to log in
            if (isConfirmed) {
              window.location.href = 'https://www.agronicweb.com';
            }
          });
        return;
      }

      const cb = () => $scope.$emit('selectUnit', { message: 'reload', unit: obj });

      if (!obj.device.connect) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('units.not_available.title'),
            content: $filter('translate')('units.not_available.text'),
            cancelText: $filter('translate')('general.cancel'),
            confirmText: $filter('translate')('general.accept'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              validateOperation(obj, cb);
            }
          });

        return;
      }

      validateOperation(obj, cb);
    }

    // function openPrograms(obj){
    //     var callback = function(){
    //         switch(obj.device.type){
    //             case 2:
    //                 var callback2 = function(){ $state.go('programs',{unit:obj.device}); };
    //                 break;
    //             case 3:
    //             case 4:
    //                 var callback2 = function(){ $state.go('a25programs',{unit:obj.device}); };
    //                 break;
    //             case 6:
    //                 var callback2 = function(){ $state.go('a55programs',{unit:obj.device}); };
    //                 break;
    //         }

    //         $scope.$emit('selectUnit',{message:'reload',unit:obj, callback:callback2});
    //     }
    //     validateOperation(obj,callback);
    // }

    // function openSectors(obj){
    //     var callback = function(){
    //         var callback2 = function(){ $state.go('sectors',{unit:obj.device}); }
    //         $scope.$emit('selectUnit',{message:'reload',unit:obj, callback:callback2});
    //     }
    //     validateOperation(obj,callback);
    // }

    function validateOperation(obj, callback) {
      if (obj.device.connected) {
        callback(); // $scope.$emit('selectUnit',{message:'reload',unit:obj});
      } else {
        callback();
      }
    }

    /* function initTable(){
                
        vm.tableParams = new NgTableParams({
            page: 1,
            count: vm.devicesArray.length
        },{
            dataset : vm.devicesArray ,
            counts: []
        });
        
    } */

    function showUserUnits(user) {
      if (!user.remark) {
        user.remark = true;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.devicesArray, (d) => {
            return Number(k) === d.device.id;
          });
          if (tmp !== undefined) tmp.remark = true;
        });
      } else {
        user.remark = false;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.devicesArray, (d) => {
            return Number(k) === d.device.id;
          });
          if (tmp !== undefined) tmp.remark = false;
        });
      }
    }

    // function changeSorting(column){
    //     if (vm.sort.column == column) {
    //         vm.sort.descending = !vm.sort.descending;
    //     } else {
    //         vm.sort.column = column;
    //         vm.sort.descending = false;
    //     }
    //     userFactory.setOrderStorage(UserData.id, vm.sort.column, vm.sort.descending);
    // }

    $scope.$on('refresh', function (event, args) {
      if (args.units !== null && !editing_time) {
        vm.devicesArray = args.units;
        // initTable();
        // checkIrrigation();
      }
    });
  }
})();
