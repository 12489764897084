(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('modbusController', modbusController);

  modbusController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'commFactory',
    'ModBus',
  ];

  function modbusController($log, $rootScope, $scope, $state, Restangular, $mdDialog, UserData, commFactory, ModBus) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.modBus = ModBus;
        backup = angular.copy(vm.modBus);
        vm.portList = { 0: 'Ninguno', 1: 'COM 1', 2: 'COM 2' };
      } else {
        $state.go('units');
      }
    }

    function save() {
      vm.form.$setPristine();
      commFactory.updateModBusClient(vm.currentUnit.id, vm.modBus).then((result) => {
        vm.modBus = result;
        backup = angular.copy(vm.modBus);
      });
    }

    function cancel_edition() {
      vm.modBus = angular.copy(backup);
    }

    $scope.$on('refresh', function (event, args) {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
