import { UNITS } from "../../utils/units.enum";

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('wifiController', wifiController);

  wifiController.$inject = ['$scope', '$state', 'commFactory', 'Wifi'];

  function wifiController($scope, $state, commFactory, Wifi) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    activate();
      vm.UNITS = UNITS

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.wifi = Wifi;
        backup = angular.copy(vm.wifi);
        vm.portList = { 0: 'Ninguno', 1: 'COM 1', 2: 'COM 2' };
      } else {
        $state.go('units');
      }
    }

    function save() {
      commFactory.updateWifi(vm.currentUnit.id, vm.wifi).then(
        (result) => {
          vm.wifi = result;
          backup = angular.copy(vm.wifi);
            vm.form.$setPristine();
        },
        (error) => {}
      );
    }

    function cancel_edition() {
      vm.wifi = angular.copy(backup);
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
