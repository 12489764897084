import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('othersController', othersController);

  othersController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'fertilizerFactory'];

  function othersController($rootScope, $scope, $state, configFactory, fertilizerFactory) {
    var vm = this;
    var backup;
    var fertilizer_updated;
    vm.activeList;
    vm.getType;
    vm.save;
    vm.cancel;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
                vm.currentUnit = $state.params.unit;
        vm.others = angular.copy(vm.currentUnit.installer);
        vm.fertilizer = angular.copy(vm.currentUnit.fertilizer);
        backup = angular.copy(vm.others);
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.toogle = toogle;
        vm.timerSuffixText = `' "`;

        vm.instantFlow = { 0: 'No - 650,00 m3/h - l/h', 1: 'Si - 6500,0 m3/h - l/h' };
        vm.formatFlow = { 0: '00000 m3', 1: '0000.0 m3', 2: '000.00 m3' };
        if (vm.currentUnit.type === vm.UNITS.A_2500 && vm.currentUnit.inoptions && !vm.currentUnit.inoptions.plus) {
          vm.fertilizerUnits = { 0: 'hh:mm', 1: 'L' };
        } else if (vm.currentUnit.type === vm.UNITS.A_5500) {
          vm.fertilizerUnits = { 0: 'hh:mm', 1: 'mm\'ss"', 2: 'L', 3: 'L/Ha' };
        } else {
          vm.fertilizerUnits = { 0: 'hh:mm', 1: 'L', 2: 'L/Ha', 3: 'mm\'ss"' };
        }

        vm.drainUnits = { 0: 'ml', 1: 'L' };

        vm.selected = 1;
        vm.activeSect = _.range(1, 9);
        vm.activeProg = _.range(1, 5);
        vm.changedFert = changedFert;
        fertilizer_updated = false;
      } else {
        $state.go('units');
      }
    }

    function toogle(id) {
      vm.selected = id;
    }

    function changedFert() {
      fertilizer_updated = true;
    }

    function save() {
      resetFormState();
      vm.others.unittype = vm.currentUnit.type;
      vm.others.progtype = vm.currentUnit.type;
      vm.loading = true;

      configFactory.updateOthers(vm.currentUnit.id, vm.others).then(
        (result) => {
          if (result) {
            vm.others = result;
          }
          backup = angular.copy(vm.others);

          if (fertilizer_updated) {
            saveFertilizer();
          } else {
            vm.loading = false;
          }
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        },
        () => {}
      );
    }

    function saveFertilizer() {
      vm.fertilizer.unittype = vm.currentUnit.type;
      fertilizerFactory.update(vm.currentUnit.id, vm.fertilizer).then(
        (result) => {
          if (vm.currentUnit.type === vm.UNITS.A_2500) vm.fertilizer = result.fertilizer;
          else vm.fertilizer = result;
          vm.loading = false;
        },
        () => {}
      );
    }

    function resetFormState() {
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        return vm.forma.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_2500) {
        return vm.formb.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_BIT) {
        return vm.formc.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_5500) {
        return vm.formd.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_7000) {
        return vm.forme.$setPristine();
      }
    }

    function cancel_edition() {
      vm.others = angular.copy(backup);
      resetFormState();

      vm.events = angular.copy(backup);
    }
    $scope.$on('refresh', function (event, args) {});

    $scope.$watch(
      () => vm.formb,
      () => {
        $scope.$emit('formToUnitUpdated', vm.formb);
      }
    );

    $scope.$watch(
      () => vm.formc,
      () => {
        $scope.$emit('formToUnitUpdated', vm.formc);
      }
    );

    $scope.$watch(
      () => vm.forma,
      () => {
        $scope.$emit('formToUnitUpdated', vm.forma);
      }
    );

    $scope.$on('formFromUnitCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
