(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('configFactory', configFactory);

  configFactory.$inject = ['Restangular', '$q'];

  function configFactory(Restangular, $q) {
    var op = {
      updateEngines: updateEngines,
      updateAlarms: updateAlarms,
      get: get,
      getEngines: getEngines,
      getAlarms: getAlarms,
      getWaterMix: getWaterMix,
      updateFilters: updateFilters,
      updateWaterMix: updateWaterMix,
      updateFilters25: updateFilters25,
      updateConfig: updateConfig,
      updateFlow: updateFlow,
      events: events,
        updateEvents45:updateEvents45,
      updateEvents: updateEvents,
      updateOthers: updateOthers,
        getHeaders:getHeaders,
        updateConfigHeader:updateConfigHeader,
        getEnginesHeader: getEnginesHeader,
        getDiesel:getDiesel,
        getHeaderWaterMix: getHeaderWaterMix,
        saveHeaderWaterMix:saveHeaderWaterMix,
        saveHeaderDiesel:saveHeaderDiesel,
        getHeaderTanks:getHeaderTanks,
        updateGeneralHeadersTanks:updateGeneralHeadersTanks,
        updateGeneralHeadersFertilizers:updateGeneralHeadersFertilizers,
        getHeadersGeneral:getHeadersGeneral
    };

    return op;

    function updateFlow(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('flow')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateEngines(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('engines')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateWaterMix(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('watermix')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateAlarms(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('alarms')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function get(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getEngines(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('engines')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getWaterMix(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('watermix')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getAlarms(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('alarms')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateConfig(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateFilters(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('filters')
        .one('config')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateFilters25(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('filters25')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function events(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .one('events')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateEvents(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .one('events')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateEvents45(id, params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('config').one('events45').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }
    function updateOthers(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .one('others')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getHeadersGeneral(id, params = null){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers').get(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function getHeaders(id,header,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',header).get(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function updateConfigHeader(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('configHeader').customPOST(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function getEnginesHeader(id, headerId){
        var deferred = $q.defer();
        Restangular.one('units',id).one('enginesHeader',headerId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function getDiesel(id, headerId){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',headerId).one('diesel').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function getHeaderWaterMix(id,header){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',header).one('watermix').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function saveHeaderWaterMix(id,header,watermix){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',header).one('watermix').customPOST(watermix).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function saveHeaderDiesel(id,header,diesel){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',header).one('diesel').customPOST(diesel).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function getHeaderTanks(id,header){
        var deferred = $q.defer();
        Restangular.one('units',id).one('headers',header).one('tanks').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function updateGeneralHeadersTanks(id, depositos){
        var deferred = $q.defer();
        Restangular.one('units', id).one('headers').one('tanks').customPOST(depositos).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function updateGeneralHeadersFertilizers(id, fertilizers){
        var deferred = $q.defer();
        Restangular.one('units', id).one('headers').one('general-fertilizers').customPOST(fertilizers).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;

    }
  }
})();
