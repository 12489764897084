import {UNITS} from '../../utils/units.enum.js';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyGeneralController', historyGeneralController);

  historyGeneralController.$inject = ['$scope', '$state', 'UserData', 'historyFactory', '$filter'];

  function historyGeneralController($scope, $state, UserData, historyFactory, $filter) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.to = moment().format('DD-MM-YYYY');
        vm.maxDateValue = moment().format('DD-MM-YYYY');
        vm.updateRangeDates = updateRangeDates;

        vm.loadHistory = loadHistory;

        vm.exportXLS = exportXLS;
        loaded = false;

        vm.pagination = {
          page: 1,
          limit: 15,
        };

        vm.groupBy = [
            {text: '10 ' + $filter('translate')('history.minutes'), value: '0'},
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];

          vm.sensorListDTO = [
              { text: $filter('translate')('sensors.all'), value: '0'},
              { text: '1', value: '1'},
              { text: '2', value: '2'},
              { text: '3', value: '3'},
              { text: '4', value: '4'}
          ];

        initGrid();
        loadHistory();
      } else {
        $state.go('units');
      }
    }

    function initGrid(){
       vm.filter.groupBy = '2';
        vm.filter.sensor = '0';
        vm.gridOptions = {
            enableSorting: true,
            enableColumnMenus: false,
            useExternalPagination: true,
            paginationPageSizes: [15, 20, 25],
            paginationPageSize: 15,
            columnDefs:getColumnDefinition(vm.currentUnit.type),
            onRegisterApi: function(gridApi){
              vm.gridApi = gridApi;
            },
      };
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_7000:
          return [
            { field: 'checkDates7()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            {
              field: '3',
              displayName: 'pivot.irrigtime',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            { field: '4', displayName: 'history.filt', width: '**', headerCellFilter: 'translate' },
            {
              field: '7',
              displayName: 'history.temp',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:10:1',
            },
            { field: '5', displayName: 'history.rain', width: '**', headerCellFilter: 'translate' },
            { field: '6', displayName: 'history.radiation', width: '**', headerCellFilter: 'translate' }, //cellFilter:'parsetounit:10:2:""'
          ];
          break;
        case UNITS.A_5500:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            {
              field: '4',
              displayName: 'pivot.irrigtime',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            { field: '5', displayName: 'history.filt', width: '**', headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'filters.pump',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '7',
              displayName: 'filters.pump',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
          ];
          case UNITS.A_4500:
                return [
                  { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                  { field: '5' , displayName: 'pivot.irrigtime' ,  width:'**' , headerCellFilter: 'translate',cellFilter: 'parsetimehhmmss'  },
                  { field: '4' , displayName: 'sectors.header' ,  width:'**' , headerCellFilter: 'translate' },
                  { field: '6' , displayName: 'filters.pump1' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},
                  { field: '7' , displayName: 'filters.pump2' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},
                  { field: '8' , displayName: 'filters.pump3' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},
                  { field: '9' , displayName: 'filters.pump4' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},
                  { field: '10' , displayName: 'filters.pump5' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},
                  { field: '11' , displayName: 'filters.pump6' ,  width:'**' , headerCellFilter: 'translate' ,cellFilter: 'parsetimehhmmss'},

                ];
      }
    }

    function checkDates() {
      return this['2'] + ' - ' + this['3'];
    }
    function checkDates7() {
      return this['1'] + ' - ' + this['2'];
    }
    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean !== undefined) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.general(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
          row.checkDates7 = checkDates7;
        });
      });
    }
    function exportXLS() {
      var name = moment(vm.from).format('DD-MM-YYYY') + '_' + moment(vm.to).format('DD-MM-YYYY') + '.xlsx';
      historyFactory.export(vm.gridApi.grid, name);
    }
    $scope.$on('refresh', function (event, args) {});
  }
})();
