import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('conditionerController', conditionerController);

  conditionerController.$inject = [
    '$scope',
    '$state',
    'sensorsFactory',
    'manualFactory',
    '$confirm',
    'unitFactory',
    'NgTableParams',
    '$filter',
    '$q',
    'fertilizerFactory'
  ];

  function conditionerController(
    $scope,
    $state,
    sensorsFactory,
    manualFactory,
    $confirm,
    unitFactory,
    NgTableParams,
    $filter,
    $q,
    fertilizerFactory
  ) {
    var vm = this;
    var digitalSensors = null;
    var meterSensors = null;
    var analogicSensors = null;
    var logicSensors = null;
    var allConditioners = null;
    vm.activeList;
    vm.overlayEl;
    vm.activeList;
    vm.stopIrrig = true;
    vm.formConditioner;
    vm.UNITS = UNITS;
    var formatsAnalog = null;
    var unitsFormat = null;
    var headerFert = 1;
    var fertilizationHeader = null;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.showOverlay = showOverlay;
      vm.dismissModal = dismissModal;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.manualAction = manualAction;
        vm.manualAction2 = manualAction2;
        vm.save = save;
        vm.cancel = cancel;

        //promise
        $q.all([loadAnalogs().then(), loadDigitalSensors().then(), loadCounterSensors().then()]).then(() => {
          filterActiveConditioners();
        });

        if ($state.params.config) {
          $state.go('conditioner.config', { unit: vm.currentUnit });
        }
      } else {
        $state.go('units');
      }
    }

    function manualAction(event, cond) {
      event.detail.preventDefault();
      let temp;
      if (vm.currentUnit.type === UNITS.A_4500) {
        if (cond.xStatus == 2) {
          temp = 0;
        } else {
          temp = 1;
        }
      }
      var text;
      if (cond.switchOut) {
        text = $filter('translate')('cond.activate');
      } else {
        text = $filter('translate')('cond.shutDown');
      }

      $confirm({
        text: text,
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(() => {
        cond.switchOut = !cond.switchOut;

        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 21;
          params.parameter1 = Number(cond.pk.id);
          params.parameter2 = temp;
        } else {
          params.action = 11;
          params.parameter1 = Number(cond.pk.id) - 1;
          params.parameter2 = cond.switchOut ? 1 : 0;
        }

        manualFactory.genericManualAction(params.deviceId, params).then(() => {
          //TODO
        });
        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
      if (!confirm) {
        cond.switchOut = !cond.switchOut;
      }
    }

    function manualAction2(cond) {
      var text = $filter('translate')('manual.outDefinitiveStop');
      var action = 24;
      if (vm.currentUnit.type !== UNITS.A_2500) {
        action = 22;
      }
      $confirm({
        text: text,
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        confirm = true;
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = action;
        params.parameter1 = Number(cond.pk.id) - 1;
        params.parameter2 = cond.stopIrrig ? 1 : 0;

        manualFactory.genericManualAction(params.deviceId, params).then(() => {
          //TODO
        });
        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function loadAnalogs() {
      vm.analogs = [];
      var params = { page: 1, limit: 50 };
      vm.loading = true;
      vm.sensor = {};
      if (vm.currentUnit.type === UNITS.A_4500) {
        return sensorsFactory.analogsactive(vm.currentUnit.id).then(function(data) {
          vm.analogs = data.plain();
          filterActiveConditioners();
          //checkFormatsA4500(sensors);
          //vm.analogs = sensors;
        });
      } else {
        return sensorsFactory.analogsPage(vm.currentUnit.id, params).then(function(data) {
          var paginator = data;
          vm.analogs = paginator.content;
          angular.copy(paginator.content[0], vm.sensor);
          vm.totals = _.range(1, paginator.totalPages);
          vm.page = paginator.number + 1;
          vm.loading = false;
          filterActiveConditioners();
        });
      }
    }

    function loadDigitalSensors() {
      return sensorsFactory.digitalsPage(vm.currentUnit.id, { limit: 50, page: 1 }).then(function(data) {
        digitalSensors = data.content;
      });
    }

    function loadCounterSensors() {
      return sensorsFactory.meters(vm.currentUnit.id).then((data) => {
        meterSensors = data.plain().filter((meterSensor) => meterSensor !== 0);
      });
    }

    function filterActiveConditioners() {

      unitFactory.conditioners(vm.currentUnit.id).then(data => {
        allConditioners = data.plain();
        vm.conditioners = allConditioners.filter((current) => {
          switch (vm.currentUnit.type) {
            case UNITS.A_2500:
            case UNITS.A_BIT:
              return (current.from !== 4 && current.sensor !== "0");
            case UNITS.A_5500:
              return (current.sensor !== "0" || current.from > 4);
            case UNITS.A_4500:
              return (current.function !== 0 && current.from !== null);
            default:
              return null;
          }
        });
        checkState();
        initTable();
      });
    }

    function checkState() {
      vm.conditioners.forEach((conditioner) => {
        conditioner.stopIrrig = true;
        switch (conditioner.xStatus) {
          case 0:
            conditioner.status = 'stop';
            break;
          case 1:
            conditioner.status = 'active';
            break;
          case 2:
            conditioner.status = 'out';
            break;
          case 3:
            conditioner.status = 'error';
            break;
        }
        groupByType(conditioner);
        loadSensor(conditioner);
      });
    }

    function loadSensor(conditioner) {
      if (vm.currentUnit.type === UNITS.A_4500) {
        switch (Number(conditioner.from)) {
          case 0:
            loadDigitalSensors4500(conditioner);
            break;
          case 1:
            prepareFormat4500(conditioner);
            loadAnalogicSensors(conditioner);
            break;
          case 2:
            loadLogicSensors(conditioner);
            break;
          case 3:
          case 4:
          case 5:
            loadCounters(conditioner);
            break;
          case 6:
            getAnalogFert(conditioner, "ce");
            break;
          case 7:
            getAnalogFert(conditioner, "ph");
            break;
          case 8:
            getAnalogFert(conditioner, "ce");
            break;
          case 9:
            getAnalogFert(conditioner, "ph");
            break;
          case 10:
            getAnalogFert(conditioner, "ces");
            break;
          case 11:
            getAnalogFert(conditioner, "phs");
            break;
          case 13:
            getAnalogFert(conditioner, "cee");
            break;
        }
      } else {
        switch (Number(conditioner.from)) {
          case 0:
            addDigitalSensorToConditioner(conditioner);
            break;
          case 1:
            prepareAnalogSensorFormat(conditioner, vm.analogs);
            loadAnalogicSensors(conditioner);
            break;
          case 2:
          case 3:
            addCounterSensorToConditioner(conditioner);
            break;
          case 4:
            if (vm.currentUnit.type === UNITS.A_5500) {
              addCounterSensorToConditioner(conditioner);
            }
            break;
          case 5:
            loadLogicSensors(conditioner);
          case 7:
            loadCEPhRegulation(conditioner, true);
            break;
          case 6:
          case 8:
            loadCEPhRegulation(conditioner, false);
            break;
          case 9:
            loadCEPhSec(conditioner, true);
            break;
          case 10:
            loadCEPhSec(conditioner, false);
            break;
          case 12:
            loadCEPhIn(conditioner, true);
            break;
        }
      }
    }

    function getAnalogFert(conditioner, option) {
      headerFert = getHeaderFert(conditioner);
      if (fertilizationHeader === null) {
        fertilizerFactory.getOneFertilizationHeader(vm.currentUnit.id, headerFert).then(response => {
          fertilizationHeader = response.plain();
          switch (option) {
            case "ce":
              loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceRegulation);
              conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
              conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "mS";
              conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "mS";
              break;
            case "ph":
              loadAnalogicSensorsA4500(conditioner, fertilizationHeader.pHRegulation);
              conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
              conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "pH";
              conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "pH";
              break;
            case "ces":
              loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceSecurity);
              conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
              conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "mS";
              conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "mS";
              break;
            case "phs":
              loadAnalogicSensorsA4500(conditioner, fertilizationHeader.pHSecurity);
              conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
              conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "pH";
              conditioner.ref1Text = conditioner.lowMarginAlarm / 10 + "pH";
              break;
            case "cee":
              loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceInput);
              conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
              break;
          }
        });
      } else {
        loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceRegulation);
        switch (option) {
          case "ce":
            loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceRegulation);
            conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
            conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "mS";
            conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "mS";
            break;
          case "ph":
            loadAnalogicSensorsA4500(conditioner, fertilizationHeader.pHRegulation);
            conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
            conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "pH";
            conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "pH";
            break;
          case "ces":
            loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceSecurity);
            conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
            conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "mS";
            conditioner.ref2Text = conditioner.lowMarginAlarm / 10 + "mS";
            break;
          case "phs":
            loadAnalogicSensorsA4500(conditioner, fertilizationHeader.pHSecurity);
            conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
            conditioner.ref1Text = conditioner.highMarginAlarm / 10 + "pH";
            conditioner.ref1Text = conditioner.lowMarginAlarm / 10 + "pH";
            break;
          case "cee":
            loadAnalogicSensorsA4500(conditioner, fertilizationHeader.ceInput);
            conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
            break;
        }
      }
    }

    function getHeaderFert(conditioner) {
      if (conditioner.header1) {
        return 1;
      } else if (conditioner.header2) {
        return 2;
      } else if (conditioner.header3) {
        return 3;
      } else if (conditioner.header4) {
        return 4;
      } else return 1;
    }

    function loadLogicSensors(conditioner) {
      if (logicSensors === null) {
        sensorsFactory.logicSensorsPage(vm.currentUnit.id, { "active": false }).then(function(data) {
          logicSensors = data.plain();
          conditioner.sensor_obj = logicSensors.find((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
          conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
          conditioner.ref1Text = formatIntToText(conditioner.sensor_obj.format, conditioner.ref1);
          conditioner.ref2Text = formatIntToText(conditioner.sensor_obj.format, conditioner.ref2);
        });
      } else {
        conditioner.sensor_obj = logicSensors.find((sensor) => Number(sensor.pk.id) === Number(conditioner.sensor));
        conditioner.value = formatIntToText(conditioner.sensor_obj.format, "" + conditioner.sensor_obj.xValue);
        conditioner.ref1Text = formatIntToText(conditioner.sensor_obj.format, conditioner.ref1);
        conditioner.ref2Text = formatIntToText(conditioner.sensor_obj.format, conditioner.ref2);
      }
    }


    function prepareAnalogSensorFormat(conditioner, sensorList) {
      if (conditioner.sensor !== null) {
        var id = Number(conditioner.sensor) - 1;
        if (id >= 0) {
          let sensor = Object.assign({}, sensorList[id]); //{...vm.analogs[id]};
          let fid = sensor.formatId - 1;
          let format = null;
          if (fid >= 0) {
            format = vm.currentUnit.formats[fid];
          } else {
            format = { decimals: 0, integers: 0, units: '-' };
          }
          if (format !== null && sensor.xValue !== null) {
            let signed_negative = sensor.xValue < 0;
            sensor.xValue = sensor.xValue.toString();
            var first = sensor.xValue.slice(signed_negative ? 1 : 0, sensor.xValue.length - format.decimals);
            var last = sensor.xValue.slice(sensor.xValue.length - format.decimals, sensor.xValue.length);
            sensor.xValue = (signed_negative ? '-' : '') + first + '.' + last;
            sensor.value = Number(sensor.xValue) + ' ' + format.units;
            conditioner.sensor_obj = Object.assign({}, sensor); //{...sensor};
            conditioner.format = Object.assign({}, format); //{...format};
          }
        }
      }
    }

    function addDigitalSensorToConditioner(conditioner) {
      conditioner.sensor_obj = digitalSensors.filter((sensor) => {
        return Number(sensor.pk.id) === Number(conditioner.sensor);
      })[0];
    }

    function addCounterSensorToConditioner(conditioner) {
      var sensor = meterSensors.filter((sensor) => {
        return Number(sensor.pk.id) === Number(conditioner.sensor);
      });

      prepareCountersA2(sensor);
      conditioner.sensor_obj = Object.assign({}, sensor[0]); // {...sensor[0]};
    }

    function loadCEPhRegulation(conditioner, ce) {
      let fertilizer = vm.currentUnit.fertilizer;
      if (fertilizer !== undefined) {
        if (ce) {
          conditioner.reference = fertilizer.pidRegulation[0].xReference;
          conditioner.value = fertilizer.pidRegulation[0].xValue;
          conditioner.sensor = fertilizer.checkCE;
        } else {
          conditioner.reference = fertilizer.pidRegulation[1].xReference;
          conditioner.value = fertilizer.pidRegulation[1].xValue;
          conditioner.sensor = fertilizer.checkPH;
        }
      }
      prepareAnalogSensorFormat(conditioner, vm.analogs);
    }

    function loadCEPhSec(conditioner, ce) {
      let fertilizer = vm.currentUnit.fertilizer;
      if (fertilizer !== undefined) {
        if (ce) {
          conditioner.reference = fertilizer.pidRegulation[0].xReference;
          conditioner.value = fertilizer.pidRegulation[0].xValue;
          conditioner.sensor = fertilizer.securityCE;
        } else {
          conditioner.reference = fertilizer.pidRegulation[1].xReference;
          conditioner.value = fertilizer.pidRegulation[1].xValue;
          conditioner.sensor = fertilizer.securityPH;
        }
      }
      prepareAnalogSensorFormat(conditioner, vm.analogs);
    }

    function loadCEPhIn(conditioner, ce) {
      let fertilizer = vm.currentUnit.fertilizer;
      if (fertilizer !== undefined) {
        if (ce) {
          conditioner.reference = fertilizer.pidRegulation[0].xReference;
          conditioner.value = fertilizer.pidRegulation[0].xValue;
          conditioner.sensor = fertilizer.inputCE;
        } else {
          conditioner.reference = fertilizer.pidRegulation[1].xReference;
          conditioner.value = fertilizer.pidRegulation[1].xValue;
          conditioner.sensor = fertilizer.inputPH;
        }
      }
      prepareFormat(conditioner);
    }

    function loadDigitalSensors4500(conditioner) {
      if (digitalSensors === null) {
        sensorsFactory.digitalsPage(vm.currentUnit.id, { "limit": 50, "page": 1 }).then(function(data) {
          digitalSensors = data.content;
          conditioner.sensor_obj = digitalSensors.find((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
        });
      } else {
        conditioner.sensor_obj = digitalSensors.find((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
      }
    }

    function loadAnalogicSensors(conditioner) {
      conditioner.sensor_obj = vm.analogs.find((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
    }

    function loadAnalogicSensorsA4500(conditioner, sensorId) {
      conditioner.sensor_obj = vm.analogs.find((sensor) => (Number(sensor.pk.id) === Number(sensorId)));
    }

    function loadCounters(conditioner) {
      if (meterSensors === null) {
        sensorsFactory.meters(vm.currentUnit.id).then(data => {
          meterSensors = data.plain().filter((o) => o.input !== 0);
          var sensor = meterSensors.filter((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
          prepareCountersA2(sensor);
          conditioner.sensor_obj = Object.assign({}, sensor[0]);//
          if (vm.currentUnit.type === UNITS.A_4500) {
            conditioner.refSMeterText = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.refSMeter);
            conditioner.ref1Text = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.ref1);
            conditioner.ref2Text = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.ref2);
          }
        });
      } else {
        var sensor = meterSensors.filter((sensor) => (Number(sensor.pk.id) === Number(conditioner.sensor)));
        prepareCountersA2(sensor);
        conditioner.sensor_obj = Object.assign({}, sensor[0]);// {...sensor[0]};
        if (vm.currentUnit.type === UNITS.A_4500) {
          conditioner.refSMeterText = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.refSMeter);
          conditioner.ref1Text = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.ref1);
          conditioner.ref2Text = formatFlowToText(conditioner.sensor_obj.flowFormat, conditioner.ref2);
        }
      }
    }

    function prepareFormat(conditioner) {
      if (conditioner.sensor !== null) {
        var id = Number(conditioner.sensor) - 1;
        if (id >= 0) {
          let sensor = Object.assign({}, vm.analogs[id]);//{...vm.analogs[id]};
          let fid = sensor.formatId - 1;
          let format = null;
          if (fid >= 0) {
            if (vm.currentUnit.type === UNITS.A_4500) format = formatsAnalog[fid];
            else format = vm.currentUnit.formats[fid];
          } else {
            format = { decimals: 0, integers: 0, units: "-" };
          }
          if (format !== null) {
            let signed_negative = sensor.xValue < 0;
            if (sensor.xValue != 0) {
              sensor.xValue = sensor.xValue.toString();
              var first = sensor.xValue.slice(signed_negative ? 1 : 0, (sensor.xValue.length - format.decimals));
              var last = sensor.xValue.slice((sensor.xValue.length - format.decimals), sensor.xValue.length);
              sensor.xValue = (signed_negative ? "-" : "") + first + "." + last;
            }
            if (vm.currentUnit.type === UNITS.A_4500) sensor.value = Number(sensor.xValue) + " " + format.units[0].symbol;
            else sensor.value = Number(sensor.xValue) + " " + format.units;
            conditioner.sensor_obj = Object.assign({}, sensor);//{...sensor};
            conditioner.format = Object.assign({}, format);//{...format};
          }
        }
      }

    }

    function prepareFormat4500(conditioner) {
      if (conditioner.sensor !== null) {
        if (conditioner.sensor >= 0) {
          let s = _.filter(vm.analogs, o => {
            return o.pk.id == conditioner.sensor;
          });
          var sensor = s[0];
          //let sensor = Object.assign({},vm.analogs[id]);
        }
        var format = sensor.format;
        if (format !== undefined) {

          //var value = ''+conditioner.xValue;
          sensor.value = formatIntToText(format, "" + conditioner.xValue);
          conditioner.ref1Text = formatIntToText(format, "" + conditioner.ref1);
          conditioner.ref2Text = formatIntToText(format, "" + conditioner.ref2);
          conditioner.refSAnalogText = formatIntToText(format, "" + conditioner.refSAnalog);

          conditioner.sensor_obj = Object.assign({}, sensor);//{...sensor};
          conditioner.format = Object.assign({}, format);//{...format};
        }
      }

    }

    function formatIntToText(format, value) {

      if (format.decimals > 0) {
        if (value < 0) {
          var sign = value.slice(0, 1);
          value = value.slice(1);
        }

        if (format.decimals > value.length) {
          var tlength = format.decimals + value.length;
          value = _.padStart(value, tlength, "0");
        }

        var first = value.slice(0, (value.length - format.decimals));
        var last = value.slice((value.length - format.decimals), value.length);

        if (sign == "-") value = sign + "" + first + "." + last;
        else value = first + "." + last;
      }

      if (format.idMagnitud < 26) {
        value = _.toNumber(value) + " " + format.units45.symbol;
      } else {
        value = _.toNumber(value) + " " + format.customUnits45.symbol;
      }

      return value;
    }

    function prepareCountersA2(counters) {
      if (vm.currentUnit.type !== UNITS.A_4500) {
        var formats = {
          0: "m3/h", 1: "L/h", 2: "L/s"
        };
        _.forEach(counters, (c) => {
          var decimals = 2;
          c.xFlow = c.xFlow.toString();
          var first = c.xFlow.slice(0, c.xFlow.length - decimals);
          var last = c.xFlow.slice(c.xFlow.length - decimals, c.xFlow.length);
          c.xFlow = first + "." + last;
          c.value = Number(c.xFlow) + formats[c.flowFormat];
          c.units = formats[c.flowFormat];
          switch (c.usedBy) {
            case 0:
              if (Number(c.xFlow) !== 0) {
                c.active = true;
                c.error = true;
              }
              break;
            case 1:
            case 2:
              c.active = true;
              break;
          }
        });
      } else {
        _.forEach(counters, c => {
          c.value = formatFlowToText(c.flowFormat, c.xInstantFlow);
          switch (c.xusedBy) {
            case "0":
              if (Number(c.xInstantFlow) !== 0) {
                c.active = true;
                c.error = true;
              }
              break;
            case "1":
            case "2":
              c.active = true;
              break;
          }
        });
      }
    }

    function formatFlowToText(flowFormat, flow) {
      let value;
      switch (flowFormat) {
        case 0:
          value = flow + " m3/h";
          break;
        case 1:
          value = (flow / 10) + " m3/h";
          break;
        case 2:
          value = (flow / 100) + " m3/h";
          break;
        case 3:
          value = flow + " L/h";
          break;
        default:
          break;
      }
      return value;
    }

    function groupByType(conditioner) {
      if (conditioner && conditioner.function !== undefined) {
        switch (conditioner.function) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 11:
            conditioner.group = 1;
            break;
          case 6:
          case 7:
            conditioner.group = 2;
            break;
          case 8:
            conditioner.group = 3;
            break;
        }
      }
    }

    function initTable() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: vm.conditioners.length
        },
        {
          dataset: vm.conditioners,
          counts: []
        }
      );
    }

    function showOverlay(state) {
      $state.go(state, { unit: vm.currentUnit, conditioners: allConditioners });
      vm.overlayEl = document.querySelector("vegga-overlay");
      vm.overlayEl.show();
    }

    function dismissModal() {
      $state.go('conditioner.config', { unit: vm.currentUnit });
    }

    function save() {
      if (vm.formConditioner && vm.formConditioner.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('conditionerFormSave', true);
        });
      }
    }

    function cancel($event) {
      if (vm.formConditioner && vm.formConditioner.$dirty) {
        typeof $event !== "undefined" ? $event.preventDefault() : null;
        $confirm({ text: $filter("translate")("programs.edit.cancelq") }).then(() => {
          $scope.$broadcast("formCancel"); //Emetem cancelació de canvis
          vm.formConditioner.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector("vegga-overlay").dismiss();
          $state.go("conditioner", { unit: vm.currentUnit });
        });
      } else {
        document.querySelector("vegga-overlay").dismiss();
        $state.go("conditioner", { unit: vm.currentUnit });
      }
    }

    $scope.$on("refresh", function(event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        $scope.$emit("refreshed", { message: "refreshed" });
        loadAnalogs();
      }
    });

    $scope.$on("formUpdated", (e, args) => {
      vm.formConditioner = args;
    });
  }
})();
