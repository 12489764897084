(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('nebulizationFactory', nebulizationFactory);

  nebulizationFactory.$inject = ['Restangular', '$q'];

  function nebulizationFactory(Restangular, $q) {
    var op = {
        getAll:getAll,
        update:update,
        update45:update45
    };

    return op;

    function getAll(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('nebulization')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('nebulization')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function update45(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('nebulization45').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }
  }
})();
