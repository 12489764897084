import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { debounce, fromEvent, map, Observable, tap, timer } from "rxjs";
// @ts-ignore
import { getAppLanguage, getToken } from "@vegga/front-utils";
import { Organization } from "@vegga-types/organization";
// @ts-ignore
import { AuthStore } from "@vegga/front-store";
import { environment } from "../environments/environment";
import Organizations from "./components/organizations/organizations.component";
import i18next from "./i18n/i18n.config";
import "./sidebar.component.scss";

import { BrowserRouter as Router } from "react-router-dom";
import { MenuItem } from "./components/menu-item";
import {
  menuOptions,
  modulesMenuOptions as modulesMenuOptionsList,
} from "./menu.options";

import { AppProps } from "single-spa";
import { MultiOptionsMenuItem } from "./components/multi-options-menu-item";
import {
  IMenuOptions,
  IModulesMenuOptions,
  IOption,
} from "./interfaces/menuOptions";
import { canActivateLink } from "./utils";
import {
  irrigationManagerFeatures,
  irrigationManagerFlags,
  irrigationManagerPermissions,
} from "./utils/irrigation-manager.statics";
import { BreakpointsEnum } from "./enums/common.enum";

const VEGGA_LOGO_HEIGHT = 56;
const VEGGA_LOGO_HEIGHT_MOBILE = 42;

const Sidebar: FunctionComponent<
  AppProps & { sidebarState$: Observable<boolean> }
> = ({ sidebarState$ }) => {
  const authStore = AuthStore.getInstance();

  const locale = getAppLanguage();
  i18next.changeLanguage(locale);

  const [token, setToken] = useState("");
  const [modulesMenuOptions, setModulesMenuOptions] = useState(
    modulesMenuOptionsList
  );

  const [isMenuShownOnMobile, setIsMenuShownOnMobile] = useState(false);

  const [sidebarContentEl, setSidebarContentEl] = useState<HTMLDivElement>();
  const sidebarContentElRef = useRef<HTMLDivElement>(null);

  const [sidebarContainerEl, setSidebarContainerEl] =
    useState<HTMLDivElement>();
  const sidebarContainerElRef = useRef<HTMLDivElement>(null);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [lastOpenedModule, setLastOpenedModule] = useState("");

  const [isSidebarFolded, setIsSidebarFolded] = useState(true);

  const [hoveredItem, setHoveredItem] = useState("");

  const ecommerceLanguage = useMemo(
    () => (locale === "es" || locale === "en" ? locale : "en"),
    [locale]
  );
  const dispatchEvent = (evName: string) => {
    const event = new CustomEvent(evName);
    window.dispatchEvent(event);
  };

  useEffect(() => {
    fromEvent(window, "resize")
      .pipe(
        // disable css transition on container when resizing
        tap(() =>
          sidebarContainerElRef.current.style.setProperty(
            "--vegga-sidebar-container-transition",
            "none"
          )
        ),
        debounce(() => timer(200))
      )
      .subscribe(() => {
        updateSidebarHeight();
      });

    getAuthToken();
    getOrganization();
  }, []);

  useEffect(() => {
    if (token && locale) {
      getIrrigationManagerMenu(locale, token);
      // getIrrigationManagerEAMenu(locale, token);
    }
  }, [token, locale]);

  useEffect(() => {
    if (!sidebarContentEl) {
      return;
    }
    sidebarContentElRef.current = sidebarContentEl;

    if (!sidebarState$) {
      setIsSidebarFolded(false);
      return;
    }

    sidebarState$.subscribe((isFolded: boolean) => {
      // enable css transition only when sidebar state changes
      setIsSidebarFolded(() => isFolded);

      if (isFolded) {
        // sidebarContentElRef.current.style.height = "unset";
      }

      setTimeout(() => {
        updateSidebarHeight();
      }, 100);
    });

    fromEvent(sidebarContentEl, "mouseenter")
      .pipe(
        tap(() =>
          sidebarContainerElRef.current.style.setProperty(
            "--vegga-sidebar-container-transition",
            "0.2s ease-in, color 0.2s ease-in"
          )
        ),
        map(() => "sidebar:mouseenter")
      )
      .subscribe(dispatchEvent);
    fromEvent(sidebarContentEl, "mouseleave")
      .pipe(map(() => "sidebar:mouseleave"))
      .subscribe(dispatchEvent);
  }, [sidebarContentEl]);

  useEffect(() => {
    setTimeout(() => {
      updateSidebarHeight();
    }, 200);
  }, [lastOpenedModule]);

  const getAuthToken = async () => {
    const token = await getToken();
    setToken(token);
  };

  const getOrganization = () => {
    authStore.get$().subscribe((org: Organization) => setOrganization(org));
  };

  //TODO - Remove after finish the migration Irrigation Manager software
  const getIrrigationManagerMenu = async (locale: string, token: string) => {
    const data = JSON.stringify({
      query: `
      mutation {
        getImMenu(vgToken: "${token}", lang: "${locale}") {
          menu {
              option
              url
          }
        }
      }`,
    });
    try {
      const response = await fetch(environment.URL.API_IRRIGATION_MANAGER, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { data: optionsResponse } = await response.json();
      const options = optionsResponse.getImMenu.menu.map((option: IOption) => {
        const feature = irrigationManagerFeatures.find(
          (feature) =>
            feature.url === option.url || feature.eaUrl === option.url
        );
        const flags = irrigationManagerFlags.find(
          (flag) => flag.url === option.url || flag.eaUrl === option.url
        );
        const permissions = irrigationManagerPermissions.find(
          (permission) =>
            permission.url === option.url || permission.eaUrl === option.url
        );

        return {
          ...option,
          url: `${environment.BASE_URL_IRRIGATION_MANAGER}${option.url}`,
          feature: feature.value,
          flags: flags.value,
          permissions: permissions.value,
        };
      });

      if (data) {
        setModulesMenuOptions((modulesMenuOptionsList) => {
          return {
            ...modulesMenuOptionsList,
            ["irrigationManager"]: {
              ...modulesMenuOptionsList.irrigationManager,
              submodules: [...options],
            },
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMenuInMobile = (isFiredByToggler = false) => {
    setIsMenuShownOnMobile(() =>
      isFiredByToggler ? !isMenuShownOnMobile : false
    );

    rotateHamburguerIcon();
  };

  const rotateHamburguerIcon = () => {
    const hamburguerIconClasslist = sidebarContainerElRef.current
      .querySelector(".vegga-sidebar__hamburger")
      .querySelector("vegga-icon").classList;
    if (isMenuShownOnMobile) {
      hamburguerIconClasslist.remove("vegga-sidebar__hamburger--rotated");
    } else {
      hamburguerIconClasslist.add("vegga-sidebar__hamburger--rotated");
    }
  };

  const onClickMenuItem = () => {
    setIsMenuShownOnMobile(false);
    rotateHamburguerIcon();
    dispatchEvent("sidebar:clicked-menu-item");
  };

  const getActivableMenuItems = (options: IMenuOptions | IModulesMenuOptions) =>
    Object.values(options).filter(
      ({ feature, flags, permissions, moduleName }) =>
        canActivateLink(feature, flags, permissions) ||
        moduleName === "irrigation-manager"
    );

  /**
   * Updates sidebar height by calculating total items height against window height
   */
  const updateSidebarHeight = () => {
    sidebarContentElRef.current.style.height =
      window.innerWidth < BreakpointsEnum.LG
        ? "calc(100vh - 82px)"
        : `${
            window.innerHeight -
            (window.innerWidth < BreakpointsEnum.XL
              ? VEGGA_LOGO_HEIGHT_MOBILE
              : VEGGA_LOGO_HEIGHT)
          }px`;
  };

  const renderMenuOptions = (options: IMenuOptions | IModulesMenuOptions) => (
    <div className="vegga-sidebar__menu-items">
      {getActivableMenuItems(options).map(
        (
          {
            title,
            icon,
            iconKind,
            context,
            moduleName,
            submodules,
            permissions,
            feature,
            flags,
            url,
            external,
          },
          index
        ) => {
          return submodules?.length ? (
            <MultiOptionsMenuItem
              key={index}
              title={title}
              icon={icon}
              iconKind={iconKind}
              context={context}
              moduleName={moduleName}
              options={submodules}
              lastOpenedModule={lastOpenedModule}
              setLastOpenedModule={setLastOpenedModule}
              permissions={permissions}
              feature={feature}
              flags={flags}
              toggleMenuInMobile={onClickMenuItem}
              isSidebarFolded={isSidebarFolded}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              token=""
              language=""
            />
          ) : (
            <MenuItem
              key={index}
              label={title}
              icon={icon}
              url={url}
              external={external}
              permissions={permissions}
              feature={feature}
              flags={flags}
              token={token}
              language={ecommerceLanguage}
              toggleMenuInMobile={onClickMenuItem}
              isSidebarFolded={isSidebarFolded}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
            />
          );
        }
      )}
    </div>
  );
  return (
    <Router>
      <div className="vegga-sidebar__wrapper">
        <div
          ref={(el) => (sidebarContainerElRef.current = el)}
          className={
            isSidebarFolded
              ? "vegga-sidebar__container vegga-sidebar__container--folded"
              : "vegga-sidebar__container"
          }
        >
          <div
            className={`vegga-sidebar__hamburger`}
            onClick={() => toggleMenuInMobile(true)}
          >
            <vegga-icon icon="arrow-down"></vegga-icon>
          </div>
          <div
            className={
              isSidebarFolded
                ? "vegga-sidebar__brand vegga-sidebar__brand--folded"
                : "vegga-sidebar__brand"
            }
          >
            <vegga-brand
              kind={
                !isSidebarFolded && window.innerWidth > BreakpointsEnum.MD
                  ? "logo"
                  : "symbol"
              }
            />
          </div>
          <div
            ref={(el) => setSidebarContentEl(el)}
            className={`vegga-sidebar__content ${
              isMenuShownOnMobile ? "vegga-sidebar__content--show" : ""
            }`}
          >
            {authStore.getNumberOfOrganizations() > 1 && <Organizations />}
            {renderMenuOptions(menuOptions)}

            <div className="vegga-sidebar__modules">
              <div className="vegga-sidebar__modules-title">
                {!isSidebarFolded && <span>{i18next.t("modules.title")}</span>}
                <vegga-icon icon="dashboard"></vegga-icon>
              </div>
              {renderMenuOptions(modulesMenuOptions)}
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Sidebar;
