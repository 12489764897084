(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('draintraysController', draintraysController);

  draintraysController.$inject = ['$rootScope', '$scope', '$state', 'trays7Factory'];

  function draintraysController($rootScope, $scope, $state, trays7Factory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        loadTrays();
        vm.selected = 0;
      } else {
        $state.go('units');
      }
    }

    function loadTrays() {
      vm.trays = [];
      return trays7Factory.getTrays($state.params.unit.id).then(function (data) {
        vm.trays = data;
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      trays7Factory.updateAll(vm.trays, vm.currentUnit.id).then((response) => {
        vm.trays = response;
      });
    }

    function cancel_edition() {
      $state.go('outputs.watermix', { unit: vm.currentUnit }, { reload: 'outputs.watermix' });
    }

    $scope.$on('refresh', function (event, args) {});
  }
})();
