import {
  A4500AgrobeeLInfo,
  A4500AgrobeeLModule,
  A4500CommunicationsApi,
  ExpansionConfigurationModuleRequest,
  ExpansionConfigurationModuleResponse,
  ExpansionModuleItemRequest,
  ExpansionModuleItemResponse,
  GetProbeCatalogTargetModuleEnum,
  ModulesApi,
  ProbeCatalogApi,
  ProbeCatalogItem,
  Sdi12ModuleItemRequest,
  Sdi12ModuleItemResponse,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { ID, updateCollectionFn } from '../common.facade';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
import { A4500CommunicationModulesTabsEnum } from './entities/communications.entities';

@Injectable('communicationsFacade')
export class CommunicationsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;

  private modulesConfigResponse = new VeggaResponse<ExpansionConfigurationModuleResponse | A4500AgrobeeLInfo>();
  private modulesResponse = new VeggaResponse<ExpansionModuleItemResponse[] | Sdi12ModuleItemResponse[]>();
  private moduleByIdResponse = new VeggaResponse<ExpansionModuleItemResponse | A4500AgrobeeLModule | Sdi12ModuleItemResponse>();
  private modulesApi: ModulesApi;
  private loader = new VeggaLoader();
  private a4500CommunicationsApi: A4500CommunicationsApi;
  private probeCatalogResponse = new VeggaResponse<ProbeCatalogItem[]>();
  private probeCatalogApi: ProbeCatalogApi;

  constructor() {
    this.modulesApi = new ModulesApi();
    this.probeCatalogApi = new ProbeCatalogApi();
    this.a4500CommunicationsApi = new A4500CommunicationsApi();
    this.modulesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.a4500CommunicationsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.probeCatalogApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get modulesConfig$(): Observable<ExpansionConfigurationModuleResponse | A4500AgrobeeLInfo> {
    return this.modulesConfigResponse.value$;
  }

  get modules$(): Observable<ExpansionModuleItemResponse[] | Sdi12ModuleItemResponse[]> {
    return this.modulesResponse.value$;
  }

  get modules(): ExpansionModuleItemResponse[] | Sdi12ModuleItemResponse[] {
    return this.modulesResponse.value;
  }

  get moduleById$(): Observable<ExpansionModuleItemResponse | A4500AgrobeeLModule | Sdi12ModuleItemResponse> {
    return this.moduleByIdResponse.value$;
  }

  get probeCatalog$(): Observable<ProbeCatalogItem[]> {
    return this.probeCatalogResponse.value$;
  }

  get probeCatalog(): ProbeCatalogItem[] {
    return this.probeCatalogResponse.value;
  }

  loadModulesConfig(unitId: number, module: string) {
    let req$: Observable<ExpansionConfigurationModuleResponse>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionConfig({ unitId });
        break;
      case A4500CommunicationModulesTabsEnum.SDI_12:
        req$ = this.modulesApi.getExpansionConfig({ unitId });
        break;
      default:
        break;
    }

    const subscription = req$.subscribe({
      next: res => {
        this.modulesConfigResponse.set(res);
      },
      error: err => {
        this.modulesConfigResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
    this.globalLoader.waitFor(subscription);
  }

  loadModules(unitId: number, module: string) {
    let req$: Observable<ExpansionModuleItemResponse[] | Sdi12ModuleItemResponse[]>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionModules({ unitId });
        break;

      case A4500CommunicationModulesTabsEnum.SDI_12:
        req$ = this.modulesApi.getSdi12Modules({ unitId });
        break;
      default:
        break;
    }

    const subscription = req$.subscribe({
      next: res => {
        this.modulesResponse.set(res);
      },
      error: err => {
        this.modulesResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
    this.globalLoader.waitFor(subscription);
  }

  loadModulesByModuleId(unitId: number, moduleId: number, module: string) {
    let req$: Observable<ExpansionModuleItemResponse | A4500AgrobeeLModule | Sdi12ModuleItemResponse>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionModule({ unitId, moduleId });
        break;
      case A4500CommunicationModulesTabsEnum.SDI_12:
        req$ = this.modulesApi.getSdi12Module({ unitId, moduleId });
        break;
    }
    const subscription = req$.subscribe({
      next: res => {
        this.moduleByIdResponse.set(res);
      },
      error: err => {
        this.moduleByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  updateExpansionConfig(unitId: number, expansionConfigurationModuleRequest: ExpansionConfigurationModuleRequest): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi.updateExpansionConfig({ unitId, expansionConfigurationModuleRequest }).pipe(
      tap((config: ExpansionModuleItemResponse) => {
        this.modulesConfigResponse.set(config);
      }),
    );
  }

  updateExpansionModuleItem(unitId: number, moduleId: number, expansionModuleItemRequest: ExpansionModuleItemRequest): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi.updateExpansionModuleItem({ unitId, moduleId, expansionModuleItemRequest }).pipe(updateCollectionFn(this.modulesResponse, moduleId, ID));
  }
  updateSdi12ModuleItem(unitId: number, moduleId: number, sdi12ModuleItemRequest: Sdi12ModuleItemRequest): Observable<Sdi12ModuleItemResponse> {
    return this.modulesApi.updateSdi12ModuleItem({ unitId, moduleId, sdi12ModuleItemRequest }).pipe(updateCollectionFn(this.modulesResponse, moduleId, ID));
  }

  addSdi12ModuleInDevice({ deviceId, moduleId, configuration, probeId }) {
    return this.a4500CommunicationsApi.addSdi12ModuleInDevice({
      id: deviceId,
      moduleId,
      a4500Sdi12ModuleConfigurationRequest: {
        configuration,
        probeId,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadProbeCatalog(targetModule?: GetProbeCatalogTargetModuleEnum) {
    const req$ = this.probeCatalogApi.getProbeCatalog({ targetModule: GetProbeCatalogTargetModuleEnum.AGROBEEL_SDI12 });
    const subscription = req$.subscribe({
      next: res => {
        this.probeCatalogResponse.set(res);
      },
      error: err => {
        this.probeCatalogResponse.setError(err, {});
      },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  reset(): void {
    this.moduleByIdResponse.clear();
  }

  clear(): void {
    this.modulesResponse.clear();
  }
}
