import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('programsConfigControllerA25', programsConfigControllerA25);

  programsConfigControllerA25.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    'resFactory',
    '$rootScope',
    'unitFactory',
    'veggaSelectFactory',
  ];

  function programsConfigControllerA25(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    resFactory,
    $rootScope,
    unitFactory,
    veggaSelectFactory
  ) {
    var vm = this;
    vm.activeList;
    vm.formats = [];
    vm.conditioners;
    vm.formProgram;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.program !== null) {
        vm.UNITS = UNITS;
        vm.program = $state.params.program;
        vm.updateFormat = updateFormat;
        vm.formatedTime = getTimeFromMins(vm.program.securityTime);
        vm.currentUnit = $scope.$parent.vm.currentUnit;
        vm.cancel = cancel_edition;
        vm.save = save;
        if (vm.currentUnit.inoptions.plus || vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.unitValues = ['hh:mm', 'm3', 'm3/Ha', '\' "', 'hh:mm/Ha'];
        } else {
          vm.unitValues = ['hh:mm', 'm3'];
        }

        if (vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.fertitlizers = _.range(1, 11);
          vm.currentUnit.installer.decimalsIrrigation = 0;
        }

        loadConditioners();
        backup();
        vm.priorValues = _.range(0, 100);
        vm.priorValuesDTO = veggaSelectFactory.formatterVeggaSelect(vm.priorValues);
      } else {
        $state.go('units');
      }
    }
    function loadConditioners() {
      vm.loading = true;
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.loading = false;
        vm.currentUnit.conditioners = [];

        const conditioners = data.plain();
        if (conditioners) {
          Object.keys(conditioners).forEach((key) => {
            vm.currentUnit.conditioners.push(conditioners[key]);
          });
        }
      });
    }
    function updateFormat(id, index, model1, model2) {
      model1 = 0;
      model2 = 0;
      if (id !== null && id > 0) {
        id = parseInt(id);
        if (id === 41) {
          vm.program.conditioners[index].value2 = 0;
          vm.formats[index] = {
            units: 'L/m2',
          };
        } else {
          vm.formats[index] = vm.currentUnit.formats[vm.currentUnit.sanalog[id - 1].formatId - 1];

          if (
            vm.program.conditioners[index].type === 3 ||
            vm.program.conditioners[index].type === 7 ||
            vm.program.conditioners[index].type === 8 ||
            vm.program.conditioners[index].type === 9
          ) {
            if (vm.currentUnit.sanalog[id - 1].formatId === 1) {
              vm.formats[index].units = 'ºCh';
            } else if (vm.currentUnit.sanalog[id - 1].formatId === 2) {
              vm.formats[index].units = 'Whm2';
            }
          }
        }
      } else {
        vm.formats[index] = null;
      }
    }

    function getTimeFromMins(mins) {
      // do not include the first validation check if you want, for example,
      // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.');
      }
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return moment(new Date()).hours(h).minutes(m);
    }
    function backup() {
      vm.program_backup = {};
      angular.copy(vm.program, vm.program_backup);
      vm.program.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.program);
    }

    function save(_ev) {
      vm.formProgram.$setPristine();
      vm.program.progtype = vm.currentUnit.type;
      //        vm.program.securityTime = vm.formatedTime
      progFactory.update(vm.program).then((response) => {
        $rootScope.$broadcast('updateEdit25', { message: 'refreshing' });
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.formProgram,
      () => {
        $scope.$emit('formUpdated', vm.formProgram);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
