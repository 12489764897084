import { UNITS } from './units.enum';
import { LOCALE } from './locale.enum';

const menuData = {
  [UNITS.A_4000]: require('../../resources/menu.2.json'),
  [UNITS.A_2500]: require('../../resources/menu.3.json'),
  [UNITS.A_BIT]: require('../../resources/menu.4.json'),
  [UNITS.A_7000]: require('../../resources/menu.5.json'),
  [UNITS.A_5500]: require('../../resources/menu.6.json'),
  [UNITS.A_4500]: require('../../resources/menu.7.json'),
};

const registerData = {
  [UNITS.A_4000]: {
    [LOCALE.ES]: require('../../resources/register/register.2.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.2.en.json'),
    [LOCALE.FR]: require('../../resources/register/register.2.fr.json'),
    [LOCALE.IT]: require('../../resources/register/register.2.it.json'),
    [LOCALE.PT]: require('../../resources/register/register.2.pt.json'),
  },
  [UNITS.A_2500]: {
    [LOCALE.ES]: require('../../resources/register/register.3.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.3.en.json'),
    [LOCALE.FR]: require('../../resources/register/register.3.fr.json'),
    [LOCALE.IT]: require('../../resources/register/register.3.it.json'),
    [LOCALE.PT]: require('../../resources/register/register.3.pt.json'),
  },
  [UNITS.A_BIT]: {
    [LOCALE.ES]: require('../../resources/register/register.4.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.4.en.json'),
    [LOCALE.FR]: require('../../resources/register/register.4.fr.json'),
    [LOCALE.IT]: require('../../resources/register/register.4.it.json'),
    [LOCALE.PT]: require('../../resources/register/register.4.pt.json'),
  },
  [UNITS.A_7000]: {
    [LOCALE.ES]: require('../../resources/register/register.5.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.5.en.json'),
    [LOCALE.FR]: require('../../resources/register/register.5.fr.json'),
    [LOCALE.IT]: require('../../resources/register/register.5.it.json'),
    [LOCALE.PT]: require('../../resources/register/register.5.pt.json'),
  },
  [UNITS.A_5500]: {
    [LOCALE.ES]: require('../../resources/register/register.6.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.6.en.json'),
    [LOCALE.FR]: require('../../resources/register/register.6.fr.json'),
    [LOCALE.IT]: require('../../resources/register/register.6.it.json'),
    [LOCALE.PT]: require('../../resources/register/register.6.pt.json'),
  },
  [UNITS.A_4500]: {
    [LOCALE.ES]: require('../../resources/register/register.7.es.json'),
    [LOCALE.EN]: require('../../resources/register/register.7.es.json'),
    [LOCALE.FR]: require('../../resources/register/register.7.es.json'),
    [LOCALE.IT]: require('../../resources/register/register.7.es.json'),
    [LOCALE.PT]: require('../../resources/register/register.7.es.json'),
  },
};

const condData = {
  [UNITS.A_4000]: require('../../resources/cond.2.json'),
  [UNITS.A_2500]: require('../../resources/cond.3.json'),
  [UNITS.A_BIT]: require('../../resources/cond.4.json'),
  [UNITS.A_7000]: require('../../resources/cond.5.json'),
  [UNITS.A_5500]: require('../../resources/cond.6.json'),
};

const ioCodeData = {
  [UNITS.A_4000]: require('../../resources/iocode.2.json'),
  [UNITS.A_2500]: require('../../resources/iocode.3.json'),
  [UNITS.A_BIT]: require('../../resources/iocode.4.json'),
  [UNITS.A_7000]: require('../../resources/iocode.5.json'),
  [UNITS.A_5500]: require('../../resources/iocode.6.json'),
  [UNITS.A_4500]: require('../../resources/iocode.7.json'),
};

const integrationData = {
  [UNITS.A_4000]: require('../../resources/integrationUnits.2.json'),
};

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('resFactory', resFactory);

  resFactory.$inject = ['$rootScope', '$q', '$http', '$translate'];

  function resFactory($rootScope, $q, $http, $translate) {
    var op = {
      menu: menu,
      menuconf: menuconf,
      cond: condList,
      register: register,
      inOutCode: inOutCode,
      integrationUnits: integrationUnits,
    };
    return op;

    function register(agronic) {
      const lang = $translate.use();
      return registerData[agronic][lang];
    }
    function menu(agronic) {
      return menuData[agronic];
    }

    function menuconf(agronic) {
      var url = '/resources/menuconf.' + agronic + '.json';
      var deferred = $q.defer();
      $http.get(url).then(function (data) {
        deferred.resolve(data.data);
      });

      return deferred.promise;
    }

    function condList(agronic) {
      return condData[agronic];
    }

    function integrationUnits(agronic) {
      return integrationData[agronic];
    }

    function inOutCode(agronic) {
      return ioCodeData[agronic];
    }
  }
})();
