import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outputsController', outputsController);

  outputsController.$inject = ['$scope', '$state', '$confirm', '$filter'];

  function outputsController($scope, $state, $confirm, $filter) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;

        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;
            vm.changeState = changeState;

        }else{
            $state.go('units');
        }
        
    }

    function changeState(location, params) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          vm.form = null;
          $state.go(location, params); //Canviem d'estat
        });
      } else {
        $state.go(location, params);
      }
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
      $scope.$emit('formToUnitUpdated', vm.form);
    });
  }
})();
