// (function() {
// 'use strict';

import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';

angular
  .module('agronicwebApp')

  //TODO - check moment
  .constant('moment', moment)

  .controller('navController', navController);

navController.$inject = [
  '$log',
  '$scope',
  '$rootScope',
  '$state',
  'UserData',
  'userFactory',
  'resFactory',
  'unitFactory',
  '$timeout',
  '$mdToast',
  '$translate',
  '$mdDialog',
  '$anchorScroll',
  'graphicsFactory',
  '$confirm',
];

function navController(
  $log,
  $scope,
  $rootScope,
  $state,
  UserData,
  userFactory,
  resFactory,
  unitFactory,
  $timeout,
  $mdToast,
  $translate,
  $mdDialog,
  $anchorScroll,
  graphicsFactory,
  $confirm
) {
  var vm = this;
  vm.logoutExit;
  vm.devicesArray;
  vm.checkStatus;
  vm.returnType;
  vm.selectUnit;
  vm.selected;
  vm.currentUnit;
  vm.menu;
  vm.notifications;
  vm.loadState;
  vm.newDay;
  vm.alertsMessages;
  var stopped;
  var toogledConfig;
  var eextra = false;
  var irridesk = false;
  var cuaderno = false;
  var _status, _irrigation;

  /*vm.unitsType = [
    { id: 2, name: 'A-4000' },
    { id: 3, name: 'A-2500' },
    { id: 4, name: 'A-BIT' },
    { id: 5, name: 'A-7000' },
    { id: 6, name: 'A-5500' },
  ];*/

  activate();

  function activate() {
    vm.currentState = checkStateInclude;
    $log.debug('navController loading');
    vm.profesional = UserData.profesional;
    vm.logoutExit = logoutExit;
    vm.selected = selected;
    vm.countdown = countdown;
    vm.stop = stop;
    vm.restart = restart;
    vm.showNotification = showNotification;
    vm.openMenu = openMenu;
    vm.notifications = [];
    vm.loadMenuConfig = loadMenuConfig;
    vm.loadMenuView = loadMenuView;
    vm.loadState = loadState;
    vm.unitHour = unitHour;
    vm.selectedName = null;
    vm.useLang = useLang;
    vm.openModal = openModal;
    vm.checkStatus = checkStatus;
    vm.checkClassStatus = checkClassStatus;
    vm.returnType = returnType;
    vm.selectUnit = selectUnit;
    vm.userHasExtra = userHasExtra;
    vm.userHasIrridesk = userHasIrridesk;
    vm.userHasCuaderno = userHasCuaderno;
    vm.alertsMessages = [];
    $rootScope.restart = restart;

    if ($state.current.name === 'crops' || $state.current.name === 'maps') {
      loadUnits($state.current.name === 'maps' ? $state.current.name : '');
    } else {
      loadUnits($state.current.name);
    }

    vm.active = 'view';
    vm.counter = 60;
    stopped = null;
    countdown();

    toogledConfig = true;
    vm.lang = $translate.use();
    eextra = _.includes(UserData.authorities, 'EEXTRA');
    irridesk = _.includes(UserData.authorities, 'EXPERT_IRRIDESK');
    cuaderno = _.includes(UserData.authorities, 'CCUSER');
  }

  function checkStateInclude(stateName) {
    if (!$state.includes(stateName)) {
      if (stateName.includes('.detail') || stateName.includes('.edit') || stateName.includes('.config')) {
        stateName = stateName.split('.')[0];
      }
    }

    return $state.includes(stateName);
  }

  function useLang(lang) {
    $translate.use(lang);
    vm.lang = lang;
    $rootScope.$broadcast('lang-update', { lang: lang });
    vm.showsubmenu = false;
  }

  function checkStatusImg(unit) {
    if (unit.status === 'ok') {
      unit.statusImg = unit.irrigation ? 'irrig' : 'nirrig';
    } else {
      unit.statusImg = unit.status;
    }
  }

  function openModal() {
    $('#myModal').modal('show');
  }

  function openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  function countdown() {
    stopped = $timeout(function () {
      if (UserData.authenticated) {
        vm.counter--;
      }
      if (vm.counter < 0) {
        vm.counter = 60;
        refreshData();
        vm.countdown();
      } else {
        vm.countdown();
      }
    }, 1000);
  }

  function stop() {
    $timeout.cancel(stopped);
    stopped = null;
  }
  function restart() {
    vm.counter = 60;
  }

  function selected(unit) {
    vm.selectedName = unit.device.name;
    _status = unit.device.status;
    _irrigation = unit.device.irrigation;
    loadSubmenu(unit);
    $('#myModal').modal('hide');
  }

    function checkClassStatus() {
        if (_status === 'ok') {
            return _irrigation ? 'irrig' : 'nirrig';
        } else {
            return _status;
        }
    }
    function loadState(item) {
        if ($state.current.parent === item.state && toogledConfig) {
        } else {
            toogledConfig = true;
            $state.go(item.state, { unit: vm.currentUnit, config: item.config });
        }
    }

    function loadSubmenu(unit) {

    if (unit.device.type === 5) var params = {};
    else var params = { add: ['format'] };

        let irrig = unit.device.irrigation;

    unitFactory.unit(unit.device.id, params).then(function (data) {
      vm.currentUnit = data.plain();
      vm.currentUnit.irrigation = irrig;
      checkStatus(vm.currentUnit);
      if (unit.device.type === UNITS.A_2500 || unit.device.type === UNITS.A_BIT || unit.device.type === UNITS.A_5500) {
        var options = vm.currentUnit.inoptions[0];
        vm.currentUnit.inoptions = options;
      }
      vm.currentUnit.selected = true;
      vm.currentUnit.lvl = unit.device.lvl;
      switch (unit.device.type) {
        case UNITS.A_4000:
        case UNITS.A_4500:
                    $state.go('unit', { unit: vm.currentUnit });
                    break;
                case UNITS.A_2500:
                case UNITS.A_BIT:
          $state.go('a25programs', { unit: vm.currentUnit });
          break;
        case UNITS.A_7000:
          $state.go('a7programs', { unit: vm.currentUnit });
          break;
        case UNITS.A_5500:
                    $state.go('a55programs', { unit: vm.currentUnit });
                    break;
                }
            });
    }

  function prepareMenuBasic() {
    if (!vm.currentUnit.inoptions.plus) {
      //BASIC
      vm.menu[6].disabled = true; // Disable Conditioners
    }
    if (!vm.currentUnit.inoptions.pivots) {
      //BASIC
      vm.menu[11].disabled = true; // Disable Pivots
    }
  }

  function loadMenuConfig() {
    vm.menu = [];
    vm.active = 'config';
    toogledConfig = false;
    resFactory.menuconf(vm.currentUnit.type).then(function (response) {
      vm.menu = response;
      vm.config = true;
      if (vm.currentUnit.type === UNITS.A_2500 && !vm.currentUnit.inoptions.plus) {
        //BASIC{
        vm.menu[4].disabled = true; // Disable Sensors
        vm.menu[5].disabled = true; // Disable Conditioners
        vm.menu[6].disabled = true; // Disable Pivots
        vm.menu[7].disabled = true; // Disable Riego Solar
      }
    });
  }

  function loadMenuView() {
    vm.menu = [];
    vm.active = 'view';
    toogledConfig = false;
    resFactory.menu(vm.currentUnit.type).then(function (response) {
      vm.menu = response;
      if (vm.currentUnit.type === UNITS.A_2500) prepareMenuBasic();
      vm.config = false;
    });
  }

  function checkStatus(obj) {
    /* **************   TODO ******************
            Posar textos especifics per l'equip 4500:
            Avería Fertilizante sin control -> Paro definitivo fertilizante
            Avería regulación PH -> Paro definitivo PH
            Paro Definitivo -> Programas en paro definitivo
        */
        toogledConfig = !toogledConfig;
        if (obj.ram !== undefined) {
            if (obj.connect) { /*"Paro Sistema"*/
                if (obj.ram.systemStopMalfunction) { obj.status = 'systemStop'; }
                else if (obj.ram.outService) { obj.status = 'outService'; }            /*"Fuera de servicio"*/
                else if (obj.ram.generalMalfunction) { obj.status = 'generalMalfunction'; }    /*"Avería General"*/
                else if (obj.ram.flowMalfunction) { obj.status = 'flowMalfunction'; }      /*"Avería Caudal"*/
                else if (obj.ram.counterMalfunction) { obj.status = 'counterMalfunction'; }    /*"Avería Contador"*/
                else if (obj.ram.ferlitzerMalfunction) { /*"Avería Fertilizante sin control"*/
                    obj.status = 'ferlitzerMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'ferlitzerMalfunction45';
                    }
                }
                else if (obj.ram.filterMalfunction) { obj.status = 'filterMalfunction'; }   /*"Avería Filtros sin control"*/
                else if (obj.ram.phMalfunction) { /*"Avería regulación PH"*/
                    obj.status = 'phMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'phMalfunction45';
                    }
                 }
                else if (obj.ram.ceMalfunction) { obj.status = 'ceMalfunction'; }        /*"Avería control conductividad"*/
                //todo falta OR parodefinitivoprogramas
                else if (obj.ram.definitiveStopMalfunction || obj.ram.definitiveStopProgramsMalfunction) { /*"Paro Definitivo"*/
                    obj.status = 'definitiveStopMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'definitiveStopMalfunction45';
                    }
                }
                else { obj.status = 'ok';
        }
      } else {
        obj.status = 'notconnected';
            }
        } else {
            if (obj.connect) {
                if (obj.systemStopMalfunction) { obj.status = 'systemStop'; }
                else if (obj.outService) { obj.status = 'outService'; }            /*"Fuera de servicio"*/
                else if (obj.generalMalfunction) { obj.status = 'generalMalfunction'; }    /*"Avería General"*/
                else if (obj.flowMalfunction) { obj.status = 'flowMalfunction'; }      /*"Avería Caudal"*/
                else if (obj.counterMalfunction) { obj.status = 'counterMalfunction'; }    /*"Avería Contador"*/
                else if (obj.ferlitzerMalfunction) { /*"Avería Fertilizante sin control"*/
                    obj.status = 'ferlitzerMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'ferlitzerMalfunction45';
                    }
                }
                else if (obj.filterMalfunction) { obj.status = 'filterMalfunction'; }   /*"Avería Filtros sin control"*/
                else if (obj.phMalfunction) { /*"Avería regulación PH"*/
                    obj.status = 'phMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'phMalfunction45';
                    }
                 }
                else if (obj.ceMalfunction) { obj.status = 'ceMalfunction'; }        /*"Avería control conductividad"*/
                else if (obj.definitiveStopMalfunction || obj.definitiveStopProgramsMalfunction) { /*"Paro Definitivo"*/
                    obj.status = 'definitiveStopMalfunction';
                    if(obj.type === UNITS.A_4500){
                        obj.status = 'definitiveStopMalfunction45';
                    }
                }
                else { obj.status = 'ok';
        }
      } else {
        obj.status = 'notconnected';
      }
    }

    checkStatusImg(obj);
  }

  function checkNotifications(obj) {
    var malfunctions = _.pickBy(obj, function (value, key) {
      return _.endsWith(key, 'Malfunction');
    });
    obj.malfunctions = [];
    if (!_.isEmpty(malfunctions)) {
      _.forEach(malfunctions, (v, k) => {
        if (v) {
          var el = {};
          el[obj.name] = k;
          vm.notifications.push(el);
          obj.malfunctions.push(el);
        }
      });
    }
  }

  function showNotification(obj, alert) {
    const toast = document.querySelector('vegga-toast-manager');
    if (!_.isEmpty(obj)) {
      // var pinTo = 'top right';
      var translate = '';
      $translate('alerts.' + _.values(obj)[0]).then(function (value) {
        translate = value;
        var text = _.keys(obj)[0] + ' | ' + translate;
        if (!vm.alertsMessages.includes(text)) {
          vm.alertsMessages.push(text);
          toast.create({ message: text, statusClass: 'vegga-alert--warning', persist: true });
        }
        // var toast = $mdToast.simple()
        //     .action(text)
        //     .hideDelay(false)
        //     .position('top left')
        //     .parent(document.querySelectorAll('#toaster'))
        //     .highlightAction(false)
        //.highlightClass('animated infinite pulse');
        // $mdToast.show(toast).then(function (response) {
        //     if (response == 'ok') {
        //         $state.go('malfunctions', { units: vm.devicesArray });
        //     }
        // });
      });
    } else {
      $mdToast.hide();
      if (alert) {
        var pinTo = 'top right';
        $translate('alerts.nomalfunctions').then(function (value) {
          var toast = $mdToast
            .simple()
            .textContent(value)
            .hideDelay(false)
            .position('top left')
            .parent(document.querySelectorAll('#toaster'))
            .hideDelay(1000)
            .highlightAction(false);
          //.highlightClass('animated infinite pulse');
          $mdToast.show(toast).then(function (response) {});
        });
      }
    }
  }

  function unitHour() {
    if (vm.currentUnit && vm.currentUnit.ram && vm.currentUnit.ram.date) {
      let dayWeek;
      dayWeek = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').day();
      switch (dayWeek) {
        case 1:
          vm.newDay = '_mon';
          break;
        case 2:
          vm.newDay = '_tue';
          break;
        case 3:
          vm.newDay = '_wed';
          break;
        case 4:
          vm.newDay = '_thu';
          break;
        case 5:
          vm.newDay = '_fri';
          break;
        case 6:
          vm.newDay = '_sat';
          break;
        case 1:
          vm.newDay = '_sun';
          break;
      }
      return moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
    } else {
      return '--:--';
    }
  }

  function logout() {
    return $.ajax({
      data: '',
      timeout: 1000,
      type: 'GET',
      url: 'logout',
    });
  }

  function loadUnits(state) {
    $rootScope.isRefreshingApp = true;
    vm.currentUnit = {};
    vm.notifications = [];
    userFactory.units(UserData.id).then(function (response) {
      vm.devicesArray = response.plain();
      _.forEach(vm.devicesArray, function (obj) {
        checkStatus(obj.device);
        checkNotifications(obj.device);
        obj.device.lvl = obj.level;
      });
      showNotification(vm.notifications[0]);
      $rootScope.devicesArray = vm.devicesArray;
      if (state) $state.go(state, { units: vm.devicesArray });
      if ($state.includes('malfunctions')) $state.go('malfunctions', { units: vm.devicesArray });
    });
  }

  function refreshData(callback) {
    $rootScope.isRefreshingApp = true;
    vm.notifications = [];
    if (vm.currentUnit.id) {
      var params = { add: ['format'] };
      unitFactory.unit(vm.currentUnit.id, params).then(
        function (data) {
          if (!data) {
            return;
          }

          $rootScope.isRefreshingApp = false;

          loadUnits();

          vm.currentUnit = data.plain();

          checkStatus(vm.currentUnit);
          if (vm.currentUnit.type === 3 || vm.currentUnit.type === 4 || vm.currentUnit.type === 6) {
            var options = vm.currentUnit.inoptions[0];
            vm.currentUnit.inoptions = options;
          }

          vm.currentUnit.selected = true;

          if (callback) {
            callback || callback();
          } else {
            $scope.$broadcast('refresh', { message: 'refreshing', unit: vm.currentUnit, units: vm.devicesArray });
          }
        },
        function (error) {
          //TODO
          $scope.$broadcast('refresh', { message: 'refreshing' });
        }
      );
    } else {
      vm.currentUnit = {};
      userFactory.units(UserData.id).then(function (response) {
        vm.devicesArray = response.plain();
        _.forEach(vm.devicesArray, function (obj) {
          checkStatus(obj.device);
          checkNotifications(obj.device);

          obj.device.lvl = obj.level;
        });
        showNotification(vm.notifications[0]);

        if (callback) {
          callback || callback();
        } else {
          $scope.$broadcast('refresh', { message: 'refreshing', unit: vm.currentUnit, units: vm.devicesArray });
        }
      });
    }
    //        vm.countdown();
  }

  function logoutExit() {
    logout().done((data) => {
      $rootScope.user = undefined;
      stop();
      graphicsFactory.clearGraphics();
      $state.go('login', { reload: true });
    });
  }

  /**
   *
   * Events listeners
   *
   **/

  /*  $scope.$on('refreshed',function(event,args){
            //vm.countdown();
        });*/

  $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
    $anchorScroll();
    if (toState.name.includes('.config') && !vm.config) {
      // loadMenuConfig();
    } else if ((toState.name.includes('.detail') || toState.name.includes('.edit')) && vm.config) {
      loadMenuView();
    }
  });

  $scope.$on('reload', function (event, args) {
    vm.counter = 60;
    refreshData();
  });

  $scope.$on('reloadUnits', function (event, args) {
    vm.currentUnit = args.unit;
    var callback;
    callback = function () {
      $state.go('malfunctions', { units: vm.devicesArray });
    };
    vm.counter = 60;
    refreshData(callback);
  });

  $scope.$on('selectUnit', function (event, args) {
    if (args.unit.device.connected === false) {
      if ($('#myModal')) $('#myModal').modal('hide');
      $confirm({
        text: 'Quiere seleccionar el equipo?',
        title: 'Este equipo esta desconectado',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        if (args.unit) {
          vm.selectectedUnit = args.unit;
          vm.selected(args.unit);
        }
        if (args.callback) {
          args.callback();
        }
      });
    } else {
      if (args.unit) {
        vm.selectectedUnit = args.unit;
        vm.selected(args.unit);
      }
      if (args.callback) {
        args.callback();
      }
    }
  });

  //TODO Refactor/Remove: Disabled Idle timeout
  /*
   * Disabled Idle timeout, not necessary on irrigiation control because this functionality
   * is moved on core service backend.
   */
  /* $scope.$on('IdleTimeout', function() {
       logout().done(function(data, textStatus, jqXHR) {
           $rootScope.user = {};
           stop();
           graphicsFactory.clearGraphics(); 
           $mdDialog.show(
              $mdDialog.alert()
                .clickOutsideToClose(true)
                .title('Alert')
                .textContent('Session timeout.')
                .ariaLabel('Alert Dialog')
                .ok('OK')
                
            ).then((item)=>{
                $state.go('login',{reload:true});
            });               
            }).fail(function(jqXHR, textStatus, errorThrown) {
                console.error('Booh! Wrong credentials, try again!');
            });
    });*/

  $rootScope.$on('cfpLoadingBar:loading', function (event, data) {
    vm.loadingbar = true; //Loading spiner
  });

  $scope.$on('cfpLoadingBar:loaded', function (event, data) {
    vm.loadingbar = false;
  });

  $scope.$on('refresh-format', function (event, args) {
    vm.currentUnit = args.unit;
  });

  /* functions for modal selector of units --> copied to units-controller.js*/
  function selectUnit(obj) {
    if (!obj.device.connect) {
      if ($('#myModal')) $('#myModal').modal('hide');
      $confirm({
        text: 'Quiere seleccionar el equipo?',
        title: 'Este equipo esta desconectado',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var callback = function () {
          $scope.$emit('selectUnit', { message: 'reload', unit: obj });
        };
        validateOperation(obj, callback);
      });
    } else {
      var callback = function () {
        $scope.$emit('selectUnit', { message: 'reload', unit: obj });
      };
      validateOperation(obj, callback);
    }
  }

  function returnType(type) {
    switch (type) {
      case 2:
        return 'A-4000';
      case 3:
        return 'A-2500';
      case 4:
        return 'A-BIT';
      case 5:
        return 'A-7000';
      case 6:
        return 'A-5500';
    }
  }

  function validateOperation(obj, callback) {
    /* if(obj.device.type === 2 || obj.device.type === 3 || obj.device.type === 4 || obj.device.type === 6){ */
    if (obj.device.connected) {
      callback(); // $scope.$emit('selectUnit',{message:'reload',unit:obj});
    } else {
      callback();
    }
    /*         }else{
                    $mdDialog.show(
                      $mdDialog.alert()
                        //.parent(angular.element(document.querySelector('#popupContainer')))
                        .clickOutsideToClose(true)
                        .title('Alerta')
                        .textContent('Actualmente este equipo no es consultable.')
                        .ariaLabel('Alert Dialog')
                        .ok('OK')
        
                    );   
                }  */
  }
  function userHasExtra() {
    return eextra;
  }
  function userHasIrridesk() {
    return irridesk;
  }

  function userHasCuaderno() {
    return cuaderno;
  }
}

// })();
