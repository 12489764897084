import XLSX from 'xlsx/dist/xlsx.full.min';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('historyFactory', historyFactory);

  historyFactory.$inject = ['Restangular', '$q', '$rootScope', 'uiGridExporterService', 'manualFactory', '$confirm'];

  function historyFactory(Restangular, $q, $rootScope, uiGridExporterService, manualFactory, $confirm) {
    var op = {
      programs: programs,
      filters: filters,
      integrateds: integrateds,
      analogs: analogs,
      meters: meters,
      logics: logics,
        flow: flow,
        general: general,
        trays: trays,
        sectors: sectors,
        avgphce: avgphce,
        fertdep: fertdep,
      sectorsExport: sectorsExport,
      analogsExport: analogsExport,
      export: _export,
      getHistory: getHistory,
    };

    return op;

    function programs(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('programs')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function sectors(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('sectors')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function sectorsExport(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('csv')
        .one('sectors')
        .get(params)
        .then(function (response) {
          var name = 'history.csv';
          response = response.replaceAll('.', ',');
          var file = new Blob([response], { type: 'text/csv' });
          saveAs(file, name);
          deferred.resolve();
        });
      return deferred.promise;
    }

    function analogsExport(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('history')
        .one('analogs')
        .one('csv')
        .get(params)
        .then(function (response) {
          var name = 'history.csv';
          response = response.replaceAll('.', ',');
          var file = new Blob([response], { type: 'text/csv' });
          saveAs(file, name);
          deferred.resolve();
        });
      return deferred.promise;
    }

    function filters(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('filters')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function integrateds(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('integrateds')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function analogs(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('analogs')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

      return deferred.promise;
    }

    function meters(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('meters')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

        return deferred.promise;
    }

    function logics(id, params){

        var deferred = $q.defer();

        Restangular.one('units',id).one('history').one('logics').get(params).then(function(response){
            deferred.resolve(response.plain());
        });

      return deferred.promise;
    }

    function flow(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('flow')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

      return deferred.promise;
    }

    function general(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('general')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

      return deferred.promise;
    }
    function trays(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('trays')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

      return deferred.promise;
    }

    function avgphce(id, params) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('history')
        .one('avgphce')
        .get(params)
        .then(function (response) {
          deferred.resolve(response.plain());
        });

        return deferred.promise;
    }

    function fertdep(id, params){

        var deferred = $q.defer();

        Restangular.one('units',id).one('history').one('fertdeposits').get(params).then(function(response){
            deferred.resolve(response.plain());
        });

      return deferred.promise;
    }

    function _export(grid, name) {
      var translates = [];
      uiGridExporterService.loadAllDataIfNeeded(grid, 'all', 'all').then(function () {
        var exportColumnHeaders = uiGridExporterService.getColumnHeaders(grid, 'all');

        var exportData = uiGridExporterService.getData(grid, 'all', 'all', false);
        exportAndSave(exportData, exportColumnHeaders, name);
      });
    }
    function exportAndSave(rows, headers, name) {
      var data = [];
      var header_columns = _.map(headers, 'displayName');

      _.forEach(rows, (row) => {
        var obj = _.zipObject(header_columns, _.map(row, 'value'));
        data.push(obj);
      });

      /* generate a worksheet */
      var ws = XLSX.utils.json_to_sheet(data);

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Hist');

      /* write workbook (use type 'binary') */
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      /* generate a download */
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), name);
    }

    function getHistory(type, id) {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (type) {
          case 2:
            var params = {};
            params.type = type;
            params.deviceId = id;
            params.action = 24;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
  }
})();
