import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('calcsWaterController', calcsWaterController);

  calcsWaterController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
  ];

  function calcsWaterController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout
  ) {
    var vm = this;
    vm.activeList;
    var unitsLoaded;
    var update;
    vm.lConfortA = 140;
    vm.lDeficit = 0;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      update = false;
      unitsLoaded = [];
      vm.sanalogs = {};

      if ($rootScope.devicesArray !== null) {
        vm.units = $rootScope.devicesArray;
      } else {
        $state.go('units');
      }

      if ($state.params.sensor !== null) {
        vm.waterDisp = $state.params.sensor;
        vm.awList = $state.params.list;
        if (vm.waterDisp.recalcDate) {
          vm.date = moment.utc(vm.waterDisp.recalcDate, 'DD/MM/YYYY').toISOString();
        }
        loadSensorsConfig(vm.waterDisp);
      }

      vm.minDate = moment().subtract(1, 'month')._d;
      vm.maxDate = moment()._d;

      vm.water_backup = {};
      angular.copy(vm.waterDisp, vm.water_backup);

      vm.nextSensor = nextSensor;
      vm.lastSensor = lastSensor;
      vm.selectSensor = selectSensor;
      vm.selectSensorPage = selectSensorPage;
      vm.loadSAnalogs = loadSAnalogs;
      vm.removeSensor = removeSensor;
      vm.addNewSensor = addNewSensor;
      vm.addAnalogSensor = addAnalogSensor;
      vm.save = save;
      vm.prepareLegend = prepareLegend;
      vm.updateRecalc = updateRecalc;
      vm.cancel = cancel;
      vm.setDate = setDate;
    }

    function loadSensorsConfig(sensor) {
      vm.empty = false;
      vm.waterDisp = sensor;
      vm.reloadedGraph = true;
      selectSensor();
    }

    function addNewSensor() {
      calculatedsFactory.newWater(UserData.id).then((data) => {
        vm.awList = data.plain();
        loadSensorsConfig(_.last(vm.awList));
      });
    }

    function selectSensor() {
      vm.reloadedGraph = false;
      if (vm.waterDisp.sensors !== undefined) {
        vm.sensors_water = vm.waterDisp.sensors;

        _.forEach(vm.sensors_water, (sensor) => {
          loadSAnalogs(sensor.deviceId, sensor.id - 1);
        });
      }
    }

    function prepareLegend() {
      if (
        vm.waterDisp !== null &&
        (vm.waterDisp.lConfortA === null ||
          vm.waterDisp.lConfortB === null ||
          vm.waterDisp.lConfortM === null ||
          vm.waterDisp.lDeficit === null)
      ) {
        let rp = Number(vm.waterDisp.cicles[0].recargaPoint);
        let tmp = 100 - rp;
        if (tmp > 0) {
          tmp = Number((tmp / 3).toFixed(2));
          vm.waterDisp.lConfortA = 100;
          vm.waterDisp.lConfortB = rp + tmp;
          vm.waterDisp.lConfortM = vm.waterDisp.lConfortB + tmp;
          vm.waterDisp.lDeficit = rp;
          vm.form.$setDirty();
        }
      }
    }

    function selectSensorPage(sensor) {
      loadSensorsConfig(sensor);
    }

    function removeSensor(index) {
      if (!vm.sensors_water[index]) return;

      vm.form.$setDirty();
      if (vm.sensors_water?.[index]?.newAdded) {
        vm.sensors_water.splice(index, 1);
      } else {
        calculatedsFactory.deleteWaterSensor(vm.waterDisp.pk.userId, vm.waterDisp.pk.id, index + 1).then(() => {
          vm.sensors_water.splice(index, 1);
        });
      }
    }

    function loadSAnalogs(deviceId, index) {
      if (deviceId !== null) {
        if (!_.includes(unitsLoaded, deviceId)) {
          unitsLoaded.push(deviceId);
          sensorsFactory.analogsactive(deviceId, true).then((response) => {
            vm.reloadedGraph = true;
            vm.sanalogs[deviceId] = response.plain();
          });
        } else {
          $timeout(() => {
            vm.reloadedGraph = true;
          }, 500);
        }
      }
    }

    function addAnalogSensor() {
      var id = vm.sensors_water.length + 1;

      if (vm.sensors_water.length < 8) {
        vm.form.$setDirty();
        vm.sensors_water.push({
          pk: {
            userId: vm.waterDisp.pk.userId,
            sensorId: vm.waterDisp.pk.id,
            id: id,
          },
          name: null,
          deviceId: null,
          analogId: null,
          graficColor: null,
          showGrafic: null,
          calc: null,
          point100: null,
          point0: null,
          creationDate: null,
          modificationDate: null,
          xStatusOrigin: null,
          xValueOrigin: null,
          xDateOrigin: null,
          xStatus: null,
          xValue: null,
          newAdded: true,
        });
      }
    }

    function lastSensor(wd) {
      var page = _.findIndex(vm.awList, (o) => {
        return o.pk.id === (wd === undefined ? vm.waterDisp.pk.id : wd.pk.id);
      });
      if (page >= 0) {
        var s;
        --page;
        if (page < 0) {
          s = _.last(vm.awList);
        } else {
          s = vm.awList[page];
        }
        if (!(s.shared && s.sharingLvl === 0)) {
          loadSensorsConfig(s);
        } else {
          lastSensor(s);
        }
      }
    }

    function nextSensor(wd) {
      var page = _.findIndex(vm.awList, (o) => {
        return o.pk.id === (wd === undefined ? vm.waterDisp.pk.id : wd.pk.id);
      });
      if (page >= 0) {
        var s;
        ++page;
        if (page >= vm.awList.length) {
          s = _.first(vm.awList);
        } else {
          s = vm.awList[page];
        }
        if (!(s.shared && s.sharingLvl === 0)) {
          loadSensorsConfig(s);
        } else {
          nextSensor(s);
        }
      }
    }

    function updateRecalc() {
      update = true;
      if (vm.waterDisp.recalcDate === null) {
        vm.waterDisp.recalcDate = new Date();
      }
    }

    function save() {
      vm.waterDisp.sensors = vm.sensors_water;
      if (!vm.waterDisp.sharedList) {
        vm.waterDisp.sharedList = [];
      }
      vm.form.$setPristine();
      if (update) {
        vm.waterDisp.recalc = vm.recalc;
        vm.waterDisp.recalcDate = moment(vm.waterDisp.recalcDate).format('L');
        update = false;
      }
      calculatedsFactory.updateWater(vm.waterDisp.pk.userId, vm.waterDisp).then((response) => {
        $scope.$emit('updateWaterList', response.plain());
      });
    }

    function setDate() {
      vm.waterDisp.recalcDate = moment(vm.date).format('DD/MM/YYYY');
    }

    function cancel() {
      angular.copy(vm.water_backup, vm.waterDisp);
      vm.form.$setPristine();
    }

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('saveWater', (e, args) => {
      save();
    });

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('cancelEditWater', (e, args) => {
      cancel();
    });

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formWaterUpdated', vm.form);
      }
    );

    $scope.$on('refresh', function (event, args) {});
  }
})();
