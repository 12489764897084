import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pdrController', pdrController);

  pdrController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    'graphicsFactory',
    '$q',
    '$confirm',
    '$filter',
  ];

  function pdrController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    graphicsFactory,
    $q,
    $confirm,
    $filter
  ) {
    var vm = this;
    var backups, chart;
    vm.VeggaOverlayTab = VeggaOverlayTab;
    vm.pdrList = [];

    activate();

    function activate() {
      if ($rootScope.devicesArray !== null) {
        vm.units = $rootScope.devicesArray;
      } else {
        $state.go('agroclimatic');
      }

      loadSensors();
      vm.unitName = unitName;
      vm.loadUnitSensors = loadUnitSensors;
      vm.newSensor = newSensor;
      vm.deleteSensor = deleteSensor;
      vm.updateSensor = updateSensor;
      vm.sensorEdition = sensorEdition;
      vm.cancelEdition = cancelEdition;
      vm.sensorHistory = sensorHistory;

      vm.changeTab = changeTab;
      vm.cancel = cancel;
      vm.changePDR = changePDR;
      vm.changingPDR = changingPDR;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.getChartData = getChartData;
      backups = {};
      $scope.$emit('stateChanged');
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        var unit = _.filter(vm.units, (o) => {
          return o.device.id === sensor.deviceId;
        })[0];
        return unit.device.name;
      }
    }

    function loadSensors() {
      calculatedsFactory.getPDR(UserData.id).then((result) => {
        if (result.plain()) {
          vm.pdrList = result.plain();
        }
      });
    }

    function updateSensors() {
      calculatedsFactory.getPDR(UserData.id).then((result) => {
        if (result.content) {
          _.forEach(result.content, (o) => {
            let tmp2 = _.find(vm.pdrList, (d) => {
              return d.pk.id === o.pk.id;
            });
            if (tmp2 !== undefined) {
              tmp2.xValue = o.xValue;
            }
          });
        }
      });
    }

    function newSensor() {
      vm.currentPDRSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function deleteSensor() {
      calculatedsFactory.deletePDR(UserData.id, vm.currentPDRSelected.pk.id).then(() => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.pdrList = vm.pdrList.filter((pdr) => pdr.pk.id !== vm.currentPDRSelected.pk.id);
        vm.currentPDRSelected = null;
      });
    }

    function sensorEdition() {
      var sensor_backup = {};
      angular.copy(vm.currentPDRSelected, sensor_backup);
      backups[vm.currentPDRSelected.pk.id] = sensor_backup;
      if (vm.currentPDRSelected.Templist === undefined || vm.currentPDRSelected.Hrlist === undefined) {
        loadUnitSensors(vm.currentPDRSelected, true, true);
      }
    }

    function loadUnitSensors(sensor, temp, hr, onChange) {
      let params = {
        limit: 100,
        page: 1,
      };
      var binding = sensor;
      if (sensor.deviceIdTemp > 0 && temp) {
        binding.Templist = [];
        sensorsFactory.analogsPage(sensor.deviceIdTemp, params).then((result) => {
          if (onChange) {
            binding.analogIdTemp = null;
          }

          if (result.content) {
            binding.Templist = result.content;
          }
        });
      }
      if (sensor.deviceIdHr > 0 && hr) {
        binding.Hrlist = [];
        sensorsFactory.analogsPage(sensor.deviceIdHr, params).then((result) => {
          if (onChange) {
            binding.analogIdHr = null;
          }

          if (result.content) {
            binding.Hrlist = result.content;
          }
        });
      }
    }

    function cancelEdition() {
      if (vm.currentPDRSelected && vm.currentPDRSelected.pk) {
        angular.copy(backups[vm.currentPDRSelected.pk.id], vm.currentPDRSelected);
      }
      vm.form.$setPristine();
    }

    function updateSensor() {
      var tmp = vm.currentPDRSelected;
      calculatedsFactory.updatePDR(UserData.id, vm.currentPDRSelected).then((result) => {
        vm.currentPDRSelected = result.plain();
        tmp.pk = vm.currentPDRSelected.pk;
        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.pdrList.push(vm.currentPDRSelected);
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentPDRSelected = null;
        }
        vm.form.$setPristine();
      });
    }

    function sensorHistory(sensor) {
      vm.currentTab = VeggaOverlayTab.DETAIL;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.currentPDRSelected = sensor;
    }

    function getChartData(reload) {
      //let chart;
      var date_to;
      var querys = [];

      loadGraficas(vm.currentPDRSelected.pk.id);

      var today = new moment()._d;
      var date_ago = new moment().subtract(7, 'days')._d;

      if (vm.currentPDRSelected.fecha_desde === undefined) vm.currentPDRSelected.fecha_desde = date_ago;
      if (vm.currentPDRSelected.fecha_hasta === undefined) vm.currentPDRSelected.fecha_hasta = today;

      if (reload) date_to = moment(vm.currentPDRSelected.fecha_hasta).add(1, 'days').format('DD-MM-YYYY');
      else date_to = moment(vm.currentPDRSelected.fecha_hasta).format('DD-MM-YYYY HH:mm');

      let params = {
        to: date_to,
        from: moment(vm.currentPDRSelected.fecha_desde).format('DD-MM-YYYY'),
      };

      let params_sensor = {
        to: date_to,
        from: moment(vm.currentPDRSelected.fecha_desde).subtract(1, 'd').format('DD-MM-YYYY 23:00'),
        group: 1,
      };

      querys.push(
        //getAnalog(vm.graphic.graphicElements[i].device, vm.graphic.graphicElements[i].sensor)
        calculatedsFactory
          .historyPDR(vm.currentPDRSelected.pk.userId, vm.currentPDRSelected.pk.id, params)
          .then((result) => {
            if (result.plain()) {
              return result.plain();
            }
          })
      );
      querys.push(
        graphicsFactory
          .getAnalog(
            vm.currentPDRSelected.pk.userId,
            vm.currentPDRSelected.deviceIdTemp,
            vm.currentPDRSelected.analogIdTemp,
            params_sensor
          )
          .then((result) => {
            if (result.plain()) {
              return result.plain();
            }
          })
      );

      if (querys.length > 0) {
        $q.all(querys).then(function (result) {
          for (var i = 0; i < result.length; i++) {
            processData(result[i], i);
          }
        });
      }
    }

    function processData(data, i) {
      let datos_grafica = [];
      var mdata = [],
        name;
      mdata = data;

      mdata.forEach(function (valor, indice, array) {
        var date_timestamp = moment(mdata[indice].date, 'DD-MM-YYYY HH:mm').format('x');

        date_timestamp = parseInt(date_timestamp);

        datos_grafica.push([date_timestamp, mdata[indice].value]);
      });

      if (i == 0) {
        name = 'PdR';
      } else name = 'Sensor Temperatura';
      chart.addSeries({
        data: datos_grafica,
        name: name,
      });
    }

    function changeTab(tab, $event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();

          vm.currentTab = tab;
          if (tab === VeggaOverlayTab.EDIT) {
            sensorEdition();
          }

          if (tab === VeggaOverlayTab.DETAIL) {
            setTimeout(() => {
              getChartData(vm.currentPDRSelected);
            }, 100);
          }
        });
      } else {
        vm.currentTab = tab;
        if (tab === VeggaOverlayTab.EDIT) {
          sensorEdition();
        }

        if (tab === VeggaOverlayTab.DETAIL) {
          setTimeout(() => {
            getChartData(vm.currentPDRSelected);
          }, 100);
        }
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentPDRSelected = null;
          }
        });
      } else {
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentPDRSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentPDRSelected = null;
        }
      }
    }

    function changePDR(e) {
      if (e.detail.value) {
        vm.currentPDRSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.DETAIL;
        //sensorHistory(vm.currentPDRSelected);
      }
    }

    function changingPDR($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changePDR($event);
          break;
      }
    }

    $scope.$on('refresh', function (event, args) {
      updateSensors();
    });

    function loadGraficas(index) {
      let id = 'graficaSensor' + index;

      chart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              this.series.name +
              ':</b> ' +
              this.y.toFixed(2) +
              '<br/>' +
              moment(this.x).format('DD-MM-YYYY HH:mm')
            );
          },
        },
        title: {
          text: 'Punto de Rocío',
        },
        chart: {
          zoomType: 'xy',
          renderTo: id,
          type: 'line',
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y %H:%M}',
            rotation: -65,
          },
        },
        yAxis: {
          title: 'ºC',
        },
      });
    }
  }
})();
