export const METERFORMATS45 = {
    0: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "m3/h"
    },
    1: {
        integers: 3,
        decimals: 1,
        max: 999.9,
        format: "m3/h"
    },
    2: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "m3/h"
    },
    3: {
        integers: 4,
        max: 9999,
        decimals: 0,
        format: "L/h"
    },
    4: {
        integers: 3,
        decimals: 1,
        max: 999.9,
        format: "L/h"
    },
    5: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "L/h"
    },
    6: {
        integers: 4,
        max: 9999,
        decimals: 0,
        format: "L/s"
    },
    7: {
        integers: 3,
        decimals: 1,
        max: 999.9,
        format: "L/s"
    },
    8: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "L/s"
    },
    9: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "m3/s"
    },
    10: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "m3/s"
    },
    11: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "m3/s"
    },
    12: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "kwh"
    },
    13: {
        integers: 3,
        decimals: 1,
        max: 999.9,
        format: "kwh"
    },
    14: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "kwh"
    },
    15: {
        integers: 4,
        decimals: 0,
        max: 9999,
        format: "m3/h"
    },
    16: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "L/m2"
    },
    17: {
        integers: 2,
        decimals: 2,
        max: 99.99,
        format: "mm"
    },
}