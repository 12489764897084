import {
  AgronomicVariableKey,
  ControlPointDetailItem,
  ControlPointRequestTypeEnum,
  ExploitationControlPointAntiFrostSurveillanceResponse,
  ExploitationControlPointAvailableWaterResponse,
  ExploitationsApi,
  GetControlPointDataHistoryRequest,
} from '@vegga-api-clients/irrigation-control-service';
import { forkJoin, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpErrorResponse } from '../../http';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
import {
  AgVarMethodMap,
  ControlPointHistoryResponses,
  ControlPointSoilHistory,
  ControlPointSoilHistoryResponses,
  ControlPointWeatherHistory,
  ControlPointWeatherHistoryResponses,
} from './control-points.entities';

@Injectable('controlPointsFacade')
export class ControlPointsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private controlPointsDataResponse = new VeggaResponse<ControlPointDetailItem[]>();

  private controlPointAvailableWaterHistory = new VeggaResponse<ExploitationControlPointAvailableWaterResponse>();
  private controlPointFrostSurveillanceHistory = new VeggaResponse<ExploitationControlPointAntiFrostSurveillanceResponse>();
  private controlPointByIdResponse = new VeggaResponse<ControlPointDetailItem, HttpErrorResponse>();

  private controlPointHistoriesResponse = new VeggaResponse<ControlPointHistoryResponses>();

  private exploitationsApi: ExploitationsApi;
  private loader = new VeggaLoader();

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get controlPointsData$(): Observable<ControlPointDetailItem[]> {
    return this.controlPointsDataResponse.value$;
  }

  get controlPointById$(): Observable<ControlPointDetailItem> {
    return this.controlPointByIdResponse.value$;
  }

  get controlPointByIdError$(): Observable<HttpErrorResponse> {
    return this.controlPointByIdResponse.error$;
  }

  get controlPointHistories$(): Observable<ControlPointHistoryResponses> {
    return this.controlPointHistoriesResponse.value$;
  }

  constructor() {
    this.exploitationsApi = new ExploitationsApi();
    this.exploitationsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  loadControlPoints() {
    const req$ = this.exploitationsApi.getAllControlPoints();

    const subscription = handleResponse(req$, this.controlPointsDataResponse).subscribe({
      next: agronomicMonitoring => {
        this.controlPointsDataResponse.set(agronomicMonitoring);
      },
      error: err => {
        this.controlPointsDataResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointById(controlPointId: number) {
    const req$ = this.exploitationsApi.getControlPoint({ id: controlPointId });

    const subscription = handleResponse(req$, this.controlPointByIdResponse).subscribe({
      next: ckeckpoint => {
        this.controlPointByIdResponse.set(ckeckpoint);
      },
      error: err => {
        this.controlPointByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadControlPointHistoriesByType(type: string, params: GetControlPointDataHistoryRequest) {
    const req$ = this.getControlPointHistoryRequests(type, params);
    const subscription = req$.subscribe({
      next: cpHistoryResponses => {
        this.controlPointHistoriesResponse.set(cpHistoryResponses);
      },
      error: err => {
        this.controlPointHistoriesResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  clearControlPointAvailableWaterHistoryResponse(): void {
    this.controlPointAvailableWaterHistory.clearValue();
    this.controlPointFrostSurveillanceHistory.clearValue();
  }

  clearControlPointByIdResponse(): void {
    this.controlPointByIdResponse.clear();
  }

  clearControlPointByIdError(): void {
    this.controlPointByIdResponse.clearError();
  }

  clearControlPointHistoriesResponse(): void {
    this.controlPointHistoriesResponse.clear();
  }

  /**
   * Joins all CP type graph history methods and injects responses
   * into single response to be consumed from client as object map key-response
   * @param type CP type
   * @param params params for the graph data request
   * @returns Object map as key -> response
   */
  private getControlPointHistoryRequests(type: string, { id, from, to, minAggregation }: GetControlPointDataHistoryRequest): Observable<ControlPointHistoryResponses> {
    const weatherAgronomicVarsMethodMap = {
      [AgronomicVariableKey.ETO]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointEto(params),
      [AgronomicVariableKey.VAPOR_PRESSURE_DEFICIT]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointDpv(params),
      [AgronomicVariableKey.DEW_POINT]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointDewPoint(params),
      [AgronomicVariableKey.ANTI_FROST_SURVEILLANCE]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointAntiFrostSurveillance(params),
    } as AgVarMethodMap<ControlPointWeatherHistory>;

    const soilAgronomicVarsMethodMap = {
      [AgronomicVariableKey.AVAILABLE_WATER]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointAvailableWater(params),
      // minAggregation returns data from ten to ten minuts, as demands nutrient dynamic graph requirements
      [AgronomicVariableKey.NUTRIENT_DYNAMICS]: (params: GetControlPointDataHistoryRequest) =>
        this.exploitationsApi.getControlPointNutrientDynamic({ ...params, minAggregation: true }),
      [AgronomicVariableKey.CAMPAIGN_IRRIGATION]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointCampaignIrrigation(params),
    } as AgVarMethodMap<ControlPointSoilHistory>;

    const getFilteredHistoryObservables = (agVarMethodMap: AgVarMethodMap<ControlPointHistoryResponses>) => {
      return Object.keys(agVarMethodMap)
        .filter(agVar => this.controlPointByIdResponse.value.availableAgronomicVariables.includes(agVar as AgronomicVariableKey))
        .map(agVar => agVarMethodMap[agVar]({ id, from, to, minAggregation }));
    };

    switch (type) {
      case ControlPointRequestTypeEnum.WEATHER.toLowerCase():
        return forkJoin([
          this.exploitationsApi.getControlPointDataHistory({ id, from, to, minAggregation }),
          ...getFilteredHistoryObservables(weatherAgronomicVarsMethodMap as AgVarMethodMap<ControlPointHistoryResponses>),
        ]).pipe(
          map(([controlPoint, eto, dpv, dewPoint, frostSurveillance]) => ({ controlPoint, eto, dewPoint, dpv, frostSurveillance })),
        ) as Observable<ControlPointWeatherHistoryResponses>;

      case ControlPointRequestTypeEnum.SOIL.toLowerCase():
        return forkJoin([...getFilteredHistoryObservables(soilAgronomicVarsMethodMap as AgVarMethodMap<ControlPointHistoryResponses>)]).pipe(
          map(([availableWater, nutrientDynamics, campaignIrrigation]) => ({ availableWater, nutrientDynamics, campaignIrrigation })),
        ) as Observable<ControlPointSoilHistoryResponses>;
    }
  }
}
