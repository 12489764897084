(function () {
    'use strict';

    angular.module('agronicwebApp')

        .factory('sectorFactory', sectorFactory);

    sectorFactory.$inject = ['Restangular', '$rootScope', '$q', '$window','utilsFactory'];

    function sectorFactory(Restangular, $rootScope, $q, $window, utilsFactory) {

        var resource = Restangular.all('units');
        var unitList;
        var unitElem;
        var list;
        var op = {
            sector: sector,
            allSectors: allSectors,
            programs: programs,
            unit: unit,
            update: update,
            history: history,
            precipitation: precipitation,
            irrigation: irrigation,
            accumulated: accumulated,
            one: one,
            crops: crops,
            cropGroup: cropGroup,
            cropClass: cropClass,
            cropFinal: cropFinal,
            cropClassAll: cropClassAll,
            cropsText: cropsText,
            cropsGroupText: cropsGroupText,
            cropsClassText: cropsClassText,
            cropsClassAllText: cropsClassAllText,
            cropsTextFinal: cropsTextFinal,
            setFilterStorage: setFilterStorage,
            getFilterStorage: getFilterStorage,
            setKcCrop: setKcCrop,
            model: model,
            getGroupSectors: getGroupSectors,
            getGroupSectorsElements: getGroupSectorsElements,
            updateGroupSectors: updateGroupSectors,
            getIrrigLinealValues: getIrrigLinealValues,
            sectorFlowFormat45:sectorFlowFormat45
        };

        return op;

        function save() {}



        function update(params) {
            var deferred = $q.defer();
            Restangular.one('units', params.pk.deviceId).one('sectors', params.pk.id).customPOST(params).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function accumulated(id, sensor) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('accumulated', sensor).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function irrigation(id) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors').get({ irrigation: true }).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function one(id, id2) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors', id2).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }
        function precipitation(id, id2, params) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors', id2).one('precipitation').get(params).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }
        function sector(id, type, installer) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors').get({ op: true }).then(function (response) {

                if (type == 7) {
                    list = response;
                    prepareFlowUnitsA4500(list, installer);
                    deferred.resolve(list);
                } else {
                    deferred.resolve(response);
                }


            });
            return deferred.promise;
        }

        function prepareFlowUnitsA4500(sectors, installer) {
            /* ng-if="vm.currentUnit.connected && vm.checkState(sector) == 2 && sector.programType == 0" */
            _.forEach(sectors, sector => {
                checkState(sector);
                sector.xFlowView = sectorFlowFormat45(sector.flowFormat,sector.xFlow);
                if (sector.xStatus == 1) {
                    if (sector.programType == 1) {
                        getIrrigLinealValues(sector.pk.deviceId, sector.pk.id).then(function (data) {
                            var xvalueSectorLineal = data.plain();
                            var xvalueSectorLinealLargest = [0, 0, 0];

                            xvalueSectorLineal.forEach(function (elem) {
                                if (xvalueSectorLinealLargest[2] < elem[2]) {
                                    xvalueSectorLinealLargest = elem;
                                }
                            });

                            sector.subprog = xvalueSectorLinealLargest[0];
                            sector.irrigProg = xvalueSectorLinealLargest[1];
                            sector.irrigPend = xvalueSectorLinealLargest[2];
                            flowUnits4500(sector, installer);
                        });
                    } else {
                        flowUnits4500(sector, installer);
                    }
                }
            });

        }

        function flowUnits4500(sector, installer) {
            var flowFormat;
            flowFormat = utilsFactory.getIrrigUnits(installer.prePostIrrigForSubprog, sector.programType, sector.irrigUnitsSubp,sector.sectorsByGroup, sector.irrigUnits);

            sector.irrigPendVisual = utilsFactory.getFormatedXValue4500(sector.irrigProg, flowFormat, installer.irrigDecimals);
            sector.irrigProgVisual = utilsFactory.getFormatedValue4500(sector.irrigPend, flowFormat ,installer.irrigDecimals);

        }

        function checkState(sector) {
            if (sector.xFlowStatus == 0 || sector.xFlowStatus == 1 || sector.xFlowStatus == 4 || sector.xFlowStatus == 8 || sector.xFlowStatus == 12
                || sector.xFlowStatus == 3 || sector.xFlowStatus == 6 || sector.xFlowStatus == 11 || sector.xFlowStatus == 14) {
                if (sector.xManualState == 1) {
                    //manual marcha
                    sector.state = 1;
                } else if (sector.xManualState == 2) {
                    //manual paro
                    sector.state = 0;
                } else {
                    if (sector.xFlowInput == 1) {
                        //inicio por entrada digital
                    } else {
                        if (sector.xStatus == 0) {
                            //sector parado
                            sector.state = 3;
                        } else {
                            //sector activo
                            sector.state = 2;
                        }
                    }
                }
            } else if (sector.xFlowStatus == 2) {
                //ERR no pasa agua
                sector.state = -1;
                sector.errFlowInput = 2;
            } else if (sector.xFlowStatus == 5 || sector.xFlowStatus == 9 || sector.xFlowStatus == 10 || sector.xFlowStatus == 13) {
                //Err pasa agua
                sector.state = -1;
                sector.errFlowInput = 5;
            } else if (sector.xFlowStatus == 7) {
                //ERROR
                sector.state = -1;
                sector.errFlowInput = 7;
            }
        }


        function sectorFlowFormat45(format, value) {
            let xFlowView;
            switch (format) {
                case 0:
                    xFlowView = value + ' m3/h';
                    break;
                case 1:
                    xFlowView = (value / 10) + ' m3/h';
                    break;
                case 2:
                    xFlowView = (value / 100) + ' m3/h';
                    break;
                case 3:
                    xFlowView = value  + ' L/h';
                    break;
                default:
                    break;
            }
            return xFlowView;
        }

        function allSectors(id) {

            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function getGroupSectors(deviceId) {
            var deferred = $q.defer();
            Restangular.one('units', deviceId).one('group-sectors').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function getGroupSectorsElements(deviceId) {
            var deferred = $q.defer();
            Restangular.one('units', deviceId).one('group-sectors-elements').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function updateGroupSectors(deviceId, groupSector) {
            var deferred = $q.defer();
            Restangular.one('units', deviceId).one('group-sectors').customPOST(groupSector).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function programs(id, id2) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors', id2).one('programs').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function history(id, id2, params) {
            var deferred = $q.defer();
            Restangular.one('units', id).one('sectors', id2).one('history').get(params).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function units(reload) {
            var deferred = $q.defer();
            if (reload !== undefined) {
                unitList = undefined;
            }
            if (unitList === undefined) {
                resource.getList().then(function (response) {
                    unitList = response;
                    deferred.resolve(unitList);
                });
            } else {
                deferred.resolve(unitList);
            }
            return deferred.promise;
        }

        function unit(id, params) {

            var deferred = $q.defer();
            Restangular.one('units', id).get(params).then(function (response) {
                unitElem = response;
                deferred.resolve(unitElem);
            });

            return deferred.promise;
        }

        function crops(page) {
            var deferred = $q.defer();
            Restangular.one('units').one('crops').get({ page: page, limit: 25 }).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropGroup() {
            var deferred = $q.defer();
            Restangular.one('units').one('cropsgroup').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropClass(id1) {
            var deferred = $q.defer();
            Restangular.one('units').one('cropsgroup', id1).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropClassAll() {
            var deferred = $q.defer();
            Restangular.one('units').one('cropsclass').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropFinal(id1, id2) {
            var deferred = $q.defer();
            Restangular.one('units').one('cropsgroup', id1).one('', id2).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function setFilterStorage(val, id) {
            var stored = JSON.stringify(val);
            $window.localStorage && $window.localStorage.setItem('sector-history-' + id, stored);
        }
        function getFilterStorage(id) {
            var stored = $window.localStorage && $window.localStorage.getItem('sector-history-' + id);
            return JSON.parse(stored);
        }

        function setKcCrop(idCultivo, kc) {
            var deferred = $q.defer();
            Restangular.one('units').one('crops', idCultivo).one('kc').customPOST(kc).then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropsText(id) {
            var deferred = $q.defer();
            Restangular.one('units').one('crop/i18n/', id).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }


        function cropsGroupText() {
            var deferred = $q.defer();
            Restangular.one('units').one('cropGroup/i18n/').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropsClassAllText() {
            var deferred = $q.defer();
            Restangular.one('units').one('cropClass/i18n/').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropsClassText(id1) {
            var deferred = $q.defer();
            Restangular.one('units').one('cropClass/i18n/', id1).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function cropsTextFinal(id1, id2) {
            var deferred = $q.defer();
            Restangular.one('units').one('crop/i18n/', id1).one('', id2).get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function getIrrigLinealValues(deviceId, id2) {
            var deferred = $q.defer();
            Restangular.one('units', deviceId).one('sectors', id2).one('sectorlinealirrig').get().then(function (response) {
                deferred.resolve(response);
            });
            return deferred.promise;
        }

        function model() {
            return {
                unittype: 0,
                modified: 4,
                modifiedDate: '01-08-2016 11:43',
                device: null,
                irrigationMeter: 1,
                name: '',
                output: 0,
                parcelId: null,
                enclosureId: null,
                pump1: 0,
                pump2: 0,
                pump3: 0,
                pump4: 0,
                fertilize1: 0,
                fertilize2: 0,
                fertilize3: 0,
                fertilize4: 0,
                stop1: 0,
                stop2: 0,
                stop3: 0,
                stop4: 0,
                plannedFlow: 0,
                waterHammerTemp: 0,
                outputAux: 0,
                phAutoTemp: 0,
                irrigationPresure: 0,
                flowInput: 0,
                area: 0,
                correctionCoefficient: 0,
                crop: 0,
                lngGPS: 0,
                latGPS: 0,
                modified2: 1,
                modifiedDate2: '19-01-2017 11:52',
                pk: {
                    deviceId: '0',
                    id: '0',
                },
                id: '0',
                deviceId: '0',
            };
        }
    }
})();
