import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .factory('unitFactory', unitFactory);

  unitFactory.$inject = ['Restangular', '$rootScope', '$q', '$translate'];

  function unitFactory(Restangular, $rootScope, $q, $translate) {
    var resource = Restangular.all('units');
    var unitList;
    var unitElem;

    var op = {
      units: units,
      unit: unit,
      programRegisters: programRegisters,
      phreference: phreference,
      registers: registers,
      meter: meter,
      totals: totals,
      totalsMeter: totalsMeter,
      conditioners: conditioners,
      unitTimeFormatter: unitTimeFormatter,
      getType: getType,
      checkStatus: checkStatus,
    };

    return op;

    function totals(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('totals')
        .get()
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function totalsMeter(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('totalsmeter')
        .get()
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function meter(id, id2) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('meters', id2)
        .get()
        .then(function (response) {
          deferred.resolve(response.plain());
        });
      return deferred.promise;
    }

    function programRegisters(id, id2, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().add(1, 'days').format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('programs', id2)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function registers(id, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().add(1, 'days').format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function units(reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        unitList = undefined;
      }
      if (unitList === undefined) {
        resource.getList().then(function (response) {
          unitList = response;
          deferred.resolve(unitList);
        });
      } else {
        deferred.resolve(unitList);
      }
      return deferred.promise;
    }

    function phreference(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('phref')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function unit(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .withHttpConfig({ cancel: deferred })
        .get(params)
        .then(function (response) {
          unitElem = response;
          deferred.resolve(unitElem);
        });

      return deferred.promise;
    }
    function conditioners(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('conditioners')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function unitTimeFormatter(hour) {
      if (hour) {
        const dayWeek = moment(hour, 'DD-MM-YYYY HH:mm').day();
        let newDay = '';
        switch (dayWeek) {
          case 1:
            newDay = '_mon';
            break;
          case 2:
            newDay = '_tue';
            break;
          case 3:
            newDay = '_wed';
            break;
          case 4:
            newDay = '_thu';
            break;
          case 5:
            newDay = '_fri';
            break;
          case 6:
            newDay = '_sat';
            break;
          case 1:
            newDay = '_sun';
            break;
        }
        return `${$translate.instant(`programs.edit.${newDay}`)} ${moment(hour, 'DD-MM-YYYY HH:mm').format(
          'DD/MM/YYYY | HH:mm'
        )}`;
      } else {
        return '--:--';
      }
    }

    function getType(type){
        switch(type){
            case 2:
                return 'A-4000';
            case 3:
                return 'A-2500';
            case 4:
                return 'A-BIT';
            case 5:
                return 'A-7000';
            case 6:
                return 'A-5500';
            case 7:
                return 'A-4500';
        }
    }

    function checkStatus(device) {
      if (device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else {
        return device.status;
      }
    }
  }
})();
