import { UNITS } from '../utils/units.enum';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorMapController', sensorMapController);

  sensorMapController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'sensorsFactory',
    '$confirm',
    '$timeout',
    'calculatedsFactory',
  ];

  function sensorMapController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    sensorsFactory,
    $confirm,
    $timeout,
    calculatedsFactory
  ) {
    var vm = $scope.$parent.vm;
    var layerLoaded;
    var currentMarker;
    var data_labels;
    vm.equipo;
    var sensorBkp;
    vm.UNITS = UNITS;
    activate();

    /*******************/

    function activate() {
      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.addSensorStep = 0; //Selection sensor
      vm.iconType = 0; //Selection type sensor
      vm.equipoInfo = false;
      vm.addMarkerS = addMarker;
      vm.setMarkerIcon = setMarkerIcon;
      vm.returnType = returnType;
      vm.unitHour = unitHour;
      vm.loadSelectionSensors = loadSelectionSensors;
      vm.prepareSensorInfo = prepareSensorInfo;
      vm.history_type = 0;
      vm.sensorsInMap = [];
      vm.fetchedSensors = [];
      layerLoaded = false;
    }

    function loadSensorLayer() {
      if (!layerLoaded && vm.map.id !== undefined) {
        mapsFactory.getMarkers(vm.map.id, 2).then((markers) => {
          layerLoaded = true;
          vm.sensorsInMap = markers;
          vm.fetchedSensors = [];
          loadMarkersToLayer(markers.plain());
          vm.map.addLayer(vm.sensorLayer);
        });
      } else {
        vm.map.addLayer(vm.sensorLayer);
      }
    }

    function loadMarkersToLayer(markers) {
      const requests = [];
      vm.sensorLayer.clearLayers();
      _.forEach(markers, (markerInfo) => {
        if (markerInfo.deviceId) {
          const unit = _.filter(vm.units, { id: Number(markerInfo.deviceId) });
          if (!_.isEmpty(unit)) {
            if (unit[0].type === 2 && Number(markerInfo.prop1) === 1) {
              //Meter on A4000 is Digital
              requests.push(sensorsFactory.getSensor(markerInfo.deviceId, markerInfo.prop1, markerInfo.prop2));
            } else {
              requests.push(sensorsFactory.getSensor(markerInfo.deviceId, markerInfo.prop1, markerInfo.prop3));
            }
          }
        }
      });

      _.filter(markers, (marker) => !marker.deviceId).forEach((markerInfo) => {
        switch (markerInfo.prop1) {
          case '3':
            calculatedsFactory.getOneDPV($rootScope.user.id, markerInfo.prop3).then((result) => {
              sensorToMarker(markerInfo, result);
            });
            break;

          case '4':
            calculatedsFactory.getOneETO($rootScope.user.id, markerInfo.prop3).then((result) => {
              sensorToMarker(markerInfo, result);
            });
            break;

          case '5':
            calculatedsFactory.getOneWaterDisp($rootScope.user.id, markerInfo.prop3).then((result) => {
              sensorToMarker(markerInfo, result, true);
            });
            break;
        }
      });

      $q.all(requests).then((sensors) => {
        _.forEach(sensors, (sensor, i) => {
          if (sensor) {
            var unit = _.filter(vm.units, { id: Number(sensor.pk.deviceId) });
            if (!_.isEmpty(unit)) {
              let stype = '0';
              if (sensor.classType === 'analog') {
                stype = '0';
              } else if (sensor.classType === 'meter') {
                stype = '1';
                if (unit[0].type === 2)
                  var markerRef = _.find(markers, {
                    deviceId: Number(sensor.pk.deviceId),
                    prop1: stype,
                    prop2: sensor.pk.id,
                  });
              } else if (sensor.classType === 'digital') {
                stype = '2';
              }
              if (markerRef === undefined)
                var markerRef = _.find(markers, {
                  deviceId: Number(sensor.pk.deviceId),
                  prop1: stype,
                  prop3: sensor.pk.id,
                });
              if (markerRef) {
                var marker = L.marker(new L.LatLng(markerRef.lat, markerRef.lng));
                //if(unit.type === 2 && markers[i].prop1 === 1){//Meter on A4000  is Digital
                var type = Number(markerRef.prop1);
                if (unit[0].type === 2 && markers[i].prop1 === 1) {
                  type = 1;
                }
                // if(unit[0].type === 4 && type === 0) {type = 1;}
                switch (
                  type //SensorType
                ) {
                  case 0: //Analog
                  case 1: //Meterd
                    if (unit[0].type !== 2) {
                      switch (
                        Number(markerRef.prop2) //IconType
                      ) {
                        case 0:
                          createNumericIcon(sensor, type, unit).then((resp) => {
                            marker.setIcon(resp);
                          });
                          break;
                        case 1:
                          createColorIcon(sensor, type).then((resp) => {
                            marker.setIcon(resp);
                          });
                          break;
                        case 2:
                          break;
                      }
                    } else {
                      createNumericIcon(sensor, type, unit, markerRef.prop3).then((resp) => {
                        marker.setIcon(resp);
                      });
                    }
                    break;
                  case 2: //Digital
                    marker.setIcon(createDigitalIcon(sensor));
                    break;
                }
                marker.properties = {};
                marker.properties.id = markerRef.id;
                marker.properties.deviceid = markerRef.deviceId;
                marker.properties.sensorType = markerRef.prop1;
                marker.properties.iconType = markerRef.prop2;
                marker.properties.sensorId = markerRef.prop3;
                marker.properties.sensor = sensor;
                sensors[i].type = markerRef.prop1;
                const index = vm.sensorsInMap.findIndex((sInMap) => sInMap.id === markerRef.id);
                vm.sensorsInMap[index] = _.assign({
                  ...vm.sensorsInMap[index],
                  name:
                    sensor.name ||
                    $filter('translate')(
                      `sensors.IDS_${
                        vm.currentUnit && vm.currentUnit.type === vm.UNITS.A_7000 ? sensor.pk.id : markerRef.prop3
                      }`
                    ),
                });
                vm.sensorLayer.addLayer(marker);
              }
            }
          }
        });
        vm.fetchedSensors.push(...sensors);
      });

      vm.sensorLayer.on({ click: showSensorInfo });
    }

    function sensorToMarker(markerInfo, result, isWaterSensor) {
      let sensorMarker;

      if (!result) {
        return;
      }

      if (result.plain()) {
        sensorMarker = result.plain();
      }

      if (isWaterSensor) {
        sensorMarker.units = '%';
      }

      if (!_.isEmpty(sensorMarker)) printMarker(sensorMarker, markerInfo);
    }

    function printMarker(sensor, markerRef) {
      var marker = L.marker(new L.LatLng(markerRef.lat, markerRef.lng));

      marker.setIcon(createCalcIcon(sensor));

      marker.properties = {};
      marker.properties.id = markerRef.id;
      marker.properties.sensorType = markerRef.prop1;
      marker.properties.sensorId = markerRef.prop3;
      marker.properties.sensor = sensor;
      const i = vm.sensorsInMap.findIndex((sInMap) => sInMap.id === markerRef.id);
      vm.sensorsInMap[i] = _.assign({
        ...vm.sensorsInMap[i],
        name:
          sensor.name ||
          $filter('translate')(
            `sensors.IDS__${vm.currentUnit.type === vm.UNITS.A_7000 ? sensor.pk.id : markerRef.prop3}`
          ),
      });
      vm.fetchedSensors.push(sensor);
      vm.sensorLayer.addLayer(marker);
    }

    function setMarkerIcon() {
      const { layer, latlng } = vm.props;
      switch (vm.sensorType) {
        case 0: //Analog
        case 1: //Meter
          switch (vm.iconType) {
            case 0:
              if (vm.unit.type !== 2) {
                createNumericIcon(vm.sensor, vm.sensorType, vm.unit).then((resp) => {
                  layer.setIcon(resp);
                });
              } else {
                sensorsFactory.getSensor(vm.sensor.pk.deviceId, 1, vm.sensor.prop2).then((sns) => {
                  sensorBkp = vm.sensor;
                  vm.sensor = sns;
                  createNumericIcon(vm.sensor, vm.sensorType, vm.unit, sensorBkp.pk.id).then((resp) => {
                    layer.setIcon(resp);
                  });
                });
              }
              break;
            case 1:
              createColorIcon(vm.sensor, vm.sensorType).then((resp) => {
                layer.setIcon(resp);
              });
              break;
            case 2:
              break;
          }
          break;
        case 2: //Digital
          layer.setIcon(createDigitalIcon(vm.sensor));
          break;
        case 3: //DPV
          layer.setIcon(createCalcIcon(vm.sensor));
          break;
        case 4: //ETo
          layer.setIcon(createCalcIcon(vm.sensor));
          break;
        case 5: //Water Disp
          vm.sensor.units = '%';
          layer.setIcon(createCalcIcon(vm.sensor));
          break;
      }

      saveMarker(latlng).then((resp) => {
        layer.properties = {};
        layer.properties.id = resp.id;
        layer.properties.deviceid = resp.deviceId;
        layer.properties.sensor = vm.sensor;
        layer.properties.sensorType = vm.sensorType;

        vm.sensorLayer.addLayer(layer);
        reloadSensors();
        layer.toggleEdit();
        vm.map.off('editable:drawing:commit');
        $scope.$emit('completedSave');
      });
    }

    function createCalcIcon(sensor) {
      var content =
        '<div class="sensor-marker-icon">' +
        '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
        '<div class="text">' +
        _.round(sensor.xValue, 2) +
        ' ' +
        (sensor.units || ' ') +
        '</div>' +
        '<div class="badge-overlay">' +
        '<div class="text-overlay"> ' +
        sensor.name +
        '</div>';
      '</div>' + '</div>' + '</div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    }

    function createDigitalIcon(sensor) {
      var labelClass;

      switch (sensor.xState) {
        case 0:
          labelClass = sensor.unittype === '2' && sensor.ActivedClosed ? 'active' : 'nactive';
          break;
        case 1:
          labelClass = sensor.unittype === '2' && sensor.ActivedClosed ? 'nactive' : 'active';
          break;
        case 2:
          labelClass = 'error';
          break;
      }
      var name = sensor.name === '' || sensor.name === null ? 'Sensor ' + sensor.pk.id : sensor.name;
      var content =
        '<div class="map-sensord-container"><div class="map-sensord-marker ' +
        labelClass +
        '"></div>' +
        '<div class="badge-overlay">' +
        '<div class="text-overlay">' +
        name +
        '</div>';
      '</div>' + '</div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    }

    function createColorIcon(sensor) {
      var value;
      var deferred = $q.defer();
      var content = '';

      switch (sensor.xState) {
        case 0:
        case 1:
          sensorsFactory.getFormat(sensor.pk.deviceId, sensor.formatId).then((resp) => {
            var format = resp.plain();
            sensor.format = format;
            var value = formatSensorValue(sensor, format);
            var color;
            if (validateCaption(sensor)) {
              color = getRangeColor(sensor.caption, sensor.xValue);
              var res = value[0] !== undefined ? value[0] + ' ' + value[1] : '-';
              var name = sensor.name === '' || sensor.name === null ? 'Sensor ' + sensor.pk.id : sensor.name;
              content =
                '<div class="map-sensord-container-color">' +
                '<div class="color-indicator" style="background-color:' +
                color +
                '"></div>' +
                '<div class="badge-overlay">' +
                '<div class="text-overlay">' +
                res +
                ' ' +
                name +
                '</div>';
              '</div>' + '</div>';
            } else {
              content = printDefaultContent(value, sensor);
            }

            deferred.resolve(
              L.divIcon({
                className: 'custom-marker',
                html: content,
              })
            );
          });
          break;
        default:
          content = printErrorContent(sensor);
          deferred.resolve(
            L.divIcon({
              className: 'custom-marker',
              html: content,
            })
          );
          break;
      }

      return deferred.promise;
    }

    function printDefaultContent(value, sensor) {
      var res = value !== undefined && value[0] !== undefined ? value[0] + ' ' + value[1] : '-';
      var name = sensor.name === '' || sensor.name === null ? 'Sensor ' + sensor.pk.id : sensor.name;
      return (
        '<div class="sensor-marker-icon">' +
        '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
        '<div class="text">' +
        res +
        '</div>' +
        '<div class="badge-overlay">' +
        '<div class="text-overlay">' +
        name +
        '</div>'
      );
      '</div>' + '</div>' + '</div>';
    }

    function printErrorContent(sensor) {
      var name = sensor.name === '' || sensor.name === null ? 'Sensor ' + sensor.pk.id : sensor.name;
      return (
        '<div class="sensor-marker-icon error">' +
        '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
        '<div class="text"> Error </div>' +
        '<div class="badge-overlay">' +
        '<div class="text-overlay">' +
        name +
        '</div>'
      );
      '</div>' + '</div>' + '</div>';
    }

    function createNumericIcon(sensor, type, unit, prop3) {
      var deferred = $q.defer();
      var value;
      var content;
      switch (type) {
        case 0: //Analog
          switch (sensor.xState) {
            case 0:
              content = printDefaultContent(undefined, sensor);
              deferred.resolve(
                L.divIcon({
                  className: 'custom-marker',
                  html: content,
                })
              );
              break;
            case 1:
              if (unit[0] && unit[0].type === 5) {
                var value = formatSensorValue(sensor, sensor.format);
                content = printDefaultContent(value, sensor);
                deferred.resolve(
                  L.divIcon({
                    className: 'custom-marker',
                    html: content,
                  })
                );
              } else {
                sensorsFactory.getFormat(sensor.pk.deviceId, sensor.formatId).then((resp) => {
                  var format = resp.plain();
                  sensor.format = format;
                  var value = formatSensorValue(sensor, format);
                  content = printDefaultContent(value, sensor);
                  deferred.resolve(
                    L.divIcon({
                      className: 'custom-marker',
                      html: content,
                    })
                  );
                });
              }

              break;
            default:
              content = printErrorContent(sensor);
              deferred.resolve(
                L.divIcon({
                  className: 'custom-marker',
                  html: content,
                })
              );
              break;
          }
          break;
        case 1: //Meter
          sensor.meter = true;

          if (sensor.xStatus >= 0 && sensor.xStatus !== undefined) {
            switch (sensor.xStatus) {
              case 0:
              case 1:
                if (sensor.xFlow !== undefined) {
                  var formats = {
                    0: 'm3/h',
                    1: 'L/h',
                    2: 'L/s',
                  };
                  var decimals = 2;
                  sensor.xFlow = sensor.xFlow.toString();
                  var first = sensor.xFlow.slice(0, sensor.xFlow.length - decimals);
                  var last = sensor.xFlow.slice(sensor.xFlow.length - decimals, sensor.xFlow.length);
                  sensor.xFlow = first + '.' + last;
                  sensor.value = [Number(sensor.xFlow), formats[sensor.flowFormat]];
                  content = printDefaultContent(sensor.value, sensor);
                  deferred.resolve(
                    L.divIcon({
                      className: 'custom-marker',
                      html: content,
                    })
                  );
                } else {
                  //A4000
                  sensorsFactory.prepareMeter4000(sensor.pk.deviceId, sensor, unit).then((meter) => {
                    var name = sensor.name === '' || sensor.name === null ? 'Sensor ' + sensor.pk.id : sensor.name;
                    content =
                      '<div class="sensor-marker-icon">' +
                      '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
                      '<div class="text">' +
                      (meter.active ? meter.value : '-') +
                      '</div>' +
                      '<div class="badge-overlay">' +
                      '<div class="text-overlay">' +
                      name +
                      '</div>';
                    '</div>' + '</div>' + '</div>';
                    deferred.resolve(
                      L.divIcon({
                        className: 'custom-marker',
                        html: content,
                      })
                    );
                  });
                }
                break;
              default:
                content = printErrorContent(sensor);
                deferred.resolve(
                  L.divIcon({
                    className: 'custom-marker',
                    html: content,
                  })
                );
            }
          } else {
            if (unit[0] && unit[0].type === 5) {
              var name = $filter('translate')('sensors.IDS7_' + sensor.pk.id);
              if (sensor.xFlow !== 0 && sensor.xFlow !== undefined) {
                var decimals = 2;
                sensor.xFlow = sensor.xFlow.toString();
                var first = sensor.xFlow.slice(0, sensor.xFlow.length - decimals);
                var last = sensor.xFlow.slice(sensor.xFlow.length - decimals, sensor.xFlow.length);
                sensor.xFlow = first + '.' + last;
              }
              //sensor.value = [Number(sensor.xFlow),formats[sensor.flowFormat]];

              content =
                '<div class="sensor-marker-icon">' +
                '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
                '<div class="text">' +
                (sensor.xFlow ? sensor.xFlow : '-') +
                '</div>' +
                '<div class="badge-overlay">' +
                '<div class="text-overlay">' +
                name +
                '</div>';
              '</div>' + '</div>' + '</div>';
              deferred.resolve(
                L.divIcon({
                  className: 'custom-marker',
                  html: content,
                })
              );
            } else {
              sensorsFactory.prepareMeter4000(sensor.pk.deviceId, sensor, unit).then((meter) => {
                var name =
                  sensor.name === '' || sensor.name === null || sensor.name === undefined
                    ? $filter('translate')('sensors.IDS_' + prop3)
                    : sensor.name;
                if (sensor.xFlow !== null && sensor.xFlow !== undefined) {
                  //var unit = _.filter(vm.units , {'id':Number(sensor.pk.deviceId)});
                  var decimals = 1;
                  sensorsFactory.installer(sensor.pk.deviceId).then((installer) => {
                    if (installer.instantFlow === 0) {
                      decimals = 2;
                    }

                    //if(sensor.xFlow == 0) sensor.xFlow = 00;
                    sensor.xFlow = sensor.xFlow.toString();
                    var first = sensor.xFlow.slice(0, sensor.xFlow.length - decimals);
                    if (first == '') first = '0';
                    var last = sensor.xFlow.slice(sensor.xFlow.length - decimals, sensor.xFlow.length);
                    sensor.xFlow = first + '.' + last;

                    content =
                      '<div class="sensor-marker-icon">' +
                      '<div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>' +
                      '<div class="text">' +
                      (meter.xFlow ? meter.xFlow : '-') +
                      ' m3/h</div>' +
                      '<div class="badge-overlay">' +
                      '<div class="text-overlay">' +
                      name +
                      ' </div>';
                    '</div>' + '</div>' + '</div>';
                    deferred.resolve(
                      L.divIcon({
                        className: 'custom-marker',
                        html: content,
                      })
                    );
                  });
                }
              });
            }
          }
          break;
      }
      return deferred.promise;
    }

    function getRangeColor(caption, value) {
      switch (caption.numLvl) {
        case 3:
          if (value > caption.range1from && value <= caption.range1to) {
            return caption.color1;
          }
          if (value > caption.range2from && value <= caption.range2to) {
            return caption.color2;
          }
          if (value > caption.range3from && value <= caption.range3to) {
            return caption.color3;
          }
          if (value <= caption.range1from) {
            return caption.color1;
          }
          if (value >= caption.range3to) {
            return caption.color3;
          }
          break;
        case 5:
          if (value > caption.range1from && value < caption.range1to) {
            return caption.color1;
          }
          if (value > caption.range2from && value < caption.range2to) {
            return caption.color2;
          }
          if (value > caption.range3from && value < caption.range3to) {
            return caption.color3;
          }
          if (value > caption.range4from && value < caption.range4to) {
            return caption.color4;
          }
          if (value > caption.range5from && value < caption.range5to) {
            return caption.color5;
          }
          if (value < caption.range1from) {
            return caption.color1;
          }
          if (value > caption.range5to) {
            return caption.color5;
          }
          break;
      }
    }

    function validateCaption(sensor) {
      if (sensor.caption === null) {
        sensor.caption_valid = false;
        return sensor.caption_valid;
      } else {
        switch (sensor.caption.numLvl) {
          case 3:
            if (
              sensor.caption.range1from !== null &&
              sensor.caption.range2from !== null &&
              sensor.caption.range3from !== null
            ) {
              if (
                sensor.caption.range1to !== null &&
                sensor.caption.range2to !== null &&
                sensor.caption.range3to !== null
              ) {
                if (
                  sensor.caption.color1 !== null &&
                  sensor.caption.color2 !== null &&
                  sensor.caption.color3 !== null
                ) {
                  sensor.caption_valid = true;
                  return sensor.caption_valid;
                }
              }
            }
            sensor.caption_valid = false;
            return sensor.caption_valid;
          case 5:
            if (
              sensor.caption.range1from !== null &&
              sensor.caption.range2from !== null &&
              sensor.caption.range3from !== null &&
              sensor.caption.range4from !== null &&
              sensor.caption.range5from !== null
            ) {
              if (
                sensor.caption.range1to !== null &&
                sensor.caption.range2to !== null &&
                sensor.caption.range3to !== null &&
                sensor.caption.range4to !== null &&
                sensor.caption.range5yo !== null
              ) {
                if (
                  sensor.caption.color1 !== null &&
                  sensor.caption.color2 !== null &&
                  sensor.caption.color3 !== null &&
                  sensor.caption.color4 !== null &&
                  sensor.caption.color5 !== null
                ) {
                  sensor.caption_valid = true;
                  return sensor.caption_valid;
                }
              }
            }
            sensor.caption_valid = false;
            return sensor.caption_valid;
          default:
            sensor.caption_valid = false;
            return sensor.caption_valid;
        }
      }
    }

    function formatSensorValue(sensor, format) {
      if (format !== undefined) {
        var value = '' + sensor.xValue;
        if (format.decimals > 0) {
          var first = value.slice(0, value.length - format.decimals);
          var last = value.slice(value.length - format.decimals, value.length);
          value = first + '.' + last;
        }
        sensor.value = _.toNumber(value) + ' ' + format.units;
        value = _.toNumber(value);
        format = format.units;
      }
      return [value, format];
    }

    function showSensorInfo(e) {
      vm.history = false;
      if (e.layer.properties.deviceid !== undefined && e.layer.properties.deviceid !== null) {
        var unit = _.filter(vm.units, { id: Number(e.layer.properties.deviceid) });
        if (!_.isEmpty(unit)) {
          vm.currentUnit = unit[0];
          vm.sensor = e.layer.properties.sensor.plain();
          if (vm.currentUnit.type === vm.UNITS.A_4000) {
            if (sensorBkp) {
              vm.sensor.prop3 = sensorBkp.pk.id;
            } else if (e.layer.properties.sensorId) {
              vm.sensor.prop3 = e.layer.properties.sensorId;
            }
          }
          prepareSensorInfo(e.layer.properties);
          currentMarker = e.layer;
          $timeout(() => {
            vm.map.invalidateSize();
            vm.map.flyTo(this.getBounds().getCenter());
          }, 200);

          $state.go(
            'maps.sensor',
            { unit: vm.currentUnit, sensor: vm.sensor, map: vm.map, fetchedSensors: vm.fetchedSensors },
            { reload: 'maps.sensor' }
          );
        }
      } else {
        vm.sensor = e.layer.properties.sensor;
        currentMarker = e.layer;
        $timeout(() => {
          vm.map.invalidateSize();
          vm.map.flyTo(this.getBounds().getCenter());
        }, 200);
        $state.go(
          'maps.sensor',
          {
            sensor: vm.sensor,
            map: vm.map,
            type: e.layer.properties.sensorType,
            unit: null,
            fetchedSensors: vm.fetchedSensors,
          },
          { reload: 'maps.sensor' }
        );
      }

      vm.overlay.show();
      $scope.$emit('editSectionChange', {
        type: 3,
        value: _.get(e, 'layer.properties.id'),
        elementsInMap: vm.sensorsInMap,
      });
    }

    function prepareSensorInfo(props) {
      vm.sensor = vm.sensor || props;
      if (props !== undefined) {
        vm.sensor.type = Number(props.sensorType);
      }
      switch (vm.sensor.type) {
        case 1:
          if (vm.sensor.value3 === 1) {
            if (vm.sensor.pk.id === '35') {
              vm.sensor.label = 'l/m2';
            } else {
              vm.sensor.label = 'm3/h';
            }
          }
          break;
      }
    }

    function formatSensor(value, format) {
      switch (vm.sensor.type) {
        case 0:
          return formatAnalog(value);
        case 1:
          if (_.isNumber(format)) return formatCounterA25(value, format);
          return formatCounter(value);
          break;
      }
    }
    function formatCounterA25(value, format) {
      //        vm.currentUnit.version  <= 218
      switch (format) {
        case 0: // 0000m3 register l.
          if (vm.currentUnit.version <= 218) value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 1: // 000.0m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 2: // 000.00m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 3: // 00000l. register l.
          vm.sensor.label = 'l';
          break;
        case 4: // 000.0l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 5: // 000.00l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 6: // 0000l/m2 register l.
          vm.sensor.label = 'l/m2';
          break;
        case 7: // 000.0 l/m2 register cl.
          value = value / 100;
          vm.sensor.label = 'l/m2';
          break;
        case 8: // 000.00 l/m2 register cl.
          vm.sensor.label = 'l/m2';
          value = value / 100;
          break;
        default: // Old
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
      }
      return value;
    }
    function formatAnalog(value) {
      if (vm.sensor.format !== undefined) {
        value = '' + value;
        if (vm.sensor.format.decimals > 0) {
          var first = value.slice(0, value.length - vm.sensor.format.decimals);
          var last = value.slice(value.length - vm.sensor.format.decimals, value.length);
          value = first + '.' + last;
        }
        value = _.toNumber(value);
        vm.sensor.label = vm.sensor.format.units;
      }
      return value;
    }

    function formatCounter(value) {
      value = '' + value;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function populateHistoryBar(dates) {
      var format;
      vm.labels = _.map(dates, (o) => {
        return moment(o.date, 'DD-MM-YYYY HH:mm').format('DD/MM');
      });
      var background = [];
      var value = _.map(dates, (o) => {
        return formatSensor(o.value, o.type);
      });
      vm.data = [value];
      if (validateCaption(vm.sensor)) {
        _.forEach(value, (val) => {
          background.push(getRangeColor(vm.sensor.caption, val));
        });
      } else {
        _.forEach(value, (val) => {
          background.push('#5882FA');
        });
      }
      vm.datasetOverride = [
        {
          yAxisID: 'y-axis-1',
          label: vm.sensor.label,
          borderWidth: 0,
          backgroundColor: background,
          hoverBackgroundColor: background,
        },
      ];
      vm.options = {
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      //vm.colors = ['#5882FA', '#5882FA'];
      vm.history = true;
    }
    function opacity(color, opacity) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return Color(color).alpha(alpha).rgbString();
    }
    function prepareLineChart(data) {
      //let to = moment().add(1,'days').format("DD-MM-YYYY");
      //let from = moment().subtract(7,'day').format("DD-MM-YYYY");
      let max = _.map(data, (o) => {
        return o.max;
      });
      let min = _.map(data, (o) => {
        return o.min;
      });
      let avg = _.map(data, (o) => {
        return o.avg;
      });
      data_labels = _.map(data, (o) => {
        return moment(o.from, 'DD-MM-YYYY HH:mm').format('DD/MM HH:mm');
      });
      let labels = _.map(data, (o) => {
        return moment(o.from, 'DD-MM-YYYY HH:mm').format('DD/MM');
      });
      let ctx = document.getElementById('base2').getContext('2d');

      if (vm.lineChart) {
        vm.lineChart.destroy();
      }

      var chartData = {
        datasets: [
          {
            label: 'max',
            backgroundColor: '#154360',
            borderColor: '#36A2EB',
            data: max, //[25,27,27,29,33,32,25],
            fill: false,
          },
          {
            label: 'min',
            backgroundColor: opacity('#36A2EB', 0.7),
            borderColor: '#66ff99',
            data: min, //[19,20,22,23,25,27,20],
            fill: '-1',
          },
          {
            label: 'avg',
            backgroundColor: '#EC7063',
            borderColor: '#FF6384',
            data: avg, //[22,24,25,27,28,28,23],
            fill: false,
          },
        ],
        labels: labels, //['a','b','c','d','e','f','g']
      };
      vm.lineChart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                /*                              var label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;
                                return label;*/
                var label = data_labels[tooltipItem[0].index];
                return label;
              },
            },
            mode: 'index',
            intersect: false,
          },
          elements: {
            line: {
              tension: 0,
            },
            point: {
              pointStyle: 'circle',
              radius: 0,
              hitRadius: 5,
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
        },
      });
      vm.history = true;
    }

    function saveMarker(latLng) {
      var params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      if (vm.sensorType < 3) {
        params.deviceId = vm.unit.id;
      }
      params.userId = $rootScope.user.id;
      params.type = 2;
      params.prop1 = vm.sensorType; //analog,meter,digital
      if (vm.sensorType < 3) {
        if (vm.sensorType == 1) {
          if (vm.sensor.prop2 == 0) {
            vm.iconType = 0;
          } else if (vm.sensor.prop2 == 1) {
            vm.iconType = 1;
          } else if (vm.unit.type === 2) {
            vm.iconType = vm.sensor.prop2;
          }
        }
        params.prop2 = vm.iconType; //Number,color,NumbAndColor
      }
      params.prop3 = vm.sensor.pk.id;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      vm.setMarkerIcon();
      vm.addSensorStep = 0;
    }

    function initAddMarker() {
      vm.filter.comp.sensor = true;
      vm.filter.comp.active = true;
    }

    function closeSensorInfo() {
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
        case 3:
          return 'Agrónic 2500';
        case 4:
          return 'Agrónic BIT';
        case 5:
          return 'Agrónic 7000';
        case 6:
          return 'Agrónic 5500';
      }
    }

    function unitHour() {
      if (vm.currentUnit && vm.currentUnit.date) {
        return moment(vm.currentUnit.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
      } else {
        return '--:--';
      }
    }

    function deleteSensorMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then((resp) => {
        vm.sensorInfo = false;
        reloadSensors();
        vm.sensorLayer.removeLayer(currentMarker);
      });
    }

    function loadSelectionSensors() {
      switch (vm.sensorType) {
        case 0: //Analog
          if (vm.unit !== undefined && vm.unit !== null) {
            sensorsFactory.analogsactive(vm.unit.id, 'all').then((resp) => {
              vm.sensors = resp.plain();
            });
          }
          break;
        case 1: //Meter
          if (vm.unit !== undefined && vm.unit !== null) {
            if (vm.unit.type === 2) {
              //A4000
              /* sensorsFactory.digitalsPage(vm.unit.id,{ 'limit': 50, 'page': 1 }).then(function(data){
                        vm.sensors = extractCounters(data.content);
                    }); */
              vm.sensors = [
                { pk: { id: 11, deviceId: vm.unit.id }, name: 'sensors.IDS_11', prop2: 1 },
                { pk: { id: 12, deviceId: vm.unit.id }, name: 'sensors.IDS_12', prop2: 2 },
                { pk: { id: 13, deviceId: vm.unit.id }, name: 'sensors.IDS_13', prop2: 3 },
                { pk: { id: 14, deviceId: vm.unit.id }, name: 'sensors.IDS_14', prop2: 4 },
                { pk: { id: 23, deviceId: vm.unit.id }, name: 'sensors.IDS_23', prop2: 1 },
                { pk: { id: 24, deviceId: vm.unit.id }, name: 'sensors.IDS_24', prop2: 2 },
                { pk: { id: 25, deviceId: vm.unit.id }, name: 'sensors.IDS_25', prop2: 3 },
                { pk: { id: 26, deviceId: vm.unit.id }, name: 'sensors.IDS_26', prop2: 4 },
              ];
            } else {
              sensorsFactory.meters(vm.unit.id).then((resp) => {
                vm.sensors = resp.plain();
              });
            }
          }
          break;
        case 2: //Digital
          if (vm.unit !== undefined && vm.unit !== null) {
            if (vm.unit.type === 2) {
              //A4000
              sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
                var sensors = data.content;
                var counters = extractCounters(sensors);
                vm.sensors = _.xor(sensors, counters);
              });
            } else {
              sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
                vm.sensors = data.content;
              });
            }
          }
          break;
        case 3: //Sensor DPV
          calculatedsFactory.getDPV($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
          });
          break;
        case 4: //Sensor ETo
          calculatedsFactory.getETO($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
          });
        case 5: //Sensor Water Disp
          calculatedsFactory.waterDisp($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
          });
          break;
      }
    }

    function closeSensorInfo() {
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
    }

    function extractCounters(sensors) {
      return _.filter(sensors, (o) => {
        return Number(o.pk.id) >= 11 && Number(o.pk.id) <= 35;
      });
    }

    function reloadSensors() {
      layerLoaded = false;
      loadSensorLayer();
    }

    $scope.$on('deleteSensor', () => {
      deleteSensorMarker();
      closeSensorInfo();
    });

    $scope.$on('closeSensor', () => {
      closeSensorInfo();
    });

    $scope.$on('reloadMap', () => {
      layerLoaded = false;
    });

    $scope.$on('addSensorMarker', (e, args) => {
      vm.props = args;
      initAddMarker();
    });

    $scope.$on('loadSensorLayer', () => {
      loadSensorLayer();
    });

    $rootScope.$on('loadSensorData', (ev, { sensor, currentUnit }) => {
      let request;

      if (currentUnit.type === 2 && Number(sensor.prop1) === 1) {
        //Meter on A4000 is Digital
        request = sensorsFactory.getSensor(sensor.deviceId, sensor.prop1, sensor.prop2);
      } else {
        request = sensorsFactory.getSensor(sensor.deviceId, sensor.prop1, sensor.prop3);
      }

      switch (sensor.prop1) {
        case '3':
          request = calculatedsFactory.getOneDPV($rootScope.user.id, sensor.prop3);
          break;

        case '4':
          request = calculatedsFactory.getOneETO($rootScope.user.id, sensor.prop3);
          break;

        case '5':
          request = calculatedsFactory.getOneWaterDisp($rootScope.user.id, sensor.prop3);
          break;
      }

      request.then((sensor) => {
        prepareSensorInfo(sensor);
      });
    });
  }
})();
