import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('rainController', rainController);

  rainController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$confirm',
    '$filter',
  ];

  function rainController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $confirm,
    $filter
  ) {
    var vm = this;
    var backups;
    vm.effectiveRain = [];
    vm.VeggaOverlayTab = VeggaOverlayTab;

    activate();

    function activate() {
      if ($rootScope.devicesArray !== null) {
        vm.units = $rootScope.devicesArray;
      } else {
        $state.go('agroclimatic');
      }

      loadSensors();
      vm.unitName = unitName;
      vm.newSensor = newSensor;
      vm.deleteSensor = deleteSensor;
      vm.updateSensor = updateSensor;
      vm.sensorEdition = sensorEdition;
      vm.cancelEdition = cancelEdition;
      vm.loadPluviometers = loadPluviometers;
      vm.changeRain = changeRain;
      vm.changingRain = changingRain;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.cancel = cancel;
      backups = {};
      $scope.$emit('stateChanged');
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        var unit = _.filter(vm.units, (o) => {
          return o.device.id === sensor.deviceId;
        })[0];
        return unit?.device?.name || '-';
      }
    }

    function loadSensors() {
      calculatedsFactory.effectiveRain(UserData.id).then((result) => {
        vm.effectiveRain = result.plain();
      });
    }

    function newSensor() {
      vm.currentRainSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();

      /*calculatedsFactory.newEffectiveRain(UserData.id).then( result => {
            var ef = result.plain();
            ef.hide = true;
            vm.effectiveRain.push(ef);
        });*/
    }

    function deleteSensor() {
      calculatedsFactory.deleteEffectiveRain(UserData.id, vm.currentRainSelected.pk.id).then((result) => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.effectiveRain = vm.effectiveRain.filter(
          (effectiveRain) => effectiveRain.pk.id !== vm.currentRainSelected.pk.id
        );
        vm.currentRainSelected = null;
      });
    }

    function sensorEdition(sensor) {
      if (sensor !== undefined && sensor.sensors === undefined) {
        loadPluviometers(sensor);
      }

      var sensor_backup = {};
      angular.copy(sensor, sensor_backup);
      backups[sensor.pk.id] = sensor_backup;

      vm.currentRainSelected = sensor;
      vm.currentTab = VeggaOverlayTab.EDIT;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function cancelEdition() {
      if (vm.currentRainSelected && vm.currentRainSelected.pk) {
        angular.copy(backups[vm.currentRainSelected.pk.id], vm.currentRainSelected);
      }
      vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
    }

    function updateSensor() {
      calculatedsFactory.newEffectiveRain(UserData.id).then((result) => {
        var ef = result.plain();
        vm.currentRainSelected.pk = ef.pk;
        calculatedsFactory.updateEffectiveRain(UserData.id, vm.currentRainSelected).then((result) => {
          vm.currentRainSelected = result.plain();
          if (vm.currentTab === VeggaOverlayTab.NEW) {
            vm.effectiveRain.push(vm.currentRainSelected);
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentRainSelected = null;
          }
        });
      });
    }

    function loadPluviometers(sensor, onChange) {
      if (sensor.deviceId !== null) {
        var unit = _.filter(vm.units, (o) => {
          return o.device.id === sensor.deviceId;
        })[0];

        if (onChange) {
          sensor.sensorId = null;
        }

        if (!unit) return;
        sensor.deviceType = unit.device.type;
        switch (unit.device.type) {
          case 2:
            sensor.sensors = [{ pk: { id: 35 } }];
            break;
          case 3:
          case 4:
            sensorsFactory.meters(sensor.deviceId).then((result) => {
              sensor.sensors = result.plain();
            });
            break;
        }
      }
    }

    function changeRain(e) {
      if (e.detail.value) {
        vm.currentRainSelected = null;
        vm.currentRainSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.EDIT;
        sensorEdition(vm.currentRainSelected);
      }
    }

    function changingRain($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeRain($event);
          break;
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentRainSelected = null;
          }
        });
      } else {
        cancelEdition();
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentRainSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentRainSelected = null;
        }
      }
    }

    $scope.$on('refresh', function (event, args) {});
  }
})();
