import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersHeaderController', filtersHeaderController);

  filtersHeaderController.$inject = ['$scope', '$state', 'filterFactory', '$confirm', '$filter', 'manualFactory'];

  function filtersHeaderController($scope, $state, filterFactory, $confirm, $filter, manualFactory) {
    var vm = this;
    vm.save;
    vm.cancel;
    vm.filtersHeader;
    vm.activeAll;
    vm.headerId;
    vm.header1;
    vm.header2;
    vm.header3;
    vm.header4;
    vm.cancel;
    vm.save;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.activeAll = true;
        vm.currentUnit = $state.params.unit;
        vm.showHeaders = showHeaders;
        vm.showOverlay = showOverlay;
        vm.changeState = changeState;
        vm.getNumberArray = getNumberArray;
        vm.cancel = cancel;
        vm.save = save;
        vm.exitMalfunction = exitMalfunction;
        vm.stopCleaning = stopCleaning;
        vm.startCleaning = startCleaning;
        vm.outOfService = outOfService;
        vm.exitOutOfService = exitOutOfService;
        /* vm.filters = $state.params.filters;      
            vm.currentHeader = $state.params.headerId;
            vm.selectFilter = vm.filters[Number(vm.currentHeader) -1]; */
        if ($state.params.filters) {
          vm.filtersHeader = $state.params.filters;
          showManualButtons();
        } else {
          loadFilters();
        }
      } else {
        $state.go('units');
      }
    }

    function loadFilters() {
      filterFactory.all(vm.currentUnit.id).then(function (data) {
        if (!vm.currentUnit.inoptions.optionPlus) {
          vm.filtersHeader = _.filter(data.plain(), (f) => {
            return f.id === 1;
          });
          vm.headerId = 1;
        } else {
          vm.filtersHeader = data.plain();
        }
        showManualButtons();
        /* if (vm.filtersGroup && vm.filtersGroup.length > 0) {
                vm.currentGroupSelected = JSON.parse(JSON.stringify(vm.filtersGroup[vm.index]));
            } */
      });
    }

    function changeHeader() {
      if (vm.header1) {
        vm.headerId = 1;
      } else if (vm.header2) {
        vm.headerId = 2;
      } else if (vm.header3) {
        vm.headerId = 3;
      } else if (vm.header4) {
        vm.headerId = 4;
      } else {
        vm.headerId = 1;
        vm.activeAll = false;
        vm.header1 = true;
      }
    }

    function showOverlay(state) {
      if (state !== 'filters.headers') {
      }
      changeHeader();
      $state.go(state, { filters: vm.filtersHeader, headerId: vm.headerId });

      vm.overlayEl = document.querySelector('#filter-header-overlay');
      vm.overlayEl.show();
    }

    function showHeaders(value) {
      switch (value) {
        case 'all':
          vm.activeAll = true;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header1':
          vm.activeAll = false;
          vm.header1 = true;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header2':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = true;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header3':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = true;
          vm.header4 = false;
          break;
        case 'header4':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = true;
          break;
      }
    }

    function showManualButtons() {
      _.forEach(vm.filtersHeader, (filter) => {
        switch (filter.xStatus) {
          case 0:
            filter.btnStartFilter = true;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 1:
          case 2:
          case 4:
          case 5:
          case 6:
            filter.btnStartFilter = false;
            filter.btnStopFilter = true;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 3:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 7:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = true;
            break;
          default:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = false;
            break;
        }
      });
    }

    function startCleaning(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 22;
        params.parameter1 = header;
        params.parameter2 = 1;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function stopCleaning(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 22;
        params.parameter1 = header;
        params.parameter2 = 0;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function outOfService(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 15;
        params.parameter1 = header;
        params.parameter2 = 1;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function exitOutOfService(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 15;
        params.parameter1 = header;
        params.parameter2 = 0;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function exitMalfunction(header) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 23;
        params.parameter1 = header;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then((result) => {});

        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    //INI funcions copiades de filtres, quan es faci la consulta shan de revisar
    /* function checkState(){
        _.forEach(vm.filtersHeader, filter =>{           
            if(filter.xStatus){
                filter.state = 'cleaning';
            }else if(vm.currentUnit.ram && vm.currentUnit.ram.filterMalfunction && filter.xActivation){
                filter.state = 'malfunction';
            }else{
                var engines = _.pickBy(filter, function(value, key) {
                    return _.startsWith(key, 'engine');
                });
                _.forEach(engines, (val,key)  => {
                        if(val){
                            var index = key.substr(key.length - 1); 
                            if(vm.currentUnit.engines){
                                var engine = vm.currentUnit.engines[index-1];
                                if(engine.xState){
                                    filter.state = 'filtering';
                                }
                            }  
                        }

                });
                if(filter.state !== 'filtering'){
                    filter.state = 'noactive';
                }
            }            
        });
    } */

    function getNumberArray(num) {
      return new Array(num);
    }
    //FI

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('#filter-header-overlay').dismiss();
          $state.go('a45filters', { unit: vm.currentUnit, filters: vm.filtersHeader });
        });
      } else {
        document.querySelector('#filter-header-overlay').dismiss();
        $state.go('a45filters', { unit: vm.currentUnit });

        //(typeof $event !== 'undefined') ? $state.go('a25programs',{program:vm.program,unit:vm.currentUnit}) : $state.go('a25programs.detail',{program:vm.program,id:vm.program.pk.id});
      }
    }
    function save(_ev) {
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function changeState(location, params) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(location, params); //Canviem d'estat
        });
      } else {
        changeHeader();
        $state.go(location, params);
      }
    }

    $scope.$on('refresh-format', function (event, args) {
      vm.currentUnit = args.unit;
      vm.overlayEl.dismiss();
    });
    $scope.$on('refresh', function (event, args) {
      /* if(args.unit){
            angular.copy(args.unit, vm.currentUnit)
            $q.all([loadDigitals().then(),loadAnalogs().then(), loadActiveLogics().then()]).then(o =>{
                if(vm.currentUnit.type === UNITS.A_4000){
                    checkConditioners();
                }  
            });
            $scope.$emit('refreshed',{message:'refreshed'});
        } */
    });

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        loadFilters();
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });

    $scope.$on('headerGeneralFiltersUpdate', (e, args) => {
      vm.currentUnit.installer.headerGeneralFilters = args;
      console.log(vm.currentUnit.installer.headerGeneralFilters, args);
    });
  }
})();
