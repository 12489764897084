(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('psepController', psepController);

  psepController.$inject = ['$scope', '$state', 'commFactory', 'PSEP'];

  function psepController($scope, $state, commFactory, PSEP) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.psep = PSEP;
        backup = angular.copy(vm.psep);
      } else {
        $state.go('units');
      }
    }

    function save() {
      commFactory.updatePSEP(vm.currentUnit.id, vm.psep).then(
        (result) => {
          vm.psep = result;
          backup = angular.copy(vm.psep);
        },
        (error) => {}
      );
    }

    function cancel_edition() {
      vm.psep = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('refresh', function (event, args) {});
  }
})();
