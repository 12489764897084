// @ts-ignore
import { showLoader, hideLoader } from '@vegga/front-utils';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('veggaLoader', [
      '$http',
      '$rootScope',
      '$transitions',
      function ($http, $rootScope, $transitions) {
        return {
          restrict: 'A',
          link: function (scope) {
            scope.isLoading = function () {
              return $http.pendingRequests.length > 0;
            };

            $transitions.onStart({}, function () {
              if ($rootScope.isRefreshingApp) {
                $rootScope.isRefreshingApp = false;
                $http.pendingRequests.forEach(function (pendingReq) {
                  if (pendingReq.cancel) {
                    pendingReq.cancel.resolve();
                    showLoading();
                  }
                });
              }
            });

            scope.$watch(scope.isLoading, function (v) {
              if (v) {
                if (!$rootScope.isRefreshingApp) {
                  showLoading();
                }
              } else {
                $rootScope.$broadcast('showSkeleton', false);
                hideLoader();
              }
            });

            function showLoading() {
              showLoader();
            }
          },
        };
      },
    ]);
})();
