import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('externalmodController', externalmodController);

  externalmodController.$inject = ['$scope', '$state', 'commFactory'];

  function externalmodController($scope, $state, commFactory) {
    var vm = this;
    vm.linkBoxes;
    var external_modules_types;
    vm.selectActive = false;
    vm.UNITS = UNITS;

    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.save = save;
        vm.cancel = cancel;

        external_modules_types = {
          1: { type: "Monocable", name: "AM120" },
          2: { type: "Radio", name: "AR868-16" },
          3: { type: "Radio", name: "AR24" },
          4: { type: "Radio", name: "AR433" },
          5: { type: "Radio", name: "MI" },
          6: { type: "Radio", name: "ME" },
          7: { type: "Radio", name: "R868" },
          8: { type: "Radio", name: "AgroBee" },
          9: { type: "Radio", name: "AgroBeeL" },
          10: { type: "Cable", name: "SDI12" }
        };
        loadLinkBoxes();
        if (vm.currentUnit.type === UNITS.A_4500 && vm.currentUnit.inoptions.optionPlus) {
          loadExternalModBus();
        }
        vm.openModule = openModule;
      } else {
        $state.go('units');
      }
    }

    function loadExternalModBus() {
      commFactory.modBusExterno(vm.currentUnit.id).then(list => {
        vm.externalModBus = { typeName: "externModbus" };  // used i18n name in order to translate it on the html
        vm.externalModBus.externalModules = list.plain();
        setLinkBoxState(vm.externalModBus);
      });
    }

    function loadLinkBoxes() {
      commFactory.linkBox(vm.currentUnit.id).then((list) => {
        vm.linkBoxes = list.plain();
        checkLinkStates();
      });
    }

    function checkLinkStates() {
      let agrobee = vm.currentUnit.inoptions.agrobee;
      let agrobeel = vm.currentUnit.inoptions.agrobeel;
      let agrobeel1;
      let agrobeel2;
      if (vm.currentUnit.type === UNITS.A_4500) {
        if (vm.currentUnit.inoptions.agroBeeL1) {
          agrobeel1 = true;
        } else if (vm.currentUnit.inoptions.agroBeeL2) {
          agrobeel2 = true;
        }
      }
      let sdi12 = true; // Ja no es una opció, sempre será actiu.
      vm.linkBoxes.forEach((box) => {

        var model = external_modules_types[box.pk.type];
        box.type = model.type;
        box.typeName = model.name;

        switch (box.pk.type) {
          case 1:
            if (vm.currentUnit.inoptions.optionMonocable || vm.currentUnit.inoptions.am) {
              if (vm.currentUnit.type === UNITS.A_4500) {
                box.enabled = box.active;
                setAR433AM120State(box);
              } else {
                box.enabled = true;
                if (box.xCommunicate) {
                  if (box.pk.type === 7) {
                    if (box.port !== null && box.port !== 0) {
                      box.state = "comm";
                    } else {
                      box.state = "noconf";
                    }
                  } else {
                    box.state = "comm";
                  }
                } else if (box.active && !box.xCommunicate) {
                  box.state = "error";
                } else {
                  box.state = "nocomm";
                }
              }
            }
            break;
          case 2:
          case 3:
          case 4:
          case 7:
            if (vm.currentUnit.inoptions.optionRadio || vm.currentUnit.inoptions.ar) {
              if (vm.currentUnit.type === UNITS.A_4500) {
                box.enabled = box.active;
                setAR433AM120State(box);
              } else {
                box.enabled = true;
                if (box.xCommunicate) {
                  if (box.pk.type === 7) {
                    if (box.port !== null && box.port !== 0) {
                      box.state = "comm";
                    } else {
                      box.state = "noconf";
                    }
                  } else {
                    box.state = "comm";
                  }
                } else if (box.active && !box.xCommunicate) {
                  box.state = "error";
                } else {
                  box.state = "nocomm";
                }
              }
            }
            break;
          case 5:
          case 6:
            if (box.xCommunicate) {
              if (box.pk.type === 7) {
                if (box.port !== null && box.port !== 0) {
                  box.state = 'comm';
                } else {
                  box.state = 'noconf';
                }
              } else {
                box.state = 'comm';
              }
            } else if (box.active && !box.xCommunicate) {
              box.state = 'error';
            } else {
              box.state = 'nocomm';
            }
            box.enabled = true;
            break;
          case 8:
            if (agrobee) {
              setLinkBoxState(box);
              box.enabled = true;
            }
            break;
          case 9:
            if (vm.currentUnit.type !== UNITS.A_4500) {
              if (agrobeel) {
                box.enabled = true;
                setLinkBoxState(box);
              }
              break;
            } else {
              if (agrobeel1 && box.pk.id === 1) {
                box.enabled = true;
                setLinkBoxState(box);
              } else if (agrobeel2 && box.pk.id === 2) {
                box.enabled = true;
                setLinkBoxState(box);
              }
            }
            break;
          case 10:
            if (sdi12) {
              box.enabled = true;
              box.state = "comm";
            }
            break;
        }
      });
    }

    function setAR433AM120State(linkBox) {
      switch (linkBox.xcomunica) {
        case 0:
          linkBox.state = "noconf";
          break;
        case 1:
          linkBox.state = "nocomm";
          break;
        case 2:
          linkBox.state = "comm";
          break;
        case 3:
          linkBox.state = "error";
          break;
        default:
          linkBox.state = "noconf";
      }
    }

    function setLinkBoxState(linkBox) {
      switch (linkBox.xState) {
        case 0:
          linkBox.state = "noconf";
          break;
        case 1:
          linkBox.state = "nocomm";
          break;
        case 2:
          linkBox.state = "comm";
          break;
        case 3:
          linkBox.state = "error";
          break;
        default:
          linkBox.state = "noconf";
      }
    }

    function openModule(_e, box) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.box = box;
      $state.go('modules.detail', { unit: vm.currentUnit, box: box, type: box.typeName });
    }

    function save() {
      $scope.$broadcast('saveEM');
    }

    function cancel() {
      $scope.$broadcast('cancelEM');
    }

    $scope.$on('emActive', (e, args) => {
      vm.selectActive = args;
    });

    $scope.$on('refresh', function (event, args) {
      loadLinkBoxes();
    });
  }
})();
