import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outEnginesController', outEnginesController);

  outEnginesController.$inject = ['$rootScope', '$scope', '$state', 'configFactory'];

  function outEnginesController($rootScope, $scope, $state, configFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.UNITS = UNITS;
    vm.currentHeader;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.currentHeader = $state.params.headerId || 1;
        if (vm.currentUnit.type === UNITS.A_4500) {
          var params = { add: ["engines"] };
          configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then(data => {
            vm.config = data.plain();
            vm.engines = vm.config.engines;
            backup = angular.copy(vm.config);
          });
        } else {
          configFactory.getEngines(vm.currentUnit.id).then((data) => {
            vm.currentUnit.engines = data.plain();
            vm.engines = angular.copy(vm.currentUnit.engines);
          });
        }

        if (vm.currentUnit.type === UNITS.A_2500) {
          vm.doubleVolt = vm.currentUnit.fabric.doubleVoltage !== 1;
        }
        if (vm.currentUnit.type === UNITS.A_BIT) {
          configFactory.getAlarms(vm.currentUnit.id).then((data) => {
            vm.currentUnit.alarms = data.plain();
            vm.alarms = angular.copy(vm.currentUnit.alarms);
          });
        }
        if (vm.currentUnit.type === UNITS.A_7000) {
          var params = {};
          configFactory.get(vm.currentUnit.id, params).then((result) => {
            vm.config = result;
          });
        }
        vm.selected = 0;
      } else {
        $state.go('units');
      }
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      vm.form.$setPristine();
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.config.engines = vm.engines;
        configFactory.updateConfigHeader(vm.currentUnit.id, vm.config).then(result => {
          vm.config = result.plain();
          if (vm.config) {
            vm.config.engines = _.sortBy(vm.config.engines, [function(o) {
              return o.pk.id;
            }]);
          }
          backup = angular.copy(vm.config);
        });
      } else {
        _.forEach(vm.engines, (engine) => {
          engine.unittype = vm.currentUnit.type;
        });

        configFactory.updateEngines(vm.currentUnit.id, vm.engines).then((result) => {
          vm.currentUnit = result;
          vm.engines = _.sortBy(vm.currentUnit.engines, [function(o) {
            return o.pk.id;
          }]);
          if (vm.currentUnit.type === UNITS.A_BIT) {
            configFactory.updateAlarms(vm.currentUnit.id, vm.alarms).then((result) => {
              vm.currentUnit = result;
              vm.alarms = _.sortBy(vm.currentUnit.alarms, [function(o) {
                return o.pk.id;
              }]);
            });
          }
          if (vm.currentUnit.type === UNITS.A_7000) {
            configFactory.updateConfig(vm.currentUnit.id, vm.config).then((result) => {
              vm.config = result;
            });
          }
          vm.form.$setPristine();
        });
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.engines = vm.config.engines;
      } else {
        vm.engines = {};
      }
      $state.go("outputs.engines", { unit: vm.currentUnit }, { reload: "outputs.engines" });
    }

    $scope.$on("refresh", function(event, args) {
    });

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
