import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    //TODO - check moment
    .constant('moment', moment)
    .controller('unitController', unitController);

  unitController.$inject = [
    '$rootScope',
    'resFactory',
    '$scope',
    '$state',
    '$confirm',
    'unitFactory',
    '$filter',
    'manualFactory',
    'userFactory',
  ];

  function unitController(
    $rootScope,
    resFactory,
    $scope,
    $state,
    $confirm,
    unitFactory,
    $filter,
    manualFactory,
    userFactory,
  ) {
    var vm = this;
    vm.menu;
    vm.loadState;
    vm.unitTimeFormatter;
    vm.getType;
    vm.checkStatus;
    vm.form;

    // function definition and props for edit unit
    vm.configUnit = configUnit;
    vm.setOverlayState = setOverlayState;
    vm.manualDate = manualDate;
    vm.cancel = cancel;
    vm.save = save;
    vm.manualActions = manualActions;
    vm.formConfigUnit;
    vm.unitDTO;
    vm.unitDate;
    vm.unitTime;
    vm.editOverlayView;

    activate();

    function activate() {
      if ($state.params.unit !== null) {
        vm.currentState = checkStateInclude;
        vm.loadState = loadState;
        vm.currentUnit = $state.params.unit;
        vm.menu = prepraeMenuAccordingUnitModel(resFactory.menu(vm.currentUnit.type));
        vm.getType = unitFactory.getType;
        vm.unitTimeFormatter = unitFactory.unitTimeFormatter;
        vm.checkStatus = unitFactory.checkStatus;
        vm.unitDateDTO = vm.unitTimeFormatter(vm.currentUnit.ram.date);
        defaultView(vm.currentUnit);
      } else {
        $state.go('units');
      }
    }

    function defaultView(unit) {
      $state.go(vm.menu[0].state, { unit });
    }

    function prepraeMenuAccordingUnitModel(menu) {
      if (vm.currentUnit.type === UNITS.A_2500) {
        return menu.filter((item) => {
          return (
            (vm.currentUnit.inoptions.pivots ? item : item.state !== 'pivots') &&
            (vm.currentUnit.inoptions.plus ? item : item.state !== 'conditioner') &&
            (vm.currentUnit.inoptions.plus ? item : item.state !== 'solar')
          );
        });
      }else if(vm.currentUnit.type === UNITS.A_4500){
            return menu.filter(item => {
                return (vm.currentUnit.inoptions.optionHidro ? item : item.state !== 'nebulization' ) &&
                        (vm.currentUnit.inoptions.optionPlus ? item :  item.state !== 'sectorsgroup')
            })
        } else {
        return menu;
      }
    }

    function checkStateInclude(stateName) {
      if (!$state.includes(stateName)) {
        if (stateName.includes('.detail') || stateName.includes('.edit') || stateName.includes('.config')) {
          stateName = stateName.split('.')[0];
        }
      }

      return $state.includes(stateName);
    }

    function loadState(event) {
      const item = vm.menu[event.detail.index];
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formFromUnitCancel'); //Emetem cancelació de canvis
          vm.form = null;
          if ($state.current.parent !== item.state) {
            $state.go(item.state, { unit: vm.currentUnit, config: item.config });
            vm.showSkeleton = true;
          }
        });
      } else {
        if (item && $state.current.parent !== item.state) {
          // toogledConfig = true;

          $state.go(item.state, { unit: vm.currentUnit, config: item.config });
          vm.showSkeleton = true;

        }
      }
    }

    function backup() {
      vm.unitDTOCopy = {};
      angular.copy(vm.unitDTO, vm.unitDTOCopy);
    }

    function configUnit(event, state) {
      const overlay = document.querySelector('#edit-unit');
      overlay.show();
      vm.editOverlayView = state;
      vm.unitDTO = {
        name: vm.currentUnit.name,
        date: moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').toDate(),
        time: moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm'),
      };
      backup();
      if (state === 'manual') {
        $state.go('edit-unit', { unit: vm.currentUnit });
      }
    }

    function setOverlayState(state) {
      if (vm.formConfigUnit && vm.formConfigUnit.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          vm.formConfigUnit.$setPristine();
          angular.copy(vm.unitDTOCopy, vm.unitDTO);
          vm.editOverlayView = state;
          if (state === 'manual') {
            $state.go('edit-unit', { unit: vm.currentUnit });
          }
        });
      } else {
        vm.editOverlayView = state;
        if (state === 'manual') {
          $state.go('edit-unit', { unit: vm.currentUnit });
        }
      }
    }

    function setUnitParams(type, deviceId, action, param1, param2, param3, param4, param5, param6, param7) {
      return {
        type: type,
        deviceId: deviceId,
        action: action,
        parameter1: param1,
        parameter2: param2,
        parameter3: param3,
        parameter4: param4,
        parameter5: param5,
        parameter6: param6,
        parameter7: param7,
      };
    }

    function manualDate() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        userFactory.updateName(vm.currentUnit.id, vm.unitDTO.name).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
            var params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              20,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              0,
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY')),
              moment(vm.unitDTO.date).isoWeekday()
            );
            break;
          case 3:
            var params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              14,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 4:
            var params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              13,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 5:
            var params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              23,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 6:
            var params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              14,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
            case 7:
                var params = setUnitParams(vm.currentUnit.type, vm.currentUnit.id, 24, moment(vm.unitDTO.time, 'HH:mm').seconds(), moment(vm.unitDTO.time, 'HH:mm').minutes(), moment(vm.unitDTO.time, 'HH:mm').hours(),moment(vm.unitDTO.date).date(), moment(vm.unitDTO.date).month() +1, Number(moment(vm.unitDTO.date).format("YY")),0);
                break;
        }
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          vm.formConfigUnit.$setPristine();
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function cancel($event) {
      if (vm.formConfigUnit && vm.formConfigUnit.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          vm.formConfigUnit.$setPristine();
          angular.copy(vm.unitDTOCopy, vm.unitDTO);
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            //defaultView(vm.currentUnit)
          } else {
            setOverlayState('info');
          }
        });
      } else {
        if (typeof $event !== 'undefined') {
          document.querySelector('vegga-overlay').dismiss();
          //defaultView(vm.currentUnit);
        } else {
          setOverlayState('info');
        }
      }
    }

    function save() {
      vm.manualDate();
    }

    function manualActions($event) {
      vm.configUnit($event, 'manual');
    }

    $scope.$on('formToUnitUpdated', (e, args) => {
      vm.form = args;
    });

    $scope.$on('refresh', function (event, args) {
      if (args.unit !== null && JSON.stringify(args.unit) !== JSON.stringify(vm.currentUnit)) {
        angular.copy(args.unit, vm.currentUnit);
      }
    });

    $rootScope.$on('showSkeleton', (ev, data) => {
      vm.showSkeleton = data;
    })
  }
})();
