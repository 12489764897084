import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)
    .directive('minutes', minutesDirective)
    .controller('programsEditController', programsEditController);

  programsEditController.$inject = [
    '$log',
    '$scope',
    '$rootScope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$filter',
    '$mdSelect',
    'resFactory',
    '$timeout',
    'sensorsFactory',
  ];

  function programsEditController(
    $log,
    $scope,
    $rootScope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $filter,
    $mdSelect,
    resFactory,
    $timeout,
    sensorsFactory
  ) {
    var vm = this;
    var program_temp;
    var fertilizers_in_unit;

    vm.activeList;
    vm.form;
    vm.cancel;
    vm.save;
    vm.checkdirty;
    vm.deleteProgram;
    vm.prepareSectorsToUpdate;
    vm.resetProgramType;
    vm.deleteSubprogram;
    vm.addSubprogram;
    vm.removeSelectedSector;
    vm.fertNum;
    vm.checkEditConditioner;
    vm.checkAllConditioners;
    vm.getSensorFormat;
    vm.fixUnit;
    vm.autofocusElement;
    vm.changedType;

    vm.showIrrigationUnits;
    vm.hideIrrigationUnits;

    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null && $state.params.program !== null) {
        vm.currentUnit = $state.params.unit;
        vm.editProgram = $state.params.program;
        vm.checkdirty = checkdirty;
        vm.deleteProgram = deleteProgram;
        vm.prepareSectorsToUpdate = prepareSectorsToUpdate;
        vm.resetProgramType = resetProgramType;
        vm.deleteSubprogram = deleteSubprogram;
        vm.addSubprogram = addSubprogram;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.selectPrograms = _.range(1, 35);
        vm.sectors = _.range(1, 100);
        vm.removeSelectedSector = removeSelectedSector;
        vm.checkEditConditioner = checkEditConditioner;
        vm.checkAllConditioners = checkAllConditioners;
        vm.getSensorFormat = getSensorFormat;
        vm.fixUnit = fixUnit;
        vm.changedType = changedType;

        vm.showIrrigationUnits = showIrrigationUnits;
        vm.hideIrrigationUnits = hideIrrigationUnits;
        vm.showFertUnits = showFertUnits;
        vm.hideFertUnits = hideFertUnits;
        vm.openSubProgramWindow = openSubProgramWindow;
        vm.cancelModule = cancelModule;
        vm.saveModule = saveModule;
        vm.isSectorSelected = isSectorSelected;

        vm.unitValues = {
          0: 'hh:mm',
          1: 'mm\' ss"',
          2: 'm3',
          4: 'l',
          16: 'm3/Ha',
        };
        vm.unitFertValues = {
          0: 'hh:mm',
          1: 'mm\' ss"',
          4: 'l',
          8: 'cl',
          32: 'l/Ha',
          128: 'dl',
        };

        //    if(!vm.editProgram.backup){
        backup();
        //    }else{

        //    }
        vm.fertNum = fertilizers_in_unit = vm.currentUnit.fertilizer.fertNum;

        vm.proportional = vm.currentUnit.fertilizer.fertType === 1 && vm.currentUnit.fertilizer.prop; //Paralel prop
        progFactory.filterSubPrograms(vm.editProgram);

        prepareParsedSectors();
        checkStartType();
        checkSubprograms();
        if (checkAllConditioners()) {
          vm.anyConditionerAdded = vm.editProgram.conditioners.some((conditioner) => conditioner.type !== 0);
          vm.showConditioners = true;
          /*resFactory.cond(vm.currentUnit.type).then(function(response){
                vm.conditionerList = response;
               })*/
          vm.conditionerList = resFactory.cond(vm.currentUnit.type);
          vm.integrationUnits = resFactory.integrationUnits(vm.currentUnit.type);

          /*resFactory.integrationUnits(vm.currentUnit.type).then(function(response){
                vm.integrationUnits = response;   
               })*/
        }

        if (vm.proportional) {
          parsePropFertilizers();
        }
      } else {
        $state.go('units');
      }
    }

    function checkEditConditioner(cond) {
      if (cond.sensor !== 0) {
        switch (cond.type) {
          case 1:
          case 2:
            return true;
            break;
          case 3:
            //cond.int = true;
            return true;
            break;
          case 7:
          case 8:
          case 9:
            cond.fact = true;
            cond.int = true;
            return true;
            break;
          case 10:
          case 11:
            cond.fact = true;
            return true;
            break;
          default:
            return false;
            break;
        }
      } else {
        return false;
      }
    }

    function checkAllConditioners() {
      var sensors = _.filter(vm.editProgram.conditioners, (item) => {
        return item.sensor !== 0;
      });
      return sensors.length > 0;
    }

    function getSensorFormat(cond) {
      var sensorId = cond.sensor;
      if (sensorId === 41) {
        var formatId = 99;
        if (cond.int && (formatId === 1 || formatId === 2 || formatId === 99)) {
          integrationFormats(formatId, cond);
        } else {
          cond.format = vm.currentUnit.formats[formatId - 1];
          cond.loaded = true;
        }
      } else {
        var params = { page: sensorId, limit: 1 };
        vm.sensor = {};
        sensorsFactory.analogsPage(vm.currentUnit.id, params).then(function (data) {
          vm.paginator = data.plain();
          angular.copy(vm.paginator.content[0], vm.sensor);
          var formatId = vm.sensor.formatId;
          if (cond.int && (formatId === 1 || formatId === 2 || formatId === 99)) {
            integrationFormats(formatId, cond);
          } else {
            cond.format = vm.currentUnit.formats[formatId - 1];
            cond.loaded = true;
          }
        });
      }
    }

    function integrationFormats(id, cond) {
      if (vm.integrationUnits === undefined) {
        return resFactory.integrationUnits(vm.currentUnit.type).then(function (response) {
          vm.integrationUnits = response;
          cond.format = vm.integrationUnits[id];
          cond.loaded = true;
        });
      } else {
        cond.format = vm.integrationUnits[id];
        cond.loaded = true;
      }
    }

    function hideIrrigationUnits() {
      //        $timeout(function(){ vm.showIrrigation = false;},100)
    }

    function showIrrigationUnits(event) {
      vm.showIrrigation = true;
      vm.showFert = false;
      //        vm.autofocusElement = event.target.id;
    }

    function hideFertUnits() {
      //       $timeout(function(){ vm.showFert = false;},100)
    }

    function showFertUnits(event) {
      vm.showFert = true;
      vm.showIrrigation = false;
      //        vm.autofocusElement = event.target.id;
    }

    function fixUnit(value, format) {}

    function prepareParsedSectors() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        item.parsedSectorsTemp = [];
        angular.copy($filter('operational')(item.parsedSectors), item.parsedSectorsTemp);
      });
    }

    function checkdirty(form) {
      if (vm.editProgram.backup) {
        form.$setDirty();
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.editProgram, vm.program_backup);
      vm.editProgram.backup = true;
    }

    function prepareSectorsToUpdate(subProgram) {
      if (
        subProgram.selectedSector &&
        Array.isArray(subProgram.selectedSector) &&
        subProgram.selectedSector.length >= 0
      ) {
        subProgram.parsedSectorsTemp = [];
        subProgram.selectedSector.forEach((sub) => {
          if (sub > 0) {
            let limit = 10;
            if (vm.currentUnit.inoptions && vm.currentUnit.inoptions.ph) {
              limit = 8;
            }
            if (subProgram.parsedSectorsTemp.length < limit) {
              subProgram.parsedSectorsTemp.push(sub);
              _.forEach(subProgram.parsedSectors, (item, key) => {
                if (subProgram.parsedSectorsTemp[key] !== undefined) {
                  subProgram['sector' + (key + 1)] = Number(subProgram.parsedSectorsTemp[key]);
                } else {
                  subProgram['sector' + (key + 1)] = 0;
                }
              });
            }
          }
        });

        subProgram.selectedSector = [];
      }
    }

    function prepareFertilizers() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        if (item.operative) {
          _.forEach(item.ferilizerValues, (value, key) => {
            if (value !== null) {
              if (vm.proportional) {
                item['fertilizer' + (key + 1)] = (item.irrigationProp[key] << 8) + item.fertilizationProp[key];
              } else {
                item['fertilizer' + (key + 1)] = value;
              }
            } else {
              item['fertilizer' + (key + 1)] = 0;
            }
          });
        }
      });
    }

    function parsePropFertilizers() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        if (item.operative) {
          var fertilizers = _.pickBy(item, function (value, key) {
            return _.startsWith(key, 'fertilizer');
          });
          var values = _.values(fertilizers);
          item.irrigationProp = [];
          item.fertilizationProp = [];
          _.forEach(values, (v, k) => {
            item.irrigationProp[k] = (values[k] >> 8) & 0xff;
            item.fertilizationProp[k] = values[k] & 0xff; //(values[k]  - item.irrigationProp[k]);
          });
        }
      });
    }

    function removeSelectedSector(index, sub) {
      sub.parsedSectorsTemp.splice(index, 1);
      _.forEach(sub.parsedSectors, (item, key) => {
        if (sub.parsedSectorsTemp[key] !== undefined) {
          sub['sector' + (key + 1)] = Number(sub.parsedSectorsTemp[key]);
        } else {
          sub['sector' + (key + 1)] = 0;
        }
      });
    }

    function save(form) {
      vm.form.$setPristine();
      if (form) {
        vm.editProgram.progtype = vm.currentUnit.type;
        prepareFertilizers();

        if (vm.editProgram.new) {
          _.forEach(vm.editProgram.subprograms, (sub) => {
            sub.pk.deviceId = vm.editProgram.pk.deviceId;
            sub.pk.programId = vm.editProgram.pk.id;
          });
        }
        if (vm.editProgram.sequential) {
          vm.editProgram.sequential = 1;
        }
        progFactory.update(vm.editProgram).then(() => $rootScope.$broadcast('updateEdit', { message: 'refreshing' }));
      }
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.editProgram);
      //$state.go('programs.detail',{unit:vm.currentUnit,program:vm.program_backup});
    }

    function checkStartType() {
      if (vm.editProgram.preIrrigation === null) {
        vm.editProgram.preIrrigation = 0;
      }
      if (vm.editProgram.start && vm.editProgram.start >= 24 * 60) {
        vm.type = 'seq';
        vm.seqProgram = vm.editProgram.start % 60;
      } else {
        vm.type = 'hour';
      }
    }

    function checkSubprograms() {
      var operative_subprograms = _.map(vm.editProgram.subprograms, function (o) {
        var item = $filter('operational')(o.parsedSectors);
        o.operative = item.length > 0;
        return item.length > 0;
      });
      vm.editProgram.operativeSubprograms = _.filter(operative_subprograms, (o) => {
        return o;
      }).length;
    }

    function deleteProgram() {
      var model;

      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        progFactory.update(model).then(
          (response) => {
            $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
            $state.go('programs', { unit: vm.currentUnit, program: null });
          },
          (error) => {
            //TODO ERROR ALERT
          }
        );
      });
    }

    function deleteSubprogram() {
      const sub = vm.editProgram.subprograms[vm.programSelectedIndex];

      sub.operative = false;
      sub.selectedSector = '0';
      var size = sub.parsedSectors.length; //Number of sectors per sub-program
      sub.value = 0;
      sub.unit = 0;
      sub.fertUnit = 0;
      sub.phReference = 0;
      var key = 1;
      _.times(size, function () {
        if (sub['sector' + key] !== undefined) {
          sub['sector' + key] = 0;
        }
        if (sub['fertilizer' + key] !== undefined) {
          sub['fertilizer' + key] = 0;
        }
        key++;
      });

      document.getElementById('vegga-modal-programs').hide();
      vm.programSelectedIndex = null;
      vm.subProgramSelected = null;
      vm.form.$setDirty();
    }

    function addSubprogram(sub, index) {
      sub.operative = true;
      sub.selectedSector = '0';
      var size = 10; //Number of sectors per sub-program
      sub.value = 0;
      sub.unit = 0;
      sub.fertUnit = 0;
      sub.phReference = 0;
      sub.parsedSectorsTemp = [];
      sub.parsedSectors = _.times(size, _.constant(0));
      sub.ferilizerValues = _.times(fertilizers_in_unit, _.constant(0)); //Number of fertilizers per sub-program
      var key = 1;
      _.times(size, function () {
        if (sub['sector' + key] !== undefined) {
          sub['sector' + key] = 0;
        }
        if (sub['fertilizer' + key] !== undefined) {
          sub['fertilizer' + key] = 0;
        }
        key++;
      });

      vm.openSubProgramWindow(index);
    }

    function prepareModel(model) {
      var id = vm.editProgram.pk.id;
      model.pk.id = id;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + id;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function resetProgramType(item) {
      if (_.isString(item)) {
        var minutes1 = 99 * 60;
        var minutes2 = Number(item);
        vm.editProgram.start = minutes1 + minutes2;
      }
    }

    function changedType() {
      vm.editProgram.start = 0;
    }

    function openSubProgramWindow(index) {
      if (vm.editProgram.subprograms[index]) {
        vm.programSelectedIndex = index;
        vm.subProgramSelected = angular.copy(vm.editProgram.subprograms[index]);
        document.getElementById('vegga-modal-programs').show();
      }
    }

    function cancelModule() {
      vm.prepareSectorsToUpdate(vm.subProgramSelected);
      vm.subProgramSelected = null;
      vm.programSelectedIndex = null;
      document.getElementById('vegga-modal-programs').hide();
    }

    function saveModule() {
      vm.prepareSectorsToUpdate(vm.subProgramSelected);
      vm.editProgram.subprograms[vm.programSelectedIndex] = angular.copy(vm.subProgramSelected);
      document.getElementById('vegga-modal-programs').hide();
      vm.subProgramSelected = null;
      vm.programSelectedIndex = null;
      vm.form.$dirty = true;
    }

    function isSectorSelected(sector) {
      const sectorTemp = vm.subProgramSelected.parsedSectorsTemp.find((s) => s === sector);
      return sectorTemp ? true : false;
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }

  function minutesDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        sec: '=',
      },
      link: function (scope, element, attr, ngModel) {
        ngModel.$parsers.push(fromUser);
        ngModel.$formatters.push(toUser);

        function fromUser(input) {
          var duration = moment(input).format('HH:mm');
          if (scope.sec === true) {
            return moment.duration(duration).asMinutes() * 60;
          } else {
            return moment.duration(duration).asMinutes();
          }
        }

        function toUser(input) {
          if (scope.sec === true) {
            input = input / 60;
          }
          if (_.isNumber(input)) {
            if (!(input >= 24 * 60 || input < 0)) {
              var h = (input / 60) | 0,
                m = input % 60 | 0;
              return moment(new Date()).hours(h).minutes(m).seconds(0).milliseconds(0)._d;
            }
          }
        }
      },
    };
  }
})();
