(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@vegga/front-utils"));
	else if(typeof define === 'function' && define.amd)
		define(["@vegga/front-utils"], factory);
	else if(typeof exports === 'object')
		exports["irrigation-control"] = factory(require("@vegga/front-utils"));
	else
		root["irrigation-control"] = factory(root["@vegga/front-utils"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__1176__) {
return 