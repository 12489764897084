import '../components/history/history-controller';
import historyTemplate from '../components/history/history.html';
import '../components/history/history-factory';
import '../components/history/programs/history.programs-controller';
import historyProgramsTemplate from '../components/history/programs/history.programs.html';
import '../components/history/sectors/history.sectors-controller';
import historySectorsTemplate from '../components/history/sectors/history.sectors.html';
//TODO - Check if exist history.filters state
import '../components/history/filters/history.filters-controller';
import historyFiltersTemplate from '../components/history/filters/history.filters.html';
import '../components/history/analogs/history.analogs-controller';
import historyAnalogsTemplate from '../components/history/analogs/history.analogs.html';
import '../components/history/meters/history.meters-controller';
import historyMetersTemplate from '../components/history/meters/history.meters.html';
import '../components/history/integrateds/history.integrateds-controller';
import historyIntegratedTemplate from '../components/history/integrateds/history.integrateds.html';
import '../components/history/flow/history.flow-controller';
import historyFlowTemplate from '../components/history/flow/history.flow.html';
import '../components/history/general/history.general-controller';
import historyGeneralTemplate from '../components/history/general/history.general.html';
import '../components/history/trays/history.trays-controller';
import historyTraysTemplate from '../components/history/trays/history.trays.html';
import '../components/history/avgphce/history.avgphce-controller';
import historyAvgPhCeTemplate from '../components/history/avgphce/history.avgphce.html';
import '../components/history/logics/history.logics-controller';
import historyLogicsTemplate from  '../components/history/logics/history.logics.html';
import '../components/history/fertdeposit/history.fertdep-controller';
import historyFertDepTemplate from  '../components/history/fertdeposit/history.fertdep.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('history', {
            parent: 'unit',
            url: `/history`,
            params: {
              unit: null,
              load: null,
            },
            template: historyTemplate,
            controller: 'historyController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          //-------> History child states
          .state('history.programs', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/programs',
            template: historyProgramsTemplate,
            controller: 'historyProgramsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.sectors', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/sectors',
            template: historySectorsTemplate,
            controller: 'historySectorsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.filters', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/filters',
            template: historyFiltersTemplate,
            controller: 'historyFiltersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.analogs', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/analogs',
            template: historyAnalogsTemplate,
            controller: 'historyAnalogsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.meters', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/meters',
            template: historyMetersTemplate,
            controller: 'historyMetersController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.integrateds', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/integrateds',
            template: historyIntegratedTemplate,
            controller: 'historyIntegratedsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.flow', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/flow',
            template: historyFlowTemplate,
            controller: 'historyFlowController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.general', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/general',
            template: historyGeneralTemplate,
            controller: 'historyGeneralController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.trays', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/trays',
            template: historyTraysTemplate,
            controller: 'historyTraysController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.avgphce', {
            //   parent:'history',
            params: {
              unit: null,
              id: null,
            },
            url: '/avgphce',
            template: historyAvgPhCeTemplate,
            controller: 'historyAvgPhCeController',
            authenticate: true,
            controllerAs: 'vm',
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.logics', {
            //   parent:'history',
            params:{
              unit:null,
              id:null
            },
            url: '/logics',
            template: historyLogicsTemplate,
            controller:'historyLogicsController',
            authenticate:true,
            controllerAs:'vm'
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state('history.fertdep', {
            //   parent:'history',
            params:{
              unit:null,
              id:null
            },
            url: '/fertdep',
            template: historyFertDepTemplate,
            controller:'historyFertDepController',
            authenticate:true,
            controllerAs:'vm'
          })
      },
    ]);
})();
