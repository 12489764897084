import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sectorsController', sectorsController);

  sectorsController.$inject = ['$state', 'sectorFactory', 'sensorsFactory', '$filter', '$confirm', '$scope'];

  function sectorsController($state, sectorFactory, sensorsFactory, $filter, $confirm, $scope) {
    var vm = this;
    var cropTexts;
    vm.newSector;
    vm.cancel;
    vm.save;
    vm.changeSectorState;
    vm.meters;
    vm.getFilterInUse;

    vm.rawSectors;
    vm.sectorData;
    vm.totalSectors;
    vm.searchBySectorName = '';

    //TODO FIX i18n
    vm.gridColumnsDef = [
      {
        headerName: $filter('translate')('history.sector'),
        field: 'id',
        width: 55,
        sorting: true,
        sortingDir: 'asc',
      },
      {
        headerName: $filter('translate')('programs.detail.name'),
        field: 'name',
        filterParams: (cellValue) => {
          if (!vm.searchBySectorName) {
            return true;
          }
          if (cellValue) {
            return cellValue.toLowerCase().includes(vm.searchBySectorName.toLowerCase());
          }
          return false;
        },
      },
      { headerName: $filter('translate')('sectors.c23'), field: 'area' },
      { headerName: $filter('translate')('sectors.c12'), field: 'crop' },
      {
        headerName: $filter('translate')('history.prog'),
        field: 'programs',
        cellTemplate: (sectorItem) => renderProgramTag(sectorItem.programs).join(''),
      },
      { headerName: $filter('translate')('sensors.preFlow'), field: 'plannedFlow' },
      {
        headerName: $filter('translate')('fert.state'),
        field: 'status',
        cellTemplate: (sectorItem) => renderStatusTag(sectorItem),
      },
    ];

    vm.gridColumnsDefA45 = [
      { headerName: $filter('translate')('history.sector'), field: '' },
      { headerName: $filter('translate')('programs.detail.name'), field: '' },
      { headerName: $filter('translate')('sectors.header'), field: '' },
      { headerName: $filter('translate')('sectors.c23'), field: '' },
      { headerName: $filter('translate')('sectors.c12'), field: '' },
      { headerName: 'Prog/Subprog', field: '' },
      { headerName: 'Riego Pend/Prog', field: '' },
      { headerName: 'Caudal Previsto', field: '' },
      { headerName: $filter('translate')('fert.state'), field: '' },
    ];

    vm.newSector;
    vm.dataGridRowClick;

    var a25FlowUnits = {
      0: 'm3/h',
      1: 'L/h',
      2: 'L/s',
    };

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.state = $state;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.sector = $state.params.load;
        vm.dataGridRowClick = dataGridRowClick;
        vm.checkIrrigation = checkIrrigation;
        //vm.loadCropText = loadCropText;
        vm.prepareFlowUnits = prepareFlowUnits;
        vm.checkState = checkState;
        vm.cancel = cancel;
        vm.save = save;
        vm.changeSectorState = changeSectorState;
        vm.changeSector = changeSector;
        vm.changingSector = changingSector;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.updateFilter = updateFilter;
        vm.gridFilter = { name: vm.searchBySectorName };
        vm.UNITS = UNITS;
        vm.getFilterInUse = getFilterInUse;
        loadSectors($state.params.unit.id, vm.currentUnit.type, vm.currentUnit.installer);
        getMeters();
        vm.showIrrig = false;
      } else {
        $state.go('units');
      }
    }

    //Funció adaptada per mirar l'estat de cada sector
    function checkState(sector) {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
        case UNITS.A_2500:
        case UNITS.A_BIT:
          if (sector.xStatus !== null && sector.xStatus !== 0 && sector.xStatus !== 4) {
            if (sector.xFlowInput === 2 || sector.xFlowInput === 3) {
              return true;
            } else return false;
          } else if (sector.xStatus === null || sector.xStatus === 0 || sector.xState === 4) {
            if (sector.xFlowInput === 5 || sector.xFlowInput === 6) {
              return true;
            }
            return false;
          }
          break;
        case UNITS.A_7000:
          if (sector.xStatus !== 0) {
            sector.state = 1;
          } else {
            sector.state = 0;
          }

          break;
        case UNITS.A_5500:
          if (sector.xStatus !== 0 && sector.xStatus !== 3 && sector.xStatus !== 5 && sector.xStatus !== 6) {
            sector.state = 1;
          } else {
            sector.state = 0;
          }
          break;
      }
    }

    function dataGridRowClick(e,pSector) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      let sector;
      if(pSector) {
        sector = pSector;
      } else {
        sector = vm.rawSectors.find((sector) => sector.pk.id === e.detail.id);
      }
      changeState({ sector: sector, id: sector.pk.id }, $state.params.config);
      //changeState(e.detail.id, $state.params.config);
    }

    function changeSector(e) {
      if (e.detail.value) {
        changeState({ sector: e.detail.value, id: e.detail.value.pk.id }, $state.params.config);
      }
    }

    function changingSector($event) {
      if (vm.formSector && vm.formSector.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSector($event);
          break;
      }
    }

    //Funcio per mirar l'estat del sector
    function checkIrrigation(sector) {
      sector.irrigation = false;
      if (sector.xStatus !== null) {
        switch (vm.currentUnit.type) {
          case UNITS.A_4000: //A4000
            if (sector.xStatus !== 4 && sector.xStatus !== 0) {
              // 4 = Stop In active (Paro definitiu de sector)
              sector.irrigation = true;
            }
            break;
          case UNITS.A_2500: //A2500
          case UNITS.A_BIT: //BIT
            if (sector.xStatus !== 0 && sector.xStatus !== 2 && sector.xStatus !== 5) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_7000: //A7000
            if (sector.xStatus !== 0) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_5500: //A5500
            if (sector.xStatus !== 0 && sector.xStatus !== 3 && sector.xStatus !== 5 && sector.xStatus !== 6) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_4500: //A4500
            if (sector.xStatus == 1) {
              sector.irrigation = true;
            }
            break;
        }
      }
      return sector.irrigation;
    }

    //Funcio necessaria per agafar els formats dels sensors contadors
    function getMeters() {
      sensorsFactory.meters(vm.currentUnit.id).then((result) => {
        vm.meters = result.plain();
      });
    }

    //Determina el format del caudal previst per cada sector
    function prepareFlowUnits(sector) {
      var flowFormat = 0;
      if (sector.irrigationMeter !== 0 && vm.meters && vm.meters.length > 0) {
        const meter = vm.meters.find((meter) => Number(meter.pk.id) === sector.irrigationMeter);
        if (meter && meter.flowFormat) {
          flowFormat = meter.flowFormat;
        }
      }
      flowFormat = a25FlowUnits[flowFormat];
      return flowFormat;
    }

    function loadSectors(id,  type, installer) {
      if (vm.currentUnit.type === UNITS.A_4500) {
        return sectorFactory.sector(id, type, installer).then(function (data) {
          vm.rawSectors = data.plain();
          prepareFlowData();
        });
      } else {
        return sectorFactory.sector(id, type, installer).then(function(data) {
          vm.rawSectors = data.plain();
          vm.totalSectors = data.length;

          vm.sectorData = vm.rawSectors.map((i) => ({
            id: i.pk.id,
            name: i.name,
            area: i.area + ' m2',
            crop: i.crop,
            programs: i.progs,
            plannedFlow: i.plannedFlow / 100 + ' ' + prepareFlowUnits(vm.rawSectors),
            status: [],
            ...i,
          }));
        });
      }
    }

    function renderProgramTag(programs) {
      if (!programs) {
        return [];
      }
      //per a separar els diferents programes de cada sector
      const parsedPrograms = programs.split(';');

      //render del vegga-tag
      return parsedPrograms.map((program) => {
        return `<vegga-tag
        class="md"
        enable-remove="false"
        tag="${program}"></vegga-tag>`;
      });
    }

    function renderStatusTag(sector) {
      const tags = [];

      if (vm.checkIrrigation(sector)) {
        tags.push(`<vegga-badge class="sm success">${$filter('translate')('sectors.c2')}</vegga-badge>`);
      }

      if (sector.xManual === 1) {
        tags.push(`<vegga-badge>${$filter('translate')('sectors.manual_' + sector.xStartStop)}</vegga-badge>`);
      }

      if (vm.checkState(sector)) {
        tags.push(`<vegga-badge>${$filter('translate')('sectors.errorflow_' + sector.xFlowInput)}</vegga-badge>`);
      }

      return tags.join('');
    }

    function updateFilter(param, field) {
      vm.gridFilter = {
        ...vm.gridFilter,
        [field]: param,
      };
    }


    function prepareFlowData() {
      let filter;
      if (vm.currentUnit.type == UNITS.A_4500) {
        filter = { xStatus: 1 };
      } else {
        filter = { state: 1 };
      }
      let meter_groups = _(_.filter(vm.rawSectors, filter))
        .groupBy('irrigationMeter')
        .map((objs, key) => ({
          irrigationMeter: Number(key),
          total: _.sumBy(objs, 'plannedFlow'),
        }))
        .value();

      _.forEach(vm.rawSectors, (sector) => {
        if (
          (vm.currentUnit.type === UNITS.A_4500 && sector.xStatus === 1) ||
          (vm.currentUnit.type !== UNITS.A_4500 && sector.state === 1)
        ) {
          let meter = _.find(meter_groups, { irrigationMeter: sector.irrigationMeter });
          sector.totalFlow = formatFlowUnit(meter.total);
        }
      });
    }

    function formatFlowUnit(flow) {
      var value = String(flow);
      if (value.length === 2) {
        value = '0.' + value;
      } else if (value.length === 1) {
        value = '0.0' + value;
      } else if (value.length > 2) {
        var first = value.slice(0, value.length - 2);
        var last = value.slice(value.length - 2, value.length);
        value = first + '.' + last;
      }
      return Number(value);
    }

    function changeState(params) {
      const sector = vm.rawSectors.find((sector) => sector.pk.id === params.id);

      vm.active = null;
      vm.sector = sector;
      $state.go('sectors.detail', params);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeSectorState(state, _params) {
      if (vm.formSector && vm.formSector.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, _params); //Canviem d'estat
        });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast al child controller de Configuració
     */
    function cancel($event) {
      if (vm.formSector && vm.formSector.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('sectors', { load: vm.sector, unit: vm.currentUnit });
          } else {
            $state.go('sectors.detail', { id: vm.sector.pk.id, sector: vm.sector });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('sectors', { unit: vm.currentUnit, load: vm.sector })
          : $state.go('sectors.detail', { sector: vm.sector, id: vm.sector.pk.id });
      }
    }
    function save(_ev) {
      if (vm.formSector && vm.formSector.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function getFilterInUse(sector) {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
        case UNITS.A_2500:
        case UNITS.A_BIT:
        case UNITS.A_7000:
        case UNITS.A_5500:
          return true;
        case UNITS.A_4500: //A4500
          if (vm.showIrrig) {
            return sector.xStatus == 1;
          } else {
            return true;
          }
      }
    }
    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formSector = args;
    });

    $scope.$on('refresh', function (event, args) {
      if ($state.includes('sectors')) {
        loadSectors($state.params.unit.id);
      }

      if ($state.includes('sectors.detail')) {
        loadSectors($state.params.unit.id).then(function () {
          if ($state.params.sector !== undefined) {
            var sector = _.filter(vm.rawSectors, { id: $state.params.sector.pk.id });
            if (sector && sector.length > 0) {
              vm.sector = sector[0];
              $scope.$broadcast('refresh:sector', { sector: sector[0], id: sector[0].pk.id, unit: $state.params.unit });
            }

          }
        });
      }
    });
  }
})();
