import { UNITS } from '../utils/units.enum';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    .constant('moment', moment)
    .controller('sensorsConfigMetersController', sensorsConfigMetersController);

  sensorsConfigMetersController.$inject = ['$scope', '$state', 'sensorsFactory', '$confirm', '$filter'];

  function sensorsConfigMetersController($scope, $state, sensorsFactory, $confirm, $filter) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    var PAGE_LIMIT;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.minDate = null;
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.formats = vm.currentUnit.formats;
        vm.selectSensor = loadMeters;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.getFormatExample = getFormatExample;
        vm.save = save;
        vm.getFormatByKey = getFormatByKey;
        vm.setFormat = setFormat;
        if (vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.totals = _.range(1, 21);
          PAGE_LIMIT = 20;
        } else if(vm.currentUnit.type === UNITS.A_4500) {
          vm.totals = _.range(1,81);
          PAGE_LIMIT = 80;
          loadAllMeters();
        } else {
          vm.totals = _.range(1, 11);
          PAGE_LIMIT = 10;
        }
        loadMeters(1);
        if(vm.currentUnit.type === UNITS.A_4500) {
          vm.measureTypes = ["Volumen", "Energia", "Unidades"];
          vm.meterTypes = ["S. Digital", "S. Analógico", "Frecuencia", "Sumatorio", "Pluviómetro"];
          vm.formatAcc = [
            ["m3", "L", "cL"],
            ["kW"],
            ["U"]
          ];
          vm.formatUnits = {
            "0": ["0000 m3/h","000.0 m3/h","00.00 m3/h","0000 L/h","000.0 L/h","00.00 L/h","0000 L/s","00.0 L/s","00.00 L/s",
              "0000 m3/s","000.0 m3/s","00.00 m3/s"],
            "1": ["0000 kW/h","000.0 kW/h","00.00 kW/h"],
            "2": ["0000 U"]
          };
        }
        loadMeters(1);
        vm.flowFormats = ['m3/h', 'L/h', 'L/s'];
        vm.acumulatedFormats = [
          '0000 m3',
          '000.0 m3',
          '000.00 m3',
          '0000 L',
          '000.0 L',
          '000.00 L',
          '0000 L/m2',
          '000.0 L/m2',
          '000.00 L/m2',
        ];
        vm.setColor = setColor;
        vm.captionRange = _.range(0, 5);
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.selectFormat = selectFormat;
        vm.resetValues = resetValues;
        vm.setFlowFormatToZero = setFlowFormatToZero;
      } else {
        $state.go('units');
      }
    }

    function getFormatByKey(key, type) {
        return type === "units" ? vm.formatUnits[key] : vm.formatAcc[key]
    }

    function loadMeters(n){
        vm.sensor = {};
        vm.loading = true;
        vm.page = n;
        sensorsFactory.metersOne(vm.currentUnit.id,n).then(function(data){
            angular.copy(data.plain(),vm.sensor);
            vm.sensor.dateReadInDate = convertDate(vm.sensor.dateReadInDate);
            vm.loading = false;
            if(vm.currentUnit.type === UNITS.A_4500){
                setFormat();
            }
        });
    }

    function loadAllMeters(){
        vm.allSensors = {};
      vm.loading = true;
        sensorsFactory.meters(vm.currentUnit.id).then(function(data){
            vm.allSensors = data.plain();
            vm.loading = false;
        });

    }

    function filterAllMeters(metersList, meterId) {
      return metersList.filter(elem => elem !== meterId);
    }

    function lastSensor(){
        --vm.page;
        if(vm.page === 0){
            vm.page = PAGE_LIMIT;
        }
        if(vm.currentUnit.type !== UNITS.A_4500){
            loadMeters(vm.page);
        }else{
            vm.sensor = vm.allSensors[page - 1];
            vm.sensor.dateReadInDate = convertDate(vm.sensor.dateReadInDate);
        }
    }

    function nextSensor(){
        ++vm.page;
        if(vm.page > PAGE_LIMIT){
            vm.page = 1;
        }
        if(vm.currentUnit.type !== UNITS.A_4500){
            loadMeters(vm.page);
        }else{
            vm.sensor = vm.allSensors[vm.page - 1];
            vm.sensor.dateReadInDate = convertDate(vm.sensor.dateReadInDate);
        }

    }

    function changeSensor(e) {
        if (e.detail.value) {
            vm.page = e.detail.value;
            if(vm.currentUnit.type !== UNITS.A_4500){
                loadMeters(vm.page);
            }else{
                vm.sensor = vm.allSensors[vm.page - 1];
                vm.sensor.dateReadInDate = convertDate(vm.sensor.dateReadInDate);
            }
        }
    }

    function changingSensor($event) {
      if (vm.form_m && vm.form_m.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form_m.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function getFormatExample(format) {
      var example = '';
      if (format.sign) {
        example = example + '\u00B1 ';
      }

      _.times(format.integers, (o) => {
        example = example + '0';
      });
      if (format.decimals > 0) {
        example = example + '.';
        _.times(format.decimals, (o) => {
          example = example + '0';
        });
      }
      example = example + ' ' + format.units;
      return example;
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function setFlowFormatToZero(){
        vm.sensor.flowFormat = 0;
    }

    function resetValues(){
        vm.sensor.logicValue1 = 0;
        vm.sensor.logicValue2 = 0;
    }

    function selectFormat(){
        vm.format = getFormatByKey(vm.sensor.flowFormat - 1, "units");
        getFormatByKey(vm.sensor.measureType,'units')[vm.sensor.flowFormat - 1]
    }

    function setFormat(){
        let minimumAndMaximum = getMinimumMaximumIntegersDecimalsLabel();
        let minimum = minimumAndMaximum[0];
        let maximum = minimumAndMaximum[1];
        let integers = minimumAndMaximum[2];
        let decimals = minimumAndMaximum[3];
        let label = minimumAndMaximum[4];
        vm.inputFormat = {
            min: minimum,
            max: maximum,
            integers: integers,
            decimals: decimals,
            label: label
        }
        // set everything to 0 since mask-sensor-unit doesn't watch minimum and maximum scope
        //vm.sensor.tara = 0;
        vm.sensor.minGraphic = 0;
        vm.sensor.maxGraphic = 0;
    }

    function getMinimumMaximumIntegersDecimalsLabel(){
        let format;
        if(vm.sensor.flowFormat === 16){
            format = "00.00 L/m2";
        }
        else if(vm.sensor.flowFormat === 17){
            format = "00.00 mm";
        }else{
            format = getFormatByKey(vm.sensor.measureType,'units')[vm.sensor.flowFormat]
        }
        let integersDecimalsLabel = getIntegersDecimals(format);
        let integers = integersDecimalsLabel[0];
        let decimals = integersDecimalsLabel[1];
        let label = integersDecimalsLabel[2];

        let totalDigits = integers + decimals;
        let minimum = 0;
        let maximum = "";
        for(let i = 0; i < totalDigits; i++){
            maximum += "9";
        }

        maximum = Number((maximum / (10**decimals)).toFixed(decimals)); // using toFixed() to avoid Float problems

        return [minimum, maximum, integers, decimals, label]

    }

    function getIntegersDecimals(format){
        let labelAndFormat = format.split(" ");
        let integersAndDecimals = labelAndFormat[0].split(".");
        if(integersAndDecimals.length > 1){
            return [integersAndDecimals[0].length, integersAndDecimals[1].length, labelAndFormat[1]]
        }else{
            return [integersAndDecimals[0].length, 0, labelAndFormat[1]]
        }
    }

    function save(){
        vm.loading = true;
        vm.form_m.$setPristine();
        vm.sensor.unittype = vm.currentUnit.type;
        if(moment(vm.sensor.dateReadInDate).isValid()){
            let m = moment(vm.sensor.dateReadInDate).format("DD-MM-YYYY hh:mm:ss");
            vm.sensor.dateReadInDate = m;
        }else vm.sensor.dateReadInDate = null;

        sensorsFactory.updateMeter(vm.currentUnit.id,vm.sensor).then((response) => {
                vm.sensor = response.plain();
                vm.sensor.dateReadInDate = convertDate(vm.sensor.dateReadInDate);
        vm.loading = false;
        $scope.$emit('refresh-sensors');
      });
    }

    function convertDate(date){
        moment.defaultFormat = "DD.MM.YYYY HH:mm:ss";
        let m = moment(date, moment.defaultFormat).toDate();
        return m;
    }

    function cancel_edition(){

        $state.go('sensors.detail',{unit:vm.currentUnit});
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form_m,
      () => {
        $scope.$emit('formUpdated', vm.form_m);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form_m);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
