(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('fertilizerFactory', fertilizerFactory);

  fertilizerFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function fertilizerFactory(Restangular, $rootScope, $q) {
    var resource = Restangular.all('units');
    var op = {
      get: get,
      all: all,
      update: update,
      updateConfig: updateConfig,
        getOneFertilizationHeader:getOneFertilizationHeader,
        getFertilizationHeaders:getFertilizationHeaders,
        updateFertilizationHeader:updateFertilizationHeader,
        updatePhytos45:updatePhytos45,
      getPhref: getPhref,
        getGeneralFertilizationHeader:getGeneralFertilizationHeader
    };

    return op;

    function get(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .get({ add: ['fertilizer'] })
        .then(function (response) {
          deferred.resolve(response.fertilizers);
        });
      return deferred.promise;
    }
    function all(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .get({ add: ['fertilizer', 'agitators'] })
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function update(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('fertilizer')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateConfig(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('config')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getOneFertilizationHeader(deviceId, headerId){
        var deferred = $q.defer();
        Restangular.one('units',deviceId).one('headers-fertilizers', headerId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function getFertilizationHeaders(deviceId){
        var deferred = $q.defer();
        Restangular.one('units',deviceId).one('headers-fertilizers').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function getGeneralFertilizationHeader(deviceId, headerId){
        var deferred = $q.defer();
        Restangular.one('units',deviceId).one('general-fertilizers').one('header', headerId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function updateFertilizationHeader(deviceId, params){
        var deferred = $q.defer();
        Restangular.one('units',deviceId).one('headers').one('fertilizers').customPOST(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function getPhref(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('phref')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updatePhytos45(deviceId, phytos){
        var deferred = $q.defer();
        Restangular.one('units',deviceId).one('fitos').customPOST(phytos).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
  }
})();
