import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('nebulizationController', nebulizationController);

  nebulizationController.$inject = ['$scope', '$state', '$filter', 'nebulizationFactory', 'sensorsFactory', '$confirm'];

  function nebulizationController($scope, $state, $filter, nebulizationFactory, sensorsFactory, $confirm) {
    var vm = this;
    vm.nebulizationList;
    vm.overlayEl;
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('programs.prog2') },
      { headerName: $filter('translate')('programs.detail.name') },
      { headerName: $filter('translate')('fert.state') },
    ];

    activate();

    function activate() {
      if ($state.params.unit !== null) {
        vm.currentState = $state;
        vm.currentUnit = $state.params.unit;
        vm.UNITS = UNITS;

        vm.changeNebulization = changeNebulization;
        vm.changeNebulizationState = changeNebulizationState;
        vm.changingNebulization = changingNebulization;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.dataGridRowClick = dataGridRowClick;
        vm.cancel = cancel;
        vm.save = save;

        loadNebulization(vm.currentUnit.id);
      }
    }

    function loadNebulization(id) {
      return nebulizationFactory.getAll(id).then((result) => {
        vm.nebulizationList = result.plain();
        //loadSensors();
      });
    }

    function dataGridRowClick(_e, neb) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.currentNebulization = neb;
      changeState({ nebulization: neb, id: neb.id });
    }

    function changeNebulization(e) {
      if (e.detail.value) {
        vm.currentNebulization = e.detail.value;
        changeState({ nebulization: vm.currentNebulization, id: vm.currentNebulization.id });
      }
    }

    function changingNebulization($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeNebulization($event);
          break;
      }
    }

    function changeNebulizationState(state) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, { nebulization: vm.currentNebulization }); //Canviem d'estat
        });
      } else {
        $state.go(state, { nebulization: vm.currentNebulization });
      }
    }

    function changeState(params) {
      vm.active = null;
      vm.nebulization = params.nebulization;
      vm.nebulization.op = true;
      $state.go('nebulization.detail', params);
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      const overlay = document.querySelector('vegga-overlay');
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go('nebulization', { nebulization: vm.nebulization, unit: vm.currentUnit });
          overlay.dismiss();
        });
      } else {
        $state.go('nebulization', { nebulization: vm.nebulization, unit: vm.currentUnit });
        overlay.dismiss();
      }
    }

    function save() {
      $scope.$broadcast('formSubmit');
      //vm.form.$setPristine();
      //nebulizationFactory.update(vm.currentUnit.id, vm.nebulizationList);
    }

    $scope.$on('refresh', function (event, args) {
      loadNebulization($state.params.unit.id).then(function () {
        if ($state.includes('nebulization.detail')) {
          if ($state.params.nebulization !== undefined) {
            var nebulization = _.filter(vm.nebulizationList, { name: $state.params.nebulization.name });
            nebulization.op = true;
            if (nebulization !== undefined && nebulization.length > 0) {
              $state.go('nebulization.detail', {
                nebulization: nebulization[0],
                id: nebulization[0].id,
                unit: $state.params.unit,
              });
            } else {
              $state.go('nebulization', { unit: $state.params.unit }, { reload: true });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    $scope.$on('updateEdit', function (event, args) {
      loadNebulization($state.params.unit.id).then(function () {
        if ($state.includes('nebulization.edit') || $state.includes('nebulization.config')) {
          if ($state.params.nebulization !== undefined && $state.params.nebulization.op) {
            var nebulization = _.filter(vm.nebulizationList, { name: $state.params.nebulization.name });
            if (nebulization !== undefined && nebulization.length > 0) {
              vm.nebulization = nebulization[0];
              vm.nebulization.op = true;
              $state.go($state.current, {
                nebulization: nebulization[0],
                id: nebulization[0].id,
                unit: $state.params.unit,
              });
            } else {
              $state.go('nebulization', { unit: $state.params.unit }, { reload: true });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, form) => {
      vm.form = form;
    });
  }
})();
