import moment from 'moment/moment'; 
import { UNITS } from '../../utils/units.enum';

(function() {
'use strict';

angular.module('agronicwebApp')
.controller('historyFertDepController',historyFertDepController)

historyFertDepController.$inject = [ '$scope','$state','$filter','UserData','historyFactory'];


function historyFertDepController( $scope, $state, $filter, UserData, historyFactory) {
    
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate(){
        vm.currentState = $state.includes;

        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;
            vm.from = moment().subtract(7,'day')._d;
            vm.to = moment()._d;      
            if(!UserData.profesional){
                vm.minDate = moment().subtract(7,'day')._d;
            }else{
                vm.minDate = null;
            }
            vm.loadHistory = loadHistory;
            vm.exportXLS = exportXLS;
            loaded = false;
            
            vm.pagination = {
                page:1,
                limit:15
            }

            vm.groupBy = [
                {text: '10 ' + $filter('translate')('history.minutes'), value: '0'},
                {text:  $filter('translate')('history.hour'), value: '1'},
                {text:  $filter('translate')('history.day'), value: '2'},
                {text:  $filter('translate')('history.week'), value: '3'},
                {text:  $filter('translate')('history.month'), value: '4'},
                {text:  $filter('translate')('history.dates'), value: '5'},
            ];
            
            vm.sectorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0'},
            { text: '1', value: '1'},
            { text: '2', value: '2'},
            { text: '3', value: '3'},
            { text: '4', value: '4'}];

            initGrid();
            
            loadHistory();
           
        
        }else{
            $state.go('units');
        }
        
    }

    function initGrid(){
        vm.filter.groupBy = '2';
        vm.filter.sector = '0';
        vm.gridOptions = {
            enableSorting: true,
            enableColumnMenus: false,
            useExternalPagination: false,
            paginationPageSizes: [15, 20, 25],
            paginationPageSize: 15,
            columnDefs:getColumnDefinition(vm.currentUnit.type), 
            onRegisterApi: function(gridApi){
              vm.gridApi = gridApi;
            }
        }
    }
            
    function getColumnDefinition(type){
        switch(type){
            case UNITS.A_4500:
                return [
                    { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '4' , displayName: 'sectors.header' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '5' , displayName: insertTitle(1) ,  width:'**' },
                    { field: '6' , displayName: insertTitle(2) ,  width:'**' },
                    { field: '7' , displayName: insertTitle(3) ,  width:'**' },
                    { field: '8' , displayName: insertTitle(4) ,  width:'**' },
                    { field: '9' , displayName: insertTitle(5),  width:'**' },
                    { field: '10' , displayName: insertTitle(6) ,  width:'**' },
                    { field: '11' , displayName: insertTitle(7) ,  width:'**' },
                    { field: '12' , displayName: insertTitle(8) ,  width:'**' }
                  ]
        }
    }
            
    function checkDates(){
        return this["2"] + " - " + this["3"];              
    }
    function insertTitle(row){
        return $filter('translate')('history.lvltank') + " " + row + " (L)";              
    }
    
    function loadHistory(clean){
        if(clean !== undefined){
            vm.pagination.page = 1;
        }
        var params = {
            from:moment(vm.from).format("DD-MM-YYYY"),
            to:moment(vm.to).add(1,'d').format("DD-MM-YYYY"),
            filter:vm.filter,
            page:vm.pagination.page,
            limit:vm.pagination.limit
        }
        historyFactory.fertdep(vm.currentUnit.id, params).then(data =>{
            vm.gridOptions.totalItems = data.total;
            vm.gridOptions.data =  data.content;
            _.forEach(vm.gridOptions.data , row => {
                row.loaded = false;
                row.checkDates = checkDates;
                row.insertTitle = insertTitle;
            });
            
        });
    }
    function exportXLS(){
        var name = moment(vm.from).format("DD-MM-YYYY") + "_" + moment(vm.to).format("DD-MM-YYYY") + ".xlsx";
        historyFactory.export(vm.gridApi.grid,name);
    }


    $scope.$on('refresh',function(event,args){
        
    });
}

    
    
})();