import { registerApplication, start, navigateToUrl } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import microfrontendLayout from "./layout/microfrontend-layout.html";
import { v4 as uuidv4 } from "uuid";
//@ts-ignore: In-browser module import
import { OrganizationStore, DataLayerStore, AuthStore } from "@vegga/front-store";
import { sha256 } from "js-sha256";

import { UserDataLayer } from "@vegga-types/data-layer";

import "./assets/fonts.css";
import "bootstrap/scss/bootstrap-grid.scss";
import "./styles/main.scss";
//@ts-ignore: In-browser module import
import { setMicroVersion, isLoggedIn, isApplicationUnderMaintenance } from "@vegga/front-utils";
import {
  combineLatest,
  fromEvent,
  interval, of,
  startWith,
  switchMap,
  tap
} from "rxjs";

const layoutData = {
  props: {
    toast: document.querySelector("vegga-toast-manager")
  },
  loaders: {}
};

let appUnderMaintenance;
isApplicationUnderMaintenance()
  .pipe(
    switchMap((data: string) => {
      const routes = constructRoutes(microfrontendLayout, layoutData);
      const applications = constructApplications({
        routes,
        loadApp({ name }) {
          return System.import(name);
        }
      });
      const layoutEngine = constructLayoutEngine({ routes, applications });
      applications.forEach(registerApplication);
      layoutEngine.activate();
      start();
      //createUserDataLayer();
      setMicroVersion();

      if (data !== null) {
        appUnderMaintenance = JSON.parse(data);
      }
      return combineLatest([
        fromEvent(window, "single-spa:before-routing-event")
          .pipe(
            tap((evt: CustomEvent) => {
                if (appUnderMaintenance) {
                  navigateToUrl("/maintenance");
                  return;
                }
                if (!isLoggedIn() && !evt.detail.newUrl.includes("/authentication")) {
                  navigateToUrl("/authentication/sign-in");
                  return;
                }
                const path = evt.detail.newUrl.split(location.origin)[1];
                if (path === "/") {
                  navigateToUrl("/home");
                } else {
                  navigateToUrl(path);
                }
              }
            ),
            switchMap((evt: CustomEvent) => {
              if (isLoggedIn()) {
                const authStore = AuthStore.getInstance();
                return authStore.get$();
              }
              if (!evt.detail.newUrl.includes("/authentication")) {
                navigateToUrl("/authentication/sign-in");
              }
              return of(null);
            }),
            tap((res) => {
                const organizationStore = OrganizationStore.getInstance();
                const authStore = AuthStore.getInstance();
                if (res) {
                  organizationStore.storeOrganization(authStore.getDefaultOrganization());
                }
              }
            )
          ),
        fromEvent(window, "single-spa:routing-event")
          .pipe(
            tap(() => {
                document.querySelector(".vegga__container") && setMicroHeight();
              }
            )),
        fromEvent(window, "load")
          .pipe(
            tap(() => {
                document.querySelector(".vegga__container") && setMicroHeight();
              }
            )),
        fromEvent(window, "resize")
          .pipe(
            tap(() => {
                document.querySelector(".vegga__container") && setMicroHeight();
              }
            ), startWith([]))
      ]);
    }),
    switchMap(() => interval(600000)),
    switchMap(() => isApplicationUnderMaintenance()))
  .subscribe({
    next: (data) => appUnderMaintenance = data
  });

function setMicroHeight() {
  const microContentEl = document.querySelector('.vegga__container').lastChild;
  const navbarEl = document.querySelector('.vegga-navbar__container');
  if (microContentEl) {
    (microContentEl as HTMLDivElement).style.minHeight = `calc(100vh - ${(navbarEl && navbarEl.clientHeight) || 0}px)`;
  }
}

// TODO - Investigate how to manage custom event in Google Analytics
// function createUserDataLayer() {
//
//     const dataLayerItem = DataLayerStore.getInstance();
//     const organization = OrganizationStore.getInstance();
//     const authStore = AuthStore.getInstance();
//     const userDataLayer: UserDataLayer = {
//       idUser:'',
//       typeUser: '',
//       type: '',
//       plan: '',
//       role: '',
//       sessionId: uuidv4(),
//       country: ''
//     };
//
//     if(organization.get()) {
//       userDataLayer.typeUser = organization.isMigratedOrg() ? 'migrated' : 'new'
//       userDataLayer.plan = organization.getPlan();
//       userDataLayer.role = organization.getRole();
//       userDataLayer.type = organization.getType();
//     }
//     if(authStore.get()) {
//       userDataLayer.idUser = sha256(authStore.getLoggedInUser().username)
//     }
//     dataLayerItem.setUserDataLayer(userDataLayer);
// }
