(function() {
'use strict';


angular.module('agronicwebApp')
.controller('programsControllerA45',programsController)
.controller('ModalInstanceSuspendCtrl',ModalInstanceSuspendCtrl)
.controller('ModalInstanceActivationCtrl',ModalInstanceActivationCtrl)
.controller('ModalInstanceDefStopCtrl',ModalInstanceDefStopCtrl)

programsController.$inject = ['$log' ,'$rootScope', '$scope','$state','progFactory','$uibModal','manualFactory','$confirm','$filter','utilsFactory'];

function programsController( $log, $rootScope, $scope, $state,progFactory, $uibModal, manualFactory, $confirm, $filter, utilsFactory) {
    
    var vm = this;
    var loadingState;
    
    //Enumerat per definir els tipus de inici
    const startTypes = Object.freeze({
        hour:0, //Inici per condicionant
        seq:1,  //Inici seqüencial
    });
    //Enumerat per definir els tipus d'unitat de reg
    const unitTypes = Object.freeze({
        HM:0, //hh:mm
        M3:1, //m3
        M3HA:2, // m3/Ha -> Sols al Plus
        MS:3, // Minuts, Segons
        HMHA:4 // Hores, Minuts /Ha v.6.00   -> Sols al Plus
    });

    
    vm.getProgramStartType;
    vm.getProgramDaysFreq;
    vm.getProgramValueInUnit;
    vm.activeList = [];
    //TODO FIX i18n
    vm.gridColumnsDef = 
    [{'headerName': $filter('translate')('programs.prog2'), 'field': ''}, 
    {'headerName': $filter('translate')('programs.detail.name'), 'field':''}, 
    {'headerName': $filter('translate')('sectors.header'), 'field':''}, 
    {'headerName': $filter('translate')('programs.edit.start'), 'field': ''}, 
    {'headerName': $filter('translate')('history.days'), 'field': ''}, 
    {'headerName': $filter('translate')('programs.detail.startirrig'), 'field': ''}, 
    {'headerName': $filter('translate')('programs.edit.sectors'), 'field': ''}, 
    {'headerName': $filter('translate')('programs.edit.irrig'), 'field': ''},
    {'headerName': $filter('translate')('fert.state'), 'field': ''}];
    vm.newProgram;
    vm.dataGridRowClick;
    vm.cancel;
    vm.save;
    vm.changeProgramState;
    vm.showIrrigatingPrograms = false;

    activate();

    function activate(){
        vm.currentState = $state;
        vm.changeState = changeState;
        vm.getProgramStartType = getProgramStartType;
        vm.getProgramDaysFreq = getProgramDaysFreq;
        vm.getProgramStartTime = getProgramStartTime;
        vm.getProgramValueInUnit = getProgramValueInUnit;
        vm.cancel = cancel;
        vm.save = save;
        vm.changeProgramState = changeProgramState;

        if($state.params.unit !== null){
            
           vm.currentUnit = $state.params.unit; 
           vm.program = $state.params.program;  
           
            
           vm.newProgram = newProgram;
           vm.openUnoperative = openUnoperative;
           vm.manualStart = manualStart;
           vm.manualStop = manualStop;
           vm.manualOutOf = manualOutOf;
           vm.manualRunOf = manualRunOf;
           vm.manualSuspend = manualSuspend;
           vm.manualFreqDays = manualFreqDays;
           vm.dataGridRowClick = dataGridRowClick;
           vm.changeProgram = changeProgram;
           vm.changingProgram = changingProgram;
           vm.nextOrPreviousItem = nextOrPreviousItem;
           vm.getTimeFromMins = progFactory.getTimeFromMins;
           vm.getProgramSectors = getProgramSectors;
           vm.getSectorsFromActiveSubprogram = getSectorsFromActiveSubprogram;
           vm.getProgramSectorValue = getProgramSectorValue;
           vm.getProgramSectorXValue = getProgramSectorXValue;

           loadingState = false;

           loadPrograms($state.params.unit.id);                
           loadProgramsSectors($state.params.unit.id);
           loadInstaller();
            
        }else{
            $state.go('units');
        }
        
    }
    
    function dataGridRowClick(_e,prog) {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
        vm.programSelected = prog;
        changeState({program:prog,id:prog.pk.id}, $state.params.config)    
    }

    function changeProgram(e) {
        if (e.detail.value) {
            vm.programSelected = e.detail.value;
            changeState({program:vm.programSelected,id:vm.programSelected.pk.id}, $state.params.config)
        }
    }

    function changingProgram($event) {
        if(vm.formProgram && vm.formProgram.$dirty){
            (typeof $event !== 'undefined') ? $event.preventDefault() : null;
            $confirm({text: $filter('translate')('programs.edit.cancelq')}).then( () => {
                $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
                vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
                this.nextOrPreviousItem($event.detail.action, $event);
            })
        }
    }

    function nextOrPreviousItem(action, $event) {
        const paginator = document.querySelector('vegga-item-paginator');
        switch (action) {
            case "next-item":
                paginator.nextItem();
                break;
            case "previous-item":
                paginator.previousItem();
                break;
            default: 
                changeProgram($event);
                break;
        }
    }
    
    
    function loadPrograms(id){
        return progFactory.getAllPrograms4500(id, vm.currentUnit.type).then(data => 
            vm.activeList = _.orderBy(data.plain()),
            ['op',(o)=>{ return new Number(o.pk.id)}],
            ['asc', 'asc']);
    }

    function loadProgramsSectors(id){
        progFactory.getActiveProgramSectors(id).then(data => {
            vm.programSectors = data.plain();
        });
    }

    function loadInstaller(){
        
        progFactory.getInstaller(vm.currentUnit.id).then(response => {
            vm.installer = response.plain();
        });
    }
    
    function changeState(params){
        vm.active = null;
        vm.program = params.program;
        vm.program.op = true;
        $state.go('a45programs.detail',params);
    }
    
    function openUnoperative(id){
        vm.active = id;
        progFactory.program(vm.currentUnit.id,id).then(result => {
            result.op = false;
            vm.program = result;
            if($state.includes('a45programs.config')){
                $state.go('a45programs.config',{program:result,id:result.pk.id,unit:vm.currentUnit});    
                
            }else{
                $state.go('a45programs.edit',{program:result,id:result.pk.id,unit:vm.currentUnit});    
            } 
        });
                
    }
    
    function newProgram(){
        var model;
        progFactory.getModel(vm.currentUnit.type).then(item =>{
            model = item;
            prepareModel(model);
            vm.program = model;
            $state.go('a45programs.edit',{unit:vm.currentUnit,program:vm.program,id:vm.program.pk.id});
        });
    }
    
    function prepareModel(model){
        
        var actives = _.map(vm.activeList,o=>{ return Number(o.pk.id)});
        var comparator = _.range(1,36);
        var result = _.xor(actives,comparator);
        var newProgram;
        
       
        newProgram = _.min(result);
        
        model.new = true;
        model.selectorNumber = result;
        model.pk.id = newProgram;
        model.pk.deviceId = vm.currentUnit.id;
        model.name = "Programa "+newProgram;
        _.forEach(model.subprograms , item => { item.pk.programId = newProgram; item.pk.deviceId = vm.currentUnit.id});
        _.forEach(model.conditioners , item => { item.pk.programId = newProgram; item.pk.deviceId = vm.currentUnit.id });
        
        
    }
    
    function manualStart() {
        $confirm({text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No'})
            .then(function() {
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.program.pk.deviceId;      
            params.action = 4 ;
            params.parameter1 = Number(vm.program.pk.id) - 1;
            manualFactory.genericManualAction(params.deviceId,params).then(result => {
                            
                    });
            var modalInstance = manualFactory.showLoadingBar();
            modalInstance.result.then( (result) => {
                 $state.go('a45programs',{unit:$state.params.unit},{reload:'a45programs'});
                 $scope.$emit('reload',{message:'reload'});
            });
        });
    };
    
    function manualStop() {
        $confirm({text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No'})
            .then(function() {
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.program.pk.deviceId;      
            params.action = 5 ;
            params.parameter1 = Number(vm.program.pk.id) - 1;
            manualFactory.genericManualAction(params.deviceId,params).then(result => {
                           
                    });
            var modalInstance = manualFactory.showLoadingBar();
            modalInstance.result.then( (result) => {
                $state.go('a45programs',{unit:$state.params.unit},{reload:'a45programs'});
                 $scope.$emit('reload',{message:'reload'});
            });
        });
    };
    
    function manualOutOf() {
        $confirm({text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No'})
            .then(function() {
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.program.pk.deviceId;      
            params.action = 0;
            params.parameter1 = Number(vm.program.pk.id) - 1;
            params.parameter2 = 1;
            manualFactory.genericManualAction(params.deviceId,params).then(result => {
                           
                    });
            var modalInstance = manualFactory.showLoadingBar();
            modalInstance.result.then( (result) => {
                $state.go('a45programs',{unit:$state.params.unit},{reload:'a45programs'});
                $scope.$emit('reload',{message:'reload'});
            });
        });

    };
    
    function manualRunOf() {
        $confirm({text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No'})
            .then(function() {
            var params = {};
            params.deviceId = vm.program.pk.deviceId;   
            params.type = vm.currentUnit.type;   
            params.action = 0;
            params.parameter1 = Number(vm.program.pk.id) - 1;
            params.parameter2 = 0;
            manualFactory.genericManualAction(params.deviceId,params).then(result => {
                           
                    });
            var modalInstance = manualFactory.showLoadingBar();
            modalInstance.result.then( (result) => {
                  $state.go('a45programs',{unit:$state.params.unit},{reload:'a45programs'});
                  $scope.$emit('reload',{message:'reload'});
            });
        });

    };

    function manualSuspend() {
        var modalInstance = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'components/programs/suspend-modal.html',
          controller: 'ModalInstanceSuspendCtrl',
          controllerAs: 'vm'
        });
        
        modalInstance.result.then(function (selectedItem) {
            
            var modalInstance = manualFactory.showLoadingBar();

            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.program.pk.deviceId;      
            params.action = 1;
            params.parameter1 = Number(vm.program.pk.id) - 1;
            params.parameter2 = selectedItem;
            params.parameter3 = (selectedItem >> 8);
            manualFactory.genericManualAction(params.deviceId,params).then(result => {});   
            modalInstance.result.then( (result) => {
                $rootScope.$broadcast('reload', { message: 'refreshing' });   
            });
          
        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
    };

    function manualFreqDays() {
        var modalInstance = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'components/programs/freq_days.html',
          controller: 'ModalInstanceCtrl',
          controllerAs: 'vm',
          resolve: {
            program : function() {
                return vm.program;
            }
          }
        });
        
        modalInstance.result.then(function (selectedItem) {
            
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.program.pk.deviceId;      
            params.action = 2;
            params.parameter1 = (vm.program.pk.id - 1);
            params.parameter2 = selectedItem;
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.genericManualAction(params.deviceId,params).then( (response) => {
               
            });
            
            modalInstance.result.then( (result) => {
                $rootScope.$broadcast('reload', { message: 'refreshing' });   
            });
          
        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
    };


    /*
    * Funcions per parsejar valors de columna "calculats"
    */
    function getProgramStartType(type){
        switch(type){
            case startTypes.hour:
                return 'programs.edit.hour';
            case startTypes.seq:
                return 'programs.edit.seq';
            case startTypes.cond:
                if(vm.currentUnit.inoptions.plus){
                    return 'programs.cond';
                }else{
                    return 'programs.edit.input';
                }
        }
    }
    function getProgramDaysFreq(program){
        //Inici per freqüencia
        let each = $filter('translate')('programs.edit.each');
        let days_string = $filter('translate')('programs.edit._days');
        return each + ' ' + program.freqDays + ' ' + days_string;
    }
    function getProgramStartTime(prog, idx){
        let minutes;
        eval(`minutes = prog.initHour${idx}`);
        return progFactory.getTimeFromMins(minutes).format("HH:mm"); 
    }
    function getProgramValueInUnit(prog){
        var unit = prog.unit;
        var input = prog.value;
        if(unit === 0 || unit === 16){
            input = input / 60;
        }
        switch(unit){
            case unitTypes.HM:
                return $filter('parsetime')(input * 60);
            case unitTypes.M3:
                return (input/ 1000).toFixed(2) +  'm3';
            case unitTypes.M3HA:
                return (input/ 1000).toFixed(2) + ' m3';
            case unitTypes.MS:
                var mins = input;
                return $filter('parsetime',true)(mins);
            case unitTypes.HMHA:
                return input + ' l';
        }
    }

    function getProgramSectors(program){
        return vm.programSectors.filter(programSector => {
            return programSector.pk.programId === program.pk.id;
        });
    }
    
    function getSectorsFromActiveSubprogram(program){
        var sectors = [];
        var programSector = getProgramSectors(program)[0];
        if(programSector !== undefined){
            for(let i = 1; i < 11; i++){
                eval(`sectors.push(programSector.sector${i})`);
            }
        }
        return sectors;
    }

    function getIrrigUnits(program, programSector, prePostIrrigForSubprog){
        return utilsFactory.getIrrigUnits(prePostIrrigForSubprog, program.programType, programSector.irrigUnits, program.sectorsByGroup, program.irrigUnits);
    }
    
    function getProgramSectorValue(program, programSector){
        if(programSector === undefined) return;
        const value = programSector.value;
        const irrigUnits = getIrrigUnits(program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
        return utilsFactory.getFormatedValue4500(value, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getProgramSectorXValue(program, programSector){
        if(programSector === undefined) return;
        const xValue = programSector.xValue;
        const irrigUnits = getIrrigUnits(program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
        return utilsFactory.getFormatedXValue4500(xValue, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config. 
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeProgramState(state,_params){

        if(vm.formProgram && vm.formProgram.$dirty){
            $confirm({text: $filter('translate')('programs.edit.cancelq'), title: $filter('translate')('programs.prog2')}).then( () => {
                $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
                vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
                $state.go(state,_params); //Canviem d'estat
            })
        }else{
            $state.go(state,_params);
        }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event){
        if(vm.formProgram && vm.formProgram.$dirty){
            (typeof $event !== 'undefined') ? $event.preventDefault() : null;
            $confirm({text: $filter('translate')('programs.edit.cancelq'), title: $filter('translate')('programs.prog2')}).then( () => {
                $scope.$broadcast('formCancel');//Emetem cancelació de canvis
                vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
                if(typeof $event !== 'undefined') {
                    document.querySelector('vegga-overlay').dismiss();
                    $state.go('a45programs',{program:vm.program,unit:vm.currentUnit})
                } else {
                    $state.go('a45programs.detail',{program:vm.program,id:vm.program.pk.id});
                }
            })
        }else{
            (typeof $event !== 'undefined') ? $state.go('a45programs',{program:vm.program,unit:vm.currentUnit}) : $state.go('a45programs.detail',{program:vm.program,id:vm.program.pk.id});
        }
    }
    function save(_ev){
        if(vm.formProgram && vm.formProgram.$dirty){
            $confirm({text: $filter('translate')('programs.edit.saveq')}).then( () => {
                $scope.$broadcast('formSubmit');        
            })
        } 
    }

    //TODO Revisar com fer el reload en la nova vista de programes
    $scope.$on('refresh',function(event,args){       
        // loadPrograms($state.params.unit.id)             
        
        loadPrograms($state.params.unit.id).then(function(){
            if($state.includes('a45programs.detail')){
                if($state.params.program !== undefined && vm.activeList.length > 0 ) {
                   var program =  _.filter(vm.activeList, o => {return o.pk.id === $state.params.program.pk.id});
                   if(program !== undefined && program.length > 0 ){
                        program[0].op = true;
                        changeState({program:program[0],id:program[0].pk.id});
                        //$state.go($state.current,{program:program[0],id:program[0].pk.id, unit:$state.params.unit},{reload: true});
                   }else if(!$state.params.program.op){
                       $state.go($state.current,{program:$state.params.program,id:$state.params.program.pk.id, unit:$state.params.unit},{reload: true});
                   }else{
                       $state.go('a45programs',{unit:$state.params.unit});
                   }
                   $scope.$emit('refreshed',{message:'refreshed'});    
                }else{
                     $state.go($state.current,{program:$state.params.program,id:$state.params.program.pk.id, unit:$state.params.unit},{reload: true});
                }

            }else{
                $scope.$emit('refreshed',{message:'refreshed'});
            }
            
        }); 
        loadProgramsSectors($state.params.unit.id);
    })

    //TODO Revisar amb la nova vista si té sentit caoturar aquest event
    $scope.$on('updateEdit45',function(event,args){
        
        loadPrograms($state.params.unit.id).then(function(){
            if($state.includes('a45programs.edit') || $state.includes('a45programs.config')){
                if($state.params.program !== undefined && $state.params.program.op){
                   var program =  _.filter(vm.activeList, o => {return o.pk.id === $state.params.program.pk.id});
                   if(program !== undefined && program.length > 0){
                        vm.program = program[0];
                        vm.program.op = true;
                        //$state.go('a45programs.detail',{program:program[0],id:program[0].pk.id, unit:$state.params.unit});
                       
                   }else{
                       $state.go('a45programs',{unit:$state.params.unit});
                   }
                   $scope.$emit('refreshed',{message:'refreshed'});    
                }

            }else{
                $scope.$emit('refreshed',{message:'refreshed'});
            }
            
        }); 
    })

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e,args) => {
        vm.formProgram = args;
    });
}

ModalInstanceSuspendCtrl.$inject = ['$uibModalInstance'];
    
function ModalInstanceSuspendCtrl($uibModalInstance) {
  var vm = this;
  vm.selectedHours = 0;

  vm.ok = function () {
    $uibModalInstance.close(vm.selectedHours);
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}; 

ModalInstanceActivationCtrl.$inject = ['$uibModalInstance','program'];
    
function ModalInstanceActivationCtrl($uibModalInstance, program) {
  var vm = this;
  vm.program = program;

  vm.ok = function () {
    $uibModalInstance.close({activations: vm.selectedProg, time: vm.selectedHours});
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
};

ModalInstanceDefStopCtrl.$inject = ['$uibModalInstance'];
    
function ModalInstanceDefStopCtrl($uibModalInstance) {
  var vm = this;
  vm.checkirrig = 1;

  vm.ok = function () {
    $uibModalInstance.close(vm.checkirrig);
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}; 
    
})();