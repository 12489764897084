import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .controller('historyAnalogsController', historyAnalogsController);

  historyAnalogsController.$inject = ['$scope', '$state', 'UserData', 'historyFactory', 'sensorsFactory', '$filter', 'uiGridConstants'];

  function historyAnalogsController($scope, $state, UserData, historyFactory, sensorsFactory, $filter, uiGridConstants) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        vm.to = moment().format('DD-MM-YYYY');
        vm.checkDates10min = checkDates10min;
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.maxDateValue = moment().format('DD-MM-YYYY');
        vm.updateRangeDates = updateRangeDates;
        vm.loadHistory = loadHistory;
        vm.exportXLS = exportXLS;
        vm.getHistory = getHistory;
        vm.showHideError = showHideError;
        loaded = false;
        vm.pagination = {
          page: 1,
          limit: 15,
        };

        vm.groupBy = [
          { text: '10 ' + $filter('translate')('history.minutes'), value: '0' },
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];
        vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];

        loadAnalogs();
        initGrid();
        loadHistory();
      } else {
        $state.go('units');
      }
    }

    function initGrid(){
        vm.filter.groupBy = '2';
        vm.filter.sensor = '0';
        vm.gridOptions = {
            enableSorting: true,
            enableColumnMenus: false,
            useExternalPagination: false,
            paginationPageSizes: [15, 20, 25],
            paginationPageSize: 15,
            columnDefs: getColumnDefinition(vm.currentUnit.type),
            onRegisterApi: function(gridApi){
              vm.gridApi = gridApi;
            }
        }
    }
    function getColumnDefinition(type){
        switch(type){
            case UNITS.A_4500:
                return [
                    { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '4' , displayName: 'history.sensor' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '5' , displayName: 'history.sensorname' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '6' , displayName: 'history.avg' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '7' , displayName: 'history.max' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '8' , displayName: 'history.min' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '10' , displayName: 'sensors.errorOfMaximum2' ,  width:'**' , headerCellFilter: 'translate', visible:false  },
                    { field: '11' , displayName: 'sensors.errorOfMinimum2' ,  width:'**' , headerCellFilter: 'translate', visible:false  },
                    { field: '12' , displayName: 'sensors.error' ,  width:'**' , headerCellFilter: 'translate', visible:false },
                  ];
            default:
                return [
                    { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: 'sensorId' , displayName: 'history.sensor' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: 'sensorName' , displayName: 'history.sensorname' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: 'avg' , displayName: 'history.avg' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: 'max' , displayName: 'history.max' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: 'min' , displayName: 'history.min' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: 'sum' , displayName: 'general.integration' ,  width:'**' , headerCellFilter: 'translate' },
                  ];
        }
    }
    function checkDates() {
      if (this.from === undefined) {
        return this['2'] + ' - ' + this['3'];
      } else {
        return this.from + ' - ' + this.to;
      }
    }
    function checkDates10min() {
      if (vm.filter.groupBy === '0') {
        if (vm.filter.sensor === 0) {
          vm.filter.sensor = 1;
        }
        vm.maxDate = moment(vm.from).add(1, 'day')._d;
        vm.to = moment(vm.from).add(1, 'day')._d;
      } else {
        vm.maxDate = null;
      }
    }
    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean !== undefined) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.analogs(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
        });
      });
    }

    function exportXLS() {
      vm.loaded = false;
      var params = {
        from: moment(vm.from).format('DD-MM-YYYY'),
        to: moment(vm.to).add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: 1,
        limit: 10000,
      };
      historyFactory.analogsExport(vm.currentUnit.id, params).then(() => {
        vm.loaded = true;
      });
    }

    $scope.$on('refresh', function (event, args) {});

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadAnalogs(){
        if(vm.currentUnit.type !== UNITS.A_4500) {
            sensorsFactory.analogsactive(vm.currentUnit.id).then(function(data){
                vm.sensorList = data.plain();
                vm.sensorListDTO.push(...vm.sensorList.map(sensor => {return {text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id), value: sensor.pk.id}}));
            });
        } else {
            sensorsFactory.analogsAll(vm.currentUnit.id).then(function(data){
                vm.sensorList = data.plain();
                vm.sensorListDTO.push(...vm.sensorList.map(sensor => {return {text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id), value: sensor.pk.id}}));
            });
        }

    }

    function showHideError(){
        _.forEach(vm.gridApi.grid.columns, column => {
          if(column.name >= 10){
                if (column.visible) {
                    column.hideColumn();
                }else{
                    column.showColumn();
                }
            }
        });
        vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    }

    $scope.$on('VeggaSelectChange', (_e, data) => {
      vm.filter.groupBy = data.value;
      vm.checkDates10min();
    });
  }
})();
