import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('historyAvgPhCeController', historyAvgPhCeController);

  historyAvgPhCeController.$inject = ['$scope', '$state', '$filter', 'UserData', 'historyFactory','sectorFactory'];

  function historyAvgPhCeController($scope, $state, $filter, UserData, historyFactory, sectorFactory) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.to = moment().format('DD-MM-YYYY');
        vm.maxDateValue = moment().format('DD-MM-YYYY');
        vm.updateRangeDates = updateRangeDates;
        vm.loadHistory = loadHistory;
        vm.exportXLS = exportXLS;
        loaded = false;

        vm.pagination = {
          page: 1,
          limit: 15,
        };

        vm.groupBy = [
          { text: '10 ' + $filter('translate')('history.minutes'), value: '0'},
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];

          vm.sectorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0'}];

        initGrid();
        loadHistory();
        loadSectors();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.filter.groupBy = '2';
      vm.filter.sector = '0';
      vm.gridOptions = {
        enableSorting: true,
        enableColumnMenus: false,
        useExternalPagination: false,
        paginationPageSizes: [15, 20, 25],
        paginationPageSize: 15,
        columnDefs: getColumnDefinition(vm.currentUnit.type),
        onRegisterApi: function (gridApi) {
          vm.gridApi = gridApi;
        },
      };
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_7000:
          return [
            { field: 'checkDates7()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sector', width: '**', headerCellFilter: 'translate' },
            { field: '5', displayName: 'history.sensorname', width: '**', headerCellFilter: 'translate' },
            { field: 'getPrograms7()', displayName: 'history.program', width: '**', headerCellFilter: 'translate' },
            {
              field: '7',
              displayName: 'history.time',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '8',
              displayName: 'history.avgph',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:10:2',
            },
            {
              field: '9',
              displayName: 'history.avgce',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:10:2',
            },
                  ];
            case UNITS.A_5500:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            { field: '3', displayName: 'history.sector', width: '**', headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sensorname', width: '**', headerCellFilter: 'translate' },
            { field: '5', displayName: 'history.program', width: '**', headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'history.time',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '7',
              displayName: 'history.avgph',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:10:1',
            },
            {
              field: '8',
              displayName: 'history.avgce',
              width: '**',
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:10:1',
            },
          ];
          case UNITS.A_4500:
                return [
                    { field: 'checkDates7()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '4' , displayName: 'history.sector' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '5' , displayName: 'sectors.c3' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '6' , displayName: 'history.program' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '7' , displayName: 'programs.edit.name' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '8' , displayName: 'history.avgce' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '9' , displayName: 'history.avgph' ,  width:'**' , headerCellFilter: 'translate' }
                  ]
      }
    }

    function checkDates() {
      return this['1'] + ' - ' + this['2'];
    }
    function checkDates7() {
      return this['2'] + ' - ' + this['3'];
    }

    function getPrograms7() {
      if (Number(this['6']) > 1) {
        return 'varios';
      } else {
        return this['11'];
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean !== undefined) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.avgphce(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
          row.checkDates7 = checkDates7;
          row.getPrograms7 = getPrograms7;
        });
      });
    }
    function exportXLS() {
      var name = moment(vm.from).format('DD-MM-YYYY') + '_' + moment(vm.to).format('DD-MM-YYYY') + '.xlsx';
      historyFactory.export(vm.gridApi.grid, name);
    }

    function loadSectors(){
        if(vm.currentUnit.type === UNITS.A_4500){
            sectorFactory.allSectors(vm.currentUnit.id).then(function(data){
                vm.sectorList = data.plain();
                vm.sectorListDTO.push(...vm.sectorList.map(sector => {return {text: sector.pk.id + ' - ' + (sector.name ? sector.name : 'Sector ' + sector.pk.id), value: sector.pk.id}}));
            });
        }


    }

    $scope.$on('refresh', function (event, args) {});
  }
})();
