export const metersFormats = {
    0: {
        0: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"m3/h"
          },
          1: {
            min:0,
            max:999.9,
            decimals:1,
            integers:3,
            label:"m3/h"
          },
          2: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"m3/h"
          },
          3: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"L/h"
          },
          4: {
            min:0,
            max:999.9,
            decimals:1,
            integers:3,
            label:"L/h"
          },
          5: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"L/h"
          },
          6: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"L/s"
          },
          7: {
            min:0,
            max:999.9,
            decimals:1,
            integers:3,
            label:"L/s"
          },
          8: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"L/s"
          },
          9: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"m3/s"
          },
          10: {
            min:0,
            max:999.9,
            decimals:1,
            integers:3,
            label:"m3/s"
          },
          11: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"m3/s"
          },
          12: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"kwh"
          },
          13: {
            min:0,
            max:999.9,
            decimals:1,
            integers:3,
            label:"kwh"
          },
          14: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"kwh"
          },
          15: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"U"
          },
          16: {
            min:0,
            max:99.99,
            decimals:2,
            integers:2,
            label:"L/m2"
          },
          17: {
            min:0,
            max:9999,
            decimals:0,
            integers:4,
            label:"mm"
          }
          
    },
    1: {
        0: {
          min:0,
          max:9999,
          decimals:0,
          integers:4,
          label:"m3"
        },
        1: {
          min:0,
          max:9999,
          decimals:0,
          integers:4,
          label:"L"
        },
        2: {
          min:0,
          max:9999,
          decimals:0,
          integers:4,
          label:"cl"
        }
    },
    2: {
        0: {
          min:0,
          max:9999,
          decimals:0,
          integers:4,
          label:"U"
        }
    }
}