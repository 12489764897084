import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsController', programsController)
    .controller('ModalInstanceCtrl', ModalInstanceCtrl);

  programsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$uibModal',
    '$confirm',
    '$filter',
  ];

  function programsController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $uibModal,
    $confirm,
    $filter
  ) {
    var vm = this;

    //Enumerat per definir els tipus de inici
    const startTypes = Object.freeze({
      hour: 0, //Inici per condicionant
      seq: 1, //Inici seqüencial
      cond: 2, //Inici per condicionant
    });
    //Enumerat per definir els tipus d'unitat de reg
    const unitTypes = Object.freeze({
      HM: 0, //hh:mm
      M3: 1, //m3
      M3HA: 2, // m3/Ha -> Sols al Plus
      MS: 3, // Minuts, Segons
      HMHA: 4, // Hores, Minuts /Ha v.6.00   -> Sols al Plus
    });

    vm.activeList = [];
    vm.filterList = [];

    //vm.gridColumnsDef = [{'headerName': 'Programas', 'field': 'name'}, {'headerName': 'Inicio Riego', 'field': 'irrigationHour'}, {'headerName': 'Estado', 'field': '_state'}];
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('programs.prog2'), field: '' },
      { headerName: $filter('translate')('programs.detail.name'), field: '' },
      { headerName: $filter('translate')('programs.edit.start'), field: '' },
      { headerName: $filter('translate')('history.days'), field: '' },
      { headerName: $filter('translate')('programs.detail.startirrig'), field: '' },
      { headerName: $filter('translate')('programs.edit.sectors'), field: '' },
      { headerName: $filter('translate')('programs.edit.irrig'), field: '' },
      { headerName: $filter('translate')('fert.state'), field: '' },
    ];
    vm.newProgram;
    vm.dataGridRowClick;
    vm.getProgramStartType;
    vm.getProgramDaysFreq;
    vm.getProgramValueInUnit;
    vm.changeProgramState;

    activate();

    function activate() {
      vm.UNITS = UNITS;
      vm.currentState = $state;
      vm.changeState = changeState;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.program = $state.params.program;
        vm.newProgram = newProgram;
        vm.openUnoperative = openUnoperative;
        vm.dataGridRowClick = dataGridRowClick;
        vm.changeProgram = changeProgram;
        vm.changingProgram = changingProgram;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.getProgramStartType = getProgramStartType;
        vm.getProgramDaysFreq = getProgramDaysFreq;
        vm.getProgramStartTime = getProgramStartTime;
        vm.getProgramValueInUnit = getProgramValueInUnit;
        vm.cancel = cancel;
        vm.save = save;
        vm.changeProgramState = changeProgramState;
        vm.filterByName = filterByName;

        // if(! ($state.includes('programs.edit') || $state.includes('programs.detail') || $state.includes('programs.config'))){
        //     $state.go('.',{unit:vm.currentUnit, program:vm.program});
        // }

        //loadFirstProgram($state.params.unit.id,$state.params.config);
        loadPrograms($state.params.unit.id);
      } else {
        $state.go('units');
      }
    }

    function dataGridRowClick(_e, prog) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.programSelected = prog;
      changeState({ program: prog, id: prog.pk.id }, $state.params.config);
    }

    function changeProgram(e) {
      if (e.detail.value) {
        vm.programSelected = e.detail.value;
        changeState({ program: vm.programSelected, id: vm.programSelected.pk.id }, $state.params.config);
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeProgram($event);
          break;
      }
    }

    // function loadFirstProgram(id,config){
    //      return progFactory.programs(id,vm.currentUnit.type,true).then(function(data){

    //         vm.activeList = progFactory.activePrograms();
    //         var actives = _.map(vm.activeList,o=>{ return Number(o.pk.id)});
    //         if(vm.currentUnit.type === 2){
    //             var comparator = _.range(1,36);
    //         }else{
    //             var comparator = _.range(1,51);
    //         }
    //         vm.unactiveList = _.xor(actives,comparator);
    //     //    if(vm.program === undefined && vm.activeList && vm.activeList.length > 0){
    //     //      changeState({program:vm.activeList[0],id:vm.activeList[0].pk.id},config)
    //     //    }

    //     });
    // }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('programs.config')) {
          $state.go('programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });

      //prepareModel(model,id);
    }
    function loadPrograms(id) {
      return progFactory.programs(id, vm.currentUnit.type, true).then(function (data) {
        vm.activeList = _.orderBy(
          progFactory.activePrograms(),
          [
            'op',
            (o) => {
              return new Number(o.pk.id);
            },
          ],
          ['asc', 'asc']
        );

        var actives = _.map(vm.activeList, (o) => {
          return Number(o.pk.id);
        });
        if (vm.currentUnit.type === vm.UNITS.A_4000) {
          var comparator = _.range(1, 36);
        } else {
          var comparator = _.range(1, 51);
        }

        vm.anyActiveProgram = vm.activeList.some((program) => program.op);

        filterByName();
      });
    }

    function changeState(params, config) {
      vm.program = params.program;
      vm.active = null;
      vm.program.op = true;
      if (
        $state.includes('programs.edit') ||
        $state.includes('programs.detail') ||
        $state.includes('programs.config')
      ) {
        $state.go('.', params);
      } else if (config) {
        $state.go('programs.config', params);
      } else {
        $state.go('programs.detail', params);
      }
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.activeList, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    /*
     * Funcions per parsejar valors de columna "calculats"
     */
    function getProgramStartType(type) {
      switch (type) {
        case startTypes.hour:
          return 'programs.edit.hour';
        case startTypes.seq:
          return 'programs.edit.seq';
        case startTypes.cond:
          if (vm.currentUnit.inoptions.plus) {
            return 'programs.cond';
          } else {
            return 'programs.edit.input';
          }
      }
    }

    function getProgramDaysFreq(program) {
      //Inici per freqüencia
      let each = $filter('translate')('programs.edit.each');
      let days_string = $filter('translate')('programs.edit._days');
      return each + ' ' + program.freqDays + ' ' + days_string;
    }

    function getProgramStartTime(prog) {
      switch (prog.type) {
        case startTypes.hour:
          return progFactory.getTimeFromMins(prog.start).format('HH:mm');
        case startTypes.seq:
          return 'P' + prog.start;
        case startTypes.cond:
          return '-';
      }
    }

    function getProgramValueInUnit(prog) {
      var unit = prog.unit;
      var input = prog.value;
      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case unitTypes.HM:
          return $filter('parsetime')(input * 60);
        case unitTypes.M3:
          return (input / 1000).toFixed(2) + 'm3';
        case unitTypes.M3HA:
          return (input / 1000).toFixed(2) + ' m3';
        case unitTypes.MS:
          var mins = input;
          return $filter('parsetime', true)(mins);
        case unitTypes.HMHA:
          return input + ' l';
      }
    }

    function changeProgramState(state, _params) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, _params); //Canviem d'estat
        });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('programs', { program: vm.program, unit: vm.currentUnit });
          } else {
            $state.go('programs.detail', { program: vm.program, id: vm.program.pk.id });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('programs', { program: vm.program, unit: vm.currentUnit })
          : $state.go('programs.detail', { program: vm.program, id: vm.program.pk.id });
      }
    }

    function filterByName() {
      if (vm.searchByProgramName) {
        vm.filterList = vm.activeList.filter((program) =>
          program.name.toLowerCase().includes(vm.searchByProgramName.toLowerCase())
        );
      } else {
        vm.filterList = vm.activeList;
      }
    }

    function save(_ev) {
      $scope.$broadcast('formSubmit');
    }

    $scope.$on('refresh', function (event, args) {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('programs.detail')) {
          if ($state.params.program !== undefined) {
            var program = _.filter(vm.activeList, { name: $state.params.program.name });
            program.op = true;
            if (program !== undefined && program.length > 0) {
              $state.go('programs.detail', { program: program[0], id: program[0].pk.id, unit: $state.params.unit });
            } else {
              $state.go('programs', { unit: $state.params.unit }, { reload: true });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    $scope.$on('updateEdit', function (event, args) {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('programs.edit') || $state.includes('programs.config')) {
          if ($state.params.program !== undefined && $state.params.program.op) {
            var program = _.filter(vm.activeList, { name: $state.params.program.name });
            if (program !== undefined && program.length > 0) {
              vm.program = program[0];
              vm.program.op = true;
              $state.go('programs.detail', { program: program[0], id: program[0].pk.id, unit: $state.params.unit });
            } else {
              $state.go('programs', { unit: $state.params.unit }, { reload: true });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });
  }

  ModalInstanceCtrl.$inject = ['$uibModalInstance', 'program'];

  function ModalInstanceCtrl($uibModalInstance, program) {
    var vm = this;
    vm.program = program;

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedProg);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
