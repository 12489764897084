import { GraphicsFilter } from '../utils/graphics';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('graphicsController', graphicsController);

  graphicsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'graphicsFactory',
    'UserData',
    '$filter',
    '$confirm',
  ];

  function graphicsController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    graphicsFactory,
    UserData,
    $filter,
    $confirm
  ) {
    var vm = this;
    var order, ordersplit;
    vm.parent = GraphicsFilter.irrigationParent;
    vm.sort = {
      column: '',
      descending: false,
    };

    vm.elementsArray = GraphicsFilter.irrigationControlGraphic;

    vm.gridColumnsDef = [
      { headerName: $filter('translate')('graphics.graphics'), field: '' },
      { headerName: $filter('translate')('graphics.name'), field: '' },
      { headerName: $filter('translate')('graphics.description'), field: '' },
      { headerName: $filter('translate')('graphics.datemod'), field: '' },
    ];

    activate();

    function activate() {
      if ($state.params.units || $rootScope.devicesArray) {
        if ($state.$current.name === GraphicsFilter.agroclimaticParent) {
          vm.parent = GraphicsFilter.agroclimaticParent;
          vm.elementsArray = GraphicsFilter.climaPlantaSolGraphic;
        } else {
          vm.isFromAgroclimatic = GraphicsFilter.irrigationParent;
        }

        vm.currentState = $state;
        order = graphicsFactory.getOrderStorage(UserData.id);
        if (order) {
          ordersplit = _.split(order, ',');
          vm.sort.column = ordersplit[0];
          if (ordersplit[1] === 'true') {
            vm.sort.descending = true;
          } else {
            vm.sort.descending = false;
          }
        }
        vm.units = $state.params.units || $rootScope.devicesArray;
        vm.changeState = changeState;
        vm.changeSorting = changeSorting;
        vm.changeGraphic = changeGraphic;
        vm.changingGraphic = changingGraphic;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.dataGridRowClick = dataGridRowClick;
        vm.save = save;
        vm.cancel = cancel;
        vm.applyGraphicFilters = applyGraphicFilters;
        vm.deleteGraphic = deleteGraphic;
        vm.changeGraphicState = changeGraphicState;
        loadGraphics();
      }
    }

    function loadGraphics() {
      const filter =
        vm.parent === GraphicsFilter.irrigationParent
          ? GraphicsFilter.irrigationControlGraphic
          : GraphicsFilter.climaPlantaSolGraphic;
      graphicsFactory.getGraphics(UserData.id).then((result) => {
        if (result) {
          vm.graphicsList = [];
          result.forEach((graphic) => {
            const graphicBackup = JSON.parse(JSON.stringify(graphic));
            graphicBackup.graphicElements = [];
            graphic.graphicElements.forEach((graphicEl) => {
              const typeExist = filter.find((element) => element.id === graphicEl.type);
              if (typeExist) {
                graphicBackup.graphicElements.push(graphicEl);
              }
            });

            if (vm.parent === GraphicsFilter.irrigationParent) {
              if (graphicBackup.graphicElements.length === graphic.graphicElements.length) {
                vm.graphicsList.push(graphic);
              }
            } else {
              if (graphicBackup.graphicElements.length > 0) {
                vm.graphicsList.push(graphic);
              }
            }
          });
          vm.graphicListRaw = vm.graphicsList;
        }
      });
    }

    function dataGridRowClick(state, g) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      changeState(state, g);
    }

    function changeState(state, g) {
      vm.graphic = g;
      $state.go(`${vm.parent}.${state}`, { graphic: g, unitList: vm.units });
    }

    function changeGraphic(e) {
      if (e.detail.value) {
        changeState('detail', e.detail.value);
      }
    }

    function changingGraphic($event) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeGraphic($event);
          break;
      }
    }

    function changeSorting(column) {
      if (vm.sort.column == column) {
        vm.sort.descending = !vm.sort.descending;
      } else {
        vm.sort.column = column;
        vm.sort.descending = false;
      }
      graphicsFactory.setOrderStorage(UserData.id, vm.sort.column, vm.sort.descending);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeGraphicState(state, g) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial
          changeState(state, g);
        });
      } else {
        changeState(state, g);
      }
    }

    function cancel(from, $event) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formGraphic.$setPristine(); //Actualitzem estat del formulari a inicial

          if (vm.graphic && from === 'button') {
            changeState('detail', vm.graphic);
          } else {
            document.querySelector('vegga-overlay').dismiss();
            $state.go(vm.parent, { units: vm.units });
          }
        });
      } else {
        if (vm.graphic && from === 'button') {
          changeState('detail', vm.graphic);
        } else {
          $state.go(vm.parent, { units: vm.units });
          document.querySelector('vegga-overlay').dismiss();
        }
      }
    }

    function save(_ev) {
      if (vm.formGraphic && vm.formGraphic.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function applyGraphicFilters(_ev) {
      $scope.$broadcast('applyFilters');
    }

    function deleteGraphic() {
      $confirm({ text: $filter('translate')('graphics.delgraphic') }).then(() => {
        graphicsFactory.deleteGraphic(vm.graphic.pk.userId, vm.graphic.pk.id).then(() => {
          document.querySelector('vegga-overlay').dismiss();
          $state.go(vm.parent);
          vm.graphicsList = vm.graphicsList.filter((graphic) => graphic.pk.id !== vm.graphic.pk.id);
        });
      });
    }

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formGraphic = args;
    });

    $scope.$on('closeOverlay', (e, args) => {
      document.querySelector('vegga-overlay').dismiss();
      $state.go(vm.parent, { units: vm.units });
      loadGraphics();
    });
  }
})();
