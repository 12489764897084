import { UNITS } from '../utils/units.enum';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyController', historyController);

  historyController.$inject = ['$scope', '$state'];

  function historyController($scope, $state) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
      } else {
        $state.go('units');
      }
    }

    $scope.$on('refresh', function (event, args) {});
  }
})();
