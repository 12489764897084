import { UNITS } from "../../utils/units.enum";

(function() {
  "use strict";


  angular.module("agronicwebApp")

    .controller("outPressureHeaderController", outPressureHeaderController);

  outPressureHeaderController.$inject = ["$scope", "$state", "configFactory", "filterFactory", "sensorsFactory"];

  function outPressureHeaderController($scope, $state, configFactory, filterFactory, sensorsFactory) {

    var vm = this;
    var backupfilters, backupconfig;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.currentHeader;
    vm.config;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.currentHeader = $state.params.headerId || 1;
        vm.currentUnit = $state.params.unit;
        loadPressureHeaders();
        loadSensorsAnalog();
        vm.selected = 0;

      } else {
        $state.go("units");
      }
    }

    function loadPressureHeaders() {
      var params = { add: [""] };
      configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result) => {
        vm.config = result.plain();
        backupconfig = angular.copy(vm.config);
      });

      filterFactory.getHeaderFilters(vm.currentUnit.id, vm.currentHeader).then(function(data) {
        vm.filters = data.plain();
        backupfilters = angular.copy(vm.filters);
      });
    }

    function loadSensorsAnalog() {
      sensorsFactory.analogsAll(vm.currentUnit.id).then(function(data) {
        vm.analogs = data.plain();
      });
    }

    function save() {
      configFactory.updateConfigHeader(vm.currentUnit.id, vm.config).then(result => {
        vm.config = result;
        vm.form.$setPristine();
        backupconfig = angular.copy(vm.config);
        filterFactory.saveHeaderFilter(vm.currentUnit.id, vm.currentHeader, vm.filters).then(result => {
          vm.filters[0] = result.plain();
          backupfilters = angular.copy(vm.filters);
        });
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backupconfig);
      vm.filters = angular.copy(backupfilters);
    }

    $scope.$on("refresh", function(event, args) {

    });

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(() => vm.form, () => {
      $scope.$emit("formUpdated", vm.form);
    });

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on("formSubmit", (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on("formCancel", (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }


})();