import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';
import { METERFORMATS45 } from '../utils/meters-formats45.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('fertilizersConfigController', fertilizersConfigController);

  fertilizersConfigController.$inject = [
    '$scope',
    '$state',
    'fertilizerFactory',
    'sensorsFactory',
    'veggaSelectFactory',
    '$confirm',
    '$filter',
    '$q',
      'configFactory',
      'progFactory',
      '$mdDialog',
      'unitFactory',
      'UserData',
      '$rootScope',
  ];
  function fertilizersConfigController(
    $scope,
    $state,
    fertilizerFactory,
    sensorsFactory,
    veggaSelectFactory,
    $confirm,
    $filter,
    $q,
    configFactory,
      progFactory,
      $mdDialog,
      unitFactory,
      UserData,
      $rootScope
  ) {
    var vm = this;
    vm.tabState = 'general';

    let allFertilizationHeaders;
    let allPhytosHeaders;
    let meterSensors;

    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = JSON.parse(JSON.stringify($state.params.unit));

        prepareFormData(vm.currentUnit.type);
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.checkFert = checkFert;
        vm.UNITS = UNITS;
        vm.changeFert = changeFert;
        vm.changingFert = changingFert;
        vm.nextOrPreviousItem = nextOrPreviousItem;

        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.setHeader = setHeader;
        }
        if (vm.currentUnit.fertilizer && !vm.currentUnit.fertilizer.backup) {
          backup();
        }
        loadFertilizerParameters();
        if (vm.currentUnit.type === vm.UNITS.A_5500) {
          loadAnalogSensors();
        }
      } else {
        $state.go('units');
      }
    }

    function prepareFormData(type) {
      switch (type) {
        case UNITS.A_4000:
          vm.numberFert = _.range(0, 9);
          vm.numberFertDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberFert);
          vm.fertTypes = {
            0: 'fert.fer49',
            1: 'fert.fer50',
            2: 'fert.fer51',
          };

          if (vm.currentUnit.installer.instantFlow === 1) {
            vm.integersFertFlow = 4;
            vm.decimalsFertFlow = 0;
            vm.maxFertFlow = 9999;
          } else {
            vm.integersFertFlow = 3;
            vm.decimalsFertFlow = 2;
            vm.maxFertFlow = 655.35;
          }

          break;
        case UNITS.A_2500:
          vm.numberFert = _.range(0, 5);
          vm.numberFertDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberFert);
          vm.numberMeter = _.range(0, 11);
          vm.numberMeterDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberMeter);
          vm.a25FlowUnits = {
            0: 'm3/h',
            1: 'L/h',
            2: 'L/s',
          };
          break;
        case UNITS.A_BIT:
          vm.numberMeter = _.range(0, 21);
          vm.numberMeterDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberMeter);
          vm.page = 1;
          break;
        case UNITS.A_7000:
          vm.numberFert = _.range(0, 9);
          vm.numberFertDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberFert);
          checkFert(1);
          break;
        case UNITS.A_5500:
          vm.numberMeter = _.range(0, 11);
          vm.numberMeterDTO = veggaSelectFactory.formatterVeggaSelect(vm.numberMeter);
          break;
        case UNITS.A_4500:
          vm.indexSelectedNav = 0;
          vm.changeNav = changeNav;
          vm.resetFlow = resetFlow;
          vm.setExampleText = setExampleText;
          if (vm.currentUnit.inoptions.optionHidro) {
            vm.tabs = {
              0: $filter('translate')('general.general'),
              1: $filter('translate')('programs.fert'),
              2: $filter('translate')('fert.tanks'),
              3: $filter('translate')('general.menu22'),
            };
            vm.navs = {
              0: 'general-config',
              1: 'fertilizers',
              2: 'tanks',
              3: 'phytosanitary',
            };
          } else {
            vm.tabs = {
              0: $filter('translate')('general.general'),
              1: $filter('translate')('programs.fert'),
            };
            vm.navs = {
              0: 'general-config',
              1: 'fertilizers',
            };
          }
          vm.numberFert = _.range(0, 9);
          vm.fertTypes = {
            0: 'Paralela',
            1: 'Serie',
            2: 'Solar',
          };
          vm.meterFormats = METERFORMATS45;
          vm.currentHeader = 1;
          vm.selectedNav = 'general-config';
          update();
          break;
      }
    }

    function resetFlow(fertilizer) {
      fertilizer.expectedFlow = 0;
      setFertilizerMetersUsed();
      setFertilizerFormat(fertilizer);
    }

    function setFertilizerFormat(fertilizer) {
      let meter = vm.meters.find((m) => {
        return fertilizer.idMeter == m.pk.id;
      });
      if (meter === undefined) {
        fertilizer.flowFormat = {
          decimals: 2,
          integers: 2,
          max: 99.99,
          format: 'L/h',
        };
      } else {
        fertilizer.flowFormat = vm.meterFormats[meter.flowFormat];
      }
    }

    function setHeader(headerId) {
      if (vm.currentHeader !== headerId) {
        vm.currentHeader = headerId;
        setCabezalFertilizacion();
        setCabezalGeneralFertilizantes();
        setCabezalGeneralDepositos();
        setCabezalGeneralFitos();
        sensorsFactory.meters(vm.currentUnit.id, { operative: 'true' }).then((result) => {
          meterSensors = result.plain();
          parseMeterSensors();
        });
        if (vm.fertilizationHeader.fertilizersNum == 0) {
          vm.selectedNav = 'general-config';
          vm.indexSelectedNav = 0;
        }
      }
    }

    function setCabezalFertilizacion() {
      vm.fertilizationHeader = allFertilizationHeaders[vm.currentHeader - 1];
    }

    function setCabezalGeneralFertilizantes() {
      if (vm.selectedNav == 'fertilizers') {
        let queries = [];
        queries.push(configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, { add: ['fertilizers'] }));
        $q.all(queries).then((response) => {
          loadGeneralFertilizationHeader(response[0]);
          for (let fert of vm.generalFertilizerHeader.fertilizers) {
            setFertilizerFormat(fert);
          }
        });
      }
    }

    function setCabezalGeneralDepositos() {
      if (vm.selectedNav == 'tanks') {
        loadGeneralTanksHeader();
      }
    }

    function setCabezalGeneralFitos() {
      if (vm.selectedNav == 'phytosanitary') {
        vm.phyto1 = allPhytosHeaders[vm.currentHeader * 2 - 2];
        vm.phyto2 = allPhytosHeaders[vm.currentHeader * 2 - 1];
      }
    }

    function changeNav(event) {
      console.log(event);
      event.preventDefault();
      if (vm.selectedNav !== vm.navs[event.detail.index]) {
        if (vm.form && vm.form.$dirty) {
          $confirm({
            text: $filter('translate')('programs.edit.cancelq'),
            title: $filter('translate')('programs.prog2'),
          }).then(() => {
            //$scope.$broadcast('formCancel'); //Emetem cancelació de canvis
            vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
            vm.indexSelectedNav = event.detail.index;
            vm.selectedNav = vm.navs[event.detail.index];
            update();
            if (vm.fertilizationHeader.fertilizersNum == 0) {
              vm.selectedNav = 'general-config';
              vm.indexSelectedNav = 0;
            }
          });
        } else {
          vm.indexSelectedNav = event.detail.index;
          vm.selectedNav = vm.navs[event.detail.index];
          update();
        }
      }
    }

    function update() {
      updateGeneralConfigTab();
      updateFertilizersTab();
      updateTanksTab();
      updatePhytosanitaryTab();
    }

    function updateGeneralConfigTab() {
      if (vm.selectedNav === 'general-config') {
        loadFertilizationHeaders();
        loadAnalogSensors();
      }
    }

    function loadFertilizerParameters() {
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.parameters = fert;
      });
    }
function updateFertilizersTab() {
      if (vm.selectedNav === 'fertilizers') {
        let queries = [];
        queries.push(
          configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, { add: ['fertilizers'] }),
          sensorsFactory.meters(vm.currentUnit.id, { operative: 'true' })
        );
        $q.all(queries).then((response) => {
          loadGeneralFertilizationHeader(response[0]);
          loadMeterSensors(response[1]);
          for (let fert of vm.generalFertilizerHeader.fertilizers) {
            setFertilizerFormat(fert);
          }
        });
      }
    }

    function updateTanksTab() {
      if (vm.selectedNav === 'tanks') {
        loadGeneralTanksHeader();
        loadAnalogSensors();
      }
    }

    function updatePhytosanitaryTab() {
      if (vm.selectedNav === 'phytosanitary') {
        let queries = [];
        queries.push(
          progFactory.getFitos(vm.currentUnit.id),
          sensorsFactory.meters(vm.currentUnit.id, { operative: 'true' })
        );
        $q.all(queries).then((response) => {
          loadGeneralPhytosanitaryHeaders(response[0]);
          loadMeterSensors(response[1]);
          setExampleText();
        });
      }
    }

    function loadFertilizationHeaders() {
      fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id).then((data) => {
        allFertilizationHeaders = data.plain();
        vm.fertilizationHeader = allFertilizationHeaders[vm.currentHeader - 1];
      });
    }
    function loadGeneralFertilizationHeader(result) {
      vm.generalFertilizerHeader = result.plain();
    }

    function loadGeneralTanksHeader() {
      configFactory.getHeaderTanks(vm.currentUnit.id, vm.currentHeader).then((data) => {
        vm.generalTanksHeader = data.plain();
      });
    }

    function loadGeneralPhytosanitaryHeaders(data) {
      allPhytosHeaders = data.plain();
      vm.phyto1 = allPhytosHeaders[vm.currentHeader * 2 - 2];
      vm.phyto2 = allPhytosHeaders[vm.currentHeader * 2 - 1];
    }

    function loadAnalogSensors() {
      var params = { page: 1, limit: 40 };
      sensorsFactory.analogsPage(vm.currentUnit.id, params).then((result) => {
        vm.analogs = result.content;
      });
    }

    function loadMeterSensors(result) {
      meterSensors = result.plain();
      parseMeterSensors();
    }

    function parseMeterSensors() {
      vm.meters = angular.copy(meterSensors);
      if (vm.selectedNav == 'fertilizers') {
        // add variable to identify meters that are already selected on the dropdown
        copyCurrentFertilizerMetersUsed();
      } else if (vm.selectedNav == 'phytosanitary') {
        copyCurrentPhytoMetersUsed();
      }
    }

    // copies the value of the attribute sensorUsed to a new attribute and sets the originial one to false in case that the checked sensor is used in the actual page.
    function copyCurrentPhytoMetersUsed() {
      for (let m of vm.meters) {
        m.used = m.isSensorUsed;
        if (m.isSensorUsed) {
          if (vm.phyto1.idmeter > 0) {
            let meter = vm.meters.find((m) => {
              return m.pk.id == vm.phyto1.idmeter;
            });
            if (meter !== undefined && meter.pk.id == m.pk.id) {
              m.isSensorUsed = false;
            }
          }
          if (vm.phyto2.idmeter > 0) {
            let meter = vm.meters.find((m) => {
              return m.pk.id == vm.phyto2.idmeter;
            });
            if (meter !== undefined && meter.pk.id == m.pk.id) {
              m.isSensorUsed = false;
            }
          }
        }
      }
    }

    // copies the value of the attribute sensorUsed to a new attribute and sets the originial one to false in case that the checked sensor is used in the actual page.
    function copyCurrentFertilizerMetersUsed() {
      for (let m of vm.meters) {
        m.used = m.isSensorUsed;
        if (m.isSensorUsed) {
          for (let i = 0; i < vm.fertilizationHeader.fertilizersNum; i++) {
            let row = vm.generalFertilizerHeader.fertilizers[i];
            if (row.idMeter > 0) {
              let meter = vm.meters.find((m) => {
                return m.pk.id == row.idMeter;
              });
              if (meter !== undefined && meter.pk.id == m.pk.id) {
                m.isSensorUsed = false;
              }
            }
          }
        }
      }
    }

    function setFertilizerMetersUsed() {
      for (let m of vm.meters) {
        m.used = m.isSensorUsed;
      }

      for (let i = 0; i < vm.fertilizationHeader.fertilizersNum; i++) {
        let row = vm.generalFertilizerHeader.fertilizers[i];
        if (row.idMeter > 0) {
          let meter = vm.meters.find((m) => {
            return m.pk.id == row.idMeter;
          });
          if (meter != undefined) {
            meter.used = true;
          }
        }
      }
    }

    function backup() {
      vm.backup = {};
      vm.backup2 = {};
      angular.copy(vm.currentUnit.fertilizer, vm.backup);
      angular.copy(vm.parameters, vm.backup2);
      vm.currentUnit.fertilizer.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.backup, vm.currentUnit.fertilizer);
      angular.copy(vm.backup2, vm.parameters);
    }

    function saveFertilizationHeaders() {
      if (vm.selectedNav === 'general-config' || vm.selectedNav === 'phytosanitary') {
        fertilizerFactory.updateFertilizationHeader(vm.currentUnit.id, [vm.fertilizationHeader]);
      }
    }
    function saveTanksHeaders() {
      configFactory.updateGeneralHeadersTanks(vm.currentUnit.id, vm.generalTanksHeader);
    }
    function saveGeneralFerilizationHeaders() {
      configFactory.updateConfigHeader(vm.currentUnit.id, vm.generalFertilizerHeader);
    }

    function savePhytos() {
      fertilizerFactory.updatePhytos45(vm.currentUnit.id, [vm.phyto1, vm.phyto2]);
    }

    function setExampleText() {
      switch (vm.fertilizationHeader.unitsTf) {
        case 0:
          vm.exampleText = '00:00';
          break;
        case 1:
          vm.exampleText = "00'" + '00"';
          break;
        case 2:
          let integers = 10 ** (5 - vm.fertilizationHeader.decimalsTF) - 1;
          integers = integers.toString().replace(/9/g, 0);
          if (vm.fertilizationHeader.decimalsTF == 0) {
            vm.exampleText = integers + ' L';
          } else {
            let decimals = 10 ** vm.fertilizationHeader.decimalsTF - 1;
            decimals = decimals.toString().replace(/9/g, 0);
            vm.exampleText = integers + '.' + decimals + '  L';
          }
          break;
        case 3:
          vm.exampleText = '000.00 L/ha';
          break;
      }
    }

    function saveGeneralTab() {
      if (vm.selectedNav === 'general-config') {
        saveFertilizationHeaders();
      }
    }

    function saveFertilizersTab() {
      if (vm.selectedNav === 'fertilizers') {
        saveGeneralFerilizationHeaders();
      }
    }

    function saveTanksTab() {
      if (vm.selectedNav === 'tanks') {
        saveTanksHeaders();
      }
    }
    function savePhytosanitaryTab() {
      if (vm.selectedNav === 'phytosanitary') {
        saveFertilizationHeaders();
        savePhytos();
      }
    }

    function save() {
      vm.form.$setPristine();
      if (vm.currentUnit.type === UNITS.A_4500) {
        saveGeneralTab();
        saveFertilizersTab();
        saveTanksTab();
        savePhytosanitaryTab();
      } else if (vm.currentUnit.type !== UNITS.A_BIT) {
          vm.currentUnit.fertilizer.unittype = vm.currentUnit.type;
          fertilizerFactory.update(vm.currentUnit.id, vm.currentUnit.fertilizer).then(() => {
              fertilizerFactory
                .updateConfig(vm.currentUnit.id, vm.parameters)
                .then(() => $scope.$emit('reload', { message: 'reload' }));
          });
      } else {
          fertilizerFactory.updateConfig(vm.currentUnit.id, vm.parameters).then(() => {
              vm.parameters.fertilizers.forEach((fert) => {
                  fert.modified = 1;
                  fert.modifiedDate = moment()._d;
              })
          });
      }
    }

    function checkFert(type) {
      if (type == 1) {
        const rest = 9 - vm.currentUnit.fertilizer.fertNumCe;
        vm.numberFertUnits = _.range(0, rest);
      } else if (type == 2) {
        const rest = 9 - vm.currentUnit.fertilizer.fertNum;
        vm.numberFert = _.range(0, rest);
      }
    }

    function changeFert(e) {
      if (e.detail.value !== null && e.detail.value !== undefined) {
        vm.page = e.detail.value;
      }
    }

    function changingFert($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancel_edition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeFert($event);
          break;
      }
    }

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('fertilizersFormSave', () => {
      save();
    });
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('tabChange', (_, tabState) => {
      vm.tabState = tabState;
    });
  }
})();