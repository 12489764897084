import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import formatcoords from 'formatcoords/index';
import ParseDMS from 'parse-dms/index';
import { VeggaOverlayTab } from '../../utils/vegga-overlay-tab.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('etoController', etoController);

  etoController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'calculatedsFactory',
    'sensorsFactory',
    '$timeout',
    '$confirm',
    '$filter',
  ];

  function etoController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    calculatedsFactory,
    sensorsFactory,
    $timeout,
    $confirm,
    $filter
  ) {
    var vm = this;
    var backups;
    vm.VeggaOverlayTab = VeggaOverlayTab;

    activate();

    function activate() {
      if ($rootScope.devicesArray !== null) {
        vm.units = $rootScope.devicesArray;
      } else {
        $state.go('agroclimatic');
      }

      loadSensors();

      vm.unitName = unitName;
      vm.loadUnitSensors = loadUnitSensors;
      vm.newSensor = newSensor;
      vm.deleteSensor = deleteSensor;
      vm.updateSensor = updateSensor;
      vm.sensorEdition = sensorEdition;
      vm.cancelEdition = cancelEdition;
      vm.sensorHistory = sensorHistory;
      vm.convertDMStoDS = convertDMStoDS;
      vm.changeETO = changeETO;
      vm.changingETO = changingETO;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.cancel = cancel;
      vm.changeTab = changeTab;
      backups = {};
      $scope.$emit('stateChanged');
    }

    function unitName(sensor) {
      if (sensor.deviceId === null) {
        return null;
      } else {
        var unit = _.filter(vm.units, (o) => {
          return o.device.id === sensor.deviceId;
        })[0];
        return unit.device.name;
      }
    }

    function loadSensors() {
      calculatedsFactory.getETO(UserData.id).then((result) => {
        if (result.plain()) {
          vm.etoList = result.plain();
        }
      });
    }

    function newSensor() {
      vm.currentETOSelected = null;
      vm.currentTab = VeggaOverlayTab.NEW;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
    }

    function deleteSensor() {
      calculatedsFactory.deleteETO(UserData.id, vm.currentETOSelected.pk.id).then(() => {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.dismiss();
        vm.currentTab = null;
        vm.etoList = vm.etoList.filter((dpv) => dpv.pk.id !== vm.currentETOSelected.pk.id);
        vm.currentETOSelected = null;
      });
    }

    function sensorEdition() {
      var sensor_backup = {};
      angular.copy(vm.currentETOSelected, sensor_backup);
      backups[vm.currentETOSelected.pk.id] = sensor_backup;
      if (
        vm.currentETOSelected.Templist === undefined ||
        vm.currentETOSelected.Hrlist === undefined ||
        vm.currentETOSelected.Radlist === undefined ||
        vm.currentETOSelected.Windlist === undefined
      ) {
        loadUnitSensors(vm.currentETOSelected, true, true, true, true);
      }
      prepareLatitudeLongitude(vm.currentETOSelected);
    }

    function loadUnitSensors(sensor, temp, hr, rad, wind, onChange) {
      let params = {
        limit: 100,
        page: 1,
      };
      var binding = sensor;
      if (sensor.deviceIdTemp > 0 && temp) {
        binding.Templist = [];
        sensorsFactory.analogsPage(sensor.deviceIdTemp, params).then((result) => {
          if (onChange) {
            sensor.analogIdTemp = null;
          }

          if (result.content) {
            binding.Templist = result.content;
          }
        });
      }
      if (sensor.deviceIdHr > 0 && hr) {
        binding.Hrlist = [];
        sensorsFactory.analogsPage(sensor.deviceIdHr, params).then((result) => {
          if (onChange) {
            sensor.analogIdHr = null;
          }
          if (result.content) {
            binding.Hrlist = result.content;
          }
        });
      }
      if (sensor.deviceIdRad > 0 && rad) {
        binding.Radlist = [];
        sensorsFactory.analogsPage(sensor.deviceIdRad, params).then((result) => {
          if (onChange) {
            sensor.analogIdRad = null;
          }
          if (result.content) {
            binding.Radlist = result.content;
          }
        });
      }
      if (sensor.deviceIdWind > 0 && wind) {
        binding.Windlist = [];
        sensorsFactory.analogsPage(sensor.deviceIdWind, params).then((result) => {
          if (onChange) {
            sensor.analogIdWind = null;
          }
          if (result.content) {
            binding.Windlist = result.content;
          }
        });
      }
    }

    function cancelEdition() {
      if (vm.currentETOSelected && vm.currentETOSelected.pk) {
        angular.copy(backups[vm.currentETOSelected.pk.id], vm.currentETOSelected);
      }
      vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
    }

    function updateSensor() {
      var tmp = vm.currentETOSelected;
      convertDMStoDS(vm.currentETOSelected);
      calculatedsFactory.updateETO(UserData.id, vm.currentETOSelected).then((result) => {
        vm.currentETOSelected = result.plain();
        tmp.pk = vm.currentETOSelected.pk;
        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.etoList.push(vm.currentETOSelected);
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentETOSelected = null;
        }
        vm.form.$setPristine();
      });
    }

    function sensorHistory(sensor, reload) {
      vm.currentTab = VeggaOverlayTab.DETAIL;
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.currentETOSelected = sensor;

      let chart;
      var date_to;
      let datos_grafica = [];

      var today = new moment()._d;
      var date_ago = new moment().subtract(7, 'days')._d;

      if (sensor.fecha_desde === undefined) sensor.fecha_desde = date_ago;
      if (sensor.fecha_hasta === undefined) sensor.fecha_hasta = today;

      if (reload) {
        if (moment(sensor.fecha_hasta).isAfter(moment(today))) date_to = moment(today).format('DD-MM-YYYY');
        else date_to = moment(sensor.fecha_hasta).add(1, 'days').format('DD-MM-YYYY');
      } else date_to = moment(sensor.fecha_hasta).format('DD-MM-YYYY');

      let params = {
        to: date_to,
        from: moment(sensor.fecha_desde).format('DD-MM-YYYY'),
      };
      calculatedsFactory
        .historyETO(sensor.pk.userId, sensor.pk.id, params)
        .then((result) => {
          var mdata = [];
          mdata = result;
          mdata.forEach(function (valor, indice, array) {
            var date_timestamp = moment(mdata[indice].date, 'DD-MM-YYYY').format('x');

            date_timestamp = parseInt(date_timestamp);

            datos_grafica.push([date_timestamp, mdata[indice].value]);
          });
          $timeout(() => {
            chart = loadGraficas(datos_grafica, sensor.pk.id);
            chart.setTitle({ text: sensor.name });
          }, 100);
        })
        .catch(function (e) {});
    }

    $scope.$on('refresh', function (event, args) {});

    function loadGraficas(datos, index) {
      let id = 'graficaSensor' + index;

      let chart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' + this.series.name + ':</b> ' + this.y.toFixed(2) + '<br/>' + moment(this.x).format('DD-MM-YYYY')
            );
          },
        },
        chart: {
          renderTo: id,
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
        },
        yAxis: {
          title: '',
        },

        series: [
          {
            name: 'ETO',
            data: datos,
          },
        ],
      });
      return chart;
    }

    function convertDMStoDS(sensor) {
      var formated_lat, formated_lng;
      formated_lat =
        sensor.latdegrees + '° ' + sensor.latminutes + "' " + sensor.latseconds / 100 + '" ' + sensor.latcord;
      formated_lng =
        sensor.lngdegrees + '° ' + sensor.lngminutes + "' " + sensor.lngseconds / 100 + '" ' + sensor.lngcord;
      var tmp = ParseDMS(formated_lat + ' ' + formated_lng);
      if (tmp !== undefined && tmp.lat && tmp.lon) {
        sensor.lat = tmp.lat;
        sensor.lng = tmp.lon;
      }
    }

    function prepareLatitudeLongitude(sensor) {
      var lat = sensor.lat;
      var lng = sensor.lng;
      if (lat !== null && lng !== null) {
        var coords = formatcoords(lat, lng);
        sensor.latdegrees = coords.latValues.degreesInt;
        sensor.latminutes = coords.latValues.minutesInt;
        sensor.latseconds = Number(coords.latValues.seconds.toFixed(2)) * 100;
        sensor.lngdegrees = coords.lonValues.degreesInt;
        sensor.lngminutes = coords.lonValues.minutesInt;
        sensor.lngseconds = Number(coords.lonValues.seconds.toFixed(2)) * 100;
        sensor.latcord = coords.north ? 'N' : 'S';
        sensor.lngcord = coords.east ? 'E' : 'W';
      }
    }

    function changeETO(e) {
      if (e.detail.value) {
        vm.currentETOSelected = e.detail.value;
        vm.currentTab = VeggaOverlayTab.DETAIL;
        sensorHistory(vm.currentETOSelected);
      }
    }

    function changingETO($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeETO($event);
          break;
      }
    }

    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();
          if (vm.currentTab === VeggaOverlayTab.NEW || $event) {
            const veggaOverlay = document.querySelector('vegga-overlay');
            veggaOverlay.dismiss();
            vm.currentTab = null;
            vm.currentETOSelected = null;
          }
        });
      } else {
        if ($event) {
          const veggaOverlay = document.querySelector('vegga-overlay');
          veggaOverlay.dismiss();
          vm.currentTab = null;
          vm.currentETOSelected = null;
        }

        if (vm.currentTab === VeggaOverlayTab.NEW) {
          vm.currentTab = null;
          vm.currentETOSelected = null;
        }
      }
    }

    function changeTab(tab, $event) {
      if (vm.form && vm.form.$dirty) {
        $event?.preventDefault();
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          cancelEdition();

          vm.currentTab = tab;
          if (tab === VeggaOverlayTab.EDIT) {
            sensorEdition();
          }

          if (tab === VeggaOverlayTab.DETAIL) {
            vm.sensorHistory(vm.currentETOSelected);
          }
        });
      } else {
        vm.currentTab = tab;
        if (tab === VeggaOverlayTab.EDIT) {
          sensorEdition();
        }

        if (tab === VeggaOverlayTab.DETAIL) {
          sensorHistory(vm.currentETOSelected);
        }
      }
    }
  }
})();
