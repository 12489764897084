import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('modulesDetailController', modulesDetailController);

  modulesDetailController.$inject = ['$scope', '$state', 'commFactory'];

  function modulesDetailController($scope, $state, commFactory) {
    var vm = this;
    vm.linkBox;
    vm.getModuleError;

    var external_modules_types = {
      1: { type: 'Monocable', name: 'AM120' },
      2: { type: 'Radio', name: 'AR868-16' },
      3: { type: 'Radio', name: 'AR24' },
      4: { type: 'Radio', name: 'AR433' },
      5: { type: 'Radio', name: 'MI' },
      6: { type: 'Radio', name: 'ME' },
      7: { type: 'Radio', name: 'R868' },
      8: { type: 'Radio', name: 'AgroBee' },
      9: { type: 'Radio', name: 'AgroBeeL' },
      10: { type: 'Cable', name: 'SDI12' },
    };

    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null && $state.params.box !== null) {
        vm.UNITS = UNITS;
        vm.linkBox = $state.params.box;
        vm.currentUnit = $state.params.unit;
        vm.saveActive = saveActive;
        vm.parseLastComm = parseLastComm;
        vm.parseLastCommDigital = parseLastCommDigital;
        vm.filterActiveAgrobee = filterActiveAgrobee;

        if (vm.linkBox.typeName !== 'externModbus') {
          loadExternalModules();
        }

        vm.activeModules = activeModules;
        vm.activeAll = 0;
        vm.getModuleError = getModuleError;
      } else {
        $state.go('units');
      }
    }

    function loadExternalModules() {
      if (vm.linkBox.pk.type === 1) {
      }
      if (vm.currentUnit.type !== UNITS.A_4500) {
        commFactory.linkBoxEM(vm.currentUnit.id, vm.linkBox.pk.type).then((modules) => {
          if (modules[0]) {
            vm.linkBox = modules[0];
            checkLinkStates();
          }
        });
      } else {
        commFactory.linkBoxEM(vm.currentUnit.id, vm.linkBox.pk.type, vm.linkBox.pk.id).then((modules) => {
          if (modules[0]) {
            vm.linkBox = modules[0];
            copyExternalModules();
            checkLinkStates();
          }
        });
      }
    }

    function copyExternalModules() {
      switch (vm.linkBox.pk.type) {
        case 1:
          vm.linkBox.externalModules = vm.linkBox.externalModulesMAM120;
          break;
        case 4:
          vm.linkBox.externalModules = vm.linkBox.externalModulesMAR433;
          break;
        case 9:
          vm.linkBox.externalModules = vm.linkBox.externalModulesLora;
          break;
      }
    }

    function sortExternalModules() {
      vm.linkBox.externalModules.sort((a, b) => a.pk.id - b.pk.id);
    }

    function saveActive() {
      vm.linkBox.unittype = vm.currentUnit.type;
      commFactory.updateLinkBox(vm.currentUnit.id, vm.linkBox).then((resp) => {
        vm.linkBox = resp.plain();
        if (vm.currentUnit.type === UNITS.A_4500) {
          copyExternalModules();
          sortExternalModules();
        }
        vm.selectActive = false;
        checkLinkStates();
      });
    }

    function activeModules() {
      if (vm.activeAll === 1) {
        vm.linkBox.externalModules.forEach((module) => {
          module.active = 1;
        });
      } else {
        vm.linkBox.externalModules.forEach((module) => {
          module.active = 0;
        });
      }
    }

    function filterActiveAgrobee(module) {
      if (
        (module.model !== null && module.model !== 0 && module.pk.type === 9) ||
        (module.model !== null && module.pk.type === 8) ||
        module.pk.type === 10
      ) {
        switch (module.xState) {
          case 0:
            return false;
          case 1:
          case 2:
          case 3:
            return true;
          default:
            return false;
        }
      }
    }

    function checkLinkStates() {
      let agrobee = vm.currentUnit.inoptions.agrobee;
      let agrobeel = vm.currentUnit.inoptions.agrobeel;
      let agrobeel1;
      let agrobeel2;
      if (vm.currentUnit.type === UNITS.A_4500) {
        if (vm.currentUnit.inoptions.agroBeeL1) {
          agrobeel1 = true;
        } else if (vm.currentUnit.inoptions.agroBeeL2) {
          agrobeel2 = true;
        }
      }
      let sdi12 = true; // Ja no es una opció, sempre será actiu.
      let box = vm.linkBox;
      switch (box.pk.type) {
        case 1:
          if (vm.currentUnit.inoptions.optionMonocable || vm.currentUnit.inoptions.am) {
            if (vm.currentUnit.type === UNITS.A_4500) {
              box.enabled = box.active;
              setAR433AM120State(box);
            } else {
              box.enabled = true;
              if (box.xCommunicate) {
                if (box.pk.type === 7) {
                  if (box.port !== null && box.port !== 0) {
                    box.state = 'comm';
                  } else {
                    box.state = 'noconf';
                  }
                } else {
                  box.state = 'comm';
                }
              } else if (box.active && !box.xCommunicate) {
                box.state = 'error';
              } else {
                box.state = 'nocomm';
              }
            }
          }
          break;
        case 2:
        case 3:
        case 4:
        case 7:
          if (vm.currentUnit.inoptions.optionRadio || vm.currentUnit.inoptions.ar) {
            if (vm.currentUnit.type === UNITS.A_4500) {
              box.enabled = box.active;
              setAR433AM120State(box);
            } else {
              box.enabled = true;
              if (box.xCommunicate) {
                if (box.pk.type === 7) {
                  if (box.port !== null && box.port !== 0) {
                    box.state = 'comm';
                  } else {
                    box.state = 'noconf';
                  }
                } else {
                  box.state = 'comm';
                }
              } else if (box.active && !box.xCommunicate) {
                box.state = 'error';
              } else {
                box.state = 'nocomm';
              }
            }
          }
          break;
        case 5:
        case 6:
          if (box.xCommunicate) {
            if (box.pk.type === 7) {
              if (box.port !== null && box.port !== 0) {
                box.state = 'comm';
              } else {
                box.state = 'noconf';
              }
            } else {
              box.state = 'comm';
            }
          } else if (box.active && !box.xCommunicate) {
            box.state = 'error';
          } else {
            box.state = 'nocomm';
          }
          // box.enabled = true;
          break;
        case 8:
          if (agrobee) {
            setLinkBoxState(box);
            box.enabled = true;
          }
          break;
        case 9:
          if (vm.currentUnit.type !== UNITS.A_4500) {
            if (agrobeel) {
              box.enabled = true;
              setLinkBoxState(box);
            }
            break;
          } else {
            if (agrobeel1 && box.pk.id === 1) {
              box.enabled = true;
              setLinkBoxState(box);
            } else if (agrobeel2 && box.pk.id === 2) {
              box.enabled = true;
              setLinkBoxState(box);
            }
          }
          break;
        case 10:
          if (sdi12) {
            box.enabled = true;
            box.state = 'comm';
          }
          break;
      }
    }

    function setAR433AM120State(linkBox) {
      switch (linkBox.xcomunica) {
        case 0:
          linkBox.state = 'noconf';
          break;
        case 1:
          linkBox.state = 'nocomm';
          break;
        case 2:
          linkBox.state = 'comm';
          break;
        case 3:
          linkBox.state = 'error';
          break;
        default:
          linkBox.state = 'noconf';
      }
    }

    function setLinkBoxState(linkBox) {
      switch (linkBox.xState) {
        case 0:
          vm.linkBox.state = 'noconf';
          break;
        case 1:
          vm.linkBox.state = 'nocomm';
          break;
        case 2:
          vm.linkBox.state = 'comm';
          break;
        case 3:
          vm.linkBox.state = 'error';
          break;
        default:
          vm.linkBox.state = 'noconf';
      }
    }

    function getModuleError(box) {
      return box.xMarker & (0x01 | 0x02);
    }

    function parseLastComm(value) {
      var limit = 16;
      if (vm.linkBox.pk.type === 2) {
        limit = 4;
      }
      var s = [];
      for (var j = 0; j < limit; j++) s.push((value & (1 << j)) >> j);
      if (vm.linkBox.pk.type === 8 || vm.linkBox.pk.type === 9) {
        return _.reverse(s);
      } else {
        return s;
      }
    }

    function parseLastCommDigital(value, model) {
      var limit = 0;
      switch (model) {
        case 1:
        case 6:
        case 13:
          limit = 2;
          break;
        case 2:
          limit = 8;
          break;
        case 7:
          limit = 9;
          break;
        case 8:
          limit = 6;
          break;
      }
      var s = [];
      for (var j = 0; j < limit; j++) s.push((value & (1 << j)) >> j);
      return s;
    }

    $scope.$on('refresh', function (event, args) {
      if (!vm.selectActive) {
        loadExternalModules();
      }
    });

    $scope.$on('saveEM', (e, args) => {
      saveActive();
    });

    $scope.$on('cancelEM', (e, args) => {
      vm.selectActive = !vm.selectActive;
    });

    $scope.$watch(
      () => vm.selectActive,
      () => {
        $scope.$emit('emActive', vm.selectActive);
      }
    );
  }
})();
