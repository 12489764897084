(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('malfunctionsController', malfunctionsController);

  malfunctionsController.$inject = ['$rootScope', '$scope', '$state', 'manualFactory', '$confirm', 'sensorsFactory', '$filter'];

  function malfunctionsController(
    $rootScope,
    $scope,
    $state,
    manualFactory,
    $confirm,
    sensorsFactory,
    $filter
  ) {
    var vm = this;
    vm.devicesArray;
    vm.endMalfunction;
    vm.units = $state.params.units || $rootScope.devicesArray;
    vm.images;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if (vm.units !== null) {
        vm.devicesArray = _.filter(vm.units, (unit) => {
          return unit.device.malfunctions && unit.device.malfunctions.length > 0;
        });
        vm.endMalfunction = endMalfunction;
        vm.endMalfunction25 = endMalfunction25;
        vm.endMalfunctionBIT = endMalfunctionBIT;
        vm.endMalfunction7 = endMalfunction7;
        vm.endMalfunction55 = endMalfunction55;
        vm.endMalfunction45 = endMalfunction45;
        vm.images = manualFactory.getManualImages();
      }else{
        $state.go('units');
      }
    }

    function endMalfunction(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'generalMalfunction':
          action = 12;
          break;
        case 'flowMalfunction':
          action = 13;
          break;
        case 'counterMalfunction':
          action = 14;
          break;
        case 'ferlitzerMalfunction':
          action = 15;
          break;
        case 'filterMalfunction':
          action = 19;
          break;
        case 'phMalfunction':
          action = 16;
          break;
        case 'ceMalfunction':
          action = 17;
          break;
        case 'definitiveStopMalfunction':
          action = 18;
          parameters = 1;
          break;
        case 'systemStopMalfunction':
          action = 1;
          break;
        case '2lMalfunction':
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-accion'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }
function endMalfunction45(alert,unit){
        var action = 25;
        var parameters = 0;

        $confirm({text: $filter('translate')('manual.send-accion'), title: $filter('translate')('manual.manual-agronic'), ok: $filter('translate')('manual.si'), cancel: $filter('translate')('manual.no')})
            .then(function(res) {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(unit, action, parameters).then( (response) => {
               //TODO
            });
            modalInstance.result.then( (result) => {
                $scope.$emit('reload',{message:'reload'});
            });
        });
    }

    function endMalfunction25(alert,unit){
        var action;
        var parameters = null;
        switch(alert[unit.name]){

            case 'filterMalfunction':
                action = 23;
                break;
            case 'systemStopMalfunction':
                action = 7;
                break;
            default:
                parameters = alert.input;
                action = 16;
                break;
        }
        $confirm({text: $filter('translate')('manual.send-accion'), title: $filter('translate')('manual.manual-agronic'), ok: $filter('translate')('manual.si'), cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunctionBIT(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'definitiveStopMalfunction':
          action = 15; //El 22 no sutiltiza
          break;
        default:
          parameters = alert.input; //FINAL PAROS I AVERIES
          action = 15;
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-accion'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunction7(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'generalMalfunction':
          action = 14;
          break;
        case 'flowMalfunction':
          action = 17;
          break;
        case 'counterMalfunction':
          action = 14;
          break;
        case 'ferlitzerMalfunction':
          action = 15;
          break;
        case 'filterMalfunction':
          action = 11;
          break;
        case 'phMalfunction':
          action = 16;
          break;
        case 'ceMalfunction':
          action = 15;
          break;
        case 'definitiveStopMalfunction':
          action = 18;
          parameters = 1;
          break;
        case 'systemStopMalfunction':
          action = 1;
          break;
        case '2lMalfunction':
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-accion'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunction55(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'filterMalfunction':
          action = 23;
          break;
        case 'systemStopMalfunction':
          //action = 7;
          break;
        default:
          parameters = alert.input;
          action = 16;
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-accion'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function checkStopEntrances() {
      _.forEach(vm.devicesArray, (obj) => {
        if (obj.device.malfunctions) {
          var alerts = obj.device.malfunctions;
          _.forEach(alerts, (item, k) => {
            if (item[obj.device.name] === 'definitiveStopMalfunction') {
              extractStopEntrance(obj.device, k);
            }
          });
        }
      });
    }

    function extractStopEntrance(device, k) {
      sensorsFactory.digitalsactive(device.id).then(function (data) {
        var sensors = data.plain();
        var stops = _.filter(sensors, (obj) => {
          return (obj.id === '6' || obj.id === '7' || obj.id === '8' || obj.id === '9') && obj.xState !== 0;
        });
        if (stops.length === 0) {
          device.malfunctions.splice(k, 1);
        } else if (stops.length === 1) {
          device.malfunctions[k].input = Number(stops[0].id) - 5;
        } else {
          device.malfunctions[k].input = Number(stops[0].id) - 5;
          stops.splice(0, 1);
          _.forEach(stops, (item) => {
            var cloned = _.clone(device.malfunctions[k]);
            cloned.input = Number(item.id) - 5;
            device.malfunctions.push(cloned);
          });
        }
      });
    }

    $scope.$on('refresh', function (event, args) {
      if (args.units) {
        vm.devicesArray = [];
        vm.devicesArray = _.filter(args.units, (unit) => {
          return unit.device.malfunctions && unit.device.malfunctions.length > 0;
        });
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });
  }
})();
