(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('others45Controller', others45Controller)

    others45Controller.$inject = ['$state','$scope','$confirm','$filter'];

    function others45Controller($state,$scope,$confirm,$filter) {

        var vm = this;
        vm.activeList;
        vm.getType;
        vm.save;
        vm.cancel;

        activate();

        function activate() {
            vm.currentState = $state;
            if ($state.params.unit !== null) {
                vm.currentUnit = $state.params.unit;
                $state.go('a45others.others',{unit: vm.currentUnit});  
                vm.changeState = changeState;              
            } else {
                $state.go('units');
            }

        }

        function changeState(state){           
            if(vm.form && vm.form.$dirty){
                $confirm({text: $filter('translate')('programs.edit.cancelq'), title: $filter('translate')('programs.prog2')}).then( () => {
                    $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
                    vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
                    $state.go('a45others.'+state,{unit: vm.currentUnit});
                })
            }else{
                $state.go('a45others.'+state,{unit: vm.currentUnit});
            }
        }
        

        /**
         * Event listener per gestionar l'estat del formulari
         */
        $scope.$on('formUpdated', (e,args) => {
            vm.form = args;
        });

        
    }
})();