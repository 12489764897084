import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsSectorsController', totalsSectorsController);

  totalsSectorsController.$inject = [
    '$scope',
    '$state',
    '$confirm',
    'manualFactory',
    'unitFactory',
    'NgTableParams',
    '$filter',
  ];

  function totalsSectorsController($scope, $state, $confirm, manualFactory, unitFactory, NgTableParams, $filter) {
    var vm = this;
    vm.activeList;
    vm.getTotalVolume;
    vm.parsefert;

    activate();

    function activate() {
      vm.UNITS = UNITS;

      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.totalNumber = 0;

        vm.getTotalVolume = getTotalVolume;
        vm.getTotalFert = getTotalFert;
        vm.parsefert = parsefert;
        vm.parseFert25 = parseFert25;
        vm.deleteTotals = deleteTotals;
        initGrid();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 99,
        },
        {
          counts: [],
          getData: function (params) {
            return unitFactory.totals(vm.currentUnit.id).then((data) => {
              vm.tableParams.total(data.length);
              _.forEach(data, (d) => {
                d.realFlow = (d.irrigationVolume / 1000 / (d.irrigation / 3600)).toFixed(2);
              });
              return data;
            });
          },
        }
      );
    }
    function deleteTotals() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 4:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          initGrid();
        });
      });
    }

    function getTotalVolume() {
      return _.sumBy(vm.tableParams.data, (obj) => {
        return obj.irrigationVolume;
      });
    }

    function parseFert25(input) {
      return input === 0 || input === null ? '-' : (input / 100).toFixed(2) + ' l';
    }

    function getTotalFert(input) {
      let total = _.sumBy(vm.tableParams.data, (obj) => {
        return obj[input];
      });
      return parseFert25(total);
    }

    function parsefert(input, first) {
      if (vm.currentUnit.flow) {
        switch (vm.currentUnit.flow.fertilizationUnit) {
          case 0:
          case 1:
            return $filter('parsetime')(input);
            break;
          case 4:
          case 8:
          case 32:
          case 128:
            if (first) {
              input = Number(input) / 10;
            }
            input = Number(input) / 10;
            return input === 0 ? '-' : input.toFixed(1) + ' l';
            break;
          default:
            return '-';
            break;
        }
      } else {
        return '-';
      }
    }

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        initGrid();
      }
    });
  }
})();
