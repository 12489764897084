import moment from 'moment/moment';
import subprogram_modal from './subprogram_modal.html';
import freqDays from './freq_days.html';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('programsDetailController', programsDetailController);

  programsDetailController.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'unitFactory',
    'UserData',
    'registerFactory',
    'resFactory',
    'NgTableParams',
    'manualFactory',
    '$confirm',
    '$rootScope',
    '$uibModal',
  ];

  function programsDetailController(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    unitFactory,
    UserData,
    registerFactory,
    resFactory,
    NgTableParams,
    manualFactory,
    $confirm,
    $rootScope,
    $uibModal
  ) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;

    activate();

    function activate() {
      vm.viewDate = moment().format();

      vm.currentState = $state.includes;

      if ($state.params.program !== null) {
        vm.program = $state.params.program;

        vm.program = progFactory.formatProgramView(vm.program);

        vm.activeSubList = progFactory.filterSubPrograms(vm.program);
        vm.getSubProgramStartTime = getSubProgramStartTime;
        vm.getSubprogramStartTimeNoIrrig = getSubStartTimeNoIrrig;
        vm.getTimeFromMins = progFactory.getTimeFromMins;
        vm.parseTimeFromMin = getTimeFromMin;
        vm.toDate = toDate;
        vm.toHour = toHour;
        vm.formatDate = formatDate;
        vm.selectedOp = '1';
        vm.manualStart = manualStart;
        vm.manualStop = manualStop;
        vm.manualOutOf = manualOutOf;
        vm.manualRunOf = manualRunOf;
        vm.manualFreqDays = manualFreqDays;
        loadRegisterData();

        vm.conditionersActive = vm.program.conditioners.some((conditioner) => conditioner.type > 0);

        vm.currentUnit = $state.params.unit;
        //    resFactory.cond(vm.currentUnit.type).then(function(response){
        //        vm.conditionerList = response;
        //    })

        vm.conditionerList = resFactory.cond(vm.currentUnit.type);
        vm.formatFert = formatFert;
        vm.proportional = vm.currentUnit.fertilizer.fertType === 1 && vm.currentUnit.fertilizer.prop; //Paralel prop
        vm.goToSector = goToSector;

        vm.viewChangeClicked = viewChangeClicked;

        /*vm.events = progFactory.getEventTable(vm.program,moment().format("MM-YYYY"));*/
      } else {
        $state.go('units');
      }
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function goToSector(id) {
      $state.go('sectors', { unit: vm.currentUnit, load: id });
    }

    function convertToMinutes(timeUnits, timeFormat) {
      switch (timeFormat) {
        case 0:
          return timeUnits;
        case 1:
          return Math.round(timeUnits / 60);
        default:
          return 0;
      }
    }

    function getAccomulatedTime(currentSubProgramId, index) {
      var currentTimeFormat;
      var accomulatedTime = 0;
      for (var i = currentSubProgramId + 1; i < index; i++) {
        currentTimeFormat = vm.activeSubList[i].unit;
        accomulatedTime += convertToMinutes(vm.activeSubList[i].value, currentTimeFormat);
      }
      return accomulatedTime;
    }

    function getSubProgramStartTime(index) {
      var currentSubProgramId = vm.program.xSubprogramCourse;
      var currentSubProgramTimeLeft = convertToMinutes(vm.program.xValue, vm.activeSubList[currentSubProgramId].unit);

      var accomulatedTime = getAccomulatedTime(currentSubProgramId, index);

      var currentTime = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm');
      var timeParts = currentTime.split(':');
      var hoursToMin = (parseInt(timeParts[0]) * 60) | 0;

      var timeInMinutes = (hoursToMin + parseInt(timeParts[1]) + accomulatedTime + currentSubProgramTimeLeft) % 1440;
      var irrigationHour = progFactory.getTimeFromMins(timeInMinutes);
      return irrigationHour.format('HH:mm');
    }

    function getSubStartTimeNoIrrig(index) {
      if (index === 0 || vm.program.sequential) {
        return vm.program.irrigationHour;
      } else {
        var total = 0;
        var parts = vm.program.irrigationHour.split(':');
        for (var i = 0; i < index; i++) {
          total = total + vm.activeSubList[i].totalTime;
        }
        var hoursToMin = (parseInt(parts[0]) * 60) | 0;
        var temp = hoursToMin + parseInt(parts[1]) + total;
        if (temp > 1440) {
          temp = temp - 1440;
        }
        var irrigationHour = progFactory.getTimeFromMins(temp);
        return irrigationHour.format('HH:mm');
      }
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }
    function viewChangeClicked(nextView) {
      if (nextView === 'year' || nextView === 'day' || nextView === 'week') {
        return false;
      }
    }

    function successRegister(data) {
      var register = data.plain();

      if (register.content !== undefined && register.content.length > 0) {
        registerFactory.parseRegister('2', register.content);

        var response = register.content;

        vm.tableParams.total(register.totalElements);

        return response;
      }
    }

    function loadRegisterData() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 15,
          filter: { type: '' },
        },
        {
          counts: [],
          getData: function (params) {
            var pageNumber = 1;
            if (params._params) {
              pageNumber = params._params.page;
            }

            if (params._params.filter.type !== '') {
              var type = params._params.filter.type;
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, type, pageNumber)
                .then(successRegister);
            } else {
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, null, pageNumber)
                .then(successRegister);
            }
          },
        }
      );
    }

    function formatDate(date) {
      return moment(date).format('MMMM-YYYY');
    }

    function formatFert(value) {
      var unity = vm.program.subprograms[vm.program.xSubprogramCourse].fertUnit;
      switch (unity) {
        case 0:
          return getTimeFromMin(value);
          break;
        case 1:
          return getTimeFromMin(value * 60); // Seconds to min.
          break;
        case 4:
          return value + ' l';
          break;
        case 8:
          return value + ' cl';
          break;
        case 32:
          return value + ' l/Ha';
          break;
        case 128:
          return value + ' dl';
          break;
      }
    }

    function manualStart() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: subprogram_modal,
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.start(vm.currentUnit, vm.program.pk.id, selectedItem).then((response) => {});

          modalInstance.result.then((result) => {
            //                $state.go('programs',{unit:vm.currentUnit},{reload:true});
            //                $scope.$emit('reload',{message:'reload'});
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    function manualStop() {
      $confirm({text: $filter('translate')('manual.send-manual'), title: $filter('translate')('manual.manual-agronic'), ok: $filter('translate')('manual.si'), cancel: $filter('translate')('manual.no')}).then(
        function () {
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.stop(vm.currentUnit, vm.program.pk.id).then((response) => {});
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        }
      );
    }

    function manualOutOf() {
      $confirm({ text: $filter('translate')('manual.send-manual'), title: $filter('translate')('manual.manual-agronic'), ok: $filter('translate')('manual.si'), cancel: $filter('translate')('manual.no' )}).then(
        function () {
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.outOfService(vm.currentUnit, vm.program.pk.id).then((response) => {});
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        }
      );
    }

    function manualRunOf() {
      $confirm({ text: $filter('translate')('manual.send-manual'), title: $filter('translate')('manual.manual-agronic'), ok: $filter('translate')('manual.si'), cancel: $filter('translate')('manual.no' )}).then(
        function () {
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.runOfService(vm.currentUnit, vm.program.pk.id).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        }
      );
    }

    function manualFreqDays() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: freqDays,
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 21;
          params.parameter1 = vm.program.pk.id;
          params.parameter2 = selectedItem;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(vm.currentUnit.id, params).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      }
    );

    $scope.$on('lang-update', function () {
      loadRegisterData();
    });
  }
})();
