(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('AuthenticationService', AuthenticationService);

  AuthenticationService.$inject = ['$http', '$rootScope', '$q'];
  // AuthenticationService.$inject = ['$http','$rootScope','$q'];

  function AuthenticationService($http, $rootScope, $q) {
    // function AuthenticationService($http,$rootScope,$q) {
    var service = {
      authenticate: authenticate,
    };

    return service;

    ////////////

    function authenticate(credentials, callback) {
      var user;
      var url = `${process.env.API_IRRIGATION_CONTROL_ENDPOINT}v1/users/auth`;
      var deferred = $q.defer();
      // if(user === undefined){
      $http.get(url).then(
        function (response) {
          $rootScope.user = response.data;
          $rootScope.user.authenticated = true;
          /*$rootScope.user.role = response.data.role;
                  $rootScope.user.id = response.data.id;
                  $rootScope.user.name = response.data.name; */
          user = $rootScope.user;
          deferred.resolve(user);
        },
        function () {
          $rootScope.authenticated = false;
          callback && callback();
        }
      );
      /*             }else{
                deferred.resolve(user);
            } */
      return deferred.promise;
    }
  }
})();
