import moment from 'moment/moment'; 

(function() {
'use strict';


angular.module('agronicwebApp')

.constant('moment', moment)

.controller('programsConfigControllerA45',programsConfigControllerA45)

programsConfigControllerA45.$inject = ['$scope','$state','progFactory','$rootScope','unitFactory', 'veggaSelectFactory','$filter'];

function programsConfigControllerA45($scope, $state, progFactory, $rootScope,unitFactory, veggaSelectFactory, $filter) {
    
    var vm = this;
    vm.activeList;
    vm.formats=[];
    vm.conditioners;
    vm.formProgram;
    vm.groupSectors;

    activate();

    function activate(){
        vm.currentState = $state.includes;    
        
        if($state.params.program !== null){            
            vm.program = $state.params.program;
            vm.updateFormat = updateFormat;
            vm.formatedTime = getTimeFromMins(vm.program.securityTime); 
            vm.currentUnit = $scope.$parent.vm.currentUnit; 
            vm.setFertUnits = setFertUnits;   
            vm.cancel = cancel_edition; 
            vm.save = save;
            vm.changeNav = changeNav;

            vm.selectedNav = 'params';

            vm.programType = {
                0:$filter('translate')('programs.config.subprograms'),
                1:$filter('translate')('pivot.lineal')
            };

            vm.initType = {
                0:$filter('translate')('programs.edit.hour'),
                1:$filter('translate')('programs.edit.seq')
            };

            vm.daysFrequency = {
                0:$filter('translate')('programs.config.weekfreq'),
                1:$filter('translate')('pivot.freq'),
                2:$filter('translate')('programs.calendar')
            };

            vm.irrigUnits = {
                0:"hh:mm",
                1:"m3",
                2:"m3/Ha",
                3:"mm:ss",
                4:"m3/Ha(t)",
                5:"mm"
            }; 

            vm.fertType = {
                0:$filter('translate')('calcs.units'),
                1:$filter('translate')('fert.uni'),
                2:$filter('translate')('fert.prop')  + " l/m3",
                3:$filter('translate')('fert.prop')  + " cl/l",
            };
            
            if(vm.currentUnit.inoptions.optionHidro){
                vm.fertType[4] = $filter('translate')('general.regce');
                vm.fertType[5] = $filter('translate')('programs.config.inputce');
            }

            vm.fertUnits = {
                0:"hh:mm",
                1:"mm:ss",
                2:"L",
                3:"L/Ha"
            };

            vm.proportionalFertType = {
                0:$filter('translate')('programs.config.meterpulse'),
                1:$filter('translate')('general.prevflow')
            };

            vm.endActiveSchedule = {
                0:$filter('translate')('cond.whatstop'),
                1:$filter('translate')('programs.config.nonstop'),
                2:$filter('translate')('programs.config.postpone')
            };

            if(vm.currentUnit.inoptions.optionPlus){
                vm.groupSectors = 20;
            }else{
                vm.groupSectors = 12;
            }

            loadConditioners();
               if(!vm.program.backup){
                   backup();
               }else{

               }
            vm.priorValues = _.range(0,100);
            vm.priorValuesDTO = veggaSelectFactory.formatterVeggaSelect(vm.priorValues);
        }else{
            $state.go('units');
        }
    }

    function changeNav(e){
        vm.selectedNav = e;
    }

    function loadConditioners() {
        vm.loading = true;
        unitFactory.conditioners(vm.currentUnit.id).then(data =>{
            vm.loading = false;
            vm.conditioners = data.plain();

            vm.conditioners = _.filter(vm.conditioners, cond => { 
                return (cond.function > 0 && cond.allPrograms == false);
            });
        });
    }
    function updateFormat(id,index,model1,model2){
        model1 = 0;
        model2 = 0;
        if(id !== null && id > 0){
           id = parseInt(id);
           if(id === 41){
                vm.program.conditioners[index].value2 = 0;
                vm.formats[index] = {
                  "units":"L/m2",
                };
           }else{
               vm.formats[index] = vm.currentUnit.formats[ (vm.currentUnit.sanalog[id - 1].formatId) - 1];
            
               if(vm.program.conditioners[index].type === 3 
                  || vm.program.conditioners[index].type === 7 
                  || vm.program.conditioners[index].type === 8 
                  || vm.program.conditioners[index].type === 9){
                   
                    if(vm.currentUnit.sanalog[id - 1].formatId === 1){
                        vm.formats[index].units = "ºCh";
                    }else if(vm.currentUnit.sanalog[id - 1].formatId === 2){
                        vm.formats[index].units = "Whm2";
                    }
               }     
           }            
        }else{
             vm.formats[index] = null;
        }
    }
    
    function setFertUnits(){
        if(vm.program.fertType == 2 || vm.program.fertType == 3){
            vm.program.fertUnits = 2;
        }
    }

    function getTimeFromMins(mins) {
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment(new Date()).hours(h).minutes(m)
    }
    function backup(){
        vm.program_backup = {};  
        angular.copy(vm.program, vm.program_backup);         
        vm.program.backup = true;
    }
    
    function cancel_edition(){
        angular.copy( vm.program_backup, vm.program);
    }
    
        
    function save(_ev){
        vm.formProgram.$setPristine();
        vm.program.progtype = vm.currentUnit.type;
        progFactory.update(vm.program).then(response => {
            $rootScope.$broadcast('updateEdit45', { message: 'refreshing' });            
        });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(()=> vm.formProgram, () => {
        $scope.$emit('formUpdated', vm.formProgram);
    });
    
    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
        save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
        //Restaurem estat del formulari a la versió previa.
        cancel_edition();
    });
}
 
    
})();