import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('waterGeneralController', waterGeneralController);

  waterGeneralController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'sensorsFactory'];

  function waterGeneralController($rootScope, $scope, $state, configFactory, sensorsFactory) {
    var vm = this;
    vm.UNITS = UNITS;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.inputsCombo = _.range(0, 7);

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.currentHeader = $state.params.headerId || 1;
        }
        loadWaterMixing();
        vm.selected = 0;
      } else {
        $state.go('units');
      }
    }

    function loadWaterMixing() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        configFactory.getHeaderWaterMix(vm.currentUnit.id, vm.currentHeader).then(function (data) {
          vm.waterMixing = data.plain();
          vm.waterMixing.pauseTimeVM2 = +vm.waterMixing.pauseTimeVM2;
          backup = angular.copy(vm.waterMixing);
        });
        loadAnalogSensors();
      } else {
        configFactory.getWaterMix(vm.currentUnit.id).then((response) => {
          vm.currentUnit.waterMixing = response.plain();
          vm.waterMixing.pauseTimeVM2 = +vm.waterMixing.pauseTimeVM2;
          vm.waterMixing = vm.currentUnit.waterMixing;

          if (vm.waterMixing.positionBy !== undefined) {
            loadAnalogSensors();
          }
        });
      }
    }

    function loadAnalogSensors() {
      var params = { page: 1, limit: 40 };
      sensorsFactory.analogsPage(vm.currentUnit.id, params).then((result) => {
        vm.analogs = result.content;
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        configFactory.saveHeaderWaterMix(vm.currentUnit.id, vm.currentHeader, vm.waterMixing).then((result) => {
          vm.form.$setPristine();
          vm.waterMixing = result.plain();
          backup = angular.copy(vm.waterMixing);
        });
      } else {
        vm.form.$setPristine();
        vm.waterMixing.unittype = vm.currentUnit.type;
        configFactory.updateWaterMix(vm.currentUnit.id, vm.waterMixing).then((result) => {
          vm.waterMixing = result.plain();
          vm.currentUnit.waterMixing[0] = vm.waterMixing;
        });
      }
    }

    function cancel_edition() {
      vm.waterMixing = angular.copy(backup);
      vm.form.$setPristine();
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
