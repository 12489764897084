import { UNITS } from "../../../utils/units.enum";

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('r433Controller', r433Controller);

  r433Controller.$inject = ['$scope', '$state', 'commFactory', 'Module'];

  function r433Controller($scope, $state, commFactory, Module) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.attemptsAR16;
    vm.netCodeAR433;
    vm.suspendedAR16;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.UNITS = UNITS;

                if(vm.currentUnit.type !== UNITS.A_4500){
                    vm.module = Module;
                    vm.attemptsAR16 = angular.copy(vm.module.attemptsAR16);
                    vm.netCodeAR433 = angular.copy(vm.module.netCodeAR433);
                    vm.suspendedAR16 = angular.copy(vm.module.suspendedAR16);
                }

        loadLinkBox();

        vm.netCodes = _.range(0, 100);
        vm.chanels = _.range(1, 99);
        /*vm.chanels = {
                    0:'1',
                    1:'2',
                    2:'3',
                    3:'4',
                    4:'5',
                    5:'6'
                };*/
        vm.modBusList = {
          0: '0',
          1: '001',
          2: '002',
          3: '003',
          4: '004',
          5: '005',
          6: '006',
          7: '007',
          100: '100',
          101: '101',
          102: '102',
          103: '103',
          104: '104',
          105: '105',
          106: '106',
          107: '107',
        };
        vm.protocolList = {
          0: '0 = 8"',
          1: '1 = 2"',
          2: '2 = 3"',
          3: '3 = 7"',
          4: '4 = 4"',
          5: '5 = 5"',
          6: '6 = 6"',
        };
        vm.save = save;
        vm.cancel = cancel_edition;
      } else {
        $state.go('units');
      }
    }

    function loadLinkBox() {
      commFactory.linkBox(vm.currentUnit.id, 4).then((result) => {
        if(vm.currentUnit.type !== UNITS.A_4500){
                vm.linkBox = result[0];
            }else{
                vm.linkBoxes = result.plain();
            }

            backup = angular.copy(vm.linkBox);
        });
    }

    function save() {
      if(vm.currentUnit.type === vm.UNITS.A_4500){
        saveA4500();
      }else{
      vm.linkBox.unittype = vm.currentUnit.type;
      vm.loading = true;
      vm.module.linkBoxes = [vm.linkBox];
      vm.module.attemptsAR16 = angular.copy(vm.attemptsAR16);
      vm.module.netCodeAR433 = angular.copy(vm.netCodeAR433);
      vm.module.suspendedAR16 = angular.copy(vm.suspendedAR16);

      commFactory.updateModule(vm.currentUnit.id, vm.module).then(
        (result) => {
          vm.module = result;
          vm.linkBox = vm.module.linkBoxes[0];
          backup = angular.copy(vm.linkBox);
          vm.loading = false;
          vm.form.$dirty = false;
        }
      );
    }
    }
    function saveA4500(){
      for(let linkBox of vm.linkBoxes){
        linkBox.unittype = vm.currentUnit.type;
        commFactory.updateLinkBox(vm.currentUnit.id, linkBox).then(result => {
          vm.form.$dirty = false;
        });
      }
    }

    function cancel_edition() {
      vm.linkBox = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('refresh', function (event, args) {});
  }
})();
