import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('nebulizationConfigController', nebulizationConfigController);

  nebulizationConfigController.$inject = ['$scope', '$state', 'sensorsFactory', 'unitFactory', 'nebulizationFactory'];

  function nebulizationConfigController($scope, $state, sensorsFactory, unitFactory, nebulizationFactory) {
    var vm = this;
    vm.configNebulization;
    vm.activeList;
    vm.form;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.changeSensor = changeSensor;
      if ($state.params.unit !== null) {
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.configNebulization = $state.params.nebulization;
        console.log(vm.configNebulization);
        loadSensors();
        loadConditioners();
        backup();
      } else {
        $state.go('units');
      }
    }

    function changeSensor(s_id) {
      if (vm.configNebulization.sensors[s_id].sensor === 0) {
        vm.configNebulization.dpvControl = 0;
      }
    }

    function loadSensors() {
      sensorsFactory.analogsPage(vm.currentUnit.id, { page: 1, limit: 120 }).then(function (data) {
        if (data.content) {
          vm.sensorsList = data.content;
        }
      });
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.conditioners = data.plain();
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    function backup() {
      vm.nebulization_backup = {};
      angular.copy(vm.configNebulization, vm.nebulization_backup);
    }

    function cancel_edition() {
      angular.copy(vm.nebulization_backup, vm.configNebulization);
    }

    function save(_ev) {
      vm.form.$setPristine();
      console.log(vm.configNebulization);
      nebulizationFactory.update45(vm.currentUnit.id, vm.configNebulization).then(() => {});
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
