import { UNITS } from '../../utils/units.enum';
import moment from 'moment/moment';

import Highcharts from 'highcharts/highcharts';
// import 'highcharts/modules/exporting';
// import 'highcharts/modules/export-data';
// import '../../../resources/js/highcharts-offline-exporting'

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('sectorLayerController', sectorLayerController);

  sectorLayerController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'UserData',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'manualFactory',
    '$confirm',
    'calculatedsFactory',
    'graphicsFactory',
    '$timeout',
    '$translate',
  ];

  function sectorLayerController(
    $log,
    $rootScope,
    $scope,
    UserData,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    manualFactory,
    $confirm,
    calculatedsFactory,
    graphicsFactory,
    $timeout,
    $translate
  ) {
    var vm = this;
    var mapId;
    var layerId;
    var layerLoaded;
    var params;
    let waterArray;
    var myChart;
    var myChartW;
    var cropTexts;
    let grid_options;
    var dataSeries = [];
    var vis;
    vm.UNITS = UNITS;
    activate();

    /*******************/

    function activate() {
      if (!$state.params.sector) {
        $state.go('^');
        return;
      }

      layerLoaded = false;
      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.editSect = false;
      vm.areaInfo = false;

      vm.deleteSectorArea = deleteSectorArea;
      vm.closeAreaInfo = closeAreaInfo;
      vm.update = update;
      vm.loadSectors = loadSectors;
      vm.prepareEdition = prepareEdition;
      vm.stop = stop;
      vm.auto = auto;
      vm.start = start;
      vm.prepareCanvasWaterHistory = prepareCanvasWaterHistory;
      vm.getValueInUnit = getValueInUnit;
      vm.prepareHistoryBar = prepareHistoryBar;
      vm.number = number;
      vm.editArea = editArea;
      vm.changeDatesGraphics = changeDatesGraphics;
      vm.filterByMapElements = filterByMapElements;
      waterArray = {};
      vm.filter = '0';
      vm.profesional = UserData.profesional;

      if ($state.params.unit) {
        vm.currentUnit = $state.params.unit;
        vm.sector = $state.params.sector;
        vm.shared = $state.params.map.shared;
        vm.sharingLvl = $state.params.map.sharingLvl;
        vm.comboUnits = $state.params.units;
        mapId = $state.params.map.id;
        layerId = $state.params.layerId;
        loadSectorData();
        if (vm.sector.crop != null && vm.sector.crop != 0) {
          loadCropText($translate.use());
        }
      }
    }

    async function loadCropText(lang) {
      if (cropTexts === undefined) {
        await sectorFactory.cropsText(vm.sector.crop).then((resp) => {
          cropTexts = resp.plain();
        });
      }
      if (vm.sector.crop != undefined) {
        switch (lang) {
          case 'es':
            vm.sector.cropLang = cropTexts[0]['name'];
            break;
          case 'it':
            vm.sector.cropLang = cropTexts[3]['name'];
            break;

          case 'en':
            vm.sector.cropLang = cropTexts[1]['name'];
            break;
          case 'fr':
            vm.sector.cropLang = cropTexts[2]['name'];
            break;
          case 'pt':
            vm.sector.cropLang = cropTexts[4]['name'];
            break;
          default:
            vm.sector.cropLang = cropTexts[0]['name'];
        }
      }
    }

    function prepareEdition() {
      vm.selectedUnit = vm.currentUnit.id;
      vm.selectedSector = vm.sector.pk.id;
      loadSectors(vm.selectedUnit);
    }

    function editArea() {
      $scope.$emit('editAreaFromSectorLayer');
    }
    function closeAreaInfo() {
      $rootScope.$broadcast('closeSector');
    }

    function deleteSectorArea() {
      $rootScope.$broadcast('deleteSectorArea');
    }

    function number(n) {
      return Number(n);
    }

    function loadSectorData() {
      vm.areaInfo = true;
      vm.actionView = 0;
      checkSectorStatus(vm.sector);
      vm.sector.unitName = vm.currentUnit.name;
      vm.selectedSector = vm.sector;
      if (checkIrrigation()) {
        vm.sector.plannedFlowF = formatFlowUnit(vm.sector.plannedFlow);
      }
      sectorFactory.programs(vm.sector.pk.deviceId, vm.sector.pk.id).then((programs) => {
        vm.sector.programInfo = extractProgramData(programs.plain(), vm.sector);
        vm.programs = vm.sector.programInfo.programs;
      });
      if (vm.sector.waterDisp !== null && vm.sector.waterDisp !== undefined && vm.sector.waterDispUser !== null) {
        let k = vm.sector.waterDisp + '_' + vm.sector.waterDispUser;
        if (waterArray[k] !== undefined && waterArray[k] !== null) {
          vm.waterValue = waterArray[k].xValue / 10 + '%';
        } else {
          calculatedsFactory
            .waterDisp(vm.sector.waterDispUser, { page: vm.sector.waterDisp, limit: 1 })
            .then((sensors) => {
              var content = sensors.plain();
              var sensor = _.find(content, (s) => {
                return s.pk.id === vm.sector.waterDisp;
              });
              if (sensor !== undefined) {
                waterArray[k] = sensor;
                vm.waterValue = waterArray[k].xValue / 10 + '%';
                loadWaterHistory(7);
              }
            });
        }
        vm.waterHistory = true;
      }
    }

    function loadIrrigationProgram() {
      switch (vm.currentUnit.type) {
        case 2:
          if (vm.sector.xProgramN != 0 && vm.sector.xSubprogramN != 0) {
            var deviceId = vm.currentUnit.id;
            var progId = vm.sector.xProgramN;
            var params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data;
              progFactory.checkIrrigation(vm.program, vm.currentUnit.type);
            });
          }
          break;
        case 3:
        case 4:
        case 6:
          if (vm.sector.xProgramN != 0) {
            var deviceId = vm.currentUnit.id;
            var progId = vm.sector.xProgramN;
            var params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data;
              progFactory.checkIrrigation(vm.program, vm.currentUnit.type);
              if (vm.currentUnit.type === vm.UNITS.A_5500) {
                var subprogram = _.filter(vm.program.programSector, { sector: Number(vm.sector.id) });
                vm.program.value = subprogram[0] !== undefined ? subprogram[0].value : 0;
                vm.program.xValue = vm.sector.xValue;
              }
              if (vm.currentUnit.type === vm.UNITS.A_2500 && vm.program.unit == 2) {
                var sectorsArray = [];
                if (vm.program.sector1 !== null && vm.program.sector1 !== 0) {
                  sectorsArray.push(vm.program.sector1);
                }
                if (vm.program.sector2 !== null && vm.program.sector2 !== 0) {
                  sectorsArray.push(vm.program.sector2);
                }
                if (vm.program.sector3 !== null && vm.program.sector3 !== 0) {
                  sectorsArray.push(vm.program.sector3);
                }
                if (vm.program.sector4 !== null && vm.program.sector4 !== 0) {
                  sectorsArray.push(vm.program.sector4);
                }

                var params_sector = {
                  sectors: sectorsArray,
                };
                progFactory.getSectorsArea(vm.currentUnit.id, params_sector).then((result) => {
                  vm.sectorsArea = result;
                  if (vm.sectorsArea > 0 && vm.sectorsArea !== null && vm.sectorsArea !== undefined) {
                    vm.programed = (vm.program.value * (vm.sectorsArea / 10000)).toFixed(2);
                  }
                });
              }
            });
          }
          break;
        case 5:
          if (vm.sector.xProgramN != 0 && vm.sector.xProgramN != 0) {
            var deviceId = vm.currentUnit.id;
            var progId = vm.sector.xProgramN;
            var params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data.plain();
              loadSectorsFromSubProgram7();
            });
          }
          break;
      }
    }
    function loadSectorsFromSubProgram7() {
      let sub = _.find(vm.program.subprograms, (s) => {
        let id = Number(vm.sector.pk.id);
        return s.sector === id && s.xState === 1;
      });
      vm.program.value = sub.value;
      vm.program.xValue = sub.xValue;
      vm.plannedFlow = formatFlowUnit(vm.sector.plannedFlow);
    }
    function prepareCanvasWaterHistory(days) {
      vm.loadingWaterhistory = true;
      var ctx = document.getElementById('sectorchart');

      //adding markers annotation to the graphi

      myChartW = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          scales: {
            yAxes: [
              {
                position: 'left',
                id: 'y-axis-1',
                display: true,
                ticks: {
                  max: 140,
                  min: 0,
                  callback: function (value, index, values) {
                    return Number(value) + ' %';
                  },
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                id: 'x-axis-1',
                position: 'bottom',
                type: 'time',
                time: {
                  isoWeekday: true,
                  unit: 'day',
                  tooltipFormat: 'DD/MM HH:mm',
                  displayFormats: {
                    day: 'DD/MM HH:mm',
                    hour: 'DD/MM HH:mm',
                  },
                },
                gridLines: {
                  display: true,
                },

                ticks: {
                  source: 'auto',
                },
              },
            ],
          },
          tooltips: {
            mode: 'nearest',
            intersect: true,
            callbacks: {
              label: function (tooltipItem, data) {
                return tooltipItem.yLabel.toFixed(1) + '%';
              },
            },
          },
          elements: {
            point: {
              pointStyle: 'circle',
              radius: 0,
              hitRadius: 5,
            },
            line: {
              fill: false,
              tension: 0.5,
            },
          },
          annotation: {
            //annotations: {}
          },
        },
      });
      if (vm.sector.waterDisp !== null && vm.sector.waterDisp !== undefined && vm.sector.waterDispUser !== null) {
        let k = vm.sector.waterDisp + '_' + vm.sector.waterDispUser;
        if (waterArray[k] !== undefined && waterArray[k] !== null) {
          vm.waterValue = waterArray[k].xValue / 10 + '%';
          loadWaterHistory(days);
        } else {
          calculatedsFactory
            .waterDisp(vm.sector.waterDispUser, { page: vm.sector.waterDisp, limit: 1 })
            .then((sensors) => {
              var content = sensors.plain();
              var sensor = _.find(content, (s) => {
                return s.pk.id === vm.sector.waterDisp;
              });
              if (sensor !== undefined) {
                waterArray[k] = sensor;
                vm.waterValue = waterArray[k].xValue / 10 + '%';
                loadWaterHistory(days);
              }
            });
        }
        vm.waterHistory = true;
      } else {
        vm.loadingWaterhistory = false;
        vm.historyWaterNodata = true;
      }
    }

    function loadWaterHistory(days) {
      let k = vm.sector.waterDisp + '_' + vm.sector.waterDispUser;
      if (waterArray[k] !== undefined) {
        vm.waterName = waterArray[k].name;
        let cicle = waterArray[k].cicles[0];
        let annotations = [
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 100,
            yMax: 140,
            borderColor: 'rgb(0,187,222,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(0,187,222,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: cicle.recargaPoint,
            yMax: 100,
            borderColor: 'rgb(0,169,159,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(0,169,159,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: cicle.estresPoint,
            yMax: cicle.recargaPoint,
            borderColor: 'rgb(255,146,100,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(255,146,100,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 0,
            yMax: cicle.estresPoint,
            borderColor: 'rgb(255,72,75,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(255,72,75,0.1)',
          },
        ];

        if (cicle.line1 !== null) {
          annotations.push({
            drawTime: 'afterDatasetsDraw',
            id: 'hline',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-1',
            value: cicle.line1,
            borderColor: 'black',
            borderWidth: 2,
            borderDash: [2, 4],
            borderDashOffset: 5,
            label: {
              backgroundColor: '#FFC72C',
              content: 'M1',
              enabled: true,
            },
          });
        }

        if (cicle.line2 !== null) {
          annotations.push({
            drawTime: 'afterDatasetsDraw',
            id: 'hline2',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-1',
            value: cicle.line2,
            borderColor: 'black',
            borderWidth: 2,
            borderDash: [2, 4],
            borderDashOffset: 5,
            label: {
              backgroundColor: '#FFC72C',
              content: 'M2',
              enabled: true,
            },
          });
        }
        myChartW.options.annotation.annotations = annotations;
        let params = { from: '', to: '' };
        let dates;
        params.group = 1;
        if (days == 365) params.group = 2;
        params.to = moment().add(1, 'days').format('DD-MM-YYYY');
        params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');
        calculatedsFactory.waterHistory(vm.sector.waterDispUser, vm.sector.waterDisp, 0, params).then(
          (dates) => {
            let dataset = {
              yAxisID: 'y-axis-1',
              xAxisID: 'x-axis-1',
              label: waterArray[k].name,
              backgroundColor: '#e6e600',
              borderColor: '#e6e600',
              data: [],
              type: 'line',
            };
            vm.loadingWaterhistory = false;
            let data = [];
            if (dates.length > 0) {
              _.forEach(dates, (o) => {
                var values = [];
                values[0] = moment(o[1], 'DD-MM-YYYY HH:mm')._d;
                values[1] = o[0];
                data.push(_.zipObject(['x', 'y'], values));
              });
              dataset.data = data;
              myChartW.data.datasets[0] = dataset;

              myChartW.update();
            } else {
              vm.historyWaterNodata = true;
            }
          },
          (error) => {
            vm.loadingWaterhistory = false;
            vm.historyWaterNodata = true;
          }
        );
      }
    }

    function checkIrrigation() {
      vm.sector.irrigation = false;
      if (vm.sector.xStatus !== null) {
        switch (vm.currentUnit.type) {
          case 2: //A4000
            if (vm.sector.xStatus !== 4 && vm.sector.xStatus !== 0) {
              // 4 = Stop In active (Paro definitiu de sector)
              vm.sector.irrigation = true;
            }
            break;
          case 3: //A2500
          case 4: //BIT
            if (vm.sector.xStatus !== 0 && vm.sector.xStatus !== 2 && vm.sector.xStatus !== 5) {
              vm.sector.irrigation = true;
            }
            break;
          case 5: //A7000
            if (vm.sector.xStatus !== 0) {
              vm.sector.irrigation = true;
            }
            break;
          case 6: //A5500
            if (
              vm.sector.xStatus !== 0 &&
              vm.sector.xStatus !== 3 &&
              vm.sector.xStatus !== 5 &&
              vm.sector.xStatus !== 6
            ) {
              vm.sector.irrigation = true;
            }
            break;
        }
      }
      if (vm.sector.irrigation) {
        loadIrrigationProgram();
      }
      return vm.sector.irrigation;
    }

    function checkSectorStatus(sector) {
      sector.irrigation = false;
      switch (vm.currentUnit.type) {
        case 2:
          if (
            vm.currentUnit.systemStopMalfunction ||
            vm.currentUnit.generalMalfunction ||
            vm.currentUnit.flowMalfunction ||
            vm.currentUnit.counterMalfunction ||
            vm.currentUnit.ferlitzerMalfunction
          ) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (vm.currentUnit.definitiveStopMalfunction) {
            if (sector.xStatus === 4) {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== 4 && sector.xStatus !== 0) {
              sector.irrigation = true;
            }
          }
          break;
        case 3:
        case 4:
          if (vm.currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (vm.currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== null && sector.xStatus !== 0 && sector.xStatus !== 2 && sector.xStatus !== 5) {
              sector.irrigation = true;
            }
          }
          break;
        case 5:
          if (vm.currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (vm.currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== null && sector.xStatus !== 0) {
              sector.irrigation = true;
            }
          }
          break;
        case 6:
          if (vm.currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (vm.currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (
              sector.xStatus !== null &&
              sector.xStatus !== 0 &&
              sector.xStatus !== 3 &&
              sector.xStatus !== 5 &&
              sector.xStatus !== 6
            ) {
              sector.irrigation = true;
            }
          }
          break;
      }
    }
    function formatFlowUnit(unit) {
      var value = String(unit);
      if (value.length === 2) {
        value = '0.' + value;
      } else if (value.length === 1) {
        value = '0.0' + value;
      } else if (value.length > 2) {
        var first = value.slice(0, value.length - 2);
        var last = value.slice(value.length - 2, value.length);
        value = first + '.' + last;
      }
      return Number(value);
    }
    function extractProgramData(programs, sector) {
      var currentProgram = null;
      switch (vm.currentUnit.type) {
        case 2: //A4000
          currentProgram = A4000ProgramData(programs, sector);
          break;
        case 3: //A2500
        case 4: //BIT
          currentProgram = A2500ProgramData(programs, sector);
          break;
      }

      var orderedPrograms = _.map(
        _.orderBy(programs, (o) => {
          return Number(o.pk.id);
        }),
        (o) => {
          return Number(o.pk.id);
        }
      );

      if (currentProgram !== null) {
        currentProgram.start = progFactory.getTimeFromMins(currentProgram.start).format('HH:mm');
      } else {
        currentProgram = {};
      }
      currentProgram.area = sector.area;
      currentProgram.programs = orderedPrograms;
      return currentProgram;
    }

    function A2500ProgramData(programs, sector) {
      var current = null;
      if (sector.xProgramN !== 0) {
        current = _.filter(programs, (o) => {
          return Number(o.pk.id) === sector.xProgramN;
        })[0];
      }
      if (current !== null && current !== undefined) {
        switch (current.xStartMotive) {
          case 0:
            current.substate = 'Inicio por horario';
            break;
          case 1:
            current.substate = 'Inicio secuencial';
            break;
          case 2:
            current.substate = 'Inicio por condicionante';
            break;
          case 3:
            current.substate = 'Inicio programa por manual';
            break;
          default:
            current.substate = 0;
            break;
        }
        current.time = current.value;
        switch (current.unit) {
          case 0:
            current.time = $filter('parsetime')(current.time);
            current.pending = $filter('parsetime')(current.xValue);
            current.done = $filter('parsetime')(current.value - current.xValue);
            break;
          case 3:
            var mins = current.time;
            current.time = $filter('parsetimesec')(current.time);
            current.pending = $filter('parsetimesec')(current.xValue);
            current.done = $filter('parsetimesec')(current.value - current.xValue);
            break;
          case 1:
            current.time = current.time / 1000 + 'm3';
            current.pending = current.xValue / 1000 + 'm3';
            current.done = (current.value - current.xValue) / 1000 + 'm3';
            break;
          case 4:
            current.time = $filter('parsetime')(current.time);
            current.pending = $filter('parsetime')(current.xValue);
            current.done = $filter('parsetime')(current.value - current.xValue);
            break;
        }
      } else if (sector.xManual) {
        current = {};
        current.substate = 'Inicio por manual';
      } else {
        current = {};
        current.substate = 'Inicio por pivot';
      }

      return current;
    }

    function A4000ProgramData(programs, sector, current, time) {
      var current = null;
      if (sector.xProgramN != 0 && sector.xSubprogramN != 0) {
        current = _.filter(programs, (o) => {
          return Number(o.pk.id) === sector.xProgramN;
        })[0];
        if (current !== null) {
          current.subprogram = _.filter(current.subprograms, (o) => {
            return Number(o.pk.id) === sector.xSubprogramN;
          })[0];
        }
      }
      if (current !== null && current.subprogram !== null) {
        current.time = current.subprogram.value;
        switch (current.subprogram.unit) {
          case 0:
            current.time = $filter('parsetime')(current.time * 60);
            break;
          case 1:
            var mins = current.time * 60 * 60;
            current.time = $filter('parsetime')(current.time);
            break;
          case 2:
            current.time = current.time + 'm3';
            break;
          case 4:
            current.time = current.time + 'l';
            break;
          case 16:
            current.time = current.time + 'm3/h';
            break;
        }
        if (current.xStartState & 0x0010) current.substate = 'Inicio por manual'; //resultat =  1;// inici manual
        else if (current.xStartState & 0x0020)
          current.substate = 'Inicio por entrada'; //resultat =  2;// inici per entrada
        else if (current.xStartState & 0x0040) current.substate = 'Inicio por SMS'; //resultat =  3;// inici per SMS
        else if (current.xStartState & 0x0080)
          current.substate = 'Inicio secuencial'; //resultat =  4;// inici per sequencial
        else if (current.xStartState & 0x0100)
          current.substate = 'Inicio por condicionante'; //resultat =  5;// inici per condicionant
        else if (current.xStartState & 0x0200)
          current.substate = 'Inicio por horario'; //resultat =  6;// inici per horari
        else current.substate = 0; //resultat =  17;// inici indefinit
      }
      return current;
    }

    function update() {
      let unit = _.find(vm.comboUnits, (unit) => unit.device.id === vm.selectedUnit).device;
      let sector = _.find(vm.sectors, (sector) => sector.pk.id === vm.selectedSector);

      var props = {};
      props.sectorId = vm.selectedSector;
      props.sectorName = sector.name;
      props.deviceId = vm.selectedUnit;
      props.deviceName = unit.name;
      props.uuid = layerId;
      props.mapId = mapId;
      mapsFactory.updateSector(mapId, props).then(() => {
        $rootScope.$broadcast('reload', { message: 'refreshing' });
        closeAreaInfo();
      });
    }

    function loadSectors(unit) {
      mapsFactory.loadSectors(unit).then((sectors) => {
        vm.sectors = sectors;
      });
    }

    function getValueInUnit(input) {
      if (vm.program !== undefined && vm.sector !== undefined) {
        var unit;
        switch (vm.currentUnit.type) {
          case 2:
            unit = vm.program.subprograms[vm.sector.xSubprogramN - 1].unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input * 60);
                break;
              case 1:
                var mins = input * 60 * 60;
                return $filter('parsetime')(mins);
                break;
              case 2:
                return input + 'm3';
                break;
              case 4:
                return input + 'l';
                break;
              case 16:
                return input + 'm3/h';
                break;
            }
            break;
          case 3:
          case 4:
            unit = vm.program.unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input);
              case 3:
                var mins = input;
                return $filter('parsetimesec')(mins);
              case 1:
                return input / 1000 + 'm3';
              case 4:
                return $filter('parsetime')(input);
              case 2:
                //if(prog == 1) input = vm.programed;
                return (input / 1000).toFixed(2) + 'm3';
            }
            break;
          case 5:
            if (!_.isNumber(input)) {
              input = 0;
            }
            switch (vm.program.units) {
              case 0:
              case 1:
                return $filter('parsetime')(input * 60);
                break;
              case 2:
                return input + 'm3';
                break;
              case 3:
                return input + 'L';
                break;
            }
            break;
          case 6:
            unit = vm.program.unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input);
                break;
              case 3:
                var mins = input;
                return $filter('parsetimesec')(mins);
                break;
              case 1:
                return input / 1000 + 'm3';
                break;
              case 2:
                return input / 1000 + 'm3/Ha';
              case 4:
                return $filter('parsetime')(input);
                break;
            }
            break;
        }
      }
    }

    /* Manual actions */

    function stop() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.sector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 8;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
    function start() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.sector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 9;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
    function auto() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.sector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 10;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function prepareHistoryBar(days) {
      myChart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        title: {
          enabled: false,
          text: '',
        },
        tooltip: {
          shared: true,
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          renderTo: 'sector25chart',
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
        },
        yAxis: {
          id: 'irrig',
          showEmpty: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              return this.value + ' mm';
            },
          },
          lineWidth: 2,
          title: {
            text: '',
          },
          offset: 15,
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color:
                // theme
                (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
            },
          },
        },
        plotOptions: {
          column: {
            pointPlacement: 'between',
            dataLabels: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadCSV', 'downloadXLS'],
            },
          },
        },
      });
      if (days == 365) {
        myChart.yAxis[0].options.stackLabels.enabled = false;
      }
      loadHistory(days);
    }

    function loadHistory(days) {
      let params = { from: '', to: '' };
      var dates;
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      switch (vm.currentUnit.type) {
        case 2:
        case 3:
        case 4:
        case 6:
          vm.loadinghistory = true;
          params.type = vm.currentUnit.type;
          //vm.historyNodata = true;
          sectorFactory.precipitation(vm.currentUnit.id, vm.sector.pk.id, params).then(function (data) {
            dates = data.plain();
            vm.loadinghistory = false;
            addEmptyDays(dates);
            $timeout(() => {
              populateHistoryBar25(dates);
            }, 1000);
          });
          if (vm.sector.effectiveRainUser !== null && vm.sector.effectiveRain !== null) {
            //Load rain
            $timeout(() => {
              loadEffectiveRainHistory(days);
            }, 1000);
          }
          if (vm.sector.idUserEto !== null && vm.sector.idEto !== null && vm.sector.idUserCrop !== null) {
            //Validar que el sector contiene ETc
            loadEtcHistory(days);
          }
          break;
      }
    }

    function loadEtcHistory(days) {
      params = { from: '', to: '' };
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');
      params.group = 1;
      graphicsFactory.getSectorETC(UserData.id, vm.sector.pk.deviceId, vm.sector.pk.id, params).then((result) => {
        populateHistoryETC(result.plain());
      });
    }

    function loadEffectiveRainHistory(days) {
      params = { from: '', to: '' };
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      /*  params.sector = vm.sector.pk.id;

            calculatedsFactory.effectiveRainHistory(vm.sector.effectiveRainUser,vm.sector.effectiveRain,params).then(result => {
            
                populateHistoryBarRain25(result.plain());
            }); */

      calculatedsFactory.effectiveRainById(vm.sector.effectiveRainUser, vm.sector.effectiveRain).then((res) => {
        var ef = res.plain();
        params.group = 2;
        graphicsFactory
          .getMeterConsumption(vm.sector.effectiveRainUser, vm.sector.pk.deviceId, ef.sensorId, params)
          .then((result) => {
            populateHistoryBarRain25(result.plain(), ef);
          });
      });
    }

    function populateHistoryBar25(dates) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        dates = _.orderBy(dates, (o) => {
          return moment(o[2], 'YYYY-MM-DD HH:mm');
        });
        _.forEach(dates, (o) => {
          date_timestamp = moment(o[2], 'YYYY-MM-DD');
          date_timestamp = date_timestamp.format('x');
          date_timestamp = parseInt(date_timestamp);
          data.push([date_timestamp, o[1] > 0 ? o[1] : o[0]]);
        });
      }
      vis = visibleSerie('Riego');
      myChart.addSeries({
        name: 'Riego',
        stack: 0,
        stacking: 'normal',
        type: 'column',
        visible: vis,
        color: '#47C5DF',
        yAxis: 'irrig',
        data: data,
      });
    }

    function populateHistoryETC(dates) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        _.forEach(dates, (o) => {
          date_timestamp = moment(o.date, 'DD-MM-YYYY').format('x');
          date_timestamp = parseInt(date_timestamp);
          data.push([date_timestamp, o.value]);
        });
      }
      vis = visibleSerie('ETc');
      myChart.addSeries({
        name: 'ETc',
        type: 'column',
        color: '#FFC72C',
        visible: vis,
        yAxis: 'irrig',
        data: data,
      });
    }

    function populateHistoryBarRain25(dates, ef) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        _.forEach(dates, (o) => {
          date_timestamp = moment(o.date, 'DD-MM-YYYY').format('x');
          date_timestamp = parseInt(date_timestamp);
          if (ef) {
            if (ef.percentage > 0 && ef.min != null) {
              o.value = (o.value - ef.min) * ef.percentage;
            } else if (ef.min > 0) {
              o.value = o.value - ef.min;
            }
            if (o.value < 0) o.value = 0;
          }
          data.push([date_timestamp, o.value]);
        });
      }

      vis = visibleSerie('Lluvia');
      myChart.addSeries({
        name: 'Lluvia',
        stack: 0,
        stacking: 'normal',
        type: 'column',
        visible: vis,
        color: '#00A5BB',
        yAxis: 'irrig',
        data: data,
      });
    }

    function visibleSerie(serieName) {
      if (dataSeries.length > 0) {
        vis = _.find(dataSeries, function (o) {
          return o[0] == serieName;
        });
        return (vis = vis[1]);
      } else {
        return (vis = true);
      }
    }

    function addEmptyDays(dates) {
      if (dates.length < 7 && dates.length > 0) {
        let labels = [];
        labels.push(moment().subtract(6, 'days'));
        labels.push(moment().subtract(5, 'days'));
        labels.push(moment().subtract(4, 'days'));
        labels.push(moment().subtract(3, 'days'));
        labels.push(moment().subtract(2, 'days'));
        labels.push(moment().subtract(1, 'days'));
        labels.push(moment());
        for (let i = 0; i < 7; i++) {
          if (dates[i] && dates[i][2] && moment(dates[i][2], 'DD-MM-YYYY HH:mm').isSameOrAfter(labels[i])) {
            dates.unshift([null, null, labels[i].format('DD-MM-YYYY')]);
          }
        }
      }
      return dates;
    }

    grid_options = grid_options = {
      scales: {
        yAxes: [
          {
            position: 'left',
            id: 'y-axis-1',
            display: false,
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return Number(value).toFixed(2) + ' mm';
              },
            },
            gridLines: {
              display: false,
            },
          },
          {
            position: 'right',
            id: 'y-axis-2',
            display: false,
            ticks: {
              callback: function (value, index, values) {
                return Number(value).toFixed(2) + ' %';
              },
            },
            gridLines: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            id: 'x-axis-2',
            stacked: true,
            type: 'time',
            time: {
              isoWeekday: true,
              unit: 'day',
              tooltipFormat: 'DD/MM',
              displayFormats: {
                day: 'DD/MM',
                hour: 'DD/MM HH:mm',
              },
            },
            gridLines: {
              display: true,
            },
            display: true,
            ticks: {
              source: 'auto',
            },
          },
          {
            id: 'x-axis-1',
            position: 'top',
            type: 'time',
            time: {
              isoWeekday: true,
              unit: 'day',
              tooltipFormat: 'DD/MM HH:mm',
              displayFormats: {
                day: 'DD/MM HH:mm',
                hour: 'DD/MM HH:mm',
              },
            },
            gridLines: {
              display: true,
            },
            display: false,
            ticks: {
              source: 'auto',
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel.toFixed(2);
          },
        },
      },
      elements: {
        point: {
          pointStyle: 'circle',
          radius: 0,
          hitRadius: 5,
        },
        line: {
          fill: false,
          tension: 0.5,
        },
      },
    };

    function changeDatesGraphics(filter, days) {
      vm.filter = filter;
      dataSeries = [];
      _.forEach(myChart.series, (o) => {
        dataSeries.push([o.name, o.visible]);
      });
      vm.prepareHistoryBar(days);
      myChartW.destroy();
      vm.prepareCanvasWaterHistory(days);
      if (days == 365) {
        myChartW.scales['x-axis-1'].options.time.unit = 'month';
      }
    }

    function filterByMapElements(element) {
      return $state.params.elementsInMap.every(
        ({ properties }) => properties.sectorName !== element.name && vm.selectedSector !== properties.sector
      );
    }

    $scope.$on('lang-update', (ev, args) => {
      loadCropText(args.lang);
    });

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });

    $scope.$on('editAction', () => {
      prepareEdition();
    });

    $scope.$on('saveEditSector', () => {
      update();
    });

    $scope.$on('editPaginatorChange', (ev, sector) => {
      vm.sector = sector;
    });

    $scope.$on('loadSectorData', (e, { sector, currentUnit }) => {
      vm.sector = sector;
      vm.currentUnit = currentUnit;
      loadSectorData();
      $scope.$broadcast('editPaginatorChange', sector);
    });
  }
})();
