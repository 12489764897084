import { UNITS } from '../../utils/units.enum';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('historySectorsController', historySectorsController);

  historySectorsController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    'uiGridConstants',
    'sectorFactory',
    '$filter',
  ];

  function historySectorsController($scope, $state, UserData, historyFactory, uiGridConstants, sectorFactory, $filter) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();
    vm.view = 1;

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.to = moment().format('DD-MM-YYYY');
        vm.maxDateValue = moment().format('DD-MM-YYYY');

        vm.loadHistory = loadHistory;
        vm.exportPdf = exportXLS;
        vm.getHistory = getHistory;
        loaded = false;
        vm.pagination = {
          page: 1,
          limit: 15,
        };
        vm.groupBy = [
          { text: '10 ' + $filter('translate')('history.minutes'), value: '0' },
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];

        vm.sectorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];
        vm.updateRangeDates = updateRangeDates;

            if(vm.currentUnit.type === UNITS.A_4000){
                vm.fertilizersFilter = _.range(5,13);
            }else if(vm.currentUnit.type === UNITS.A_2500){
                vm.fertilizersFilter = _.range(5,13);
            }else if(vm.currentUnit.type === UNITS.A_7000){
                vm.fertilizersFilter = _.range(5,21);
            }else if(vm.currentUnit.type === UNITS.A_5500){
                vm.fertilizersFilter = _.range(4,14);
            }else if(vm.currentUnit.type === UNITS.A_4500){
                vm.fertilizersFilter = _.range(6,22);
            }

            initGrid();
            loadHistory();
            loadSectors();
        }else{
            $state.go('units');
        }
    }

    function initGrid() {
      vm.filter.groupBy = '2';
      vm.filter.sector = '0';
      vm.gridOptions = {
        enableSorting: true,
        enableColumnMenus: false,
        enableColumnResizing: true,
        useExternalPagination: false,
        paginationPageSizes: [15, 20, 25],
        paginationPageSize: 15,
        enableHorizontalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,
        columnDefs: getColumnDefinition(vm.currentUnit.type),
        onRegisterApi: function (gridApi) {
          vm.gridApi = gridApi;
        },
      };
      vm.loaded = true;
    }
    function checkDates() {
      if(vm.currentUnit.type === UNITS.A_4500){
            return this["3"] + " - " + this["4"];
        }else  if(this.from === undefined){
           return this['2'] + ' - ' + this['3'];
      } else {
        return this.from + ' - ' + this.to;
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.sectors(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
          row.parseRealFlow = parseRealFlow;
          row.parsePrevFlow = parsePrevFlow;
          row.getMilimeters = getMilimeters;
        });
      });
    }

    function parseRealFlow() {
      let format = this[this.length - 1];
      let unit = '';
      switch (format) {
        case 0:
          unit = ' m3/h';
          break;
        case 1:
          unit = ' L/h';
          break;
        case 2:
          unit = ' L/s';
          break;
      }
      switch (vm.currentUnit.type) {
        case 3:
          return this[21] + unit;
          break;
        case 4:
          return this[13] + unit;
          break;
        case 5:
          break;
      }
    }
    function parsePrevFlow() {
      let format = this[this.length - 1];
      let unit = '';
      let input;
      switch (format) {
        case 0:
          unit = ' m3/h';
          break;
        case 1:
          unit = ' L/h';
          break;
        case 2:
          unit = ' L/s';
          break;
      }
      switch (vm.currentUnit.type) {
        case 3:
          input = this[22];
          return input === 0 ? '-' : (Number(input) / 100).toFixed(2) + unit;
          break;
        case 4:
          input = this[14];
          return input === 0 ? '-' : (Number(input) / 100).toFixed(2) + unit;
          break;
        case 5:
          break;
      }
    }

    function getMilimeters() {
      let liters, meters;
      switch (vm.currentUnit.type) {
        case 2:
          liters = this['volume'];
          meters = this['area'] * 10000;
          break;
        case 3:
          liters = this[7];
          meters = this[23] * 10000;
          break;
      }
      if (meters > 0) {
        return (liters / meters).toFixed(2);
      } else {
        return 0;
      }
    }

    function gerFilterForFert() {
      //A4000 only
      if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
        return 'parsetime';
      } else {
        return 'parsevolumedl';
      }
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4000:
          if (vm.currentUnit.flow.fertilizationUnit === 0 || vm.currentUnit.flow.fertilizationUnit === 1) {
            return [
              //Cell filter time fertilizer
              { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
              { field: 'sector', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'time',
                displayName: 'history.time',
                minWidth: 70,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
              },
              { field: 'name', displayName: 'history.sensorname', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'volume',
                displayName: 'history.volume',
                minWidth: 70,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolume',
              },
              {
                field: 'fert1',
                displayName: 'history.f1',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert2',
                displayName: 'history.f2',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert3',
                displayName: 'history.f3',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert4',
                displayName: 'history.f4',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert5',
                displayName: 'history.f5',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert6',
                displayName: 'history.f6',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert7',
                displayName: 'history.f7',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              {
                field: 'fert8',
                displayName: 'history.f8',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
                visible: false,
              },
              { field: 'phAvg', displayName: 'history.phavg', minWidth: 50, headerCellFilter: 'translate' },
              { field: 'ceAvg', displayName: 'history.ceavg', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'realFlow',
                displayName: 'history.realflow',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetounit:null:3:" m3/h"',
              },
              {
                field: 'prevFlow',
                displayName: 'history.prevflow',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetounit:100:2:" m3/h"',
              },
              { field: 'desvFlow', displayName: 'history.desc', minWidth: 50, headerCellFilter: 'translate' },
              { field: 'area', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'kc',
                displayName: 'history.kc',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'fixed:2',
              },
              { field: 'getMilimeters()', displayName: 'graphics.mm', minWidth: 50, headerCellFilter: 'translate' },
            ];
          } else {
            return [
              //Cell filter volume fertilizer
              { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
              { field: 'sector', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
              { field: 'name', displayName: 'history.sensorname', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'time',
                displayName: 'history.time',
                minWidth: 70,
                headerCellFilter: 'translate',
                cellFilter: 'parsetime',
              },
              {
                field: 'volume',
                displayName: 'history.volume',
                minWidth: 70,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolume',
              },
              {
                field: 'fert1',
                displayName: 'history.f1',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert2',
                displayName: 'history.f2',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert3',
                displayName: 'history.f3',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert4',
                displayName: 'history.f4',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert5',
                displayName: 'history.f5',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert6',
                displayName: 'history.f6',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert7',
                displayName: 'history.f7',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              {
                field: 'fert8',
                displayName: 'history.f8',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsevolumedl',
                visible: false,
              },
              { field: 'phAvg', displayName: 'history.phavg', minWidth: 50, headerCellFilter: 'translate' },
              { field: 'ceAvg', displayName: 'history.ceavg', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'realFlow',
                displayName: 'history.realflow',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetounit:null:3:" m3/h"',
              },
              {
                field: 'prevFlow',
                displayName: 'history.prevflow',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'parsetounit:100:2:" m3/h"',
              },
              { field: 'desvFlow', displayName: 'history.desc', minWidth: 50, headerCellFilter: 'translate' },
              { field: 'area', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
              {
                field: 'kc',
                displayName: 'history.kc',
                minWidth: 50,
                headerCellFilter: 'translate',
                cellFilter: 'fixed:2',
              },
              { field: 'getMilimeters()', displayName: 'graphics.mm', minWidth: 50, headerCellFilter: 'translate' },
            ];
          }
        case UNITS.A_2500:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
            { field: '5', displayName: 'history.sensorname', minWidth: 50, headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'history.time',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '7',
              displayName: 'history.volume',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolume',
            },
            {
              field: '8',
              displayName: 'history.f1',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
              visible: false,
            },
            {
              field: '9',
              displayName: 'history.f1l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '10',
              displayName: 'history.f2',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
              visible: false,
            },
            {
              field: '11',
              displayName: 'history.f2l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '12',
              displayName: 'history.f3',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
              visible: false,
            },
            {
              field: '13',
              displayName: 'history.f3l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '14',
              displayName: 'history.f4',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
              visible: false,
            },
            {
              field: '15',
              displayName: 'history.f4l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            { field: 'parseRealFlow()', displayName: 'history.realflow', minWidth: 50, headerCellFilter: 'translate' },
            { field: 'parsePrevFlow()', displayName: 'history.prevflow', minWidth: 50, headerCellFilter: 'translate' },
            { field: '24', displayName: 'history.desc', minWidth: 50, headerCellFilter: 'translate' },
            { field: '23', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
            { field: 'getMilimeters()', displayName: 'graphics.mm', minWidth: 50, headerCellFilter: 'translate' },
          ];
        case UNITS.A_BIT:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
            { field: '5', displayName: 'history.sensorname', minWidth: 50, headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'history.time',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '7',
              displayName: 'history.volume',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolume',
            },
            { field: 'parseRealFlow()', displayName: 'history.realflow', minWidth: 50, headerCellFilter: 'translate' },
            { field: 'parsePrevFlow()', displayName: 'history.prevflow', minWidth: 50, headerCellFilter: 'translate' },
            { field: '16', displayName: 'history.desc', minWidth: 50, headerCellFilter: 'translate' },
            { field: '17', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
          ];
        case UNITS.A_7000:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
            { field: '5', displayName: 'general.name', minWidth: 50, headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'history.time',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsetime',
            },
            {
              field: '7',
              displayName: 'history.volume',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolume',
            },
            { field: '8', displayName: 'F1/F8', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '9', displayName: 'F1/F8 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '10', displayName: 'F2/F7', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '11', displayName: 'F2/F7 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '12', displayName: 'F3/F6', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '13', displayName: 'F3/F6 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '14', displayName: 'F4/F5', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '15', displayName: 'F4/F5 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '16', displayName: 'F5/F4', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '17', displayName: 'F5/F4 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '18', displayName: 'F6/F3', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '19', displayName: 'F6/F3 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '20', displayName: 'F7/F2', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '21', displayName: 'F7/F2 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            { field: '22', displayName: 'F8/F1', minWidth: 50, cellFilter: 'parsetime', visible: false },
            { field: '23', displayName: 'F8/F1 (L)', minWidth: 50, cellFilter: 'parsevolumedl', visible: false },
            {
              field: '24',
              displayName: 'history.realflow',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:null:3',
            },
            {
              field: '25',
              displayName: 'history.prevflow',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:100:2',
            },
            { field: '26', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
          ];
        case UNITS.A_5500:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', minWidth: 150, headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sector', minWidth: 50, headerCellFilter: 'translate' },
            {
              field: '6',
              displayName: 'history.time',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
            },
            {
              field: '7',
              displayName: 'history.volume',
              minWidth: 70,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolume',
            },
            {
              field: '8',
              displayName: 'history.f1',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
              visible: false,
            },
            {
              field: '9',
              displayName: 'history.f1l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '10',
              displayName: 'history.f2',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
              visible: false,
            },
            {
              field: '11',
              displayName: 'history.f2l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '12',
              displayName: 'history.f3',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
              visible: false,
            },
            {
              field: '13',
              displayName: 'history.f3l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '14',
              displayName: 'history.f4',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
              visible: false,
            },
            {
              field: '15',
              displayName: 'history.f4l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '16',
              displayName: 'history.f5',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetimesec',
              visible: false,
            },
            {
              field: '17',
              displayName: 'history.f5l',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsevolumecl',
              visible: false,
            },
            {
              field: '18',
              displayName: 'history.realflow',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:null:3:" m3/h"',
            },
            {
              field: '19',
              displayName: 'history.prevflow',
              minWidth: 50,
              headerCellFilter: 'translate',
              cellFilter: 'parsetounit:100:2:" m3/h"',
            },
            { field: '22', displayName: 'history.desc', minWidth: 50, headerCellFilter: 'translate' },
            { field: '20', displayName: 'history.area', minWidth: 50, headerCellFilter: 'translate' },
          ];
          case UNITS.A_4500:
                return [
                { field: 'checkDates()' , displayName: 'history.fromto' , minWidth:150, headerCellFilter: 'translate'  },
                { field: '5' , displayName: 'history.sector' , minWidth:50,  headerCellFilter: 'translate'},
                { field: '6' , displayName: 'general.name' , minWidth:50,  headerCellFilter: 'translate'},
                { field: '7' , displayName: 'history.time' , minWidth:70,  headerCellFilter: 'translate',cellFilter: 'parsetimehhmmss'},
                { field: '8' , displayName: 'history.volume' , minWidth:70,   headerCellFilter: 'translate' },
                { field: '9' , displayName: 'history.out' , minWidth:70,   headerCellFilter: 'translate' },
                { field: '20' , displayName: 'F1' , minWidth:50,    cellFilter: 'parsetimehhmmss', visible:false},
                { field: '21' , displayName: 'F1 (L)' , minWidth:50,    visible:false },
                { field: '22' , displayName: 'F2' , minWidth:50,   cellFilter: 'parsetimehhmmss', visible:false },
                { field: '23' , displayName: 'F2 (L)' , minWidth:50,   visible:false},
                { field: '24' , displayName: 'F3' , minWidth:50,  cellFilter: 'parsetimehhmmss', visible:false },
                { field: '25' , displayName: 'F3 (L)' , minWidth:50,   visible:false },
                { field: '26' , displayName: 'F4' , minWidth:50,    cellFilter: 'parsetimehhmmss', visible:false},
                { field: '27' , displayName: 'F4 (L)' , minWidth:50,   visible:false },
                { field: '28' , displayName: 'F5' , minWidth:50,    cellFilter: 'parsetimehhmmss', visible:false},
                { field: '29' , displayName: 'F5 (L)' , minWidth:50,   visible:false },
                { field: '30' , displayName: 'F6' , minWidth:50,    cellFilter: 'parsetimehhmmss', visible:false},
                { field: '31' , displayName: 'F6 (L)' , minWidth:50,   visible:false },
                { field: '32' , displayName: 'F7' , minWidth:50,   cellFilter: 'parsetimehhmmss', visible:false},
                { field: '33' , displayName: 'F7 (L)' , minWidth:50,  visible:false },
                { field: '34' , displayName: 'F8' , minWidth:50,   cellFilter: 'parsetimehhmmss', visible:false},
                { field: '35' , displayName: 'F8 (L)' , minWidth:50,  visible:false },
                { field: '13' , displayName: 'history.realflow' , minWidth:50,  headerCellFilter: 'translate' },
                { field: '16' , displayName: 'history.prevflow' ,  minWidth:50,  headerCellFilter: 'translate' },
                { field: '17' , displayName: 'history.desc' ,  minWidth:50,  headerCellFilter: 'translate' },
                { field: '18' , displayName: 'history.area' , minWidth:50,  headerCellFilter: 'translate' },
                { field: '19' , displayName: 'graphics.mm' , minWidth:50,  headerCellFilter: 'translate' }
                ]
      }
    }

    function exportXLS() {
      vm.loaded = false;
      var params = {
        from: moment(vm.from).format('DD-MM-YYYY'),
        to: moment(vm.to).add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: 1,
        limit: 10000,
      };
      historyFactory.sectorsExport(vm.currentUnit.id, params).then(() => {
        vm.loaded = true;
      });
    }

    vm.showHideFert = function () {
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        vm.fertilizersFilter = [5, 6, 7, 8, 9, 10, 11, 12];
      } else if (vm.currentUnit.type === vm.UNITS.A_2500) {
        vm.fertilizersFilter = [5, 6, 7, 8, 9, 10, 11, 12];
      } else if (vm.currentUnit.type === vm.UNITS.A_7000) {
        vm.fertilizersFilter = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
      } else if (vm.currentUnit.type === vm.UNITS.A_5500) {
        vm.fertilizersFilter = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
      }
      _.forEach(vm.fertilizersFilter, (i) => {
        var column = vm.gridApi.grid.columns[i];
        if (column.visible) {
          column.hideColumn();
          vm.view = 1;
        }else {
          column.showColumn();
          vm.view = 0;
        }
      });
      vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.$on('refresh', function (event, args) {});

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadSectors() {
      sectorFactory.allSectors(vm.currentUnit.id).then(function (data) {
        vm.sectorList = data.plain();
        vm.sectorListDTO.push(
          ...vm.sectorList.map((sector) => {
            return {
              text: sector.pk.id + ' - ' + (sector.name ? sector.name : 'Sector ' + sector.pk.id),
              value: sector.pk.id,
            };
          })
        );
      });
    }
  }
})();
