(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('expertIrrideskSensorsController', expertIrrideskSensorsController);

  expertIrrideskSensorsController.$inject = [
    '$state',
    'irrideskFactory',
    'sensorsFactory',
    '$rootScope',
    '$confirm',
    '$filter',
  ];

  function expertIrrideskSensorsController($state, irrideskFactory, sensorsFactory, $rootScope, $confirm, $filter) {
    var vm = this;
    var maxSensors, idSubscription, subscription, x;
    vm.sensors = [];
    vm.sensorValue;
    vm.sensorList = [];
    vm.form;

    activate();

    function activate() {
      if (!$state.params.units && !$rootScope.devicesArray) {
        return;
      }

      vm.currentState = $state.includes;
      vm.units = $state.params.units || $rootScope.devicesArray;

      if (vm.units === null) {
        $state.go('units');
      }

      vm.filterMeasurement = filterMeasurement;
      vm.filterUnit = filterUnit;
      vm.loadUnitSensors = loadUnitSensors;
      vm.save = save;
      vm.cancel = cancel;
      vm.changeName = changeName;
      vm.deleteSensor = deleteSensor;
      loadSubscription();
      loadFormFactory();
    }

    function backup() {
      vm.sensorsCopy = [];
      angular.copy(vm.sensors, vm.sensorsCopy);
    }

    function prepare() {
      let total = maxSensors - vm.sensors.length;
      _.times(total, () => {
        vm.sensors.push({ sensorType: 'SA', type: '', measurement: '', units: '', edit: true });
      });
      let edit = _.filter(vm.sensors, ['edit', true]);
      if (edit.length > 0) {
        vm.edit = true;
      }
      backup();
    }

    function loadSubscription() {
      irrideskFactory.getSubscription().then((result) => {
        if (result) {
          vm.subscription = result.plain();
          maxSensors = vm.subscription.maxSensors;
          vm.sensors = vm.subscription.sensors;
          idSubscription = vm.subscription.id;
          for (x = 0; x < vm.sensors.length; x++) {
            if (
              (vm.sensors[x].type != undefined || vm.sensors[x].type != null) &&
              (vm.sensors[x].measurement != undefined || vm.sensors[x].measurement != null) &&
              (vm.sensors[x].pk != undefined || vm.sensors[x].pk != null) &&
              (vm.sensors[x].name != undefined || vm.sensors[x].name != null)
            ) {
              vm.sensors[x].edit = false;
            }
          }
          prepare();
        }
      });
    }

    function loadFormFactory() {
      irrideskFactory.getFormFactory().then((result) => {
        if (result) {
          vm.formFactory = result.channel_outlines.sensor_outlines;
        }
      });
    }

    function filterMeasurement(type, value) {
      if (type !== '') {
        let filterList = vm.formFactory.measurement_type.restrictions[type];
        if (filterList) {
          return _.includes(filterList, value);
        }
      }
      return true;
    }

    function filterUnit(type, value) {
      if (type !== '') {
        let filterList = vm.formFactory.measurement_units.restrictions[type];
        if (filterList) {
          return _.includes(filterList, value);
        }
      }
      return true;
    }

    function loadCounters(deviceId, val, i) {
      let unit = _.filter(vm.units, ['device.id', deviceId]);
      if (unit[0].device.type === 2) {
        let unit = _.filter(vm.units, ['device.id', deviceId]);
        sensorsFactory.digitalsactive(deviceId).then((data) => {
          if (unit[0].device.type == 2 && val == 2)
            vm.sensorList[i] = _.filter(data.plain(), (o) => {
              return o.input !== 0 && o.pk.id == 35;
            });
          else
            vm.sensorList[i] = _.filter(data.plain(), (o) => {
              return o.input !== 0 && o.pk.id >= 11 && o.pk.id <= 34;
            });
          // vm.sensorsByElement[index] = sensorByUnitAndType[deviceId+'-'+val];
          // if(origin === null || origin === undefined) backupSensorList();
        });
      } else if (unit[0].device.type === 5) {
        //A7000 comptadors són s.digitals del 1 al 14
        sensorsFactory.digitalsactive(deviceId).then((data) => {
          vm.sensorList[i] = _.filter(data.plain(), (o) => {
            return o.input !== 0 && o.pk.id >= 1 && o.pk.id <= 14;
          });
          // vm.sensorsByElement[index] = sensorByUnitAndType[deviceId+'-'+val];
          // if(origin === null || origin === undefined) backupSensorList();
        });
      } else {
        sensorsFactory.meters(deviceId).then((data) => {
          vm.sensorList[i] = _.filter(data.plain(), (o) => {
            return o.input !== 0;
          });
          // vm.sensorsByElement[index] = sensorByUnitAndType[deviceId+'-'+val];
          // if(origin === null || origin === undefined) backupSensorList();
        });
      }
    }

    function loadAnalogs(deviceId, i) {
      let params = {
        limit: 100,
        page: 1,
      };
      sensorsFactory.analogsPage(deviceId, params).then((result) => {
        if (result.content) {
          vm.sensorList[i] = result.content;
        }
      });
    }

    function loadUnitSensors(i, sensor) {
      vm.sensorList[i] = null;
      if (sensor.pk !== undefined && sensor.pk.deviceId) {
        if (sensor.type == 'WM') {
          if (sensor.measurement == 'WFL') {
            loadCounters(sensor.pk.deviceId, 1, i);
          } else if (sensor.measurement == 'WMA') {
            loadCounters(sensor.pk.deviceId, 2, i);
          } else {
            loadAnalogs(sensor.pk.deviceId);
          }
        } else if (sensor.type == 'SS') {
          loadAnalogs(sensor.pk.deviceId, i);
        } else if (sensor.type == 'PS') {
          loadAnalogs(sensor.pk.deviceId, i);
        } else if (sensor.type == 'WS') {
          if (sensor.measurement == 'PPT') {
            loadCounters(sensor.pk.deviceId, 1, i);
          } else {
            loadAnalogs(sensor.pk.deviceId, i);
          }
        } else {
          loadAnalogs(sensor.pk.deviceId, i);
        }
      }
    }

    function changeName(i) {
      if (vm.sensors[i].name === '' || vm.sensors[i].name === null || vm.sensors[i].name === undefined) {
        let sensor = _.find(vm.sensorList[i], function (s) {
          return s.pk.id == vm.sensors[i].pk.id;
        });
        vm.sensors[i].name = sensor.name;
      }
    }

    //falta posar subscriptionId a cada sensor
    function save() {
      vm.form.$setPristine();
      var sensorArray = [],
        exit;
      _.forEach(vm.sensors, (s) => {
        if (s.pk) {
          if (
            (s.pk.deviceId != '' || s.pk.deviceId != null) &&
            (s.pk.id != '' || s.pk.id != null) &&
            s.type !== '' &&
            s.measurement != '' &&
            s.units != ''
          ) {
            s.pk.subscriptionId = idSubscription;
            s.edit = false;
            sensorArray.push(s);
          } else {
            exit = true;
          }
        }
      });

      if (exit) {
        $rootScope.toast({
          type: 'error',
          title: 'Faltan campos para rellenar',
        });
      } else {
        subscription = { id: idSubscription, sensors: sensorArray };
        irrideskFactory.updateSensors(idSubscription, subscription).then(() => {
          //backup();
          $state.params.sensors = subscription.sensors;
          let edit = _.filter($state.params.sensors, ['edit', true]);
          if (edit.length > 0) {
            vm.edit = true;
          } else {
            vm.edit = false;
          }
        });
      }
    }

    function deleteSensor(i) {
      $confirm({
        text: $filter('translate')('graphics.delelementtxt'),
        title: $filter('translate')('irridesk.sensor'),
      }).then(() => {
        var sensor = vm.sensors[i];
        irrideskFactory.deleteSensor(idSubscription, sensor.pk.deviceId, sensor.pk.id).then((response) => {
          vm.sensors.splice(i, 1);

          prepare();
        });
      });
    }

    function cancel() {
      $confirm({
        text: $filter('translate')('programs.edit.cancelq'),
        title: $filter('translate')('irridesk.sensor'),
      }).then(() => {
        vm.form.$setPristine();
        angular.copy(vm.sensorsCopy, vm.sensors);
      });
    }
  }
})();
