import { UNITS } from "../../utils/units.enum";

(function() {
  "use strict";

  angular.module("agronicwebApp")

    .controller("outFitosController", outFitosController);

  outFitosController.$inject = ["$scope", "$state", "configFactory"];

  function outFitosController($scope, $state, configFactory) {

    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {

        vm.currentUnit = $state.params.unit;
        vm.currentHeader = $state.params.headerId || 1;
        loadFitos();

      } else {
        $state.go("units");
      }

    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }

    }

    function loadFitos() {
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        var params = { add: ["fitos"] };
        configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result) => {
          vm.config = result.plain();
          backup = angular.copy(vm.config);
          vm.loaded = true;
        });
      }
    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = vm.currentUnit.type;
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        configFactory.updateConfigHeader(vm.currentUnit.id, vm.config).then(result => {
          vm.config = result.plain();
          if (vm.config.fitos) {
            vm.config.fitos = _.sortBy(vm.config.fitos, [function(o) {
              return o.pk.id;
            }]);
          }
          backup = angular.copy(vm.config);
        });
      }

    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    $scope.$on("refresh", function(event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(() => vm.form, () => {
      $scope.$emit("formUpdated", vm.form);
    });

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on("formSubmit", (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on("formCancel", (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();