(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('regulationController', regulationController)

    regulationController.$inject = ['$scope','$state','$confirm','$filter'];

    function regulationController($scope,$state,$confirm,$filter) {

        var vm = this;
        vm.activeList;
        vm.getType;
        vm.save;
        vm.cancel;

        activate();

        function activate() {
            vm.currentState = $state.includes;
            if ($state.params.unit !== null) {
                vm.currentUnit = $state.params.unit;                
                vm.currentHeader = $state.params.headerId || '1';
                
                if(!vm.currentUnit.inoptions.optionHidro){
                    $state.go('regulation.pid', {
                        unit: vm.currentUnit,
                        headerId:vm.currentHeader
                    });
                }else{
                    $state.go('.general', {
                        unit: vm.currentUnit,
                        headerId:vm.currentHeader
                    });
                }

                vm.changeHeader = changeHeader;
                vm.changeState = changeState;              
            } else {
                $state.go('units');
            }

        }

        function changeHeader(params) {
            
            // let params = {unit:vm.currentUnit, headerId:vm.currentHeader}
            if(vm.form && vm.form.$dirty){
                $confirm({text: $filter('translate')('programs.edit.cancelq'), title: $filter('translate')('programs.prog2')}).then( () => {
                    $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
                    vm.form.$setPristine(); //Actualitzem estat del formulari a inicial                    
                    vm.currentState.url = $state.current.name;
                    vm.currentHeader = params.headerId;
                    $state.go(vm.currentState.url,params);  //Canviem d'estat
                })
            }else{

                if($state.current.name != 'regulation.modulation'){
                    vm.currentState.url = $state.current.name;
                }else vm.currentState.url = 'regulation.general';
                vm.currentHeader = params.headerId;
                $state.go(vm.currentState.url,params); 
            } 
        }
    
        function changeState(location,params){
           
            if(vm.form && vm.form.$dirty){
                $confirm({text: $filter('translate')('programs.edit.cancelq'), title: $filter('translate')('programs.prog2')}).then( () => {
                    $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
                    vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
                    vm.form = null;
                    vm.currentState.url = $state.current.name;
                    vm.currentHeader = params.headerId;
                    $state.go(location,params); //Canviem d'estat
                })
            }else{
                vm.currentState.url = $state.current.name;
                vm.currentHeader = params.headerId;
                $state.go(location,params); 
            }
        }

        /**
         * Event listener per gestionar l'estat del formulari
         */
        $scope.$on('formUpdated', (e,args) => {
            vm.form = args;
        });
      

        
    }
})();