import "../components/communications/communications-controller";
import commTemplate from "../components/communications/communications.html";

import "../components/communications/pccomm/pccomm-controller";
import commPcTemplate from "../components/communications/pccomm/pc.html";

import "../components/communications/modules/modules-controller";
import modulesTemplage from "../components/communications/modules/modules.html";

import "../components/communications/modules/m120/m120-controller";
import m120Templage from "../components/communications/modules/m120/m120.html";

import "../components/communications/modules/radio16/r16-controller";
import r16Template from "../components/communications/modules/radio16/r16.html";

import "../components/communications/modules/radio24/r24-controller";
import r24Template from "../components/communications/modules/radio24/r24.html";

import "../components/communications/modules/radio433/r433-controller";
import r433Template from "../components/communications/modules/radio433/r433.html";

import "../components/communications/modules/agrobee/agrobee-controller";
import agrobeeTemplate from "../components/communications/modules/agrobee/agrobee.html";

import "../components/communications/modules/agrobeel/agrobeel-controller";
import agrobeelTemplate from "../components/communications/modules/agrobeel/agrobeel.html";

import "../components/communications/modules/sdi12/sdi12-controller";
import sdi12Template from "../components/communications/modules/sdi12/sdi12.html";

import "../components/communications/modules/davis/davis-controller";
import davisTemplate from "../components/communications/modules/davis/davis.html";

import "../components/communications/sms/sms-controller";
import smsTemplate from "../components/communications/sms/sms.html";

import phoneTemplate from "../components/communications/sms/phone.html";

import otherTemplate from "../components/communications/sms/other.html";

import "../components/communications/psep/psep-controller";
import psepTemplate from "../components/communications/psep/psep.html";

import "../components/communications/modbus/modbus-controller";
import modbusTemplate from "../components/communications/modbus/modbus.html";

import "../components/communications/modules/modbus-port/modbusPort-controller";
import modbusPortTemplate from "../components/communications/modules/modbus-port/modbusPort.html";

import "../components/communications/modules/modbus-externo/modbusExterno-controller";
import modbusExternoTemplate from "../components/communications/modules/modbus-externo/modbusExterno.html";

import "../components/communications/gprs/gprs-controller";
import gprsTemplate from "../components/communications/gprs/gprs.html";

import "../components/communications/wifi/wifi-controller";
import wifiTemplate from "../components/communications/wifi/wifi.html";

(function() {
  "use strict";

  angular
    .module("agronicwebApp")

    .config([
      "$stateProvider",
      "$urlRouterProvider",
      function($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state("communications", {
            parent: "unit",
            url: "/communications",
            params: {
              unit: null,
              load: null
            },
            template: commTemplate,
            controller: "commController",
            authenticate: true,
            controllerAs: "vm"
          })
          //-------> communications child states
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.pc", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            url: "/pc",
            template: commPcTemplate,
            controller: "pcCommController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              Module: [
                "commFactory",
                "$stateParams",
                function(commFactory, $stateParams) {
                  if ($stateParams.unit !== null) {
                    return commFactory.module($stateParams.unit.id);
                  } else {
                    return null;
                  }
                }
              ]
            },
            url: "/modules",
            template: modulesTemplage,
            controller: "modulesController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.m120", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/monocable120",
            template: m120Templage,
            controller: "m120Controller",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.r16", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/radio868-16",
            template: r16Template,
            controller: "r16Controller",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.r24", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/radio2.4",
            template: r24Template,
            controller: "r24Controller",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.r433", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/radio433",
            template: r433Template,
            controller: "r433Controller",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.agrobee", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/agrobee",
            template: agrobeeTemplate,
            controller: "agrobeeController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.agrobeel", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: 1
            },
            url: "/agrobeel",
            template: agrobeelTemplate,
            controller: "agrobeelController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.agrobeel2", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: 2
            },
            url: "/agrobeel",
            template: agrobeelTemplate,
            controller: "agrobeelController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.sdi12", {
            //parent:'communications.modules',
            params: {
              unit: null,
              id: null
            },
            url: "/sdi12",
            template: sdi12Template,
            controller: "sdi12Controller",
            authenticate: true,
            controllerAs: "vm"
          })
          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.davis", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              Davis: ["commFactory", "$stateParams", function(commFactory, $stateParams) {
                if ($stateParams.unit !== null) {
                  return commFactory.getDavis($stateParams.unit.id);
                } else {
                  return null;
                }

              }]
            },
            url: "/davis",
            template: davisTemplate,
            controller: "davisController",
            authenticate: true,
            controllerAs: "vm"
          })
          .state("communications.modules.external-modbus", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              ModBusExterno: ["commFactory", "$stateParams", function(commFactory, $stateParams) {
                if ($stateParams.unit !== null) {
                  return commFactory.modBusExterno($stateParams.unit.id);
                } else {
                  return null;
                }

              }]
            },
            url: "/external-modbus",
            template: modbusExternoTemplate,
            controller: "modbusExternoController",
            authenticate: true,
            controllerAs: "vm"
          })
          /******/
          // WARNING: there already exists a state with the same content, however, the state name is not correct, neither the url
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modules.modbus-port", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              ModBusPort: ["commFactory", "$stateParams", function(commFactory, $stateParams) {
                if ($stateParams.unit !== null) {
                  return commFactory.modBusClient($stateParams.unit.id);
                } else {
                  return null;
                }

              }]
            },
            url: "/modbus-port",
            template: modbusPortTemplate,
            controller: "modbusPortController",
            authenticate: true,
            controllerAs: "vm"
          })
          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.sms", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              SMS: ["commFactory", "$stateParams", function(commFactory, $stateParams) {
                if ($stateParams.unit !== null) {
                  return commFactory.SMS($stateParams.unit.id);
                } else {
                  return null;
                }

              }]
            },
            url: "/sms",
            template: smsTemplate,
            controller: "smsController",
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.sms.phone", {
            //parent:'communications.sms',
            params: {
              unit: null,
              id: null
            },
            url: "/phone",
            template: phoneTemplate,
            authenticate: true,
            controllerAs: "vm"
          })
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.sms.other", {
            //¡parent:'communications.sms',
            params: {
              unit: null,
              id: null
            },
            url: "/other",
            template: otherTemplate,
            authenticate: true,
            controllerAs: "vm"
          })
          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.psep", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              PSEP: ["commFactory", "$stateParams", function(commFactory, $stateParams) {
                if ($stateParams.unit !== null) {
                  return commFactory.PSEP($stateParams.unit.id);
                } else {
                  return null;
                }
              }]
            },
            url: "/psep",
            template: psepTemplate,
            controller: "psepController",
            authenticate: true,
            controllerAs: "vm"
          })
          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.modbus", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              ModBus: [
                "commFactory",
                "$stateParams",
                function(commFactory, $stateParams) {
                  if ($stateParams.unit !== null) {
                    return commFactory.modBusClient($stateParams.unit.id);
                  } else {
                    return null;
                  }
                }
              ]
            },
            url: "/modbus",
            template: modbusTemplate,
            controller: "modbusController",
            authenticate: true,
            controllerAs: "vm"
          })

          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.gprs", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              GPRS: [
                "commFactory",
                "$stateParams",
                function(commFactory, $stateParams) {
                  if ($stateParams.unit !== null) {
                    return commFactory.GPRS($stateParams.unit.id);
                  } else {
                    return null;
                  }
                }
              ]
            },
            url: "/gprs",
            template: gprsTemplate,
            controller: "gprsController",
            authenticate: true,
            controllerAs: "vm"
          })

          /******/
          // TODO - If state is defined with ".", it's not necessary to add parent attribute
          .state("communications.wifi", {
            //parent:'communications',
            params: {
              unit: null,
              id: null
            },
            resolve: {
              Wifi: [
                "commFactory",
                "$stateParams",
                function(commFactory, $stateParams) {
                  if ($stateParams.unit !== null) {
                    return commFactory.Wifi($stateParams.unit.id);
                  } else {
                    return null;
                  }
                }
              ]
            },
            url: "/wifi",
            template: wifiTemplate,
            controller: "wifiController",
            authenticate: true,
            controllerAs: "vm"
          });

        /******/
      }
    ]);
})();
