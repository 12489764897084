import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .factory('progFactory', progFactory);

  progFactory.$inject = ['Restangular', '$rootScope', '$q', 'sectorFactory', '$http'];

  function progFactory(Restangular, $rootScope, $q, sectorFactory, $http) {
    var resource = Restangular.all('units');
    var list;
    var active;
    var events = [];
    var op = {
      resourse: resource,
      program: program,
      programs: programs,
      getAllPrograms4500: getAllPrograms4500,
      activePrograms: activeList,
      filterSubPrograms: filterSubPrograms,
      formatProgramView: formatProgramView,
      getTimeFromMins: getTimeFromMins,
      checkIrrigation: checkIrrigation,
      getEventTable: getEventTable,
      getModel: getModel,
      update: update,
      getSectorsArea: getSectorsArea,
      events: events,
      subprograms: subprograms,
      getProgramSectors: getProgramSectors,
      updateProgramSectors: updateProgramSectors,
      getInstaller: getInstaller,
      updateSubprograms: updateSubprograms,
      getFitos: getFitos,
      getActiveProgramSectors: getActiveProgramSectors,
    };

    return op;

    function getActiveProgramSectors(deviceId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('programs')
        .one('sectors')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function update(params) {
      var deferred = $q.defer();
      Restangular.one('units', params.pk.deviceId)
        .one('programs', params.pk.id)
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateProgramSectors(params) {
      var deferred = $q.defer();
      Restangular.one('units', params[0].pk.deviceId)
        .one('programs', params[0].pk.programId)
        .one('sectors')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateSubprograms(params, fertType) {
      var filledParams = { subprogram: params, fertType: fertType };
      var deferred = $q.defer();
      Restangular.one('units', params[0].pk.deviceId)
        .one('programs', params[0].pk.programId)
        .one('subprograms')
        .customPOST(filledParams)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function activeList() {
      return active;
    }

    function getFitos(deviceId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('fitos')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getModel(agronic) {
      var url = '/resources/program.' + agronic + '.json';
      var deferred = $q.defer();
      $http.get(url).then(function (data) {
        deferred.resolve(data.data);
      });

      return deferred.promise;
    }

    function getSectorsArea(deviceId, params) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('sectors')
        .one('area')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function program(deviceId, progId, params) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('programs', progId)
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function programs(id, type, reload) {
      var deferred = $q.defer();
      if (reload !== undefined) {
        list = undefined;
      }
      if (list === undefined) {
        Restangular.one('units', id)
          .one('programs')
          .get()
          .then(function (response) {
            list = response;

            filterActivePrograms(list, type);

            deferred.resolve(list);
          });
      } else {
        deferred.resolve(list);
      }
      return deferred.promise;
    }

    function getAllPrograms4500(id, type) {
      if (type != 7) {
        return;
      }

      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('programs')
        .get()
        .then(function (response) {
          list = response;

          filterActivePrograms(list, type);

          deferred.resolve(list);
        });
      return deferred.promise;
    }

    function subprograms(deviceId, programId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('programs', programId)
        .one('subprograms')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getProgramSectors(deviceId, programId) {
      var deferred = $q.defer();
      Restangular.one('units', deviceId)
        .one('programs', programId)
        .one('sectors')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function getInstaller(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('installer')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function filterActivePrograms(list, type) {
      active = [];
      var temp = list;
      switch (type) {
        case 2:
          _.forEach(temp, function (item) {
            item.subprograms.some(function (sub) {
              var sectors = _.pickBy(sub, function (value, key) {
                return _.startsWith(key, 'sector');
              });
              var serial = _.values(sectors).join('');
              if (parseInt(serial) > 0) {
                item.op = true; // Indica que el programa es operatiu
                checkIrrigation(item, type);
                setNextIrrigation(item, type);
              }
              active.push(item);
              return true;
            });
          });
          break;
        case 3:
        case 4:
          _.forEach(temp, function (item) {
            var sectors = _.pickBy(item, function (value, key) {
              return _.startsWith(key, 'sector');
            });
            var serial = _.values(sectors).join('');
            if (parseInt(serial) > 0) {
              item.op = true; //Indica que el programa es operatiu ja que té sectors configurats
              checkIrrigation(item, type);
              setNextIrrigation(item, type);
            }
            active.push(item);
            return true;
          });
          break;
        case 5:
          _.forEach(temp, function (item) {
            item.subprograms.some(function (sub) {
              if (sub.sector > 0) {
                item.op = true;
                checkIrrigation(item, type);
                setNextIrrigation(item, type);
              }
              active.push(item);
              return true;
            });
          });
          break;
        case 6:
          _.forEach(temp, function (item) {
            item.programSector.some(function (sub) {
              var sectors = _.pickBy(sub, function (value, key) {
                return _.startsWith(key, 'sector');
              });
              var serial = _.values(sectors).join('');
              if (parseInt(serial) > 0) {
                item.op;
                checkIrrigation(item, type);
                setNextIrrigation(item, type);
              }
              active.push(item);
              return true;
            });
          });
          break;
        case 7:
          _.forEach(temp, function (item) {
            // var sectors =  _.pickBy(item, function(value, key) {
            //   return _.startsWith(key, "sector");
            // });
            //var serial = _.values(sectors).join("");
            //if (parseInt(serial) > 0) {
            //item.op = true; //Indica que el programa es operatiu ja que té sectors configurats
            //setNextIrrigation(item,type)
            //}
            checkIrrigation(item, type);
            active.push(item);
            return true;
          });
          break;
      }
    }

    function getEventTable(item, month) {
      if (item)
        switch (item.type) {
          case 0: //hours
            return parseWeekTable(item, month);

            break;
          case 1: //Frequency
            events = [];
            var actuality = moment().format('MM-YYYY');
            var day;

            if (month === actuality) {
              day = moment();
              return parseFreqTable(item, month, day, 0);
            } else {
              var last = _.last(item.nextMonth);
              day = moment(last, 'DD-MM');
              return parseFreqTable(item, month, day, 0);
            }

            break;
          case 2: //Activations
            events = [];
            return parseActivationTable(item, month);
            break;
        }
    }

    function setNextMonth(item, date) {
      if (item.nextMonth === undefined) {
        item.nextMonth = [];
      }
      item.nextMonth.push(moment(date, 'D-MM-YYYY').format('DD-MM'));
    }

    function parseFreqTable(item, month, day, i) {
      if (i < 60 && item.freqDays > 0) {
        var days = moment(month, 'MM-YYYY').daysInMonth();
        var day_n = Number(moment().format('DD'));
        var month_n = moment(month, 'MM-YYYY').month();
        var check = day.diff(moment(month, 'MM-YYYY'), 'days') + 1;
        var nextDay;
        if (i === 0) {
          nextDay = check + item.xFreqDays;
          if (day_n === nextDay) {
            if (!checkStartsToday(item)) {
              nextDay = nextDay + 1;
            }
          }
        } else {
          nextDay = check + item.freqDays;
        }

        if (nextDay <= days) {
          var parse = moment().month(month_n).date(nextDay).format('DD-MM-YYYY');
          addEvent(parse);
          setNextMonth(item, parse);
          parseFreqTable(item, month, moment().month(month_n).date(nextDay), i + 1);
        } else {
          nextDay = nextDay - days;
          var parse = moment()
            .month(month_n + 1)
            .date(nextDay)
            .format('DD-MM-YYYY');
          addEvent(parse);
          setNextMonth(item, parse);
          //parseFreqTable(item,month,moment().month(month_n).date(nextDay), i+58)
        }

        return events;
      }
    }

    function checkStartsToday(item) {
      var hours = moment().hours();
      var minutes = moment().minutes();

      var time = hours * 60 + minutes;

      return item.start > time;
    }

    function parseActivationTable(item, month) {
      var days = moment(month, 'MM-YYYY').daysInMonth();
      var mid = moment(month, 'MM-YYYY').format('MM-YYYY');
      for (var i = 1; i <= days; i++) {
        var day = i + '-';
        var parsed = day + mid;
        if (moment().diff(moment(parsed, 'D-MM-YYYY'), 'days') <= 0) {
          addEvent(parsed);
          setNextMonth(item, parsed);
        }
      }
      days = 7;
      var tempNext = moment(month, 'MM-YYYY').month();
      mid = moment()
        .month(tempNext + 1)
        .format('MM-YYYY');
      for (var i = 1; i <= days; i++) {
        var day = i + '-';
        var parsed = day + mid;
        if (moment().diff(moment(parsed, 'D-MM-YYYY'), 'days') <= 0) {
          addEvent(parsed);
          setNextMonth(item, parsed);
        }
      }
      return events;
    }

    function parseWeekTable(item, month) {
      if (item.sequential) {
        item.nextMonth = [item.next, item.next];
      } else {
        var tabledays = [];
        events = [];

        if (item.monday) {
          tabledays.push(0);
        }
        if (item.tuesday) {
          tabledays.push(1);
        }
        if (item.wednesday) {
          tabledays.push(2);
        }
        if (item.thursday) {
          tabledays.push(3);
        }
        if (item.friday) {
          tabledays.push(4);
        }
        if (item.saturday) {
          tabledays.push(5);
        }
        if (item.sunday) {
          tabledays.push(6);
        }

        var days = moment(month, 'MM-YYYY').daysInMonth();
        var mid = moment(month, 'MM-YYYY').format('MM-YYYY');
        for (var i = 1; i <= days; i++) {
          var day = i + '-';
          var parsed = day + mid;
          var check = moment(parsed, 'D-MM-YYYY').weekday();
          if (_.indexOf(tabledays, check) !== -1) {
            if (moment().diff(moment(parsed, 'D-MM-YYYY'), 'days') <= 0) {
              addEvent(parsed);
              setNextMonth(item, parsed);
            }
          }
        }
        days = 7;
        var tempNext = moment(month, 'MM-YYYY').month();
        mid = moment()
          .month(tempNext + 1)
          .format('MM-YYYY');
        for (var i = 1; i <= days; i++) {
          var day = i + '-';
          var parsed = day + mid;
          var check = moment(parsed, 'D-MM-YYYY').weekday();
          if (_.indexOf(tabledays, check) !== -1) {
            if (moment().diff(moment(parsed, 'D-MM-YYYY'), 'days') <= 0) {
              addEvent(parsed);
              setNextMonth(item, parsed);
            }
          }
        }
      }
      return events;
    }

    function addEvent(date) {
      var eventModel = {
        title: 'Riego', // The title of the event
        startsAt: new Date(2017, 2, 7, 0, 0), // A javascript date object for when the event starts
        color: {
          // can also be calendarConfig.colorTypes.warning for shortcuts to the deprecated event types
          primary: '#4DD6FF', // the primary event color (should be darker than secondary)
          //secondary: '#fdf1ba' // the secondary event color (should be lighter than primary)
        },
        draggable: false, //Allow an event to be dragged and dropped
        resizable: false, //Allow an event to be resizable
        incrementsBadgeTotal: false, //If set to false then will not count towards the badge total amount on the month and year view
        //recursOn: 'month', // If set the event will recur on the given period. Valid values are year or month
        cssClass: 'a-css-class-name', //A CSS class (or more, just separate with spaces) that will be added to the event when it is displayed on each view. Useful for marking an event as selected / active etc
        allDay: true, // set to true to display the event as an all day event on the day view
      };
      var event = _.clone(eventModel);
      event.startsAt = moment(date, 'DD-MM-YYYY')._d;
      events.push(event);
    }

    function setNextIrrigation(item, type) {
      var next;
      switch (type) {
        case 2: //A4000
          if (item.start <= 24 * 60) {
            //Checking if program is sequential.
            var value = _.reduce(
              item.subprograms,
              (result, value, key) => {
                var sectors = _.pickBy(value, function (item, k) {
                  return _.startsWith(k, 'sector');
                });
                var serial = _.values(sectors).join('');
                if (parseInt(serial) > 0) {
                  result.push(value.value);
                }
                return result;
              },
              []
            ).join('');
            if (Number(value) === 0) {
              next = 'sensors.noactive';
              item.inactive = true;
            } else {
              switch (item.type) {
                case 0: //Hours
                  var days = _.values(
                    _.pick(item, 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday')
                  );
                  var today = moment().weekday();
                  var nextDay = null;
                  for (var i = today; i < 7; i++) {
                    if (days[i]) {
                      nextDay = i;
                      i = 10;
                    }
                  }
                  var next2;
                  if (nextDay === null) {
                    next = 'sensors.noactive';
                    item.inactive = true;
                  } else if (nextDay === today && checkStartsToday(item)) {
                    next = 'general.pf14 ';
                    next2 = getTimeFromMins(item.start).format('HH:mm');
                  } else if (nextDay === today + 1) {
                    next = 'general.pf15 ';
                    next2 = getTimeFromMins(item.start).format('HH:mm');
                  } else {
                    var nextD = nextDay + 1;
                    for (var i = nextD; i < 7; i++) {
                      if (days[i]) {
                        nextDay = i;
                        i = 10;
                      }
                    }
                    next =
                      moment()
                        .day(nextDay + 1)
                        .format('ddd DD') +
                      ' ' +
                      getTimeFromMins(item.start).format('HH:mm');
                  }
                  break;
                case 1: //Frequency
                  if (item.xFreqDays === 0 && checkStartsToday(item)) {
                    next = 'general.pf14 ';
                    next2 = getTimeFromMins(item.start).format('HH:mm');
                  } else if (item.xFreqDays === 1 || (item.xFreqDays === 0 && !checkStartsToday(item))) {
                    next = 'general.pf15 ';
                    next2 = getTimeFromMins(item.start).format('HH:mm');
                  } else {
                    next =
                      moment().add(item.xFreqDays, 'days').format('ddd DD') +
                      ' ' +
                      getTimeFromMins(item.start).format('HH:mm');
                  }
                  break;
                case 2: //Activations
                  //Each activationsFreq minutes
                  if (item.xActivationDays >= 0 && checkStartsToday(item)) {
                    //Today
                    var total = item.start + item.xfreqActivationTime;
                    next = 'general.pf14 ';
                    next2 = getTimeFromMins(total).format('HH:mm');
                  } else {
                    next = 'general.pf15 ';
                    next2 = getTimeFromMins(item.start).format('HH:mm');
                  }
                  break;
              }
            }
          } else {
            //Sequential
            var seq = item.start % 60;
            next = 'Sec P' + seq;
            item.sequential = true;
          }
          item.next = next;
          item.next2 = next2;
          break;
        case 3: //A2500
          switch (item.type) {
            case 0: //Horario
              var days = _.values(
                _.pick(item, 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday')
              );
              var today = moment().weekday();
              var nextDay = null;
              for (var i = today; i < 7; i++) {
                if (days[i]) {
                  nextDay = i;
                  i = 10;
                }
              }
              var next2;
              if (nextDay === null || item.value === 0) {
                next = 'sensors.noactive';
                item.inactive = true;
              } else if (nextDay === today && checkStartsToday(item)) {
                next = 'general.pf14 ';
                next2 = getTimeFromMins(item.start).format('HH:mm');
              } else if (nextDay === today + 1 && nextDay === today && !checkStartsToday(item)) {
                next = 'general.pf15 ';
                next2 = getTimeFromMins(item.start).format('HH:mm');
              } else {
                // next = moment().day(nextDay+1).format("ddd DD") + " " + getTimeFromMins(item.start).format("HH:mm");
                next = '';
                item.inactive = true;
              }
              break;
            case 1: //Secuencial
              var seq = item.start;
              if (seq === 0) {
                next = 'sensors.noactive';
                item.inactive = true;
              } else {
                next = 'Sec P' + seq;
              }
              item.sequential = true;
              break;
            case 2: //Condicionante
              next = 'Cond./Entrada';
              break;
          }
          item.next = next;
          item.next2 = next2;
          break;
      }
    }

    function filterSubPrograms(program) {
      var activeTemp = [];
      var temp = program.subprograms;
      var factor = program.manualFactor;

      _.forEach(temp, function (item) {
        var fertilizers = _.pickBy(item, function (value, key) {
          return _.startsWith(key, 'fertilizer');
        });

        item.ferilizerValues = _.values(fertilizers);

        var sectors = _.pickBy(item, function (value, key) {
          return _.startsWith(key, 'sector');
        });
        var serial = _.values(sectors).join('');
        var serial2 = _.values(sectors).join(' ').replace(/[0]/g, '');
        if (parseInt(serial) > 0) {
          item.parsedSectors = _.values(sectors); //serial2;

          //setTimePrediction(item,_.values(sectors),program);

          if (program.irrigation) {
            if (Number(item.pk.id) === program.xSubprogramCourse + 1) {
              item.current = true;
            } else if (Number(item.pk.id) > program.xSubprogramCourse + 1) {
              item.pending = true;
            } else if (Number(item.pk.id) < program.xSubprogramCourse + 1) {
              item.done = true;
            }
          }

          activeTemp.push(item);
        }
      });

      return activeTemp;
    }

    function setTimePrediction(value, sectors, program) {
      var unity = value.unit;
      var factor = program.manualFactor;
      value.totalTime = null;

      switch (unity) {
        case 0:
          value.totalTime = value.value;
          if (_.isNumber(program.totalDuration)) {
            program.totalDuration = program.totalDuration + Number(value.totalTime);
          }
          break;
        case 1:
          value.totalTime = value.value / 60;
          if (_.isNumber(program.totalDuration)) {
            program.totalDuration = program.totalDuration + Number(value.totalTime);
          }
          break;
        case 2: // Volume in m3
          var flow = 0;
          var prom = [];
          _.forEach(sectors, function (obj) {
            if (obj !== 0) {
              prom.push(
                sectorFactory.one(value.pk.deviceId, obj).then(function (data) {
                  if (data.plannedFlow === 0) {
                    //liters/hour
                    flow = 0;
                  } else {
                    flow = flow + data.plannedFlow;
                  }
                })
              );
            }
          });
          $q.all(prom).then(function () {
            if (flow === 0) {
              value.totalTime = null;
            } else {
              value.totalTime = (value.value * 1000) / flow;
              value.totalTime = parseInt(value.totalTime.toFixed(1));
              if (factor > 0 || factor < 0) {
                value.totalTime = value.totalTime + factor / 100;
                if (value.totalTime < 0) {
                  value.totalTime = 0;
                }
              }
            }
            if (_.isNumber(program.totalDuration)) {
              program.totalDuration = program.totalDuration + Number(value.totalTime);
            }
          });

          break;
        case 4: // Volume in liters
          var flow = 0;
          var prom = [];
          _.forEach(sectors, function (obj) {
            if (obj !== 0) {
              prom.push(
                sectorFactory.one(value.pk.deviceId, obj).then(function (data) {
                  if (data.plannedFlow === 0) {
                    //liters/hour
                    flow = 0;
                  } else {
                    flow = flow + data.plannedFlow;
                  }
                })
              );
            }
          });
          $q.all(prom).then(function () {
            if (flow === 0) {
              value.totalTime = null;
            } else {
              value.totalTime = (value.value * 1000) / flow;
              if (factor > 0 || factor < 0) {
                value.totalTime = value.totalTime + factor / 100;
                value.totalTime = parseInt(value.totalTime.toFixed(1));
                if (value.totalTime < 0) {
                  value.totalTime = 0;
                }
              }
            }
            if (_.isNumber(program.totalDuration)) {
              program.totalDuration = program.totalDuration + Number(value.totalTime);
            }
          });

          break;
        case 16: //Volume in m3/hour
          var flow = 0;
          var area = 0; //Ha
          var prom = [];
          _.forEach(sectors, function (obj) {
            if (obj !== 0) {
              prom.push(
                sectorFactory.one(value.pk.deviceId, obj).then(function (data) {
                  if (data.plannedFlow === 0 || data.area === 0) {
                    //liters/hour
                    flow = 0;
                  } else {
                    flow = flow + data.plannedFlow;
                    area = area + data.area;
                  }
                })
              );
            }
          });
          $q.all(prom).then(function () {
            if (flow === 0) {
              value.totalTime = null;
            } else {
              value.totalTime = (value.value * 1000) / (flow * (area / 10000));
              if (factor > 0 || factor < 0) {
                value.totalTime = value.totalTime + factor / 100;
                value.totalTime = parseInt(value.totalTime.toFixed(1));
                if (value.totalTime < 0) {
                  value.totalTime = 0;
                }
              }
            }
            if (_.isNumber(program.totalDuration)) {
              program.totalDuration = program.totalDuration + Number(value.totalTime);
            }
          });
          break;
      }
    }

    function checkIrrigation(program, type) {
      switch (type) {
        case 2: //A4000
          var EstatPrograma4Actiu = 0x0001,
            EstatPrograma4ParoCond1 = 0x0008,
            EstatPrograma4ParoCond2 = 0x0010,
            EstatPrograma4ParoFiltres = 0x0020,
            EstatPrograma4ParoDiesel = 0x0040;

          var A4_ProgInicioManual = 0x0010,
            A4_ProgInicioEntrada = 0x0020,
            A4_ProgInicioSMS = 0x0040,
            A4_ProgInicioSecuencial = 0x0080,
            A4_ProgInicioCondicionante = 0x0100,
            A4_ProgInicioHorario = 0x0200,
            A4_ProgInicioIndefinido = 0x0400;

          var estat = program.xState;
          var motiuIni = program.xStartState;
          var tempsSegur = program.xSecurityTime;
          program.irrigation = false;
          program.substate = 'cond.stop';
          program._state = 'noirrigation';
          if (
            (estat & EstatPrograma4Actiu) === 1 &&
            !(estat & EstatPrograma4ParoCond1) &&
            !(estat & EstatPrograma4ParoCond2) &&
            !(estat & EstatPrograma4ParoFiltres) &&
            !(estat & EstatPrograma4ParoDiesel)
          ) {
            program.irrigation = true;
          }

          if (program.irrigation) {
            program._state = 'irrigation';
            if (motiuIni & A4_ProgInicioManual) program.substate = 'general.pf1 '; //resultat =  1;		// inici manual
            else if (motiuIni & A4_ProgInicioEntrada)
              program.substate = 'general.pf2 '; //resultat =  2;		// inici per entrada
            else if (motiuIni & A4_ProgInicioSMS) program.substate = 'general.pf3 '; //resultat =  3;		// inici per SMS
            else if (motiuIni & A4_ProgInicioSecuencial)
              program.substate = 'general.pf4 '; //resultat =  4;		// inici per sequencial
            else if (motiuIni & A4_ProgInicioCondicionante)
              program.substate = 'general.pf5 '; //resultat =  5;		// inici per condicionant
            else if (motiuIni & A4_ProgInicioHorario)
              program.substate = 'general.pf6 '; //resultat =  6;		// inici per horari
            else program.substate = 'general.irrigating'; //resultat =  17;	    // inici indefinit
          } else {
            program._state = 'out';

            if (estat & 0x0080) {
              program.substate = 'manual.outof';
              program.out = true;
            } //resultat =  7; 	// Fora de servei
            else if (estat & 0x0018) {
              program.substate = 'io.func4';
              program.out = true;
            } //resultat =  8;		// Paro Condicional ( ent. digital o  condicionant)
            else if (estat & 0x0040) {
              program.substate = 'general.pf7 ';
              program.out = true;
            } //resultat =  9;		// Paro per arranc diesel
            else if (estat & 0x0100) {
              program.substate = 'events.IDS_18';
              program.out = true;
            } //resultat =  10;	// Solape de fertilizante
            else if (estat & 0x0020) {
              program.substate = 'general.pf8 ';
              program.out = true;
            } //resultat =  11;	// Paro per filtres
            else if (estat & 0x0800) program.substate = 'general.pf9 '; //resultat =  12;	// En espera per prioritat
            else if (tempsSegur) program.substate = 'general.pf10 '; //resultat =  13;	// Temps de seguretat

            if (!estat) {
              program._state = 'unactive';
              if ((motiuIni & 0x0004) !== 0x0004)
                program.substate = 'general.pf11 '; //$resultat =  16;	// Periode no actiu
              else if ((motiuIni & 0x0002) !== 0x0002)
                program.substate = 'general.pf12 '; //	$resultat =  15;	// Horari no actiu
              else if ((motiuIni & 0x0001) !== 0x0001) program.substate = 'general.pf13 '; //$resultat =  14;	// Dia no actiu
            }
          }
          break;
        case 3: //A2500
          var A25_ProgInicioHorario = 0;
          var A25_ProgInicioSecuencial = 1;
          var A25_ProgInicioCondicionante = 2;
          var A25_ProgInicioManual = 3;

          var A25_ProgRegando = 1;
          var A25_ProgEsperaFiSequencial = 2;
          var A25_ProgEsperaActivaciones = 3;
          var A25_ProgSuspendido = 4;
          var A25_ProgAplazadoInicio = 5;
          var A25_ProgAplazadoRiego = 6;
          var A25_ProgParoDefinitivo = 7;
          var A25_ProgFueraServicio = 8;

          var estat = program.xState;
          var motiuIni = program.xStartMotive;
          var motiuAjor = program.xDelayedMotive;
          var inicialtern = program.xFirstSeq;

          program.irrigation = false;
          program.substate = 'cond.stop';
          program._state = 'noirrigation';

          if (estat === A25_ProgRegando) {
            program.irrigation = true;
            program._state = 'irrigation';
            switch (motiuIni) {
              case A25_ProgInicioHorario:
                program.substate = 'general.pf6';
                break;
              case A25_ProgInicioSecuencial:
                program.substate = 'general.pf4';
                break;
              case A25_ProgInicioCondicionante:
                program.substate = 'general.pf5';
                break;
              case A25_ProgInicioManual:
                program.substate = 'general.pf1';
                break;
            }
          } else {
            switch (estat) {
              case A25_ProgParoDefinitivo:
                program.substate = 'manual.definitiveStop';
                program._state = 'out';
                program.out = true;
                break;
              case A25_ProgFueraServicio:
                program.substate = 'manual.outof';
                program.out = true;
                break;
              case A25_ProgEsperaActivaciones:
                program.substate = 'general.pf18';
                break;
              case A25_ProgEsperaFiSequencial: // És prog. sequencial, Espera final del prog. anterior,, al 2500 tampoc surt text
                program.substate = 'cond.stop';
                break;
              case A25_ProgSuspendido:
                program.substate = 'general.pf19';
                program._state = 'out';
                program.out = true;
                break;
              case A25_ProgAplazadoInicio:
              case A25_ProgAplazadoRiego:
                program.out = true;
                switch (motiuAjor) {
                  case 0: // Ajornat x filtres
                    program.substate = 'general.pf30';
                    break;
                  case 1: // Ajornat x fertilitzants
                    program.substate = 'general.pf21';
                    break;
                  case 2: // Ajornat x max. sectors actius
                    program.substate = 'general.pf31';
                    break;
                  case 3: // Ajornat x temps entre inicis
                    program.substate = 'general.pf22';
                    break;
                  case 4: // Ajornat x STOP
                    program.substate = 'general.pf23';
                    break;
                  case 5: // Ajornat paro condicional
                    program.substate = 'general.pf24';
                    break;
                  case 6: // Ajornat x sector ya activo
                    program.substate = 'general.pf25';
                    break;
                  case 7: // Ajornat x sector manual
                    program.substate = 'general.pf26';
                    break;
                  case 8: // Ajornat x paro definitiu
                    program.substate = 'general.pf27';
                    break;
                  case 9: // Ajornat x Horari actiu
                    program.substate = 'general.pf28';
                    break;
                  case 10: // Ajornat x sequencial actiu
                    program.substate = 'general.pf29';
                    break;
                  case 11: // Ajornat x diesel
                    program.substate = 'general.pf32';
                    break;
                  case 12: // Ajornat x prioritat
                    program.substate = 'general.pf33';
                    break;
                  default:
                    program.substate = '-';
                    break;
                }
                break;
            }
          }
          break;
        case 4: //ABIT
          var ABit_ProgInicioHorario = 0;
          var ABit_ProgInicioSecuencial = 1;
          var ABit_ProgInicioCondicionante = 2;
          var ABit_ProgInicioManual = 3;

          var ABit_ProgRegando = 1; // programa normal: regant (=sectors oberts)
          var ABit_ProgEsperaFiSequencial = 2; // inici seqüèncial, espera a que acabi el programa per iniciar
          var ABit_ProgEsperaActivaciones = 3; // espera parat les activacions pendents
          var ABit_ProgSuspendido = 4; // no pot regar durant un nº determinat d'hores
          var ABit_ProgAplazadoInicio = 5; // està ajornat en espera de poder entrar en curs
          var ABit_ProgAplazadoRiego = 6; // estava regant i s'ha ajornat el reg (abans paro condicional)
          var ABit_ProgParoDefinitivo = 7; // en paro definitiu
          var ABit_ProgFueraServicio = 8; // en fora de servei
          var ABit_ProgSecuencialFinal = 9; // està actiu i hi ha un programa enllaçat amb ell

          var estat = program.xState;
          var motiuIni = program.xStartMotive;
          var motiuAjor = program.xDelayedMotive;
          var inicialtern = program.xFirstSeq;

          program.irrigation = false;
          program.substate = 'cond.stop';
          program._state = 'noirrigation';

          if (estat === ABit_ProgRegando || estat === ABit_ProgSecuencialFinal) {
            // Prog. Actiu
            program.irrigation = true;
            program._state = 'irrigation';
            switch (motiuIni) {
              case ABit_ProgInicioHorario:
                program.substate = 'general.pf6';
                break;
              case ABit_ProgInicioSecuencial:
                program.substate = 'general.pf4';
                break;
              case ABit_ProgInicioCondicionante:
                program.substate = 'general.pf5';
                break;
              case ABit_ProgInicioManual:
                program.substate = 'general.pf1';
                break;
            }
          } else {
            switch (estat) {
              case ABit_ProgParoDefinitivo:
                program.substate = 'manual.definitiveStop';
                program._state = 'out';
                program.out = true;
                break;
              case ABit_ProgFueraServicio:
                program.substate = 'manual.outof';
                program.out = true;
                break;
              case ABit_ProgEsperaActivaciones:
                program.substate = 'general.pf18';
                break;
              case ABit_ProgEsperaFiSequencial:
                program.substate = '-';
                break;
              case ABit_ProgSuspendido:
                program.substate = 'general.pf19';
                program._state = 'out';
                program.out = true;
                break;
              case ABit_ProgAplazadoInicio:
              case ABit_ProgAplazadoRiego:
                program.out = true;
                switch (motiuAjor) {
                  case 0: // Ajornat x grup de reg
                    program.substate = 'general.pf20';
                    break;
                  case 1: // Ajornat x fertilitzants
                    program.substate = 'general.pf21';
                    break;
                  case 2: // Ajornat x temps entre inicis
                    program.substate = 'general.pf22';
                    break;
                  case 3: // Ajornat x STOP
                    program.substate = 'general.pf23';
                    break;
                  case 4: // Ajornat paro condicional
                    program.substate = 'general.pf24';
                    break;
                  case 5: // Ajornat x sector ya activo
                    program.substate = 'general.pf25';
                    break;
                  case 6: // Ajornat x sector manual
                    program.substate = 'general.pf26';
                    break;
                  case 7: // Ajornat x paro definitiu
                    program.substate = 'general.pf27';
                    break;
                  case 8: // Ajornat x Horari actiu
                    program.substate = 'general.pf28';
                    break;
                  case 9: // Ajornat x sequencial actiu
                    program.substate = 'general.pf29';
                    break;
                  default:
                    program.substate = '-';
                    break;
                }
                break;
            }
          }
          break;
        case 5:
          var EstatPrograma7Actiu = 0x01; // Programa regant
          var EstatPrograma7HorariActiu = 0x02; // Programas dins del Horari actiu
          var EstatPrograma7DiaActiu = 0x04; // Programa en Dia actiu
          var EstatPrograma7PeriodeActiu = 0x08; // Programa dins del Periode actiu
          var EstatPrograma7PostReg = 0x10; // Programa fent PostReg
          var EstatPrograma7Manual = 0x20; // Programa Iniciat per manual
          var EstatPrograma7Seguretat = 0x40; // Programa fent reg de seguretat
          var EstatPrograma7Ajornat = 0x80; // Programa ajornat

          var estat = program.xState;
          let motiuajornament = program.delayMotive;
          let tempssegur = program.xConTime;

          program.irrigation = false;
          program.substate = 'cond.stop';
          program._state = 'noirrigation';
          if (estat & EstatPrograma7Ajornat) {
            program.out = true;
            program.substate = null;
            switch (motiuajornament) {
              case 0: // programa en paro condicional
                program.substate = 'general.pf24';
                break;
              case 1: // fent neteja de filtres
                program.substate = 'general.pf30';
                break;
              case 2: // fent pre-agitació de fertilitzant
                program.substate = 'general.pf35';
                break;
              case 3: // posicionant motoritzades de mescla d'aigues
                program.substate = 'general.pf34';
                break;
              case 4: // programa en fora de servei
                program.substate = 'programs.out';
                break;
              case 5: // paro de sistema (STOP)
                program.substate = 'general.pf23';
                break;
              case 6: // fora de servei general
                program.substate = 'programs.out';
                break;
              case 7: // alarma de pH baix
                program.substate = 'general.pf36';
                break;
              case 8: // alarma de CE
                program.substate = 'general.pf37';
                break;
              case 9: // avaria general o de cabal
                program.substate = 'alerts.generalMalfunction';
                break;
              case 10: // per prioritat
                program.substate = 'general.pf33';
                break;
              default: // -
                program.substate = '-';
                break;
            }
          } else {
            if (estat & EstatPrograma7Actiu) {
              program.irrigation = true;
              program._state = 'irrigation';
              program.substate = null;
              if (estat & EstatPrograma7PostReg); // Realiza postriego
              if (estat & EstatPrograma7Manual) program.substate = 'general.pf1'; // Inicio manual
              else if (estat & EstatPrograma7Seguretat)
                program.substate = 'general.pf38'; // Realiza riego de seguridad
              else; // Regando
            } else {
            }
          }
          break;
        case 6:
          var estat = program.xState;
          var motiuIni = program.xStartMotive;
          var motiuAjor = program.xDelayedMotive;
          var inicialtern = program.xFirstSeq;
          if (estat == 1 || estat == 9) {
            // Prog. Actiu
            program.irrigation = true;
            program._state = 'irrigation';
            switch (motiuIni) {
              case 0:
                program.substate = 'general.pf6';
                break; //Horari
              case 1:
                program.substate = 'general.pf4';
                break; //Sequencial
              case 2:
                program.substate = 'general.pf5';
                break; //Condicionant
              case 3:
                program.substate = 'general.pf1';
                break; //Manual
            }
          } else {
            switch (estat) {
              case 7: //A55_ProgParoDefinitivo
                program.substate = 'manual.definitiveStop';
                program._state = 'out';
                program.out = true;
                break;
              case 8: //A55_ProgFueraServicio
                program.substate = 'manual.outof';
                program.out = true;
                break;

              case 3: //A55_ProgEsperaActivaciones
                program.substate = 'general.pf18';
                break;

              case 2: //A55_ProgEsperaFiSequencial
                program.substate = '-';
                break;

              case 4: //A55_ProgSuspendido
                program.substate = 'general.pf19';
                program._state = 'out';
                program.out = true;
                break;
              case 5: //A55_ProgAplazadoInicio
              case 6: //A55_ProgAplazadoRiego
                // Aqui mostrarem el motiu de l'ajornament,, es a dir, es com si tinguessim un sol enumerat
                program.out = true;
                switch (motiuAjor) {
                  case 0 /* aplazado por limpieza de filtros */:
                    program.substate = 'general.pf30';
                    break;
                  case 1 /* aplazado por solape de fertilizante */:
                    program.substate = 'general.pf21';
                    break;
                  case 2 /* aplazado por prioridad */:
                    program.substate = 'general.pf33';
                    break;
                  case 3 /* aplazado por tiempo entre inicios */:
                    program.substate = 'general.pf22';
                    break;
                  /*Próximo inicio en */
                  //us += FormatoHM(ADOTPrograma55->FieldByName("XTiempoSeguridad")->AsInteger, true);
                  case 4 /* aplazado por equipo en STOP */:
                    program.substate = 'general.pf23';
                    break;
                  case 5 /* aplazado por paro condicional */:
                    program.substate = 'general.pf24';
                    break;
                  case 6 /* aplazado por sector ya activo */:
                    program.substate = 'general.pf25';
                    break;
                  case 7 /* aplazado por sector en manual*/:
                    program.substate = 'general.pf26';
                    break;
                  case 8 /* aplazado por paro definitivo */:
                    program.substate = 'general.pf27';
                    break;
                  case 9 /* aplazado por horario activo */:
                    program.substate = 'general.pf28';
                    break;
                  case 10 /* aplazado por secuencial activo */:
                    program.substate = 'general.pf29';
                    break;
                  case 11 /* aplazado por mezcla de aguas */:
                    program.substate = 'general.pf34';
                    break;
                  case 12 /* aplazado por arranque diesel */:
                    program.substate = 'general.pf32';
                    break;
                  case 13 /* aplazado por preagitación */:
                    program.substate = 'general.pf35';
                    break;
                  default:
                    program.substate = '-';
                    break;
                }
                break;
              default:
                break;
            }
          }
          break;
        case 7:
          //ESTATS PROGRAMES
          var A45_prog_parat = 0; // parat
          var A45_prog_regant = 1; // programa normal: regant (=sectors oberts)
          var A45_prog_espera_fi = 2; // inici seqüèncial, espera a que acabi el programa per iniciar
          var A45_prog_espera_act = 3; // espera parat les activacions pendents
          var A45_prog_suspes = 4; // no pot regar durant un nº determinat d'hores
          var A45_prog_ajornat_inici = 5; // està ajornat en espera de poder entrar en curs
          var A45_prog_ajornat_ini_subprog = 6; // està ajornat en espera de poder entrar en curs a l'inici del subprograma o agrup.
          var A45_prog_ajornat_reg = 7; // estava regant i s'ha ajornat el reg (abans paro condicional)
          var A45_prog_paro_def = 8; // en paro definitiu
          var A45_prog_fora_servei = 9; // en fora de servei
          var A45_prog_seq_fi = 10; // està actiu i hi ha un programa enllaçat amb ell, espera per parar
          //var A45_prog_final = 11;

          //MOTIU AJORNAT
          var A45_prog_ajor_filt = 0; // ajornat per filtres
          var A45_prog_ajor_fert = 1; // ajornat per fertilització
          var A45_prog_ajor_prioritat = 2; // ajornat per prioritat
          var A45_prog_ajor_temps = 3; // ajornat per temps entre inicis
          var A45_prog_ajor_stop = 4; // ajornat per stop
          var A45_prog_ajor_paro_cond = 5; // ajornat per paro condicional
          var A45_prog_ajor_sect = 6; // ajornat per sector activat
          var A45_prog_ajor_sect_manual = 7; // ajornat per que hi ha un sector en manual
          var A45_prog_ajor_paro_def = 8; // ajornat per paro definitiu
          var A45_prog_ajor_horari_actiu = 9; // ajornat per fi d'horari actiu
          var A45_prog_ajor_seq_actiu = 10; // ajornat per seqüencial actiu
          var A45_prog_ajor_mescla = 11; // ajornat per mescla d'aigues
          var A45_prog_ajor_stop_prog = 12; // ajornat per stop de programa
          var A45_prog_ajor_diesel = 13; // ajornat mentre arrenca el diésel (no es fa registre)
          var A45_prog_ajor_preagit = 14; // ajornat mentre fa la pre-agitacio (no es fa registre)
          var A45_prog_ajor_solar = 15; // ajornat a l'espera que el control del reg solar el faci entrar
          var A45_prog_ajor_solar_escalat = 16; // ajornat pel control del reg solar esperant retard escalat de sectors
          //var A45_prog_ajor_final = 17;

          //MOTIU INICI
          var A45_prog_iniciat_horari = 0; // per horari
          var A45_prog_iniciat_manual = 1; // manualment
          var A45_prog_iniciat_cond = 2; // per un condicionant
          var A45_prog_iniciat_sequencial = 3; // seqüencial d'un altre programa
          var A45_prog_iniciat_seguretat = 4; // iniciat perquè ha passat el temps de seguretat
          //var A45_prog_iniciat_final = 5;

          var estat = program.xState;
          var motiuIni = program.xStartMotive;
          var motiuAjor = program.xDelayedMotive;

          program.irrigation = false;
          program.substate = 'cond.stop';
          program._state = 'noirrigation';

          if (estat === A45_prog_regant) {
            program.irrigation = true;
            program._state = 'irrigation';
            switch (motiuIni) {
              case A45_prog_iniciat_horari:
                program.substate = 'general.pf6';
                break;
              case A45_prog_iniciat_sequencial:
                program.substate = 'general.pf4';
                break;
              case A45_prog_iniciat_cond:
                program.substate = 'general.pf5';
                break;
              case A45_prog_iniciat_manual:
                program.substate = 'general.pf1';
                break;
              case A45_prog_iniciat_seguretat:
                program.substate = 'general.pf38';
                break;
            }
          } else {
            switch (estat) {
              case A45_prog_paro_def:
                program.substate = 'manual.definitiveStop';
                program._state = 'out';
                program.out = true;
                break;
              case A45_prog_fora_servei:
                program.substate = 'manual.outof';
                program.out = true;
                break;
              case A45_prog_espera_act:
                program.substate = 'general.onhold';
                break;
              case A45_prog_espera_fi:
                program.substate = 'general.onhold';
                break;
              case A45_prog_seq_fi:
                program.substate = 'general.onhold';
                break;
              case A45_prog_suspes:
                program.substate = 'general.pf19';
                program._state = 'out';
                program.out = true;
                break;
              case A45_prog_parat:
                program.substate = 'cond.stop';
                program._state = 'out';
                program.out = true;
                break;

              case A45_prog_ajornat_inici:
              case A45_prog_ajornat_ini_subprog:
              case A45_prog_ajornat_reg:
                program.out = true;
                switch (motiuAjor) {
                  case A45_prog_ajor_filt: // Ajornat x filtres
                    program.substate = 'general.pf30';
                    break;
                  case A45_prog_ajor_fert: // Ajornat x fertilitzants
                    program.substate = 'general.pf21';
                    break;
                  case A45_prog_ajor_mescla: // Ajornat x mezcla de aguas
                    program.substate = 'general.pf34';
                    break;
                  case A45_prog_ajor_temps: // Ajornat x temps entre inicis
                    program.substate = 'general.pf22';
                    break;
                  case A45_prog_ajor_stop: // Ajornat x STOP
                    program.substate = 'general.pf23';
                    break;
                  case A45_prog_ajor_paro_cond: // Ajornat paro condicional
                    program.substate = 'general.pf24';
                    break;
                  case A45_prog_ajor_sect: // Ajornat x sector ya activo
                    program.substate = 'general.pf25';
                    break;
                  case A45_prog_ajor_sect_manual: // Ajornat x sector manual
                    program.substate = 'general.pf26';
                    break;
                  case A45_prog_ajor_paro_def: // Ajornat x paro definitiu
                    program.substate = 'general.pf27';
                    break;
                  case A45_prog_ajor_horari_actiu: // Ajornat x Horari actiu
                    program.substate = 'general.pf28';
                    break;
                  case A45_prog_ajor_seq_actiu: // Ajornat x sequencial actiu
                    program.substate = 'general.pf29';
                    break;
                  case A45_prog_ajor_diesel: // Ajornat x diesel
                    program.substate = 'general.pf32';
                    break;
                  case A45_prog_ajor_prioritat: // Ajornat x prioritat
                    program.substate = 'general.pf33';
                    break;
                  case A45_prog_ajor_stop_prog: // ajornat per stop de programa
                    program.substate = 'general.pf39';
                    break;
                  case A45_prog_ajor_preagit: // ajornat mentre fa la pre-agitacio (no es fa registre)
                    program.substate = 'general.pf35';
                    break;
                  case A45_prog_ajor_solar: // ajornat a l'espera que el control del reg solar el faci entrar
                    program.substate = 'general.pf40';
                    break;
                  case A45_prog_ajor_solar_escalat: // ajornat pel control del reg solar esperant retard escalat de sectors
                    program.substate = 'general.pf40';
                    break;
                  default:
                    program.substate = '-';
                    break;
                }
                break;
            }
          }
          break;
      }
    }

    function getTimeFromMins(mins) {
      // do not include the first validation check if you want, for example,
      // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      if (mins >= 24 * 60 || mins < 0) {
        //throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        return moment(new Date()).hours(0).minutes(0);
      }
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return moment(new Date()).hours(h).minutes(m);
    }

    function formatProgramView(program) {
      var irrigationHour;
      if (program.start > 1440) {
        irrigationHour = 'Sec';
      } else {
        irrigationHour = getTimeFromMins(program.start);
        if (irrigationHour.hours() === 0 && irrigationHour.minutes() === 0) {
          //                irrigationHour = "-"
          program.irrigationHour = irrigationHour.format('HH:mm');
        } else {
          program.irrigationHour = irrigationHour.format('HH:mm');
        }
      }

      switch (program.type) {
        case 0:
          var actHStart = getTimeFromMins(program.startActivationHour),
            actHEnd = getTimeFromMins(program.endActivationHour);
          program.actHStart = actHStart;
          program.actHEnd = actHEnd;

          break;
        case 1:
          var nextIrrigation;

          break;

        case 2:
          var nextIrrigation;
          break;
      }
      return program;
    }
  }
})();
