import { GraphicsFilter } from '../utils/graphics';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('graphicsEditController', graphicsEditController);

  graphicsEditController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'graphicsFactory',
    'UserData',
    'sensorsFactory',
    'calculatedsFactory',
    '$timeout',
    'sectorFactory',
    '$filter',
  ];

  function graphicsEditController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    graphicsFactory,
    UserData,
    sensorsFactory,
    calculatedsFactory,
    $timeout,
    sectorFactory,
    $filter
  ) {
    var graphic_backup, device_backup, sensor_backup, i;
    var vm = this;
    var deviceId;
    var sensorByUnitAndType = [];
    vm.graphic;
    vm.enabletype = false;
    vm.sensorsByElement = [];
    vm.parent = 'graphics';
    var sensorArray = ['1', '2', '3', '4', '7', '10'];

    vm.elementsArray = GraphicsFilter.irrigationControlGraphic;

    vm.graphicTypeArray = [
      { id: 'column', name: 'graphics.g12', icon: 'column-icon' },
      { id: 'line', name: 'graphics.g13', icon: 'line-icon' },
      { id: 'areaspline', name: 'graphics.g14', icon: 'area-icon' },
      { id: 'scatter', name: 'graphics.g15', icon: 'point-icon' },
    ];

    var colorArray = [
      'D50000',
      'C51162',
      'AA00FF',
      '6200EA',
      '304FFE',
      '2962FF',
      '0091EA',
      '00B8D4',
      '00BFA5',
      '00C853',
      '64DD17',
      'AEEA00',
      'FFD600',
      'FFAB00',
      'FF6D00',
      'DD2C00',
      '3E2723',
      '212121',
      '263238',
      '000000',
    ];

    activate();

    function activate() {
      if ($state.$current.name === 'agroclimaticGraphics.edit') {
        vm.parent = 'agroclimaticGraphics';
        vm.elementsArray = GraphicsFilter.climaPlantaSolGraphic;
      } else {
        vm.isFromAgroclimatic = 'parent';
      }

      vm.groupBy = [
        { text: '10 ' + $filter('translate')('history.minutes'), value: '0' },
        { text: $filter('translate')('history.hour'), value: '1' },
        { text: $filter('translate')('history.day'), value: '2' },
        { text: $filter('translate')('history.week'), value: '3' },
        { text: $filter('translate')('history.month'), value: '4' },
      ];

      if ($state.params.unitList !== null) {
        vm.newElement = newElement;
        vm.newGraphic = newGraphic;
        vm.toogle = toogle;
        vm.cancel = cancel;
        vm.save = save;
        vm.deleteElement = deleteElement;
        vm.selectType = selectType;
        vm.selectName = selectName;
        vm.showDevice = showDevice;
        vm.initColor = initColor;
        vm.changeForm = changeForm;

        if ($state.params.graphic !== null) {
          vm.graphic = $state.params.graphic;
          vm.units = $state.params.unitList;

          for (i = 0; i < vm.graphic.graphicElements.length; i++) {
            selectType(vm.graphic.graphicElements[i], i);
          }

          vm.selected = 0;
          // if (!vm.graphic.backup) {
          backup();
          // }
        } else {
          vm.units = $state.params.unitList;
          vm.graphic = { name: '', graphicElements: [], shared: null, sharingLvl: null };
          vm.newForm = true;
          newElement(false);
          //newGraphic();
        }
      } else {
        document.querySelector('vegga-overlay').dismiss();
        $state.go(vm.parent);
      }
    }

    function toogle(elem) {
      if (elem.selected === false || elem.selected === null || elem.selected === undefined) elem.selected = true;
      else if (elem.selected === true) elem.selected = false;
    }

    function changeConfiguration(elem) {
      if (elem.type === 1) elem.graphicType = 'line';
      else if (elem.type === 2) elem.graphicType = 'line';
      else if (elem.type === 3) elem.graphicType = 'column';
      else if (elem.type === 4) elem.graphicType = 'column';
      else if (elem.type === 5) elem.graphicType = 'line';
      else if (elem.type === 6) elem.graphicType = 'column';
      else if (elem.type === 7) elem.graphicType = 'column';
      else if (elem.type === 8) elem.graphicType = 'line';
      else if (elem.type === 9) elem.graphicType = 'line';
      else if (elem.type === 10) elem.graphicType = 'column';
    }

    function getMaxId(elements) {
      return _.max(_.map(elements, 'pk.id'));
    }

    function newElement(fireDirtyEvent = true) {
      if (vm.graphic.graphicElements === undefined) {
        graphicsFactory.update(UserData.id, vm.graphic).then(
          (response) => {
            vm.graphic = response.plain();
            let elementId = 1;
            let elementObject = {
              device: null,
              sensor: null,
              type: null,
              deviceName: null,
              sensorName: null,
              colour: null,
              configuration: null,
              fertilizer: null,
              numFertilizer: null,
              graphicType: null,
              type: null,
              unitGraphic: 0,
              group: false,
              showAxisY: true,
            };
            // pk:{graphicId:vm.graphic.pk.id, userId:vm.graphic.pk.userId, id: elementId}
            if (!vm.newForm) {
              elementObject.pk = { graphicId: vm.graphic.pk.id, userId: vm.graphic.pk.userId, id: elementId };
            }
            vm.graphic.graphicElements.push(elementObject);
            vm.form.$setDirty();
          },
          (error) => {}
        );
      } else {
        let elementId;
        if (vm.graphic.graphicElements.length === 0) {
          elementId = 1;
        } else {
          elementId = getMaxId(vm.graphic.graphicElements) + 1;
        }
        let elementObject = {
          device: null,
          sensor: null,
          type: null,
          deviceName: null,
          sensorName: null,
          colour: null,
          configuration: null,
          fertilizer: null,
          numFertilizer: null,
          graphicType: null,
          type: null,
          unitGraphic: 0,
          group: false,
          showAxisY: true,
        };
        // pk:{graphicId:vm.graphic.pk.id, userId:vm.graphic.pk.userId, id: elementId}
        if (!vm.newForm) {
          elementObject.pk = { graphicId: vm.graphic.pk.id, userId: vm.graphic.pk.userId, id: elementId };
        }
        vm.graphic.graphicElements.push(elementObject);
        if (fireDirtyEvent) {
          vm.form.$setDirty();
        }
      }
    }

    function newGraphic() {
      //let graphicObject = {name:"",  graphicElements:[], shared: null, sharingLvl: null}
      graphicsFactory.update(UserData.id, graphicObject).then((response) => {
        vm.graphic = response;
      });
    }

    function save() {
      vm.form.$setPristine();

      if (vm.newForm) {
        let { graphicElements, ...graphic } = vm.graphic;
        graphic.graphicElements = [];

        graphicsFactory.update(UserData.id, graphic).then((response) => {
          graphic = response;
          graphic.graphicElements = vm.graphic.graphicElements;

          graphic.graphicElements.forEach((element, index) => {
            element.pk = { graphicId: graphic.pk.id, userId: graphic.pk.userId, id: index + 1 };
          });

          graphicsFactory.update(graphic.pk.userId, graphic).then(() => {
            $scope.$emit('closeOverlay');
          });
        });
      } else {
        graphicsFactory.update(vm.graphic.pk.userId, vm.graphic).then((response) => {
          vm.graphic = response;
          backup();
        });
      }
    }

    function deleteElement(index) {
      vm.graphic.graphicElements.splice(index, 1);
      vm.form.$setDirty();
    }

    function backupSensorList() {
      sensor_backup = [];
      angular.copy(vm.sensorsByElement, sensor_backup);
    }

    function backup() {
      graphic_backup = {};
      angular.copy(vm.graphic, graphic_backup);
      vm.graphic.backup = true;
    }

    function cancel() {
      angular.copy(graphic_backup, vm.graphic);
      angular.copy(sensor_backup, vm.sensorsByElement);
    }

    function getSensorAnalogs(deviceId, val, index, origin) {
      var params = {
        limit: 100,
        page: 1,
      };
      sensorsFactory.analogsPage(deviceId, params).then((result) => {
        if (result.plain()) {
          sensorByUnitAndType[deviceId + '-' + parseInt(val)] = result.content;
          vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + val];
          if (origin === null) backupSensorList();
        }
      });
    }

    function loadCounters(deviceId, val, index, origin) {
      let unit = _.filter(vm.units, ['device.id', deviceId]);
      if (unit[0].device.type === 2) {
        let unit = _.filter(vm.units, ['device.id', deviceId]);
        sensorsFactory.digitalsactive(deviceId).then((data) => {
          if (unit[0].device.type == 2 && val == 3)
            sensorByUnitAndType[deviceId + '-' + parseInt(val)] = _.filter(data.plain(), (o) => {
              return o.input !== 0 && o.pk.id == 35;
            });
          else
            sensorByUnitAndType[deviceId + '-' + parseInt(val)] = _.filter(data.plain(), (o) => {
              return o.input !== 0 && o.pk.id >= 11 && o.pk.id <= 34;
            });
          vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + val];
          if (origin === null || origin === undefined) backupSensorList();
        });
      } else if (unit[0].device.type === 5) {
        //A7000 comptadors són s.digitals del 1 al 14
        sensorsFactory.digitalsactive(deviceId).then((data) => {
          sensorByUnitAndType[deviceId + '-' + parseInt(val)] = _.filter(data.plain(), (o) => {
            return o.input !== 0 && o.pk.id >= 1 && o.pk.id <= 14;
          });
          vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + val];
          if (origin === null || origin === undefined) backupSensorList();
        });
      } else {
        sensorsFactory.meters(deviceId).then((data) => {
          sensorByUnitAndType[deviceId + '-' + parseInt(val)] = _.filter(data.plain(), (o) => {
            return o.input !== 0;
          });
          vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + val];
          if (origin === null || origin === undefined) backupSensorList();
        });
      }
    }

    function loadSectors(deviceId, val, index, origin) {
      sectorFactory.allSectors(deviceId).then(function (data) {
        sensorByUnitAndType[deviceId + '-' + parseInt(val)] = data.plain();
        vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + val];
        if (origin === null || origin === undefined) backupSensorList();
      });
    }

    function sliceFertilizer(type, elem) {
      if (type === 2) {
        elem.fertilizerArray = _.range(1, 9);
        elem.showFert = true;
      } else if (type === 3) {
        elem.fertilizerArray = _.range(1, 5);
        elem.showFert = true;
      } else if (type === 6) {
        elem.fertilizerArray = _.range(1, 6);
        elem.showFert = true;
      } else if (type === 4) {
        elem.fertilizerArray = [];
        elem.fertilizer = 0;
        elem.numFertilizer = null;
        elem.showFert = false;
      }
    }

    function selectType(elem, index, origin, dev) {
      if (origin === 1) {
        vm.graphic.graphicElements[index].device = null;
        vm.graphic.graphicElements[index].sensor = null;
        showDevice(elem);
        changeConfiguration(elem);
      } else if (origin === 2) {
        vm.graphic.graphicElements[index].sensor = null;
      } else showDevice(elem);

      if (vm.graphic.graphicElements[index].device !== null) deviceId = vm.graphic.graphicElements[index].device;
      else deviceId = null;

      if (elem.device !== null) {
        let device = _.filter(vm.units, (d) => {
          return d.device.id === elem.device;
        });
        if (device[0] !== undefined) {
          elem.deviceName = device[0].device.name;
          sliceFertilizer(device[0].device.type, elem);
        }
      } else {
        elem.deviceName = null;
      }

      if (sensorByUnitAndType[deviceId + '-' + elem.type] !== undefined) {
        vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + elem.type];
      } else {
        switch (elem.type) {
          case 1:
          case 10:
            if (deviceId !== null) getSensorAnalogs(deviceId, elem.type, index, origin);
            break;
          case 2:
            if (deviceId !== null) loadCounters(deviceId, elem.type, index, origin);
            break;
          case 3:
            if (deviceId !== null) loadCounters(deviceId, elem.type, index, origin);
            break;
          case 4:
            if (deviceId !== null) loadSectors(deviceId, elem.type, index, origin);
            break;
          case 5:
            vm.graphic.graphicElements[index].device = null;
            calculatedsFactory.getPDR(UserData.id).then((result) => {
              if (result.plain()) {
                sensorByUnitAndType[deviceId + '-' + parseInt(elem.type)] = result.plain();
                vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + elem.type];
                if (origin === null || origin === undefined) backupSensorList();
              }
            });
            break;
          case 6:
            vm.graphic.graphicElements[index].device = null;

            calculatedsFactory.getETO(UserData.id).then((result) => {
              if (result.plain()) {
                sensorByUnitAndType[deviceId + '-' + parseInt(elem.type)] = result.plain();
                vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + elem.type];
                if (origin === null || origin === undefined) backupSensorList();
              }
            });
            break;
          case 7:
            if (deviceId !== null) loadSectors(deviceId, elem.type, index, origin);
            break;
          case 8:
            vm.graphic.graphicElements[index].device = null;
            calculatedsFactory.getDPV(UserData.id).then((result) => {
              if (result.plain()) {
                sensorByUnitAndType[deviceId + '-' + parseInt(elem.type)] = result.plain();
                vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + elem.type];
                if (origin === null || origin === undefined) backupSensorList();
              }
            });
            break;
          case 9:
            vm.graphic.graphicElements[index].device = null;
            calculatedsFactory.waterDisp(UserData.id).then((result) => {
              sensorByUnitAndType[deviceId + '-' + parseInt(elem.type)] = result.plain();
              vm.sensorsByElement[index] = sensorByUnitAndType[deviceId + '-' + elem.type];
              if (origin === null || origin === undefined) backupSensorList();
            });
            break;

          default:
        }
      }
    }

    function selectName(elem, index) {
      let sensor = _.filter(vm.sensorsByElement[index], (d) => {
        return d.pk.id === elem.sensor;
      });
      elem.userSensor = sensor[0].pk.userId;
      if (elem.type === 7) {
        elem.sensorName = 'ETc - ' + sensor[0].name;
      } else if (elem.type === 10) {
        elem.sensorName = 'Int. - ' + sensor[0].name;
      } else {
        elem.sensorName = sensor[0].name;
      }
    }

    function showDevice(elem) {
      elem.showDevice = sensorArray.includes('' + elem.type);
    }

    function initColor(elem) {
      if (elem.colour === null) {
        var randomElement = _.sample(colorArray);
        elem.colour = '#' + randomElement;
        vm.hoverColor = '#' + randomElement;
      }
    }

    function changeForm(elem, i) {
      if (elem.group === true) {
        elem.configuration = i;
        vm.form.$setDirty();
      } else {
        elem.configuration = null;
      }
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel();
    });
  }
})();
