(function() {
    'use strict';


    angular.module('agronicwebApp')

    .controller('sectorsGroupController',sectorsGroupController)

    sectorsGroupController.$inject = ['$rootScope', '$scope','$state','sectorFactory','$confirm'];

    function sectorsGroupController($rootScope, $scope, $state, sectorFactory, $confirm) {
        
        var vm = this;
        var backup;
        vm.activeList;
        vm.save;
        vm.cancel;
        vm.currentHeader;
        vm.sectorgroupSelected;
        vm.alert = false;
        activate();

        function activate(){
            vm.currentState = $state.includes;       
            vm.save = save;
            vm.cancel = cancel_edition;
            vm.changeSectorGroup = changeSectorGroup;
            vm.checkSectors = checkSectors;
            vm.changeHeaderSelected = changeHeaderSelected;
            if($state.params.unit !== null){
                vm.currentUnit = $state.params.unit;                
                loadSectorsGroup();
                //loadAllSectors();
            }else{
                $state.go('units');
            }        
        }

        function loadSectorsGroup(){
            sectorFactory.getGroupSectors(vm.currentUnit.id).then(response => {
                vm.sectorsGroup = response.plain();
                vm.sectorgroupSelected = vm.sectorsGroup[0];
                backup = angular.copy(vm.sectorgroupSelected);

                sectorFactory.allSectors(vm.currentUnit.id).then(response => {
                    vm.allSectors = response.plain();
                    checkSectors();
                    checkHeaders();
                }); 
            });
        }

        function changeSectorGroup(e) {
            if (e.detail.value) {
                vm.sectorgroupSelected = e.detail.value;
                angular.copy(vm.sectorgroupSelected,backup); 
                checkSectors();
                checkHeaders();               
            }
        }

        function loadAllSectors(){     
            sectorFactory.allSectors(vm.currentUnit.id).then(response => {
                vm.allSectors = response.plain();                
            });     
        }

        function save(){
            sectorFactory.updateGroupSectors(vm.currentUnit.id,vm.sectorgroupSelected).then(result => {
                vm.config = result;
                vm.form.$setPristine();            
                backup = angular.copy(vm.sectorsGroup);
                $rootScope.toast({
                    type:"success",
                    title:"Guardado correcto"
                });
            }, error => {
                $rootScope.toast({
                    type:"error",
                    title:"Error en el guardado"
                });    
            });      
        }

        function cancel_edition(){
            vm.form.$setPristine();
            vm.config = angular.copy(backup);
        }

        function checkSectors(){
            _.forEach(vm.allSectors , s => { 
                let exist = _.find(vm.sectorgroupSelected.elements, gs => {return gs.sector == parseInt(s.pk.id) });
                if(exist){
                    s.enabled = true;
                }else s.enabled = false;               
            });

        }

        function changeHeaderSelected(e,i){
            //set new header in sector group selected
            let sect = _.find(vm.allSectors, sec => {return sec.pk.id == parseInt(e.detail.value)});
            if(sect){
                sect.header = 0;
                vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements[i].header = sect.header;
                checkHeaders();
            } 
        }

        function checkHeaders(){
            //get first sector with header of sector group (this is the header of the sector group)
            let select = _.find(vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements, gs => {return gs.header > 0});
            if(select){
                let sector = _.find(vm.sectorsGroup[vm.sectorgroupSelected.id - 1].elements, sec => {return sec.header != select.header});
                if(sector && sector.header != null){
                    vm.alert = true;                
                }else{
                    vm.alert = false;
                }   
            }            
        }

        $scope.$on('refresh',function(event,args){
            
        });
        
        /**
         * Emetem estat del formulari quan detecta canvis en l'objecte.
         */
        $scope.$watch(()=> vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });
        
        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save();
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });     
    }

    
    
})();