import { UNITS } from '../utils/units.enum';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .factory('registerFactory', registerFactory);

  registerFactory.$inject = ['$rootScope', '$q', '$http', 'resFactory', 'Restangular'];

  function registerFactory($rootScope, $q, $http, resFactory, Restangular) {
    var list = {};
    var parser = {};
    var op = {
      parseRegister: parseRegister,
      all: all,
      get: get,
    };
    return op;

    function all(id, type, page) {
      var deferred = $q.defer();

      var from = moment().subtract(1, 'weeks').format('DD-MM-YYYY');
      var to = moment().format('DD-MM-YYYY');

      Restangular.one('units', id)
        .one('register')
        .get({ page: page, limit: 15, type: type, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function get(id, filter, from, to, page, limit) {
      var deferred = $q.defer();
      var parsed_from = moment(from, 'DD/MM/YYYY').format('DD-MM-YYYY');
      var parsed_to = moment(to, 'DD/MM/YYYY').add(1, 'days').format('DD-MM-YYYY');

      var parameters = {
        page: page,
        limit: limit,
        from: parsed_from,
        to: parsed_to,
        filter: JSON.stringify(filter),
      };

      Restangular.one('units', id)
        .one('register')
        .get(parameters)
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function parseRegister(device, data) {
      // resFactory.register(device).then(function(response){

            // parser = response;
            parser = resFactory.register(device);
            _.forEach(data,function(reg){
                if(device === UNITS.A_4500){ //A4500 utilitza grup i registre.
                    if(parser[reg.group]){
                        if( reg.group === 6){// registres condicionants no utilitzem el registre com a selector
                            if(reg.register >= 12){// registre Cambio frec.
                                reg.value2 = "";//Modifiquem registre perquè no printi el motiu "por"
                            }
                            parseOne(parser[reg.group],reg);
                        } else{
                            parseOne(parser[reg.group][reg.register],reg);
                        }

                    }
                }else{
                    parseOne(parser[reg.register],reg);
                }
            })
        // });
    }

    function parseOne(parser, data) {
      if (parser !== undefined) {
        var select_length = parser.selectors.length;
        data.textLine = '';
        if (select_length > 0) {
          for (var i = 0; i < select_length; i++) {
            if (parser.selectors[i] === '') {
              var str = parser.texts[i].text;

              _.forEach(parser.texts[i].parsers, function (val) {
                str = str.replace(/{\w+}/, function (all) {
                  return formatter(all, data[val]);
                });
              });
              data.textLine = data.textLine + str;
            } else {
              var key = data[parser.selectors[i]];
              var str = '';
              if (parser.texts[i][key] !== undefined) {
                str = parser.texts[i][key].text;
                _.forEach(parser.texts[i][key].parsers, function (val) {
                  str = str.replace(/{\w+}/, function (all) {
                    return formatter(all, data[val]);
                  });
                });
              }
              data.textLine = data.textLine + str;
            }
          }
        } else {
          _.forEach(parser.texts, function (obj) {
            var str = obj.text;

            _.forEach(obj.parsers, function (val) {
              str = str.replace(/{\w+}/, function (all) {
                return formatter(all, data[val]);
              });
            });
            data.textLine = data.textLine + str;
          });
        }
      }
    }

    function formatter(format, value) {
      return value;
    }
  }
})();
