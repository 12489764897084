import { A4500DrainageApi, A4500DrainageResponse, A4500DrainageRequest } from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { VeggaFacade } from '../vegga-facade/vegga-facade';

@Injectable('drainageFacade')
export class DrainageFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private a4500DrainagesResponse = new VeggaResponse<A4500DrainageResponse[]>();
  private a4500DrainageResponse = new VeggaResponse<A4500DrainageResponse>();

  private a4500DrainageApi: A4500DrainageApi;
  private loader = new VeggaLoader();

  constructor() {
    this.a4500DrainageApi = new A4500DrainageApi();
    this.a4500DrainageApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get a4500Drainage$(): Observable<A4500DrainageResponse> {
    return this.a4500DrainageResponse.value$;
  }

  get a4500Drainage(): A4500DrainageResponse {
    return this.a4500DrainageResponse.value;
  }

  get a4500Drainages$(): Observable<A4500DrainageResponse[]> {
    return this.a4500DrainagesResponse.value$;
  }

  get a4500Drainages(): A4500DrainageResponse[] {
    return this.a4500DrainagesResponse.value;
  }

  loadA4500Drainages(id: number) {
    const req$ = this.a4500DrainageApi.getDrainageListA4500({ id });
    const subscription = req$.subscribe({
      next: res => {
        this.a4500DrainagesResponse.set(res);
      },
      error: err => {
        this.a4500DrainagesResponse.setError(err, {});
      },
    });
    this.loader.waitFor(subscription);
  }

  loadA4500DrainageById(id: number, drainageId: number) {
    const req$ = this.a4500DrainageApi.getDrainageByIdA4500({ id, drainageId });
    const subscription = req$.subscribe({
      next: res => {
        this.a4500DrainageResponse.set(res);
      },
      error: err => {
        this.a4500DrainageResponse.setError(err, {});
      },
    });
    this.loader.waitFor(subscription);
  }

  updateA4500Drainage(id: number, drainageId: number, a4500DrainageRequest: A4500DrainageRequest): Observable<A4500DrainageResponse> {
    return this.a4500DrainageApi.updateDrainageByIdA4500({ id, drainageId, a4500DrainageRequest }).pipe(
      tap(drainage => {
        const index = this.a4500Drainages.findIndex(d => d.id === drainage.id);
        this.a4500Drainages[index] = drainage;
        this.a4500DrainagesResponse.set([...this.a4500Drainages]);
      }),
    );
  }

  reset(): void {
    this.a4500DrainageResponse.clear();
  }

  clear(): void {
    this.a4500DrainageResponse.clear();
    this.a4500DrainagesResponse.clear();
  }
}
