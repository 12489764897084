(function() {
'use strict';


angular.module('agronicwebApp')

.controller('modbusPortController',modbusPortController)

modbusPortController.$inject = ['$rootScope', '$scope', '$state', 'ModBusPort', 'commFactory'];

function modbusPortController($rootScope, $scope, $state, ModBusPort, commFactory) {
    
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    activate();

    function activate(){
        vm.currentState = $state.includes;
        vm.save = save;
        vm.cancel = cancel_edition;
        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;
            vm.modBusPort = ModBusPort;
            backup = angular.copy(vm.modBus);
        }else{
            $state.go('units');
        }
        
    }
    
    
    function save(){
        vm.form.$setPristine();
        commFactory.updateModBusClient(vm.currentUnit.id,vm.modBusPort).then(result => {
            vm.modBus = result;    
            backup = angular.copy(vm.modBus);
        });
    }
    
    function cancel_edition(){
        vm.modBus = angular.copy(backup);
    }
    
    $scope.$on('refresh',function(event,args){
        
    });

    $scope.$watch(() => vm.form, () => {
        $scope.$emit('formUpdated', vm.form);
    });

    $scope.$on('formCancel', (e, args) => {
        //Restaurem estat del formulari a la versió previa.
        cancel_edition();
    });
}
})();