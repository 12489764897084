import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('flowController', flowController);

  flowController.$inject = ['$rootScope', '$scope', '$state', 'sensorsFactory', 'configFactory'];

  function flowController($rootScope, $scope, $state, sensorsFactory, configFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.flow = angular.copy(vm.currentUnit.flow);
        initUnits();
        loadMeters();
      } else {
        $state.go('units');
      }
    }

    function initUnits() {
      if ($state.params.unit.type == 5) {
        vm.fertilizer = {
          0: 'hh:mm',
          1: '\'min "sec',
          2: 'l',
          3: 'cl',
        };
        vm.irrigation = {
          0: 'hh:mm',
          1: '\'min "sec',
          2: 'm3',
          3: 'l',
        };
      } else {
        vm.irrigation = {
          0: 'hh:mm',
          1: '\'min "sec',
          2: 'm3',
          4: 'l',
          16: 'm3/Ha',
        };
        vm.fertilizer = {
          0: 'hh:mm',
          1: '\'min "sec',
          4: 'l',
          8: 'cl',
          32: 'l/Ha',
          128: 'dl',
        };
      }

      vm.stopTypes = {
        0: 'No para',
        1: 'Temporal',
        2: 'Definitivo',
      };
    }

    function loadMeters() {
      sensorsFactory.meters(vm.currentUnit.id).then((data) => {
        vm.meters = data;
        backup = angular.copy(vm.meters);
      });

      if (vm.currentUnit.type == vm.UNITS.A_7000) {
        sensorsFactory.fertmeters(vm.currentUnit.id).then((data) => {
          vm.fertMeters = data;
        });
      }
    }
    function save() {
      vm.flow.unittype = vm.currentUnit.type;
      _.forEach(vm.meters, (meter) => {
        meter.unittype = vm.currentUnit.type;
      });
      _.forEach(vm.fertMeters, (fertMeters) => {
        fertMeters.unittype = vm.currentUnit.type;
      });
      vm.loading = true;
      var params = {
        flow: vm.flow,
        meters: vm.meters,
        meterfert: vm.fertMeters,
      };
      vm.flow = null;
      vm.meters = null;
      vm.fertMeters = null;
      configFactory.updateFlow(vm.currentUnit.id, params).then(
        (result) => {
          vm.meters = _.orderBy(result.meters, 'pk.id');
          vm.currentUnit.meters = vm.meters;
          vm.flow = result.flow;
          vm.currentUnit.flow = vm.flow;
          vm.fertMeters = _.orderBy(result.meterfert, 'pk.id');
          vm.currentUnit.fertMeters = vm.fertMeters;
          vm.loading = false;
          vm.form.$dirty = false;
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        },
        (error) => {}
      );
    }
    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formToUnitUpdated', vm.form);
      }
    );

    $scope.$on('refresh', function (event, args) {});
  }
})();
