import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('sensorsFactory', sensorsFactory);

  sensorsFactory.$inject = ['Restangular', '$rootScope', '$q'];

  function sensorsFactory(Restangular, $rootScope, $q) {
    var resource = Restangular.all('units');
    var op = {
      digitalsPage: digitalsPage,
        digitalsAll: digitalsAll,
      digitalsactive: digitalsactive,
      analogsPage: analogsPage,
      analogsAll: analogsAll,
        analogById:analogById,
        formatsAll: formatsAll,
      analogsactive: analogsactive,
      meters: meters,
      metersOne: metersOne,
      fertmeters: fertmeters,
      registerDigitals: registerDigitals,
      registerAnalogs: registerAnalogs,
      historyAnalogs: historyAnalogs,
      getFormat: getFormat,
      updateFormats: updateFormats,
        updateFormats45:updateFormats45,
      updateDigitals: updateDigitals,
      updateDigitalsArray: updateDigitalsArray,
      updateMeter: updateMeter,
      updateMeters: updateMeters,
      updateAnalogs: updateAnalogs,
        updateCustomUnidad45:updateCustomUnidad45,
      lightenDarkenColor: lightenDarkenColor,
      prepareMeter4000: prepareMeter4000,
      installer: installer,
      getSensor: getSensor,
      getDigitals: getDigitals,
        analogformatsAll: analogformatsAll,
        analogformatsViewAll: analogformatsViewAll,
        magnitudeAll:magnitudeAll,
        unidadesAll:unidadesAll,
        customUnidadesAll:customUnidadesAll,
        unidad45ByMagAndId:unidad45ByMagAndId,
        customUnidad45ById:customUnidad45ById,
        logicSensorsPage:logicSensorsPage,
        getLogics:getLogics,
        getActiveLogics:getActiveLogics,
        updateLogics:updateLogics,
        getMetersSector:getMetersSector,
        updateMetersSector:updateMetersSector
    };
    var meterA4000 = {};
    return op;

    function getSensor(unitId, type, id) {
      var sensor_type;
      switch (Number(type)) {
        case 0:
          sensor_type = 'analogs';
          break;
        case 1:
          sensor_type = 'meters';
          break;
        case 2:
          sensor_type = 'digitals';
          break;
        case 3:
          sensor_type = 'meters_';
          break;
      }
      var deferred = $q.defer();
      Restangular.one('units', unitId)
        .one(sensor_type, id)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function digitalsPage(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('digitals')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function digitalsAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).one('digitals').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function analogsPage(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('analogs')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function analogsAll(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .all('analogs')
        .getList()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    
    function analogById(deviceId, sensorId){
        var deferred = $q.defer();
        Restangular.one('units', deviceId).one('analogs', sensorId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function logicSensorsPage(id, params){

        var deferred = $q.defer();
        Restangular.one('units', id).one('logics').get(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }


    function formatsAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('formats').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function analogformatsAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('formats').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function unidad45ByMagAndId(id, unidadId, magId){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').one('unidades', unidadId).one('magnitud', magId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function customUnidad45ById(id, unidadId){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').one('unidades-custom', unidadId).get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function updateCustomUnidad45(id, params){
        var deferred = $q.defer();
        Restangular.one('units', id).one('analogs').one('unidades-custom').customPOST(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function analogformatsViewAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('formatsview').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function magnitudeAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('magnitudes').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function unidadesAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('unidades').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function customUnidadesAll(id){
        var deferred = $q.defer();
        Restangular.one('units', id).all('analogs').all('unidades-custom').getList().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function registerDigitals(id,sensor,type){
        var deferred = $q.defer();
        var to = moment().add(1,'days').format('DD-MM-YYYY');
      var from = moment().subtract(7, 'day').format('DD-MM-YYYY');
      Restangular.one('units', id)
        .one('digitals', sensor)
        .one('register')
        .get({ from: from, to: to, unittype: type })
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function registerAnalogs(id, sensor, type) {
      var deferred = $q.defer();
      var to = moment().add(1, 'days').format('DD-MM-YYYY');
      var from = moment().subtract(7, 'day').format('DD-MM-YYYY');
      Restangular.one('units', id)
        .one('analogs', sensor)
        .one('register')
        .get({ from: from, to: to, unittype: type })
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function historyAnalogs(id, sensor) {
      var deferred = $q.defer();
      var to = moment().add(1, 'days').format('DD-MM-YYYY');
      var from = moment().subtract(7, 'day').format('DD-MM-YYYY');
      Restangular.one('units', id)
        .one('analogs', sensor)
        .one('history')
        .get({ filter: { groupBy: 1 }, limit: 1100, page: 1, from: from, to: to })
        .then(function (response) {
          deferred.resolve(response.content);
        });
      return deferred.promise;
    }

    function digitalsactive(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('digitals')
        .get({ active: true })
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function getDigitals(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('digitals')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function getLogics(id){
        var deferred = $q.defer();
        Restangular.one('units',id).one('logics').get().then(function(response){
            deferred.resolve(response);
        });

        return deferred.promise;
    }

    function getActiveLogics(id, active){
        var deferred = $q.defer();
        var param = null;
        if(active === undefined){
            param = {'active':true}
        }
        Restangular.one('units',id).one('logics').get(param).then(function(response){
            deferred.resolve(response);
        });

        return deferred.promise;
    }

    function analogsactive(id, all) {
      var deferred = $q.defer();
      var param = null;
      if (all === undefined) {
        param = { active: true };
      }
      Restangular.one('units', id)
        .one('analogs')
        .get(param)
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function counters(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('counters')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function meters(id, params){
        var deferred = $q.defer();
        if(params === undefined){
            params = {operative:false}
        }
      Restangular.one('units', id)
        .one('meters')
        .get(params)
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }
    function metersOne(id, id2) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('meters', id2)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function fertmeters(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('fertmeters')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function getMetersSector(id){
        var deferred = $q.defer();

        Restangular.one('units',id).one('metersSector').get().then(function(response){
            deferred.resolve(response);
        });

        return deferred.promise;
    }

    function updateMetersSector(params){
        var deferred = $q.defer();
        Restangular.one('units',params.pk.deviceId).one('metersSector').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }
    
    function getFormat(id1,id2){
        var deferred = $q.defer();
     
        Restangular.one('units',id1).one('formats',id2).get().then(function(response){
            deferred.resolve(response);
        });

      return deferred.promise;
    }

    function updateFormats(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('formats')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateFormats45(id,params){
        var deferred = $q.defer();
        console.log(params);
        Restangular.one('units',id).one('formats45').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }

    function updateDigitals(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('sensord').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }
    function updateDigitalsArray(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('sensorsd')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateMeter(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('meter')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateMeters(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('meters')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateAnalogs(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('sensora')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }

    function updateLogics(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('logics').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }

    function lightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == '#') {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }

    function installer(id) {
      var deferred = $q.defer();

      Restangular.one('units', id)
        .one('installer')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });

      return deferred.promise;
    }

    function prepareMeter4000(id, counter) {
      var deferred = $q.defer();
      if (meterA4000[id] === undefined) {
        meters(id).then((data1) => {
          fertmeters(id).then((data2) => {
            installer(id).then((data3) => {
              meterA4000[id] = {};
              meterA4000[id].meters = data1.plain();
              meterA4000[id].fertmeters = data2.plain();
              meterA4000[id].installer = data3.plain();
              deferred.resolve(parseMeterData(data1.plain(), data2.plain(), counter, data3.plain()));
            });
          });
        });
      } else {
        deferred.resolve(
          parseMeterData(meterA4000[id].meters, meterA4000[id].fertmeters, counter, meterA4000[id].installer)
        );
      }
      return deferred.promise;
    }

    function parseMeterData(meters, fertmeters, c, installer) {
      if (meters.length > 0 || fertmeters.length > 0) {
        switch (c.pk.id) {
          case '11':
          case '23':
            c.valueNumber = parseUnitFlow(meters[0], installer);
            c.value = c.valueNumber + ' m3/h';
            c.active = meters[0].xActive || c.valueNumber > 0;
            c.meter = 1;
            break;
          case '12':
          case '24':
            c.valueNumber = parseUnitFlow(meters[1], installer);
            c.value = c.valueNumber + ' m3/h';
            c.active = meters[1].xActive || c.valueNumber > 0;
            c.meter = 2;
            break;
          case '13':
          case '25':
            c.valueNumber = parseUnitFlow(meters[2], installer);
            c.value = c.valueNumber + ' m3/h';
            c.active = meters[2].xActive || c.valueNumber > 0;
            c.meter = 3;
            break;
          case '14':
          case '26':
            c.valueNumber = parseUnitFlow(meters[3], installer);
            c.value = c.valueNumber + ' m3/h';
            c.active = meters[3].xActive || c.valueNumber > 0;
            c.meter = 4;
            break;
          case '15':
          case '27':
            c.valueNumber = parseUnitFert(fertmeters[0], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[0].xActive || c.valueNumber > 0;
            break;
          case '16':
          case '28':
            c.valueNumber = parseUnitFert(fertmeters[1], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[1].xActive || c.valueNumber > 0;
            break;
          case '17':
          case '29':
            c.valueNumber = parseUnitFert(fertmeters[2], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[2].xActive || c.valueNumber > 0;
            break;
          case '18':
          case '30':
            c.valueNumber = parseUnitFert(fertmeters[3], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[3].xActive || c.valueNumber > 0;
            break;
          case '19':
          case '31':
            c.valueNumber = parseUnitFert(fertmeters[4], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[4].xActive || c.valueNumber > 0;
            break;
          case '20':
          case '32':
            c.valueNumber = parseUnitFert(fertmeters[5], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[5].xActive || c.valueNumber > 0;
            break;
          case '21':
          case '33':
            c.valueNumber = parseUnitFert(fertmeters[6], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[6].xActive || c.valueNumber > 0;
            break;
          case '22':
          case '34':
            c.valueNumber = parseUnitFert(fertmeters[7], installer);
            c.value = c.valueNumber + ' l/m2';
            c.active = fertmeters[7].xActive || c.valueNumber > 0;
            break;
          case '35': //Irrigation meter
            c.active = true;
            break;
        }
      }
      return c;
    }

    function parseUnitFlow(value, installer) {
      value = '' + value.xFlow;
      if (installer) {
        var decimals = 1;
        if (installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function parseUnitFert(value, installer) {
      value = '' + value.xFlow;
      if (installer) {
        var decimals = 1;
        if (installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }
  }
})();
