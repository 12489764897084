//////////////////////////////////////////////////
// The main module configuration section shows  //
// how to define when (redirects) and otherwise //
// (invalid urls) to arrangesite navigation     //
// using ui-router.                             //
//////////////////////////////////////////////////
// (function() {
// 'use strict';

import angular from 'angular';

import '../components/login/login-controller';
import loginTemplate from '../components/login/login.html';

import '../components/graphics/graphics-factory';
import '../components/utils/nav-controller';
import '../components/utils/resources-factory';
import '../components/utils/secure-service';
import '../components/utils/unit-factory';
import '../components/utils/user-factory';
import mainTemplate from '../main.html';

import '../components/home/home-controller';
import homeTemplate from '../components/home/home.html';

import '../components/units/units-controller';
import unitsTemplate from '../components/units/units.html';

import '../components/unit/unit-controller';
import unitTemplate from '../components/unit/unit.html';

import '../components/programs/programs-factory';
import '../components/sectors/sector-factory';

import '../components/usermanager/newuser-controller';
import '../components/usermanager/user-controller';
import '../components/usermanager/usermanager-controller';
import userManagerTemplate from '../components/usermanager/usermanager.html';
import userManagerNewTemplate from '../components/usermanager/usermanager.new.html';
import userManagerUserTemplate from '../components/usermanager/usermanager.user.html';

import '../components/directives/ioselector/ioselector-directive';
import '../components/directives/ioselector/ioselector.factory';
import '../components/directives/mask-sensor-unit/mask-sensor-unit.directive';
import '../components/directives/mask-conversion-value/mask-conversion-value.directive';
import '../components/directives/stateClass/state-class.directive';
import '../components/directives/vegga-loader/vegga-loader.directive';
import '../components/directives/timeSelector/time.selector-directive';
import '../components/directives/vegga-input-legacy/vegga-input-legacy.directive';
import '../components/directives/vegga-overlay/vegga-overlay-view.directive';
import '../components/directives/vegga-select-legacy/vegga-select-legacy.directive';
import '../components/directives/vegga-dropdown-legacy/vegga-dropdown-legacy.directive';
import '../components/directives/vegga-dropdown/vegga-dropdown.directive';
import '../components/directives/vegga-radio-button-group-legacy/vegga-radio-button-group-legacy.directive';
import '../components/directives/vegga-ranged-time-picker-legacy/vegga-ranged-time-picker-legacy.directive';
import '../components/directives/vegga-select-legacy/vegga-select-legacy.directive';
import '../components/utils/unitsparser-directive';
import '../components/utils/errorhandler-factory';
import '../components/utils/vegga-select.factory';
import '../components/directives/mask-ip/mask-ip.directive';
import '../components/directives/water-graph/water-demo.directive';

import '../components/programs/programs-controller';
import '../components/programs/programs-factory';
import programsTemplate from '../components/programs/programs.html';

import '../components/programs/programs.detail-controller';
import programsDetailTemplate from '../components/programs/programs.detail.html';

import '../components/programs/programs.edit-controller';
import programsEditTemplate from '../components/programs/programs.edit.html';

import '../components/programs/programs.config-controller';
import programsConfigTemplate from '../components/programs/programs.config.html';

import '../components/sectors/sectors-controller';
import sectorsTemplate from '../components/sectors/sectors.html';

import '../components/sectors/sectors.detail-controller';
import sectorsDetailTemplate from '../components/sectors/sectors.detail.html';

import '../components/sectors/sectors.config-controller';
import sectorsConfigTemplate from '../components/sectors/sectors.config.html';

import '../components/filters/filters-controller';
import '../components/filters/filters-factory';
import filtersTemplate from '../components/filters/filters.html';

import '../components/filters/filters.config-controller';
import filtersConfigTemplate from '../components/filters/filters.config.html';

import '../components/sensors/sensors-controller';
import sensorTemplate from '../components/sensors/sensors.html';

import '../components/sensors/sensors-factory';
import '../components/sensors/sensors.autoformat-factory';
import '../components/sensors/sensors.detail-controller';

import '../components/sensors/sensors.config-controller';
import sensorsConfigTemplate from '../components/sensors/sensors.config.html';

import '../components/sensors/sensors.config.as-controller';
import sensorsConfigAsTemplate from '../components/sensors/sensors.config.as.html';
import '../components/sensors/sensors.config.ds-controller';
import sensorsConfigDsTemplate from '../components/sensors/sensors.config.ds.html';
import '../components/sensors/sensors.config.meters-controller';
import sensorsConfigMetersTemplate from '../components/sensors/sensors.config.meters.html';
import '../components/sensors/sensors.config.logics-controller';
import sensorsConfigLogicsTemplate from '../components/sensors/sensors.config.logic.html';
import '../components/sensors/sensors.formats-controller';
import sensorsFormatsTemplate from '../components/sensors/sensors.formats.html';
import sensorsCustomFormatsTemplate from '../components/sensors/view/format-custom-as-45.html';

import '../components/register/register-controller';
import registerTemplate from '../components/register/register.html';
//TODO - check if it's necessary to import factory
// import '../components/register/register-factory';
import '../components/register/register.list-controller';
import registerListTemplate from '../components/register/register.list.html';

import '../components/fertilizers/fertilizers-controller';
import fertilizersTemplate from '../components/fertilizers/fertilizers.html';
//TODO - check if it's necessary to import factory
// import '../components/fertilizers/fertilizers-factory';
import '../components/fertilizers/fertilizers.config-controller';
import fertilizersConfigTemplate from '../components/fertilizers/fertilizers.config.html';

import '../components/totals/totals-controller';
import totalsTemplate from '../components/totals/totals.html';
import '../components/totals/totals.meters-controller';
import totalsMetersTemplate from '../components/totals/totals.meters.html';
import '../components/totals/totals.sectors-controller';
import totalsSectorsTemplate from '../components/totals/totals.sectors.html';

import '../components/external_modules/em.detail-controller';
import emDetailTemplate from '../components/external_modules/em.detail.html';
import emTemplate from '../components/external_modules/em.html';
import '../components/external_modules/externalmod-controller';

import '../components/manual/manual-controller';
import manualTemplate from '../components/manual/manual.html';
//TODO - check if it's necessary to import factory
// import '../components/manual/manual-factory';

import '../components/malfunctions/malfunctions-controller';
import malfunctionsTemplate from '../components/malfunctions/malfunctions.html';

import '../components/flow/flow-controller';
import flowTemplate from '../components/flow/flow.html';

import '../components/crops/crops-controller';
import cropsTemplate from '../components/crops/crops.html';

import '../components/graphics/graphics-controller';
import '../components/graphics/graphics.detail-controller';
import '../components/graphics/graphics.edit-controller';
import graphicsTemplate from '../components/graphics/graphics.html';
import graphisDetailTemplate from '../components/graphics/view/graphics.detail.html';
import graphicsEditTemplate from '../components/graphics/view/graphics.edit.html';
import graphicsListTemplate from '../components/graphics/view/graphics.list.html';

//TODO - check if it's necessary to import factory
//import '../components/calc_sensors/calcs-factory';

import '../components/nebulization/nebulization-controller';
import '../components/nebulization/nebulization.config-controller';
import nebulizationConfigTemplate from '../components/nebulization/nebulization.config.html';
import '../components/nebulization/nebulization.detail-controller';
import nebulizationDetailTemplate from '../components/nebulization/nebulization.detail.html';
import '../components/nebulization/nebulization.edit-controller';
import nebulizationEditTemplate from '../components/nebulization/nebulization.edit.html';
import nebulizationTemplate from '../components/nebulization/nebulization.html';
//TODO check if it's necessary to import -> nebulization factory
import '../components/nebulization/nebulization-factory';

import '../components/programs/agronic55/programs-controller';
import '../components/programs/agronic55/programs.child-controller';
import a55programConfigTemplate from '../components/programs/agronic55/programs.config.html';
import a55programDetailTemplate from '../components/programs/agronic55/programs.detail.html';
import a55programEditTemplate from '../components/programs/agronic55/programs.edit.html';
import a55programsTemplate from '../components/programs/agronic55/programs.html';

import '../components/extractions/extractions-controller';
import '../components/extractions/extractions.detail-controller';
import '../components/extractions/extractions.edit-controller';
import eextraTemplate from '../components/extractions/extractions.html';
import eextraDetailTemplate from '../components/extractions/view/extractions.detail.html';
import eextraEditTemplate from '../components/extractions/view/extractions.edit.html';
import eextraListTemplate from '../components/extractions/view/extractions.list.html';

import modulosWebTemplate from '../components/modulos_web/modulosWeb-controller';
import '../components/modulos_web/modulos_web.html';

import '../components/utils/utils-factory'

import './app-states.a25';
import './app-states.a45';
import './app-states.a7';
import './app-states.communication';
import './app-states.config';
import './app-states.expert';
import './app-states.history';
import './app-states.maps';
import './app-states.agroclimatic';

angular.module('agronicwebApp').config([
  '$stateProvider',
  '$urlRouterProvider',
  '$locationProvider',
  function ($stateProvider, $urlRouterProvider, $locationProvider) {
    // .config(['$stateProvider', '$locationProvider', function ($stateProvider, $locationProvider) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
    });

    ///////////////////////////////
    // 1-Redirects and Otherwise //
    ///////////////////////////////

    // Use $urlRouterProvider to configure any redirects (when) and invalid urls (otherwise).
    $urlRouterProvider

      // The `when` method says if the url is ever the 1st param, then redirect to the 2nd param
      // Here we are just setting up some convenience urls.
      //                .when('/t?id', '/topics/:id')
      //                    .when('/t/:id', '/topics/:id')

      // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
      .otherwise('units');
    //////////////////////////

    // 2-State Configurations
    // Several states hav been configured:
    // home
    // tasks
    //
    //////////////////////////
    // We must configure states using $stateProvider.
    $stateProvider
      .state('main', {
        abstract: true,
        // url: `${process.env.BASE_HREF}`,
        resolve: {
          UserData: [
            'AuthenticationService',
            function (AuthenticationService) {
              return AuthenticationService.authenticate().then(function (data) {
                return data;
              });
            },
          ],
          State: [
            '$state',
            function ($state) {
              return $state.current.name;
            },
          ],
        },
        template: mainTemplate,
        controller: 'navController',
        authenticate: true,
        controllerAs: 'vm',
      })

      //////////
      // Home //
      //////////
      .state('home', {
        // Use a url of '/' to set a states as the 'index'.
        parent: 'main',
        url: `${process.env.BASE_HREF}/home`,
        template: homeTemplate,
        controller: 'homeController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('adminmanager', {
        parent: 'main',
        url: '/adminmanager',
        templateUrl: 'components/manager/manager.html',
        controller: 'adminController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('manager', {
        parent: 'main',
        url: '/manager',
        template: userManagerTemplate,
        controller: 'managerController',
        authenticate: true,
        controllerAs: 'vm',
      })
      //-------> Manager child states
      .state('user', {
        // Use a url of '/' to set a states as the 'index'.
        parent: 'manager',
        url: '/user',
        params: {
          user: null,
        },
        template: userManagerUserTemplate,
        controller: 'userController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('new', {
        // Use a url of '/' to set a states as the 'index'.
        parent: 'manager',
        url: '/new',
        template: userManagerNewTemplate,
        controller: 'newUserController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('programs', {
        parent: 'unit',
        url: `/programs`,
        params: {
          unit: null,
          config: null,
        },
        template: programsTemplate,
        controller: 'programsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      //-------> Programs child states
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('programs.detail', {
        // parent:'programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/detail/{id}',
        params: {
          program: null,
          id: null,
        },
        template: programsDetailTemplate,
        controller: 'programsDetailController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('programs.edit', {
        // parent:'programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/edit/{id}',
        params: {
          program: null,
          id: null,
        },
        template: programsEditTemplate,
        controller: 'programsEditController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('programs.config', {
        // parent:'programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/config/{id}',
        params: {
          program: null,
          id: null,
        },
        template: programsConfigTemplate,
        controller: 'programsConfigController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('sectors', {
        parent: 'unit',
        url: '/sectors',
        params: {
          unit: null,
          load: null,
          config: null,
        },
        template: sectorsTemplate,
        controller: 'sectorsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      //-------> Sectors child states
      .state('sectors.detail', {
        // parent:'sectors',
        // Use a url of '/' to set a states as the 'index'.
        url: '/detail/{id}',
        params: {
          sector: null,
          id: null,
        },
        template: sectorsDetailTemplate,
        controller: 'sectorsDetailController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sectors.config', {
        // parent:'sectors',
        // Use a url of '/' to set a states as the 'index'.
        url: '/config/{id}',
        params: {
          sector: null,
          id: null,
        },
        template: sectorsConfigTemplate,
        controller: 'sectorsConfigController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('filters', {
        parent: 'unit',
        url: 'filters',
        params: {
          unit: null,
          config: null,
        },
        template: filtersTemplate,
        controller: 'filtersController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('filters.config', {
        // parent:'filters',
        // Use a url of '/' to set a states as the 'index'.
        url: '/config',
        params: {},
        template: filtersConfigTemplate,
        controller: 'filtersConfigController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('sensors', {
        parent: 'unit',
        url: `/sensors`,
        params: {
          unit: null,
          config: null,
        },
        template: sensorTemplate,
        controller: 'sensorsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      //-------> Sensors child states
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.config', {
        // parent:'sensors',
        // Use a url of '/' to set a states as the 'index'.
        url: '/config',
        params: { unit: null },
        template: sensorsConfigTemplate,
        controller: 'sensorsConfigController',
        authenticate: true,
        controllerAs: 'vm',
      })
      //---------->Sensors config child states
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.config.as', {
        // parent:'sensors.config',
        // Use a url of '/' to set a states as the 'index'.
        url: '/as',
        params: { unit: null },
        template: sensorsConfigAsTemplate,
        controller: 'sensorsConfigASController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.config.ds', {
        // parent:'sensors.config',
        // Use a url of '/' to set a states as the 'index'.
        url: '/ds',
        params: { unit: null },
        template: sensorsConfigDsTemplate,
        controller: 'sensorsConfigDSController',
        authenticate: true,
        controllerAs: 'vm',
      })

      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.config.meters', {
        // parent:'sensors.config',
        // Use a url of '/' to set a states as the 'index'.
        url: '/meters',
        params: { unit: null },
        template: sensorsConfigMetersTemplate,
        controller: 'sensorsConfigMetersController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.config.logics', {
        // parent:'sensors.config',
        // Use a url of '/' to set a states as the 'index'.
        url: '/logics',
        params:{ unit:null},
        template: sensorsConfigLogicsTemplate,
        controller:'sensorsConfigLogicController',
        authenticate:true,
        controllerAs:'vm'
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('sensors.formats', {
        // parent:'sensors.config',
        // Use a url of '/' to set a states as the 'index'.
        url: '/formats',
        params: { unit: null },
        template: sensorsFormatsTemplate,
        controller: 'sensorsFormatsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('sensors.customFormats', {
        // Use a url of '/' to set a states as the 'index'.
        url: '/custom',
        params:{ unit:null},
        template: sensorsCustomFormatsTemplate,
        controller:'sensorsFormatsController',
        authenticate:true,
        controllerAs:'vm'
      })
      .state('register', {
        parent: 'unit',
        url: 'register',
        params: {
          unit: null,
          load: null,
        },
        template: registerTemplate,
        controller: 'registerController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      //-------> Register child states
      .state('register.list', {
        // parent:'register',
        // Use a url of '/' to set a states as the 'index'.
        url: '/list',
        template: registerListTemplate,
        controller: 'registerListController',
        authenticate: true,
        controllerAs: 'vm',
      })
      //         .state('sensors.calendar', {
      //                       parent:'register',
      //                       // Use a url of '/' to set a states as the 'index'.
      //                       url: '/calendar',
      //                       templateUrl: 'components/register/sensors.calendar.html',
      //                       controller:'registerConfigController',
      //                       authenticate:true,
      //                       controllerAs:'vm'
      //                     })

      .state('fertilizers', {
        parent: 'unit',
        url: 'fertilizers',
        params: {
          unit: null,
          config: null,
        },
        template: fertilizersTemplate,
        controller: 'fertilizersController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('fertilizers.config', {
        // parent:'fertilizers',
        params: {
          id: null,
        },
        url: '/config',
        template: fertilizersConfigTemplate,
        controller: 'fertilizersConfigController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('totals', {
        parent: 'unit',
        url: 'totals',
        params: {
          unit: null,
          load: null,
        },
        template: totalsTemplate,
        controller: 'totalsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      //-------> Totals child states
      .state('totals.sectors', {
        // parent:'totals',
        params: {
          unit: null,
          id: null,
        },
        url: '/sectors',
        template: totalsSectorsTemplate,
        controller: 'totalsSectorsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('totals.meters', {
        // parent:'totals',
        params: {
          unit: null,
          id: null,
        },
        url: '/meters',
        template: totalsMetersTemplate,
        controller: 'totalsMetersController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('modules', {
        parent: 'unit',
        url: 'externalmodules',
        params: {
          unit: null,
        },
        template: emTemplate,
        controller: 'externalmodController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('modules.detail', {
        // parent:'modules',
        params: {
          unit: null,
          box: null,
          type: null,
        },
        url: '/{type}',
        template: emDetailTemplate,
        controller: 'modulesDetailController',
        authenticate: true,
        controllerAs: 'vm',
      })
      /* TODO - Added as a view inside unit-edit state
                .state('manual', {
                      parent:'unit',
                      url: `${process.env.BASE_HREF}/manual`,
                      params:{
                          unit:null,
                        },
                      template: manualTemplate,
                      controller:'manualController',
                      authenticate:true,
                      controllerAs:'vm'
                  })*/

      .state('malfunctions', {
        parent: 'main',
        url: `${process.env.BASE_HREF}/malfunctions`,
        params: {
          units: null,
        },
        template: malfunctionsTemplate,
        controller: 'malfunctionsController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('units', {
        parent: 'main',
        // url: '/irrigation-control/units',
        url: `${process.env.BASE_HREF}/units`,
        params: {
          units: null,
        },
        template: unitsTemplate,
        controller: 'unitsController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('unit', {
        parent: 'main',
        // url: '/irrigation-control/units',
        url: `${process.env.BASE_HREF}/unit`,
        params: {
          unit: null,
        },
        authenticate: true,
        views: {
          '': {
            template: unitTemplate,
            controller: 'unitController',
            controllerAs: 'vm',
          },
          'manual@unit': {
            template: manualTemplate,
            controller: 'manualController',
            controllerAs: 'vm',
          },
        },
      })

      // .state('edit-unit', {
      //   parent:'unit',
      //   // url: '/irrigation-control/units',
      //   url: `/edit-unit`,
      //   views: {
      //     'manual': {
      //       url: '?manual-actions',
      //       params:{
      //         unit:null,
      //       },
      //       template: manualTemplate,
      //       controller:'manualController',
      //       authenticate:true,
      //       controllerAs:'vm'
      //     }
      //   }
      // })

      .state('flow', {
        parent: 'unit',
        url: 'flow',
        params: {
          unit: null,
        },
        template: flowTemplate,
        controller: 'flowController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('crops', {
        parent: 'main',
        url: `${process.env.BASE_HREF}/crops`,
        params: {
          units: null,
        },
        template: cropsTemplate,
        controller: 'cropsController',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('graphics', {
        parent: 'main',
        url: `${process.env.BASE_HREF}/graphics`,
        params: {
          units: null,
        },
        template: graphicsTemplate,
        controller: 'graphicsController',
        authenticate: true,
        controllerAs: 'vm',
      })

      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      /*.state('graphics.list', {
                                      //parent:'graphics',
                                      params:{
                                          units:null,                                                          
                                          graphic:null
                                      },
                                      url: '/list',
                                      template: graphicsListTemplate,
                                      controller:'graphicsController',
                                      authenticate:true,
                                      controllerAs:'vm'
                              })*/

      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('graphics.detail', {
        //parent:'graphics',
        params: {
          unitList: null,
          graphic: null,
        },
        url: '/detail',
        template: graphisDetailTemplate,
        controller: 'graphicsDetailController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('graphics.edit', {
        //parent:'graphics',
        params: {
          unitList: null,
          graphic: null,
        },
        url: '/edit',
        template: graphicsEditTemplate,
        controller: 'graphicsEditController',
        authenticate: true,
        controllerAs: 'vm',
      })

      /*Login*/
      .state('login', {
        // Use a url of '/' to set a states as the 'index'.
        // url: '/irrigation-control/login',
        url: `${process.env.BASE_HREF}/login`,
        params: {
          reload: null,
        },
        template: loginTemplate,
        controller: 'LoginController',
        authenticate: false,
        controllerAs: 'vm',
      })

      .state('nebulization', {
        parent:'unit',
        url: '/nebulization',
        params:{
          unit:null
        },
        template: nebulizationTemplate,
        controller:'nebulizationController',
        authenticate:true,
        controllerAs:'vm'
      })
      .state('nebulization.detail', {
        url: '/detail/{id}',
        params:{
          nebulization: null,
          id: null
        },
        template: nebulizationDetailTemplate,
        controller:'nebulizationDetailController',
        authenticate:true,
        controllerAs:'vm'
      })
      .state('nebulization.edit', {
        url: '/edit/{id}',
        params:{
          nebulization: null,
          id: null
        },
        template: nebulizationEditTemplate,
        controller:'nebulizationEditController',
        authenticate:true,
        controllerAs:'vm'
      })
      .state('nebulization.config', {
        url: '/config/{id}',
        params:{
          nebulization: null,
          id: null
        },
        template: nebulizationConfigTemplate,
        controller:'nebulizationConfigController',
        authenticate:true,
        controllerAs:'vm'
      })

      .state('a55programs', {
        parent: 'unit',
        url: '/a55/programs',
        params: {
          unit: null,
          config: null,
        },
        template: a55programsTemplate,
        controller: 'programsControllerA55',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('a55programs.detail', {
        //parent: 'a55programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/detail/{id}',
        params: {
          program: null,
          id: null,
        },
        template: a55programDetailTemplate,
        controller: 'programsChildControllerA55',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('a55programs.edit', {
        //parent: 'a55programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/edit/{id}',
        params: {
          program: null,
          id: null,
        },
        template: a55programEditTemplate,
        controller: 'programsChildControllerA55',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('a55programs.config', {
        //parent: 'a55programs',
        // Use a url of '/' to set a states as the 'index'.
        url: '/config/{id}',
        params: {
          program: null,
          id: null,
        },
        template: a55programConfigTemplate,
        controller: 'programsChildControllerA55',
        authenticate: true,
        controllerAs: 'vm',
      })

      .state('eextra', {
        parent: 'main',
        url: '/extractions',
        params: {
          units: null,
          extractions: null,
        },
        template: eextraTemplate,
        //controller:'extractionsController',
        authenticate: true,
        //controllerAs:'vm'
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('eextra.list', {
        //parent:'eextra',
        params: {
          units: null,
          extractions: null,
        },
        url: '/list',
        template: eextraListTemplate,
        controller: 'extractionsController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('eextra.detail', {
        //parent:'eextra',
        params: {
          units: null,
          extractions: null,
        },
        url: '/detail',
        template: eextraDetailTemplate,
        controller: 'extractionsDetailController',
        authenticate: true,
        controllerAs: 'vm',
      })
      // TODO - If state is defined with ".", it's not necessary to add parent attribute
      .state('eextra.edit', {
        //parent:'eextra',
        params: {
          units: null,
          extractions: null,
        },
        url: '/edit',
        template: eextraEditTemplate,
        controller: 'extractionsEditController',
        authenticate: true,
        controllerAs: 'vm',
      })
      .state('cuaderno', {
        parent: 'main',
        url: '/cuaderno',
        params: {
          units: null,
        },
        template: modulosWebTemplate,
        controller: 'modulosWebController',
        authenticate: true,
        controllerAs: 'vm',
      });
  },
]);
// })();
