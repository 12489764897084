import moment from 'moment/moment'; 
import { FERT_UNITS } from './enums/programs/programs-fertilization-units.enum';
import { IRRIGATIONS_UNITS_A4500 } from './irrigation-units.enum';

(function() {
    'use strict';

    angular.module('agronicwebApp')


    //TODO - check moment
    .constant("moment", moment)

    .factory('utilsFactory', utilsFactory);

    utilsFactory.$inject = [];

    function utilsFactory(){
        
        
        var op = {
            getFormatedValue4500:getFormatedValue4500,
            getFormatedXValue4500:getFormatedXValue4500,
            getFormatedPrePostIrrig4500:getFormatedPrePostIrrig4500,
            getIrrigUnits:getIrrigUnits,
            getFormatedFertilizationA4500:getFormatedFertilizationA4500,
            getFormatedFitoA4500:getFormatedFitoA4500,
            fromMinutesToHHMM:fromMinutesToHHMM
        };

        const IRRIG_UNITS_A4500 = IRRIGATIONS_UNITS_A4500;
        const FERT_UNITS_A4500 = FERT_UNITS;
        
        return op;   
        
        
        function formatWithDecimals(value, decimals){
            return value.toFixed(decimals);
        }

        function getIrrigUnits(prePostIrrigForSubprogram,programType, programSectorIrrigUnits, programSectorsByGroup, programIrrigUnits){
            var irrigUnits;
            if(prePostIrrigForSubprogram){
                if(programType == 0){
                    irrigUnits = programSectorIrrigUnits;
                }else{
                    if(programSectorsByGroup > 1){
                        irrigUnits = programIrrigUnits;
                    }else{
                        irrigUnits = programSectorIrrigUnits;
                    }
                }
            }else{
                irrigUnits = programIrrigUnits;
            }
            return irrigUnits;
        }

        function getFormatedXValue4500(value, irrigUnits, irrigDecimals){
            var formatedValue;
            switch(irrigUnits){
                case IRRIGATIONS_UNITS_A4500.HHMM: // hh:mm
                    formatedValue = fromSecondsToHHMM(value);
                    //formatedValue += " hh:mm";
                    break;
                case IRRIG_UNITS_A4500.M3: case IRRIG_UNITS_A4500.M3HA: // m3 - m3/ha
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, irrigDecimals);
                    formatedValue += " m3";
                    break;
                case IRRIGATIONS_UNITS_A4500.MMSS: // mm:ss
                    formatedValue = fromSecondsToMMSS(value); 
                    break;
                case IRRIGATIONS_UNITS_A4500.M3HAT: // hh:mm
                    formatedValue = fromSecondsToHHMM(value);
                    break;
                case IRRIG_UNITS_A4500.MM: // mm
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, irrigDecimals);
                    formatedValue += " m3";
                    break;
            }
            return formatedValue;
        }
        function getFormatedValue4500(value, irrigUnits, irrigDecimals){
            var formatedValue;
            switch(irrigUnits){
                case IRRIGATIONS_UNITS_A4500.HHMM: // hh:mm
                    formatedValue = fromSecondsToHHMM(value);
                    //formatedValue += " hh:mm";
                    break;
                case IRRIG_UNITS_A4500.M3:// m3
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, irrigDecimals);
                    formatedValue += " m3";
                    break;
                case IRRIG_UNITS_A4500.M3HA: // m3/ha
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, 2);
                    formatedValue += " m3/ha";
                    break;
                case IRRIGATIONS_UNITS_A4500.MMSS: // mm:ss
                    formatedValue = fromSecondsToMMSS(value); 
                    break;
                case IRRIG_UNITS_A4500.M3HAT: // m3/ha(t)
                    formatedValue = value / 1000; 
                    formatedValue = formatWithDecimals(formatedValue, 2);
                    formatedValue += " m3/ha(t)";
                    break;
                case IRRIG_UNITS_A4500.MM: // mm
                    formatedValue = value / 10000;
                    formatedValue = formatWithDecimals(formatedValue, 2);
                    formatedValue += " mm";
                    break;
            }
            return formatedValue;
        }
        

        function getFormatedPrePostIrrig4500(value, irrigUnits, irrigDecimals){
            var formatedValue;
            switch(irrigUnits){
                case IRRIGATIONS_UNITS_A4500.HHMM: // hh:mm
                    formatedValue = fromSecondsToHHMM(value);
                    //formatedValue += " hh:mm";
                    break;
                case IRRIG_UNITS_A4500.M3: case IRRIG_UNITS_A4500.M3HA: // m3 - m3/ha
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, irrigDecimals);
                    formatedValue += " m3";
                    break;
                case IRRIGATIONS_UNITS_A4500.MMSS: // mm:ss
                    formatedValue = fromSecondsToMMSS(value); 
                    break;
                case IRRIG_UNITS_A4500.M3HAT: // m3/ha(t)
                    formatedValue = fromSecondsToHHMM(value);
                    break;
                case IRRIG_UNITS_A4500.MM: // mm
                    formatedValue = value / 1000;
                    formatedValue = formatWithDecimals(formatedValue, irrigDecimals);
                    formatedValue += " m3";
                    break;
            }
            return formatedValue;
        }
        
        function getFormatedFertilizationA4500(value, fertUnits){
            var formatedValue;
            switch(fertUnits){
                case FERT_UNITS_A4500.HOURS_MINUTES:
                    formatedValue = fromSecondsToHHMM(value);
                    break;
                case FERT_UNITS_A4500.MINUTES_SECONDS:
                    formatedValue = fromSecondsToMMSS(value); 
                    break;
                case FERT_UNITS_A4500.LITERS:
                    formatedValue = value/100 + " L";
                    break;
                case FERT_UNITS_A4500.LITERS_Ha:
                    formatedValue = value/100 + " L/Ha";
                    break;
            }
            return formatedValue;
        }

        function getFormatedFitoA4500(value, fitoUnits){
            var formatedValue;
            switch(fitoUnits){
                case FERT_UNITS_A4500.HOURS_MINUTES:
                    formatedValue = fromSecondsToHHMM(value);
                    break;
                case FERT_UNITS_A4500.MINUTES_SECONDS:
                    formatedValue = fromSecondsToMMSS(value); 
                    break;
                case FERT_UNITS_A4500.LITERS:
                    formatedValue = value/100 + " L";
                    break;
                case FERT_UNITS_A4500.LITERS_Ha:
                    formatedValue = value/100 + " L/Ha";
                    break;
            }
            return formatedValue;
        }

        function fromSecondsToHHMM(seconds) {
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            let secondsRemainder = seconds % 60;
            
            if(secondsRemainder > 0){
                minutes += 1;
                if(minutes === 60){
                    minutes = 0;
                    hours += 1;
                }
            }
    
            // Add leading zero to minutes and seconds if needed
            
            let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
            let formattedHours = hours < 10 ? "0" + hours : hours;
            
            return formattedHours + ":" + formattedMinutes;
        }

        function fromSecondsToMMSS(seconds) {
            let minutes = Math.floor(seconds / 60);
            let remainingSeconds = seconds % 60;
            
            // Add leading zero if necessary
            if (minutes < 10) {
              minutes = "0" + minutes;
            }
            if (remainingSeconds < 10) {
              remainingSeconds = "0" + remainingSeconds;
            }
            
            return `${minutes}' ${remainingSeconds}"`;
        }

        function fromMinutesToHHMM(minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
            return `${formattedHours}:${formattedMinutes}`;
          }
          

    }

})();