import {
  MapDevicesItem,
  MapItem,
  MapMarkerItem,
  MapModulesItem,
  MapsApi,
  MapSectorItem,
  MapSensorsAnalogItem,
  MapSensorsCalculatedItem,
  MapDigitalSensorItem,
  MapSensorsMeterItem,
  MapSharedPermissionItem,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '../../di';
import { VeggaResponse } from '../../http/veggaResponse';
import { HttpErrorResponse } from '../../http';
import { VeggaMapsStatus } from './maps-legacy.interface';

@Injectable('mapsLegacyFacade')
export class MapsLegacyFacade {
  private markersResponse = new VeggaResponse<MapMarkerItem[], HttpErrorResponse>();
  private mapsResponse = new VeggaResponse<MapMarkerItem[], HttpErrorResponse>();
  private mapsSectorsAreaResponse = new VeggaResponse<MapSectorItem[], HttpErrorResponse>();
  private mapsSectorsStatusResponse = new VeggaResponse<VeggaMapsStatus, HttpErrorResponse>();
  private mapModulesResponse = new VeggaResponse<MapModulesItem[], HttpErrorResponse>();
  private mapsApi: MapsApi;
  private mapsDevicesResponse = new VeggaResponse<MapDevicesItem[], HttpErrorResponse>();
  private mapsSensorsCalculatedResponse = new VeggaResponse<MapSensorsCalculatedItem[], HttpErrorResponse>();
  private mapsSensorsAnalogResponse = new VeggaResponse<MapSensorsAnalogItem[], HttpErrorResponse>();
  private mapsSensorsDigitalResponse = new VeggaResponse<MapDigitalSensorItem[], HttpErrorResponse>();
  private mapsSensorsMeterResponse = new VeggaResponse<MapSensorsMeterItem[], HttpErrorResponse>();
  private mapPermissionsResponse = new VeggaResponse<MapSharedPermissionItem[], HttpErrorResponse>();

  get markers$(): Observable<MapMarkerItem[]> {
    return this.markersResponse.value$;
  }

  get markersError$(): Observable<HttpErrorResponse> {
    return this.markersResponse.error$;
  }

  get markers(): MapMarkerItem[] {
    return this.markersResponse.value;
  }

  get maps$(): Observable<MapMarkerItem[]> {
    return this.mapsResponse.value$;
  }

  get mapsError$(): Observable<HttpErrorResponse> {
    return this.mapsResponse.error$;
  }

  get maps(): MapItem[] {
    return this.mapsResponse.value;
  }

  get mapSectorsArea$(): Observable<MapSectorItem[]> {
    return this.mapsSectorsAreaResponse.value$;
  }

  get mapSectorsAreaErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSectorsAreaResponse.error$;
  }

  get mapSectorsArea(): MapSectorItem[] {
    return this.mapsSectorsAreaResponse.value;
  }

  get mapDevices$(): Observable<MapDevicesItem[]> {
    return this.mapsDevicesResponse.value$;
  }

  get mapDevicesErrors$(): Observable<HttpErrorResponse> {
    return this.mapsDevicesResponse.error$;
  }

  get mapDevices(): MapDevicesItem[] {
    return this.mapsDevicesResponse.value;
  }

  get mapSectorsStatus$(): Observable<VeggaMapsStatus> {
    return this.mapsSectorsStatusResponse.value$;
  }

  get mapSectorsStatusErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSectorsStatusResponse.error$;
  }

  get mapSectorsStatus(): VeggaMapsStatus {
    return this.mapsSectorsStatusResponse.value;
  }

  get mapModules$(): Observable<MapModulesItem[]> {
    return this.mapModulesResponse.value$;
  }

  get getModulesErrors$(): Observable<HttpErrorResponse> {
    return this.mapModulesResponse.error$;
  }

  get mapModules(): MapModulesItem[] {
    return this.mapModulesResponse.value;
  }

  get mapsSensorsCalculated$(): Observable<MapSensorsCalculatedItem[]> {
    return this.mapsSensorsCalculatedResponse.value$;
  }
  get mapsSensorsCalculatedErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSensorsCalculatedResponse.error$;
  }

  get mapsSensorsCalculated(): MapSensorsCalculatedItem[] {
    return this.mapsSensorsCalculatedResponse.value;
  }

  get mapsSensorsAnalog$(): Observable<MapSensorsAnalogItem[]> {
    return this.mapsSensorsAnalogResponse.value$;
  }
  get mapsSensorsAnalogErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSensorsAnalogResponse.error$;
  }

  get mapsSensorsAnalog(): MapSensorsAnalogItem[] {
    return this.mapsSensorsAnalogResponse.value;
  }

  get mapsSensorsDigital$(): Observable<MapDigitalSensorItem[]> {
    return this.mapsSensorsDigitalResponse.value$;
  }
  get mapsSensorsDigitalErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSensorsDigitalResponse.error$;
  }

  get mapsSensorsDigital(): MapDigitalSensorItem[] {
    return this.mapsSensorsDigitalResponse.value;
  }

  get mapsSensorsMeter$(): Observable<MapSensorsMeterItem[]> {
    return this.mapsSensorsMeterResponse.value$;
  }
  get mapsSensorsMeterErrors$(): Observable<HttpErrorResponse> {
    return this.mapsSensorsMeterResponse.error$;
  }

  get mapsSensorsMeter(): MapSensorsMeterItem[] {
    return this.mapsSensorsMeterResponse.value;
  }

  get mapPermissions$(): Observable<MapSharedPermissionItem[]> {
    return this.mapPermissionsResponse.value$;
  }

  get mapPermissionsError$(): Observable<HttpErrorResponse> {
    return this.mapPermissionsResponse.error$;
  }

  get mapPermissions(): MapSharedPermissionItem[] {
    return this.mapPermissionsResponse.value;
  }

  constructor() {
    this.mapsApi = new MapsApi();
    this.mapsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  /**
   * Method to load the maps  for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMaps() {
    const req$ = this.mapsApi.getMaps();

    req$.subscribe({
      next: maps => {
        this.mapsResponse.set(maps);
      },
      error: err => {
        this.mapsResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to loads the map markers for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMarkers(mapId: string) {
    const req$ = this.mapsApi.getMapMarkers({ id: mapId });

    req$.subscribe({
      next: markers => {
        this.markersResponse.set(markers);
      },
      error: err => {
        this.markersResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to load the map sectors for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapSectorsArea(id: string) {
    const req$ = this.mapsApi.getMapSectors({ id });

    req$.subscribe({
      next: mapSectorsArea => {
        this.mapsSectorsAreaResponse.set(mapSectorsArea);
      },
      error: err => {
        this.mapsSectorsAreaResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to load the map devices for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapDevices(id: string) {
    const req$ = this.mapsApi.getMapDevices({ id });

    req$.subscribe({
      next: mapDevices => {
        this.mapsDevicesResponse.set(mapDevices);
      },
      error: err => {
        this.mapsDevicesResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to load the map sector status for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapSectorsStatus(id: string) {
    const req$ = this.mapsApi.getMapSectorsStatus({ id });

    req$.subscribe({
      next: mapSectorsStatus => {
        this.mapsSectorsStatusResponse.set({
          irrigationStatus: mapSectorsStatus,
        });
      },
      error: err => {
        this.mapsSectorsStatusResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to load the maps sector water disp for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapSectorsWaterDisp(id: string) {
    const req$ = this.mapsApi.getMapSectorsWaterDisp({ id });

    req$.subscribe({
      next: mapSectorsStatus => {
        this.mapsSectorsStatusResponse.set({ waterDisp: mapSectorsStatus });
      },
      error: err => {
        this.mapsSectorsStatusResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to load the maps modules for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapModules(id: string) {
    const req$ = this.mapsApi.getMapModules({ id });

    req$.subscribe({
      next: mapModules => {
        this.mapModulesResponse.set(mapModules);
      },
      error: err => {
        this.mapModulesResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to loads the maps sensors calculated (ETO, DPV and water available) for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapsSensorsCalculated(id: string) {
    const req$ = this.mapsApi.getMapSensorsCalculated({ id });
    req$.subscribe({
      next: mapsSensorsCalculated => {
        this.mapsSensorsCalculatedResponse.set(mapsSensorsCalculated);
      },
      error: err => {
        this.mapsSensorsCalculatedResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to loads the maps sensors analog for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapsSensorsAnalog(id: string) {
    const req$ = this.mapsApi.getMapSensorsAnalog({ id });
    req$.subscribe({
      next: mapsSensorsAnalog => {
        this.mapsSensorsAnalogResponse.set(mapsSensorsAnalog);
      },
      error: err => {
        this.mapsSensorsAnalogResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to loads the maps sensors digital for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapsSensorsDigital(id: string) {
    const req$ = this.mapsApi.getMapSensorDigital({ id });
    req$.subscribe({
      next: mapsSensorsDigital => {
        this.mapsSensorsDigitalResponse.set(mapsSensorsDigital);
      },
      error: err => {
        this.mapsSensorsDigitalResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to loads the maps sensors meter for a given ID.
   *
   * @param id - The ID of the map.
   */
  loadMapsSensorsMeter(id: string) {
    const req$ = this.mapsApi.getMapSensorsMeter({ id });
    req$.subscribe({
      next: mapsSensorsMeter => {
        this.mapsSensorsMeterResponse.set(mapsSensorsMeter);
      },
      error: err => {
        this.mapsSensorsMeterResponse.setError(err, {});
      },
    });
  }

  /**
   * Method to validate user permissions before shared map
   *
   * @param id - The ID of the map.
   * @param user - The user to validate permission
   */
  validateMapPermissions(id: string, user: string) {
    const req$ = this.mapsApi.getUserMapPermission({ id, user });
    req$.subscribe({
      next: mapPermissions => {
        this.mapPermissionsResponse.set(mapPermissions);
      },
      error: (err: HttpErrorResponse) => {
        this.mapPermissionsResponse.setError(err, {});
      },
    });
  }

  deleteMarker(markerId: string): void {
    this.markersResponse.set(this.markers.filter((marker: MapMarkerItem) => marker.id !== markerId));
  }

  addMarker(marker: MapMarkerItem): void {
    this.markersResponse.set([...this.markers, marker]);
  }

  deleteMap(mapId: string): void {
    this.mapsResponse.set(this.maps.filter((map: MapItem) => map.id !== mapId));
  }

  addMap(map: MapItem): void {
    this.mapsResponse.set([...this.maps, map]);
  }

  clearMarkerResponse(): void {
    this.markersResponse.clear();
  }

  clearMapResponse(): void {
    this.mapsResponse.clear();
  }

  clearMapSectors(): void {
    this.mapsSectorsAreaResponse.clear();
    this.mapsSectorsStatusResponse.clear();
  }

  clearSectorAreas(): void {
    this.mapsSectorsAreaResponse.clear();
  }

  clearMapSectorsStatus(): void {
    this.mapsSectorsStatusResponse.clear();
  }

  clearMapModules(): void {
    this.mapModulesResponse.clear();
  }
}
