import { UNITS } from "../../utils/units.enum";

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('gprsController', gprsController);

  gprsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'commFactory',
    'GPRS',
  ];

  function gprsController($log, $rootScope, $scope, $state, Restangular, $mdDialog, UserData, commFactory, GPRS) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.open = open;
        if($state.params.unit !== null){

            vm.currentUnit = $state.params.unit;
            vm.gprs = GPRS;
            backup = angular.copy(vm.gprs);
            vm.gprs.pin = vm.gprs.pin || '0000';

        }else{
            $state.go('units');
        }
    }

    function save(_ev, form) {
      vm.form.$setPristine();
      commFactory.updateGPRS(vm.currentUnit.id, vm.gprs).then((result) => {
        vm.gprs = result;
        backup = angular.copy(vm.gprs);
      });
      $rootScope.$broadcast('refresh', { message: 'refreshing' });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.gprs = angular.copy(backup);
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
