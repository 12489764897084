(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('regulationEngineFlowController', regulationEngineFlowController)

        regulationEngineFlowController.$inject = ['$state', '$scope', '$rootScope', 'headerRegulationFactory', '$q','configFactory'];

    function regulationEngineFlowController($state, $scope, $rootScope, headerRegulationFactory, $q,configFactory) {

        var vm = this;      
        var headerScalingEngines_backup = []; 
        var header_backup = []; 

        activate();

        function activate() {
            vm.currentState = $state;
            if ($state.params.unit !== null) {
                vm.save = save;
                vm.cancel = cancel_edition;
                vm.currentUnit = $state.params.unit;
                vm.currentHeader = $state.params.headerId || '1';
                loadHeaderScalingEngines();
                loadGeneralHeader();

            } else {
                $state.go('units');
            }
        }

        function backup(){
            angular.copy(vm.headerScalingEngines, headerScalingEngines_backup);  
            angular.copy(vm.generalHeader, header_backup);         
       
        }

        function loadGeneralHeader(){
            configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader).then((result)=>{            
                vm.generalHeader = result.plain();
                angular.copy(vm.generalHeader, header_backup); 
            });
        } 

        function loadHeaderScalingEngines() {
            headerRegulationFactory.getHeaderScalingEngines(vm.currentUnit.id, vm.currentHeader).then(function (data) {
                vm.headerScalingEngines = data.plain();
                angular.copy(vm.headerScalingEngines, headerScalingEngines_backup);  
            });
        }

        function save(){            
            let queries = [];
            queries.push(
                configFactory.updateConfigHeader(vm.currentUnit.id,vm.generalHeader),
                headerRegulationFactory.updateHeaderScalingEngines(vm.currentUnit.id, vm.currentHeader, vm.headerScalingEngines)                 
            );
            $q.all(queries).then(response => {
                vm.form.$setPristine();                      
                backup();        
            });  
            
        }     

        function cancel_edition() {
            vm.form.$setPristine();
            angular.copy(headerScalingEngines_backup, vm.headerScalingEngines);
            angular.copy(header_backup, vm.generalHeader);
        }

        /**
         * Emetem estat del formulari quan detecta canvis en l'objecte.
         */
        $scope.$watch(()=> vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });
        
        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save(e,vm.form);
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });

    }
})();