import moment from 'moment/moment';

(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('others45clockController', others45clockController)

    others45clockController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'filterFactory'];

    function others45clockController($rootScope, $scope, $state, configFactory, filterFactory) {

        var vm = this;
        var installer_backup = {};
        vm.installer = {};
        vm.save;
        vm.cancel;

        activate();

        function activate() {
            vm.currentState = $state.includes;
            if ($state.params.unit !== null) {
                vm.currentUnit = $state.params.unit;
                angular.copy(vm.currentUnit.installer,vm.installer);
                angular.copy(vm.currentUnit.installer,installer_backup);
                vm.save = save;
                vm.cancel = cancel_edition;
                vm.changeMaxDays = changeMaxDays;
                setMaxDayMonthPeriods();
                vm.timeZone = {
                    0: '+0',
                    1: '+1',
                    2: '+2',
                    3: '+3',
                    4: '+3:30',
                    5: '+4',
                    6: '+4:30',
                    7: '+5',
                    8: '+5:30',
                    9: '+6',
                    10: '+7',
                    11: '+8',
                    12: '+9',
                    13: '+9:30',
                    14: '+10',
                    15: '+11',
                    16: '+12',
                    17: '-11',
                    18: '-10',
                    19: '-9',
                    20: '-8',
                    21: '-7',
                    22: '-6',
                    23: '-5',
                    24: '-4',
                    25: '-3:30',
                    26: '-3',
                    27: '-2',
                    28: '-1'
                }
            } else {
                $state.go('units');
            }

        }

        function setMaxDayMonthPeriods() {
            vm.maxDays = [];
            let month, days;
            month = vm.installer.startMonthSummer;
            days = moment("2016-" + month, "YYYY-MM").daysInMonth();
            vm.maxDays.push(days);
            month = vm.installer.endMonthSummer;
            days = moment("2016-" + month, "YYYY-MM").daysInMonth();
            vm.maxDays.push(days);
        }

        function changeMaxDays(e) {
            if (e == 'start') {
                let month = vm.installer.startMonthSummer;
                if (month > 0) {
                    vm.maxDays[0] = moment("2016-" + month, "YYYY-MM").daysInMonth();
                    if (vm.installer.startDaySummer > vm.maxDays[0]) {
                        vm.installer.startDaySummer = vm.maxDays[0];
                    }
                }
            } else if (e == 'end') {
                let month = vm.installer.startMonthSummer;
                if (month > 0) {
                    vm.maxDays[1] = moment("2016-" + month, "YYYY-MM").daysInMonth();
                    if (vm.installer.endDaySummer > vm.maxDays[1]) {
                        vm.installer.endDaySummer = vm.maxDays[1];
                    }
                }
            }
        }


        function save() {
            vm.installer.unittype = vm.currentUnit.type;
            filterFactory.updateInstaller(vm.currentUnit.id, vm.installer).then(res => {                
                angular.copy(vm.installer,installer_backup);
                vm.form.$setPristine();
            })
        }

        function cancel_edition() {
            vm.form.$setPristine();
            angular.copy(installer_backup, vm.installer);
        }

        /**
        * Emetem estat del formulari quan detecta canvis en l'objecte.
        */
        $scope.$watch(() => vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });

        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save(e, vm.form);
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });

    }
})();