import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyIntegratedsController', historyIntegratedsController);

  historyIntegratedsController.$inject = ['$scope', '$state', 'UserData', 'historyFactory', '$filter'];

  function historyIntegratedsController($scope, $state, UserData, historyFactory, $filter) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.to = moment().format('DD-MM-YYYY');
        vm.maxDateValue = moment().format('DD-MM-YYYY');
        vm.updateRangeDates = updateRangeDates;
        vm.loadHistory = loadHistory;
        vm.exportXLS = exportXLS;
        vm.getHistory = getHistory;
        loaded = false;

        vm.pagination = {
          page: 1,
          limit: 15,
        };

        vm.groupBy = [
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];

        initGrid();

        loadHistory();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.filter.groupBy = '2';
      vm.gridOptions = {
        enableSorting: true,
        enableColumnMenus: false,
        useExternalPagination: true,
        paginationPageSizes: [15, 20, 25],
        paginationPageSize: 15,
        columnDefs: [
          { field: 'checkDates()', displayName: 'history.day', width: '**', headerCellFilter: 'translate' },
          {
            field: 'rain',
            displayName: 'history.rain',
            cellFilter: 'parseunit:"l/m2"',
            width: '**',
            headerCellFilter: 'translate',
          },
          {
            field: 'radiation',
            displayName: 'history.radiation',
            cellFilter: 'parseunit:"Wh/m2"',
            width: '**',
            headerCellFilter: 'translate',
          },
          {
            field: 'temp',
            displayName: 'history.temp',
            cellFilter: 'parseunit:"ºCh"',
            width: '**',
            headerCellFilter: 'translate',
          },
          { field: 'evap', displayName: 'history.evap', width: '**', headerCellFilter: 'translate' },
        ],
        onRegisterApi: function (gridApi) {
          vm.gridApi = gridApi;
          //loadHistory();
        },
      };
    }
    function checkDates() {
      if (this.from === undefined) {
        let varfrom = moment(this['2'], 'DD-MM-YYYY').format('DD-MM-YYYY');
        let varto = moment(this['3'], 'DD-MM-YYYY').format('DD-MM-YYYY');
        return varfrom + ' - ' + varto;
      } else {
        let varfrom = moment(this.from, 'DD-MM-YYYY').format('DD-MM-YYYY');
        let varto = moment(this.to, 'DD-MM-YYYY').format('DD-MM-YYYY');
        return varfrom + ' - ' + varto;
      }
    }
    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean !== undefined) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.integrateds(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
          row.rain = row.rain / 10;
          row.evap = row.evap / 100;
        });
      });
    }
    function exportXLS() {
      var name = moment(vm.from).format('DD-MM-YYYY') + '_' + moment(vm.to).format('DD-MM-YYYY') + '.xlsx';
      historyFactory.export(vm.gridApi.grid, name);
    }
    $scope.$on('refresh', function (event, args) {});

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }
  }
})();
