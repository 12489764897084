(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('regulationPidController', regulationPidController)

    regulationPidController.$inject = ['$state', '$scope', 'headerRegulationFactory', 'configFactory', '$q'];

    function regulationPidController($state, $scope, headerRegulationFactory, configFactory, $q) {

        var vm = this;      
        var headerPid_backup = []; 
        var header_backup = []; 

        activate();

        function activate() {
            vm.currentState = $state;
            if ($state.params.unit !== null) {
                vm.save = save;
                vm.cancel = cancel_edition;
                vm.currentUnit = $state.params.unit;
                vm.currentHeader = $state.params.headerId || '1';
                loadHeaderPid();
                loadGeneralHeader();

            } else {
                $state.go('units');
            }
        }

        function backup(){
            angular.copy(vm.headerPid, headerPid_backup);  
            angular.copy(vm.generalHeader, header_backup);      
        }

        function loadGeneralHeader(){
            configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader).then((result)=>{            
                vm.generalHeader = result.plain();
                angular.copy(vm.generalHeader, header_backup); 
            });
        }

        function loadHeaderPid() {
            headerRegulationFactory.getHeaderPid(vm.currentUnit.id, vm.currentHeader).then(function (data) {
                vm.headerPid = data.plain();
                angular.copy(vm.headerPid, headerPid_backup);
            });
        }

        function save() {
            let queries = [];
            queries.push(
                configFactory.updateConfigHeader(vm.currentUnit.id,vm.generalHeader),
                headerRegulationFactory.updateHeaderPid(vm.currentUnit.id, vm.currentHeader, vm.headerPid)                 
            );
            $q.all(queries).then(response => {
                vm.form.$setPristine();  
                backup();                    
            });  
        }
    
        function cancel_edition() {
            vm.form.$setPristine();
            angular.copy(header_backup, vm.headerPid);
            angular.copy(headerPid_backup, vm.headerPid);
        }

        /**
         * Emetem estat del formulari quan detecta canvis en l'objecte.
         */
        $scope.$watch(()=> vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });
        
        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save(e,vm.form);
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });

    }
})();