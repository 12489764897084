import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('eventsController', eventsController);

  eventsController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'Events',
    'configFactory',
    '$filter'
  ];

  function eventsController($rootScope, $scope, $state, Events, configFactory, $filter) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.getType;
    vm.save;
    vm.cancel;
    vm.UNITS = UNITS;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.events = Events;
        backup = angular.copy(vm.events);
        vm.getType = getType;
        vm.save = save;
        vm.cancel = cancel_edition;

        if(vm.currentUnit.type === UNITS.A_4500){
                    vm.events = vm.events.plain();
                    backup = angular.copy(vm.events);
                    vm.eventsMap = [];
                    let map = [];
                    for(let key in vm.events){
                        let event = vm.events[key];
                        event.absoluteId = Number(key) + 1;
                        backup[key].absoluteId = Number(key) + 1;
                        if(Number(event.pk.groupId) > vm.eventsMap.length + 1){
                            vm.eventsMap.push(map);
                            map = [];
                        }
                        map.push(event);
                    }
                    vm.eventsMap.push(map);
                    vm.tableHeaders = [

                        $filter('translate')('general.device'),    // Equipo
                        $filter('translate')('cond.manual'),       // Manual
                        $filter('translate')('fert.program'),      // Programa
                        $filter('translate')('totals.sector'),     // Sector
                        $filter('translate')('sensors.name'),      // Sensores
                        $filter('translate')('cond.title'),        // Condicionantes
                        $filter('translate')('general.menu15'),    // Comunicaciones
                        $filter('translate')('fert.fert'),         // Fertilizantes
                        $filter('translate')('general.filters'),   // Filtros
                        $filter('translate')('general.waterm'),    // Mezcla de aguas
                        $filter('translate')('general.diesel'),    // Diesel
                        $filter('translate')('general.menu18'),    // Nebulizacion
                        $filter('translate')('calcs.ldrenaje'),    // Drenaje
                        $filter('translate')('pivot.pivot'),       // Pivot
                        $filter('translate')('general.menu20'),    // Riego solar
                    ]
                }

                if(vm.currentUnit.type === UNITS.A_7000){
                    vm.types7000 = {0:'Anomalía',1:'Actuación',2:'Interno'};
                }
        }else{
            $state.go('units');
        }
    }

    function getType(event) {
      var intern = false;
      switch (event.pk.id) {
        case '46':
        case '47':
        case '53':
        case '54':
        case '55':
        case '56':
        case '58':
        case '59':
        case '60':
          intern = true;
          break;
      }
      if (intern) {
        return { 0: 'Anomalía', 1: 'Actuación', 2: 'Interno' };
      } else {
        return { 0: 'Anomalía', 1: 'Actuación' };
      }
    }

    function saveEvents45(){
        let modifiedEvents = []
        for(let i = 0; i < backup.length; i++){
            if(!_.isEqual(backup[i], vm.events[i])){
                vm.events[i].modified = 1;
                modifiedEvents.push(vm.events[i]);
            }
        }
        configFactory.updateEvents45(vm.currentUnit.id, modifiedEvents).then(result => {
            vm.loading = false;
        });
    }

    function save(){
        vm.form.$setPristine();
        vm.loading = true;
        if(vm.UNITS.A_4500 === vm.currentUnit.type){
            saveEvents45();
        }else{
            _.forEach(vm.events, (event) => {
               event.unittype = vm.currentUnit.type;
            });
            configFactory.updateEvents(vm.currentUnit.id,vm.events).then((result) => {
                vm.events = _.sortBy(result,(o)=>{return Number(o.pk.id);
              });  backup = angular.copy(vm.events);
                vm.loading = false;
            });
        }
    }
    function cancel_edition() {
      vm.events = angular.copy(backup);
    }
    $scope.$on('refresh', function (event, args) {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formToUnitUpdated', vm.form);
      }
    );

    $scope.$on('formFromUnitCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
