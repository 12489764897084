import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyMetersController', historyMetersController);

  historyMetersController.$inject = ['$scope', '$state', 'UserData', 'historyFactory', 'sensorsFactory', '$filter'];

  function historyMetersController(
    $scope,
    $state,

    UserData,
    historyFactory,
    sensorsFactory,
    $filter
  ) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    var loaded;
    vm.pagination;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.from = moment().subtract(7, 'day').format('DD-MM-YYYY');
        if (!UserData.profesional) {
          vm.minDateValue = moment().subtract(7, 'day').format('DD-MM-YYYY');
        } else {
          vm.minDateValue = null;
        }
        vm.to = moment().format('DD-MM-YYYY');
        vm.maxDateValue = moment().format('DD-MM-YYYY');
        vm.updateRangeDates = updateRangeDates;
        vm.loadHistory = loadHistory;
        vm.exportXLS = exportXLS;
        vm.getHistory = getHistory;
        loaded = false;

        vm.pagination = {
          page: 1,
          limit: 15,
        };

        vm.groupBy = [
          { text: '10 ' + $filter('translate')('history.minutes'), value: '0' },
          { text: $filter('translate')('history.hour'), value: '1' },
          { text: $filter('translate')('history.day'), value: '2' },
          { text: $filter('translate')('history.week'), value: '3' },
          { text: $filter('translate')('history.month'), value: '4' },
          { text: $filter('translate')('history.dates'), value: '5' },
        ];

        vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];

        loadMeters();

        initGrid();

        loadHistory();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.filter.groupBy = '2';
      vm.filter.sensor = '0';
      vm.gridOptions = {
        enableSorting: true,
        enableColumnMenus: false,
        useExternalPagination: true,
        paginationPageSizes: [15, 20, 25],
        paginationPageSize: 15,
        columnDefs: getColumnDefinition(vm.currentUnit.type),
        onRegisterApi: function (gridApi) {
          vm.gridApi = gridApi;
        },
      };
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4000:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            { field: 'sensorId', displayName: 'history.sensor', width: '**', headerCellFilter: 'translate' },
            { field: 'sensorName', displayName: 'history.sensorname', width: '**', headerCellFilter: 'translate' },
            { field: 'avg', displayName: 'history.avg', width: '**', headerCellFilter: 'translate' },
            { field: 'max', displayName: 'history.max', width: '**', headerCellFilter: 'translate' },
            { field: 'min', displayName: 'history.min', width: '**', headerCellFilter: 'translate' },
          ];
        case UNITS.A_7000:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            { field: '4', displayName: 'history.sensor', width: '**', headerCellFilter: 'translate' },
            { field: 'getType7()', displayName: 'events.type', width: '**', headerCellFilter: 'translate' },
            { field: 'getVolume7()', displayName: 'history.volume', width: '**', headerCellFilter: 'translate' },
          ];
        case UNITS.A_2500:
        case UNITS.A_BIT:
        case UNITS.A_5500:
          return [
            { field: 'checkDates()', displayName: 'history.fromto', width: '**', headerCellFilter: 'translate' },
            { field: 'sensorId', displayName: 'history.sensor', width: '**', headerCellFilter: 'translate' },
            { field: 'sensorName', displayName: 'history.sensorname', width: '**', headerCellFilter: 'translate' },
            { field: 'avg', displayName: 'history.volume', width: '**', headerCellFilter: 'translate' },
            { field: 'min', displayName: 'history.out', width: '**', headerCellFilter: 'translate' },
          ];
          case UNITS.A_4500:
                return [
                    { field: 'checkDates()' , displayName: 'history.fromto' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '4' , displayName: 'history.sensor' ,  width:'**' , headerCellFilter: 'translate'  },
                    { field: '5' , displayName: 'history.sensorname' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '7' , displayName: 'history.flow' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '9' , displayName: 'history.volume' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '10' , displayName: 'history.out' ,  width:'**' , headerCellFilter: 'translate' },
                    { field: '12' , displayName: 'history.desc' ,  width:'**' , headerCellFilter: 'translate' },
                  ];
      }
    }

    function checkDates() {
      if (this.from === undefined) {
        return this['2'] + ' - ' + this['3'];
      } else {
        return this.from + ' - ' + this.to;
      }
    }

    function getType7() {
      switch (this['6']) {
        case 5:
          return 'Riego';
        case 6:
          return 'Fertilizante';
        default:
          return '';
      }
    }

    function getVolume7() {
      switch (this['6']) {
        case 5:
          let r = Number(this['5']) / 1000;
          isNaN(r) ? (r = 0) : r.toString();
          return r + ' m3';
        case 6:
          let f = Number(this['5']) / 1000;
          isNaN(f) ? (f = 0) : '';
          return f.toString() + ' L';
        default:
          return '';
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
    }

    function loadHistory(clean) {
      if (clean !== undefined) {
        vm.pagination.page = 1;
      }

      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.pagination.page,
        limit: vm.pagination.limit,
      };
      historyFactory.meters(vm.currentUnit.id, params).then((data) => {
        vm.gridOptions.totalItems = data.total;
        vm.gridOptions.data = data.content;
        _.forEach(vm.gridOptions.data, (row) => {
          row.loaded = false;
          row.checkDates = checkDates;
          row.getType7 = getType7;
          row.getVolume7 = getVolume7;
        });
      });
    }

    function exportXLS() {
      var name = moment(vm.from).format('DD-MM-YYYY') + '_' + moment(vm.to).format('DD-MM-YYYY') + '.xlsx';
      historyFactory.export(vm.gridApi.grid, name);
    }

    $scope.$on('refresh', function (event, args) {});

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadMeters() {
      if (vm.currentUnit.type === UNITS.A_4000) {
        sensorsFactory.getDigitals(vm.currentUnit.id).then(function (data) {
          vm.sensorList = _.filter(data.plain(), (o) => {
            return o.pk.id >= 11 && o.pk.id <= 35;
          });
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            })
          );
        });
      } else if (vm.currentUnit.type === UNITS.A_7000) {
        sensorsFactory.getDigitals(vm.currentUnit.id).then(function (data) {
          vm.sensorList = _.filter(data.plain(), (o) => {
            return (o.pk.id >= 1 && o.pk.id <= 14) || (o.pk.id >= 39 && o.pk.id <= 47) || o.pk.id == 18;
          });
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            })
          );
        });
      } else if(vm.currentUnit.type === UNITS.A_4500){
          sensorsFactory.meters(vm.currentUnit.id).then(function(data){
              var sensorList = data.plain();
              vm.sensorListDTO.push(...sensorList.map(sensor => {return {text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id), value: sensor.pk.id}}));
          });
      }else {
        sensorsFactory.metersOne(vm.currentUnit.id).then(function (data) {
          vm.sensorList = data.plain();
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            })
          );
        });
      }
    }
  }
})();
