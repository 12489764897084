export const CONDITIONERS_45= {
    S_DIGITAL: 0,
    S_ANALOG: 1,
    S_LOGIC: 2,
    S_FLOW_METER: 3,
    S_ACCOMULATED_METER: 4,
    FLOW_ERROR: 5,
    CE_ERROR: 6,
    PH_ERROR: 7,
    CE_100: 8,
    PH_100: 9,
    CE_SECURITY: 10,
    PH_SECURITY: 11,
    CE_PROPORTION: 12,
    CE_MIXTURE_ERROR: 13,
    DRAINAGE_ERROR: 14,
    CE_DRAINAGE_ERROR: 15,
    PH_DRAINAGE_ERROR: 16,
    COMMUNICATION:17,
    SCHEDULE:18,
    FERT_TANK: 19
} 