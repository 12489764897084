import { UNITS } from '../utils/units.enum';

(function() {
'use strict';



angular.module('agronicwebApp')

.controller('filtersHeaderConfigController',filtersHeaderConfigController)

filtersHeaderConfigController.$inject = ['$log' ,'$rootScope', '$scope','$state','Restangular','$mdDialog','UserData','filterFactory','$confirm'];

function filtersHeaderConfigController($log ,$rootScope, $scope, $state, Restangular,$mdDialog, UserData,filterFactory,$confirm) {
    
    var vm = this;
    vm.save;
    vm.cancel;
    activate();


    function activate(){
        vm.currentState = $state.includes;
        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;  
            vm.filters = $state.params.filters;
            vm.currentHeader = $state.params.headerId;
            
            $state.go('.header',{filter: vm.filters[Number(vm.currentHeader) -1], headerId:vm.currentHeader})

        }else{
            $state.go('units');
        }
        
    }   
    
}

    
    
})();