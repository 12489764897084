(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .filter('operational', function () {
      return function (input) {
        var temp = [];
        if (_.isArray(input)) {
          _.forEach(input, (val) => {
            if (val !== 0) temp.push(val);
          });
          return temp;
        } else {
          _.forEach(_.values(input), (val) => {
            if (val !== 0) temp.push(val);
          });
          return temp;
        }
      };
    })

    .filter('units', function () {
      return function (input) {
        var temp = [];
        if (_.isArray(input)) {
          return _.filter(input, (obj) => {
            return obj.device.type === 2 || obj.device.type === 3 || obj.device.type === 4 || obj.device.type === 6; //obj.device.connected &&
          });
        }
      };
    })

    .filter('parsetime', function () {
      function pad(str) {
        return ('0' + str).slice(-2);
      }
      function hhmmss(secs, normal) {
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (normal) {
          var response = pad(minutes);
          response = pad(hours) + ':' + pad(minutes);
          return response;
        } else {
          var response = pad(minutes) + 'min';
          if (hours > 0) response = hours + 'h ' + pad(minutes) + 'min';
          return response;
        }
      }
      return function (input, normal) {
        return input === 0 ?'-':hhmmss(input,normal) ;
    };
})
.filter('parsetimehhmmss', function() {
  return function(input) {
     return (input===0)?'-':new Date(input * 1000).toISOString().substring(11, 19);
  };
})
.filter('parsetimesec', function () {
      function pad(str) {
        return ('0' + str).slice(-2);
      }
      function hhmmss(secs, normal) {
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var response = pad(secs) + 's';
        if (minutes > 0) {
          response = minutes + "'" + pad(secs) + '"';
        }
        return response;
      }
      return function (input, normal) {
        return input === 0 ? '-' : hhmmss(input, normal);
      };
    })

    .filter('parsevolume', function () {
      return function (input) {
        return input === 0 ? '-' : (Number(input) / 1000).toFixed(2) + ' m3';
      };
    })
    .filter('parsevolumecl', function () {
      return function (input) {
        return input === 0 ? '-' : (Number(input) / 100).toFixed(2) + ' l';
      };
    })
    .filter('parsevolumedl', function () {
      return function (input) {
        return input === 0 ? '-' : (Number(input) / 10).toFixed(2) + ' l';
      };
    })

    .filter('parsetounit', function () {
      return function (input, divisor, fixed, unit) {
        if (divisor === null) {
          return input === 0 ? '-' : Number(input).toFixed(fixed) + (unit !== undefined ? unit : '');
        } else {
          return input === 0 ? '-' : (Number(input) / divisor).toFixed(fixed) + (unit !== undefined ? unit : '');
        }
      };
    })

    .filter('fixed', function () {
      return function (input, number) {
        return Number(input).toFixed(number);
      };
    })

    .filter('reverse', function () {
      return function (items) {
        if (items !== undefined) {
          return items.slice().reverse();
        } else {
          return [];
        }
      };
    });
})();
