(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('solarController', solarController);

  solarController.$inject = ['$rootScope', '$scope', '$state', 'solarFactory', 'unitFactory'];

  function solarController($rootScope, $scope, $state, solarFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.getType;
    vm.save;
    vm.cancel;

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.save = save;
        vm.cancel = cancel_edition;
        backup = {};
        loadSolar();
        loadConditioners();
      } else {
        $state.go('units');
      }
    }

    function loadSolar() {
      solarFactory.solar(vm.currentUnit.id).then(
        (data) => {
          vm.solar = data;
          backup = angular.copy(vm.solar);
        },
        () => {}
      );
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((data) => {
        vm.currentUnit.conditioners = data.plain();
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.solar.unittype = vm.currentUnit.type;
      vm.loading = true;
      solarFactory.update(vm.currentUnit.id, vm.solar).then((result) => {
        vm.solar = result;
        backup = angular.copy(vm.solar);
        vm.loading = false;
      });
    }
    function cancel_edition() {
      vm.solar = angular.copy(backup);
      vm.form.$setPristine();
    }

    $scope.$on('refresh', function (event, args) {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formToUnitUpdated', vm.form);
      }
    );

    $scope.$on('formFromUnitCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
