(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('commFactory', commFactory);

  commFactory.$inject = ['Restangular', '$q'];

  function commFactory(Restangular, $q) {
    var op = {
      pcComm: pccomm,
      updatePcComm: updatePcComm,
      linkBox: linkBox,
      linkBoxEM: linkBoxEM,
      externalModule: externalModule,
      module: module,
      updateModule: updateModule,
      updateLinkBox: updateLinkBox,
      updateSMS: updateSMS,
      SMS: SMS,
      updatePSEP: updatePSEP,
      PSEP: PSEP,
      updateModBusClient: updateModBusClient,
      modBusClient: modBusClient,
      modBusExterno: modBusExterno,
      updateModBusExterno: updateModBusExterno,
      GPRS: GPRS,
      updateGPRS: updateGPRS,
      Wifi: Wifi,
      updateWifi: updateWifi,
      updateEM: updateEM,
      getDavis:getDavis,
      updateDavis:updateDavis
    };

    return op;

    function pccomm(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('pccomm')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updatePcComm(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('pccomm')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function linkBox(id, type) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('linkbox', type)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function linkBoxEM(id, type) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('linkbox', type)
        .one('em')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function linkBoxEM(id,type,linkboxId){
          var deferred = $q.defer();
          Restangular.one('units',id).one('linkbox',type).one('',linkboxId).one('em').get().then(function(response){
              deferred.resolve(response);
          });
          return deferred.promise;
    }
    function externalModule(id, type, module) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('linkbox', type)
        .one('em', module)
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function module(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('module')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateLinkBox(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('linkbox')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateModule(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('module')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function SMS(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('sms')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateSMS(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('sms')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function PSEP(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('psep')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updatePSEP(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('psep')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function modBusClient(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('modbus')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateModBusClient(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('modbus')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function modBusExterno(id){
        var deferred = $q.defer();
        Restangular.one('units',id).one('external-modbus').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function updateModBusExterno(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('external-modbus').customPOST(params).then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function GPRS(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('gprs')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateGPRS(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('gprs')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function Wifi(id) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('wifi')
        .get()
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateWifi(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('wifi')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function updateEM(id, params) {
      var deferred = $q.defer();
      Restangular.one('units', id)
        .one('em')
        .customPOST(params)
        .then(function (response) {
          deferred.resolve(response);
        });
      return deferred.promise;
    }
    function getDavis(id){
        var deferred = $q.defer();
        Restangular.one('units',id).one('davis').get().then(function(response){
            deferred.resolve(response);
        });
        return deferred.promise;
    }
    function updateDavis(id,params){
        var deferred = $q.defer();
        Restangular.one('units',id).one('davis').customPOST(params).then(function(response){
                deferred.resolve(response);
            });
        return deferred.promise;
    }

}

})();
