(function() {
    'use strict';


    angular.module('agronicwebApp')

    .controller('outAcidsController',outAcidsController)

    outAcidsController.$inject = ['$scope','$state','configFactory'];

    function outAcidsController($scope, $state, configFactory) {
        
        var vm = this;
        var backup;
        vm.activeList;
        vm.save;
        vm.cancel;
        vm.config;
        vm.currentHeader;
        activate();

        function activate(){
            vm.currentState = $state.includes;       
            vm.save = save;
            vm.cancel = cancel_edition;
            if($state.params.unit !== null){
                vm.currentHeader = $state.params.headerId || 1;
                vm.currentUnit = $state.params.unit;
                loadAcids();
                vm.selected = 0;

            }else{
                $state.go('units');
            }        
        }

        function loadAcids(){
            var params = {add:['acids']};
            configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result)=>{            
                vm.config = result.plain();
                backup = angular.copy(vm.config);
            });
        }
        

        function save(){
            configFactory.updateConfigHeader(vm.currentUnit.id,vm.config).then(result => {
                vm.config = result;
                if(vm.config.acids){
                    vm.config.acids = _.sortBy(vm.config.acids,[function(o) { return o.pk.id; }]);
                } 
                vm.form.$setPristine();            
                backup = angular.copy(vm.config);
            });
            
        }
        function cancel_edition(){
            vm.form.$setPristine();
            vm.config = angular.copy(backup);
        }
        $scope.$on('refresh',function(event,args){
            
        });
        
        /**
         * Emetem estat del formulari quan detecta canvis en l'objecte.
         */
        $scope.$watch(()=> vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });
        
        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save();
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });     
    }

    
    
})();