import { useState } from "react";

import DropdownItem from "./dropdown-item";
import { dropdownItems } from "../../navbar.options";

interface INavDropdown {
  username: string;
  logOut: () => void;
  closeSidebarMenu: () => void;
}

const NavDropdown = ({ username, logOut, closeSidebarMenu }: INavDropdown) => {
  const [isDropdownUnfolded, setIsDropdownUnfolded] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownUnfolded(!isDropdownUnfolded);
  };
  return (
    <>
      <div className="vegga-navbar__profile" onClick={toggleDropdown}>
        <vegga-icon icon="profile"></vegga-icon>
        <vegga-text class="light">{username}</vegga-text>
        <vegga-icon
          class="vegga-navbar__profile-arrow"
          icon="arrow-down"
        ></vegga-icon>
      </div>
      {isDropdownUnfolded && (
        <div
          className="vegga-navbar__profile-options"
        >
          {dropdownItems.map((item, index) => {
            const { label, linkTo, flag, isLink, features } = item;

            return (
              <DropdownItem
                key={index}
                flag={flag}
                label={label}
                linkTo={linkTo}
                isLink={isLink}
                logOut={logOut}
                toggleDropdown={toggleDropdown}
                closeSidebarMenu={closeSidebarMenu}
                features={features}
              />
            );
          })}
        </div>
      )}
      {isDropdownUnfolded && (
        <div
          className="vegga-navbar__profile-backdrop"
          onClick={() => {
            setIsDropdownUnfolded(false);
          }}
        ></div>
      )}
    </>
  );
};

export default NavDropdown;
