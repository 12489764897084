import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outFertilizersController', outFertilizersController);

  outFertilizersController.$inject = [
    '$scope',
    '$state',
    'configFactory',
  ];

  function outFertilizersController($scope, $state, configFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;
    activate();

    function activate(){
        vm.loaded = false;
        vm.currentState = $state.includes;
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.open = open;
        vm.UNITS = UNITS;
        if($state.params.unit !== null){
            vm.currentHeader = $state.params.headerId || 1;
            vm.currentUnit = $state.params.unit;
            if(vm.currentUnit.type === UNITS.A_4500){
                loadFertilizers45();
            }else{
                loadFertilizers();
            }
        //vm.fertNum = Number(vm.currentUnit?.fertilizer?.fertNum ?? 10);
        vm.selected = 0;
      } else {
        $state.go('units');
      }
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadFertilizers45(){
        var params = {add:['fertilizers','agitators']};
        configFactory.getHeaders(vm.currentUnit.id, vm.currentHeader, params).then((result)=>{
            vm.config = result.plain();
            backup = angular.copy(vm.config);
            vm.loaded = true;
        });
    }

    function loadFertilizers(){
        var params = {add:['fertilizer','agitators']};
        configFactory.get(vm.currentUnit.id,params).then((result)=>{

            vm.config = result.plain();
            backup = angular.copy(vm.config);
            vm.loaded = true;
        });

    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = vm.currentUnit.type;
      if(vm.currentUnit.type === UNITS.A_4500){
            configFactory.updateConfigHeader(vm.currentUnit.id,vm.config).then(result => {
                vm.config = result;
                if(vm.config.fertilizers){
                    vm.config.fertilizers = _.sortBy(vm.config.fertilizers,[function(o) { return o.pk.id; }]);
                    vm.config.agitators = _.sortBy(vm.config.agitators,[function(o) { return o.pk.id; }]);
                }
                backup = angular.copy(vm.config);
            });
        }else{
            configFactory.updateConfig(vm.currentUnit.id,vm.config).then((result) => {
                vm.config = result;
                if(vm.config.fertilizers){
                    vm.config.fertilizers = _.sortBy(vm.config.fertilizers,[function(o) { return o.pk.id;
                  },
            ]);
            vm.config.agitators = _.sortBy(vm.config.agitators, [
              function (o) {
                return o.pk.id;
                } ,
                ]);
          }backup = angular.copy(vm.config);
            });
        }

    }
    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }
    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
