(function() {
  "use strict";


  angular.module("agronicwebApp")

    .controller("generalHeadersController", generalHeadersController);

  generalHeadersController.$inject = ["$scope", "$state", "$confirm", "$filter"];

  function generalHeadersController($scope, $state, $confirm, $filter) {

    var vm = this;
    vm.currentHeader;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.changeState = changeState;
      vm.changeHeader = changeHeader;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;

        vm.selected = 0;

        vm.currentHeader = $state.params.headerId || "1";
        $state.go(".filters", {
          unit: vm.currentUnit,
          id: vm.currentUnit.id,
          headerId: vm.currentHeader
        });

      } else {
        $state.go("units");
      }

    }

    function changeHeader(params) {
      vm.currentState.url = $state.current.name;
      vm.currentHeader = params.headerId;
      $state.go(vm.currentState.url, params);
    }

    function changeState(location, params) {
      vm.currentHeader = params.headerId;
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter("translate")("programs.edit.cancelq"),
          title: $filter("translate")("programs.prog2")
        }).then(() => {
          $scope.$broadcast("formCancel"); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          vm.form = null;
          $state.go(location, params); //Canviem d'estat
        });
      } else {
        $state.go(location, params);
      }
    }

    $scope.$on("refresh", function(event, args) {

    });
  }


})();