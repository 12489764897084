export enum A4500CommunicationModulesTabsEnum {
  AGRONIC_MONOCABLE_LINK_120 = 'eam_120',
  RADIO_433 = 'radio_433',
  AGROBEE_L_1 = 'agrobee-l-1',
  AGROBEE_L_2 = 'agrobee-l-2',
  MODBUS_PORT = 'modbus_port',
  MODBUS_EXTERNAL = 'modbus_external',
  DAVIS = 'davis',
  EXPANSION_MODULES = 'expansion-modules',
  SDI_12 = 'sdi-12',
}
