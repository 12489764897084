(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('regulationModulationController', regulationModulationController)

        regulationModulationController.$inject = ['$state','$scope','$rootScope','fertilizerFactory','filterFactory','$q'];

    function regulationModulationController($state,$scope,$rootScope,fertilizerFactory,filterFactory,$q) {

        var vm = this;
        let allFertilizationHeaders, allGeneralHeader;
        vm.activeList;
        vm.getType;
        vm.save;
        vm.cancel;

        activate();

        function activate() {
            vm.currentState = $state;
            if ($state.params.unit !== null) {
                vm.save = save;
                vm.cancel = cancel_edition;
                vm.currentUnit = $state.params.unit;                
                loadInstaller();           
            } else {
                $state.go('units');
            }
        }

        function loadInstaller(){
            filterFactory.getInstaller(vm.currentUnit.id).then(function(data){            
                vm.installer = data.plain();
            });
        }

        function loadFertilizationHeaders(){
            fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id).then(data => {
                allFertilizationHeaders = data.plain();
                vm.fertilizationHeader = allFertilizationHeaders[vm.currentHeader - 1];
            });
        }

        function save() {
            if($state.params.headerId != null){
                fertilizerFactory.updateFertilizationHeader(vm.currentUnit.id, [vm.fertilizationHeader]).then(response => {
                    vm.form.$setPristine();
                    vm.fertilizationHeader = response[0].plain();
                });
            }else{
                vm.installer.unittype = vm.currentUnit.type;
                filterFactory.updateInstaller(vm.currentUnit.id,vm.installer).then(res => {
                    vm.form.$setPristine();
                    vm.installer = response[0].plain();
                });
            }
            
        }

        function cancel_edition(){
            vm.form.$setPristine();
        }

        $scope.$on('refresh',function(event,args){
            
        });
        
        /**
         * Emetem estat del formulari quan detecta canvis en l'objecte.
         */
        $scope.$watch(()=> vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });
        
        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save();
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });  

              

        
    }
})();