import { UNITS } from "../../../utils/units.enum";

(function() {
'use strict';


angular.module('agronicwebApp')

.controller('davisController', davisController)

davisController.$inject = ['$scope','$state','commFactory', 'Davis'];

function davisController($scope, $state, commFactory, Davis) {
    
    var vm = this;
    var backup;
    activate();

    function activate(){
        vm.currentState = $state.includes;
        vm.UNITS = UNITS;

        vm.save = save;

        if($state.params.unit !== null){
            vm.currentUnit = $state.params.unit;
            vm.davis = Davis;
        }else{
            $state.go('units');
        }
        
    }

    function save(){
        commFactory.updateDavis(vm.currentUnit.id, vm.davis).then(response => {
            vm.form.$dirty = false;
        });
    }
    
    function cancel_edition(){
        vm.linkBox = angular.copy(backup);
    }

   /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(()=> vm.form, () => {
        $scope.$emit('formUpdated', vm.form);
    });
    
    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
        save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
        //Restaurem estat del formulari a la versió previa.
        cancel_edition();
    }); 

    $scope.$on('refresh',function(event,args){
        
    });
}

    
    
})();