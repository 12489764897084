import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outFiltersController', outFiltersController);

  outFiltersController.$inject = [
    "$scope",
    "$state",
    "configFactory",
    "filterFactory"
  ];

  function outFiltersController(
    $scope,
    $state,
    configFactory,
    filterFactory
  ) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.currentHeader = $state.params.headerId || 1;
        loadFilters();
        vm.selected = 0;
        if (vm.currentUnit.type === UNITS.A_2500) {
          vm.inputsCombo = _.range(0, 28);
        }
      } else {
        $state.go('units');
      }
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadFilters() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        filterFactory.getHeaderFilters(vm.currentUnit.id, vm.currentHeader).then(function(data) {
          vm.filters = [];
          vm.filters.push(data.plain());
          backup = angular.copy(vm.filters[0]);
        });
      } else {
        filterFactory.all(vm.currentUnit.id).then(function(data) {
          vm.filters = data.plain();
          backup = angular.copy(vm.filters);
        });
      }
    }

    function save() {
      vm.form.$setPristine();
      _.forEach(vm.filters, (filter) => {
        filter.unittype = vm.currentUnit.type;
      });

      switch (vm.currentUnit.type) {
        case UNITS.A_4000:

          configFactory.updateFilters(vm.currentUnit.id, vm.filters).then(result => {
            vm.filters = result;
            backup = angular.copy(vm.filters);
          });
          break;
        case UNITS.A_2500:
        case UNITS.A_7000:
        case UNITS.A_5500:
          configFactory.updateFilters25(vm.currentUnit.id, vm.filters).then(result => {
            vm.filters = result;
            backup = angular.copy(vm.filters);
          });
          break;
        case UNITS.A_4500:
          /*
          A_4500 sempre només té un filtre per capçal, per facilitar implementació continuem treballant amb array de filtres
          **/
          vm.filters[0].modifiedForGeneral = true;
          if (vm.filters[0].outputFirst > 0 && vm.filters[0].outputLast > 0) {
            vm.filters[0].numFilters = Math.max(vm.filters[0].outputFirst, vm.filters[0].outputLast) - Math.min(vm.filters[0].outputFirst, vm.filters[0].outputLast) + 1;
          }

          filterFactory.saveHeaderFilter(vm.currentUnit.id, vm.currentHeader, vm.filters[0]).then(result => {
            vm.filters[0] = result.plain();
            backup = angular.copy(vm.filters);
          });
          break;
      }

    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.filters = angular.copy(backup);
    }
    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
