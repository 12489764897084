(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('calcsController', calcsController);

  calcsController.$inject = ['$rootScope', '$scope', '$state'];

  function calcsController($rootScope, $scope, $state) {
    var vm = this;
    vm.activeList;
    vm.currentNavigationIndex = 0;

    vm.menu = [
      { name: 'calcs.waterdisp1', state: 'agroclimatic.water' },
      { name: 'calcs.dpv', state: 'agroclimatic.dpv' },
      { name: 'Punto de Rocío', state: 'agroclimatic.pdr' },
      { name: 'calcs.eto', state: 'agroclimatic.eto' },
      { name: 'calcs.erain', state: 'agroclimatic.rain' },
    ];

    activate();

    function activate() {
      vm.currentState = $state;

      if ($rootScope.devicesArray !== null) {
        vm.currentUnit = $rootScope.devicesArray;
        vm.loadState = loadState;
        vm.navigationSelected = navigationSelected;
        $state.go(vm.menu[0].state);
      } else {
        $state.go('units');
      }
    }

    function loadState(event) {
      const item = vm.menu[event.detail.index];
      if (item && $state.current.parent !== item.state) {
        $state.go(item.state);
      }
    }

    function navigationSelected() {
      vm.menu.forEach((option, index) => {
        if (vm.currentState.includes(option.state)) {
          vm.currentNavigationIndex = index;
        }
      });
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Event listener per gestionar la navegacio
     */
    $scope.$on('stateChanged', (e, args) => {
      navigationSelected();
    });
  }
})();
