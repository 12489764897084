import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { AgroclimaticVarsApi, ListAgroclimaticVarsRequest } from '@vegga-api-clients/irrigation-control-service';

@Injectable('agroclimaticIndicesFacade')
export class AgroclimaticIndicesFacade {
  private agroclimaticVarsResponse = new VeggaResponse();
  private agroclimaticVarsApi: AgroclimaticVarsApi;
  @Inject('httpClient') private httpClient: HttpClient;

  constructor() {
    this.agroclimaticVarsApi = new AgroclimaticVarsApi();
    this.agroclimaticVarsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get agroclimaticVars$(): Observable<unknown> {
    return this.agroclimaticVarsResponse.value$;
  }

  loadAgroclimaticVars(params: ListAgroclimaticVarsRequest = { type: null }) {
    const req$ = this.agroclimaticVarsApi.listAgroclimaticVars(params);

    handleResponse(req$, this.agroclimaticVarsResponse).subscribe({
      next: response => {
        this.agroclimaticVarsResponse.set(response);
      },
      error: err => {
        this.agroclimaticVarsResponse.setError(err, {});
      },
    });
  }
}
