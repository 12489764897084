import { FERT_MODE } from '../utils/enums/programs/programs-fertilization-mode.enum';
import { FERT_TYPE } from '../utils/enums/programs/programs-fertilization-type.enum';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('fertilizersController', fertilizersController);

  fertilizersController.$inject = [
    '$scope',
    '$state',
    'manualFactory',
    '$confirm',
    'progFactory',
    'fertilizerFactory',
    'sensorsFactory',
    '$filter',
    '$q',
    'utilsFactory',
      'configFactory',
      'conditionerFactory'
  ];

  function fertilizersController(
    $scope,
    $state,
    manualFactory,
    $confirm,
    progFactory,
    fertilizerFactory,
    sensorsFactory,
    $filter,
    $q, utilsFactory, configFactory, conditionerFactory
  ) {
    var vm = this;
    vm.activeList;
    vm.overlayEl;
    vm.formFertilizer;
    vm.tabState = 'general';

    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.formatFertilizerUnit = formatFertilizerUnit;
        vm.formatSignedIrr = formatSignedIrr;
        vm.formatSignedFert = formatSignedFert;
        vm.getCalculatedFert = getCalculatedFert;
        vm.getTotalFert = getTotalFert;
        vm.getParsed = getParsed;
        vm.checkValue = checkValue;
        vm.formatFert55 = formatFert55;
        vm.getFertF5Text55 = getFertF5Text55;
        vm.endAlarmPH = endAlarmPH;
        vm.endAlarmCE = endAlarmCE;
        vm.showOverlay = showOverlay;
        vm.save = save;
        vm.cancel = cancel;
        vm.changeTab = changeTab;
        vm.getProgrammedFertValueMax = getProgrammedFertValueMax;
           vm.getProgrammedPropFertValueMax = getProgrammedPropFertValueMax;
           vm.getProgrammedPropIrrigValueMax = getProgrammedPropIrrigValueMax;
           vm.getFormatedFert = getFormatedFert;
           vm.getFormatedIrrig = getFormatedIrrig;
           vm.getFormatedQuantity = getFormatedQuantity;
           vm.getFormatedFito = getFormatedFito;
           vm.existsFertValue = existsFertValue;
           vm.existsCEFertValue = existsCEFertValue;
           vm.existsPropFertValue = existsPropFertValue;
           vm.showHeaders = showHeaders;
           vm.getFertilizerTypeLabel = getFertilizerTypeLabel;
           vm.getProgrammedFitoValue1 = getProgrammedFitoValue1;
           vm.getProgrammedFitoValue2 = getProgrammedFitoValue2;
           vm.getProgrammedReferencePH = getProgrammedReferencePH;
           vm.UNITS = UNITS;
           vm.FERT_MODE = FERT_MODE;
           vm.FERT_TYPE = FERT_TYPE;
           if(vm.currentUnit.type === UNITS.A_4500){
               vm.headerId = 1;
           }
           loadPrograms();
        }else{
            $state.go('units');
        }
    }

    function showHeaders(headerId){
        vm.headerId = headerId;
        loadData();
    }

    function loadData(){
        let queries = [];
        queries.push(
            fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id),
            fertilizerFactory.getGeneralFertilizationHeader(vm.currentUnit.id, vm.headerId),
            progFactory.getFitos(vm.currentUnit.id),
            configFactory.getHeaderTanks(vm.currentUnit.id, vm.headerId),
            conditionerFactory.getFertConditioners(vm.currentUnit.id, vm.headerId)
        );

        $q.all(queries).then(response => {
            loadFertilizersHeaders(response[0]);
            loadGeneralFertilizersHeader(response[1]);
            loadFitos(response[2]);
            loadGeneralTanksHeader(response[3]);
            loadFertConditioners(response[4]);
            if(vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.cesecurity > 0){
                loadCESecurityAnalogSensor(vm.fertHeader.cesecurity);
            }
            if(vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.ceinput > 0){
                loadCEEntradaAnalogSensor(vm.fertHeader.ceinput);
            }
            if(vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.pHSecurity > 0){
                loadPHSecurityAnalogSensor(vm.fertHeader.pHSecurity);
            }
            if(vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.pHRegulation > 0){
                loadPHRegulationAnalogSensor(vm.fertHeader.pHRegulation);
            }
        });
    }

    function loadPHSecurityAnalogSensor(sensorId){
        sensorsFactory.analogById(vm.currentUnit.id, sensorId).then(response => {
            let result = response.plain();
            vm.fertHeader.pHSecuritySensor = result;
        });
    }

    function loadPHRegulationAnalogSensor(sensorId){
        sensorsFactory.analogById(vm.currentUnit.id, sensorId).then(response => {
            let result = response.plain();
            vm.fertHeader.pHRegulationSensor = result;
        });
    }

    function loadCESecurityAnalogSensor(sensorId){
        sensorsFactory.analogById(vm.currentUnit.id, sensorId).then(response => {
            let result = response.plain();
            vm.fertHeader.ceSecuritySensor = result;
        });
    }

    function loadCEEntradaAnalogSensor(sensorId){
        sensorsFactory.analogById(vm.currentUnit.id, sensorId).then(response => {
            let result = response.plain();
            vm.fertHeader.ceInputSensor = result;
        });
    }

    function loadFertConditioners(response){
        vm.conditioners = response.plain();
    }

    function loadGeneralTanksHeader(response){
        vm.tanks = response.plain();
    }

    function loadFitos(response){
        let allFitos = response.plain();
        vm.headerFito1 = allFitos[vm.headerId*2 - 2];
        vm.headerFito2 = allFitos[vm.headerId*2 - 1];
        if(vm.fertHeader.xNumProgramTF > 0){
            progFactory.program(vm.currentUnit.id, vm.fertHeader.xNumProgramTF, {'init-program-ferts': true}).then(response => {
                vm.programFertTF = response.plain();
            });
        }
    }

    function loadGeneralFertilizersHeader(response){
        vm.genFertHeader = response.plain();
        for(let genFertHeader of vm.genFertHeader){
            const fertValue = genFertHeader.xQuantity;
            const irrigValue = fertValue % Math.pow(2, 16);
            const newFertValue = (fertValue - irrigValue) / Math.pow(2, 16);
            genFertHeader.xPropFert = newFertValue;
            genFertHeader.xPropIrrig = irrigValue;
        }
        loadProgramsAndFerts();
    }

    function loadProgramsAndFerts(){
        if(vm.fertHeader.xNumProgramCE > 0){
            progFactory.program(vm.currentUnit.id, vm.fertHeader.xNumProgramCE, {'init-program-ferts': true}).then(response => {
                vm.programFerts = response.plain();
                vm.fertType = vm.programFerts.fertType;
            });
        }else{
            for(let fert of vm.genFertHeader){
                if(fert.xNProgram > 0){
                    progFactory.program(vm.currentUnit.id, fert.xNProgram, {'init-program-ferts': true}).then(response => {
                        fert.program = response.plain();
                        // it can be assigned here since fertilizers from same header must be same type
                        vm.fertType = fert.program.fertType;
                    });
                }
            }
        }
    }

    function getProgrammedFertValueMax(genFertHeader, idx){
        let value;
        if(genFertHeader.program.programType === 0){ // subprogram
            eval(`value = genFertHeader.program.progFerts[genFertHeader.program.xSubProgramInProgress-1].fertValue${idx}`);
        }else{
            eval(`value = genFertHeader.program.progFerts[0].fertValue${idx}`);
        }
        return value;
    }

    function getProgrammedPropFertValueMax(genFertHeader, idx){
        let value;
        if(genFertHeader.program.programType === 0){ // subprogram
            eval(`value = genFertHeader.program.progFerts[genFertHeader.program.xSubProgramInProgress-1].proportionalParsedFertValue${idx}_1`);
        }else{ // lineal
            eval(`value = genFertHeader.program.progFerts[0].proportionalParsedFertValue${idx}_1`);
        }
        return value;
    }

    function getProgrammedPropIrrigValueMax(genFertHeader, idx){
        let value;
        if(genFertHeader.program.programType === 0){ // subprogram
            eval(`value = genFertHeader.program.progFerts[genFertHeader.program.xSubProgramInProgress-1].proportionalParsedFertValue${idx}_2`);
        }else{
            eval(`value = genFertHeader.program.progFerts[0].proportionalParsedFertValue${idx}_2`);
        }
        return value;
    }

    function getProgrammedFitoValue1(){
        let value;
        if(vm.programFertTF.programType === 0){ // subprogram
            value = vm.programFertTF.progFerts[vm.programFertTF.xSubProgramInProgress - 1].fitosValue1;
        }else{ // lineal
            value = vm.programFertTF.progFerts[0].fitosValue1;
        }
        return value;
    }

    function getProgrammedFitoValue2(){
        let value;
        if(vm.programFertTF.programType === 0){ // subprogram
            value = vm.programFertTF.progFerts[vm.programFertTF.xSubProgramInProgress - 1].fitosValue2;
        }else{ // lineal
            value = vm.programFertTF.progFerts[0].fitosValue2;
        }
        return value;
    }

    function getProgrammedReferencePH(){
        let value;
        if(vm.programFertPH.programType === 0){ // subprogram
            value = vm.programFertPH.progFerts[vm.programFertPH.xSubProgramInProgress - 1].referencePH;
        }else{ // lineal
            value = vm.programFertPH.progFerts[0].referencePH;
        }
        return value;
    }


    function getFormatedQuantity(genFertHeader){
        const fertUnits = genFertHeader.program.fertUnits;
        var value = genFertHeader.xQuantity;
        if(genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 || genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L){
            return value;
        }
        return utilsFactory.getFormatedFertilizationA4500(value, fertUnits);
    }

    function getFormatedFert(genFertHeader, idx){
        const fertUnits = genFertHeader.program.fertUnits;
        var value;
        let subProgramIdx = 0;
        if(genFertHeader.program.programType === 0){ // subprogram
            subProgramIdx = genFertHeader.program.xSubProgramInProgress - 1;
        }

        eval(`value = genFertHeader.program.progFerts[subProgramIdx].fertValue${idx}`)
        if(genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 || genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L){
            eval(`value = genFertHeader.program.progFerts[subProgramIdx].proportionalParsedFertValue${idx}_1`)
            return value;
        }
        return utilsFactory.getFormatedFertilizationA4500(value, fertUnits);
    }

    function getFormatedIrrig(genFertHeader, idx){
        var value;
        let subProgramIdx = 0;
        if(genFertHeader.program.programType === 0){ // subprogram
            subProgramIdx = genFertHeader.program.xSubProgramInProgress - 1;
        }
        eval(`value = genFertHeader.program.progFerts[subProgramIdx].proportionalParsedFertValue${idx}_2`)
        return value;
    }

    function getFormatedFito(fitoValue, fitoUnits){
        return utilsFactory.getFormatedFitoA4500(fitoValue, fitoUnits);
    }

    function existsFertValue(genFertHeader, idx){
        if(genFertHeader.program){
            return true;
        }else{
            return false
        }
    }

    function existsCEFertValue(program, idx){
        if(program.programType === 0){
            let exists;
            eval(`exists = program.progFerts[vm.programFerts.xSubProgramInProgress - 1].fertValue${idx} > 0`);
            return exists;
        }else{
            let exists;
            eval(`exists = program.progFerts[0].fertValue${idx} > 0`);
            return exists;
        }
    }

    function getFertilizerTypeLabel(type){
        switch(type){
            case 0:
                return $filter('translate')('irridesk.units');
            case 1:
                return $filter('translate')('fert.uni');
            case 2:
                return $filter('translate')('fert.prop') + " L/m3";
            case 3:
                return $filter('translate')('fert.prop') + " cl/L";
            case 4:
                return $filter('translate')('general.regce');
            case 5:
                return $filter('translate')('programs.config.inputce');
        }
    }

    function existsPropFertValue(genFertHeader){
        if(genFertHeader.program){
            return true;
        }else{
            return false
        }
    }

    function loadFertilizersHeaders(response){
        vm.fertsHeaders = response.plain();
        vm.fertHeader = vm.fertsHeaders[vm.headerId - 1];
        if(vm.fertHeader.xNumProgramPH > 0){
            progFactory.program(vm.currentUnit.id, vm.fertHeader.xNumProgramPH, {'init-program-ferts': true}).then(response => {
                vm.programFertPH = response.plain();
            });
        }
    }

    function loadFertilizers(response){
        vm.ferts = response.plain();
        vm.fert = ferts[vm.program.xSubProgramInProgress - 1];
    }


    function showOverlay(state) {
      $state.go(state, { unit: vm.currentUnit });
      vm.overlayEl = document.querySelector('vegga-overlay');
      vm.overlayEl.show();
    }

    function checkValue(max, volume) {
      if (volume === 0) {
        return 0;
      } else {
        return max - volume;
      }
    }

    function loadPrograms() {
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_4000:
          if (vm.currentUnit.inoptions && vm.currentUnit.inoptions.ph) {
            fertilizerFactory.getPhref(vm.currentUnit.id).then((data) => {
              if (data !== null && data !== undefined) {
                vm.phref = data.plain();
                vm.phref.xValue = String(vm.phref.xValue);
                if (vm.phref.xValue.length > 1) {
                  var first = vm.phref.xValue.slice(0, vm.phref.xValue.length - 1);
                  var last = vm.phref.xValue.slice(vm.phref.xValue.length - 1, vm.phref.xValue.length);
                  vm.phref.xValue = first + '.' + last + ' pH';
                } else {
                  vm.phref.xValue = '0.' + vm.phref.xValue + ' pH';
                }
              }
            });
          }

          fertilizerFactory.get(vm.currentUnit.id).then((fert) => {
            vm.fertilizers = fert;
          });
          programData();
          break;
        case vm.UNITS.A_2500:
        case vm.UNITS.A_BIT:
          fertilizerFactory.all(vm.currentUnit.id).then((result) => {
            vm.fertUnit = result.fertilizerUnits;
            vm.fertDecimals = result.decimalsFert;
            vm.fertilizers = result.fertilizers;
          });
          programData();
          break;
        case vm.UNITS.A_7000:
          vm.fertilizer = vm.currentUnit.fertilizer;
          programData7();
          break;
        case vm.UNITS.A_5500:
          vm.fertilizer = vm.currentUnit.fertilizer;
          programData55();
          break;
      case vm.UNITS.A_4500:
          loadData();
          break;
      }
    }

    function programData() {
      progFactory.programs(vm.currentUnit.id, vm.currentUnit.type, true).then(function () {
        var activeList = progFactory.activePrograms();
        vm.programs = activeList.filter((p) => {
          if (p.irrigation) {
            extractFertilizers(p);
            if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
              prepareProgressBarA4(p);
            } else {
              prepareProgressBar(p);
            }
          }
          return p.irrigation;
        });
      });
    }

    function programData7() {
      vm.parseFert7 = parseFert7;
      vm.ceReg = vm.currentUnit.otherValues.ceReg;
      vm.phReg = vm.currentUnit.otherValues.phReg;

      if (vm.fertilizer.xNumProgramCE > 0) {
        progFactory.program(vm.currentUnit.id, vm.fertilizer.xNumProgramCE).then((response) => {
          vm.programCE = response.plain();
          sensorsFactory.analogsPage(vm.currentUnit.id, { page: 1, limit: 1 }).then(function (data) {
            if (data.content[0]) {
              vm.sensorCE = data.content[0];
            }
          });
          sensorsFactory.analogsPage(vm.currentUnit.id, { page: 2, limit: 1 }).then(function (data) {
            if (data.content[0]) {
              vm.sensorSecurityCE = data.content[0];
            }
          });
          sensorsFactory.analogsPage(vm.currentUnit.id, { page: 3, limit: 1 }).then(function (data) {
            if (data.content[0]) {
              vm.sensorInputCE = data.content[0];
            }
          });
        });
      }
      if (vm.fertilizer.xNumProgramPH > 0) {
        progFactory.program(vm.currentUnit.id, vm.fertilizer.xNumProgramPH).then((response) => {
          vm.programPH = response.plain();
          sensorsFactory.analogsPage(vm.currentUnit.id, { page: 4, limit: 1 }).then(function (data) {
            if (data.content[0]) {
              vm.sensorPH = data.content[0];
            }
          });
          sensorsFactory.analogsPage(vm.currentUnit.id, { page: 5, limit: 1 }).then(function (data) {
            if (data.content[0]) {
              vm.sensorSecurityPH = data.content[0];
            }
          });
        });
      }
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.parameters = fert.plain();
      });
    }

    function parseFert7(val) {
      switch (vm.currentUnit.flow.fertilizationUnit) {
        case 0: // hh:mm
          return $filter('parsetime')(val, true);
          break;
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
          break;
        case 2: //"L",
          return val + ' l';
        case 3: //"cl"
          return val + ' cl';
      }
    }

    function programData55() {
      if (vm.fertilizer.xNumProgram > 0) {
        progFactory.program(vm.currentUnit.id, vm.fertilizer.xNumProgram).then((response) => {
          vm.program = response.plain();
          if (vm.fertilizer.checkCE > 0) {
            sensorsFactory
              .analogsPage(vm.currentUnit.id, { page: vm.fertilizer.checkCE, limit: 1 })
              .then(function (data) {
                if (data.content[0]) {
                  vm.sensorCE = data.content[0];
                }
              });
          }
          if (vm.fertilizer.securityCE > 0) {
            sensorsFactory
              .analogsPage(vm.currentUnit.id, { page: vm.fertilizer.securityCE, limit: 1 })
              .then(function (data) {
                if (data.content[0]) {
                  vm.sensorSecurityCE = data.content[0];
                }
              });
          }
          if (vm.fertilizer.inputCE > 0) {
            sensorsFactory
              .analogsPage(vm.currentUnit.id, { page: vm.fertilizer.inputCE, limit: 1 })
              .then(function (data) {
                if (data.content[0]) {
                  vm.sensorInputCE = data.content[0];
                }
              });
          }
          if (vm.fertilizer.checkPH > 0) {
            sensorsFactory
              .analogsPage(vm.currentUnit.id, { page: vm.fertilizer.checkPH, limit: 1 })
              .then(function (data) {
                if (data.content[0]) {
                  vm.sensorPH = data.content[0];
                }
              });
          }
          if (vm.fertilizer.securityPH > 0) {
            sensorsFactory
              .analogsPage(vm.currentUnit.id, { page: vm.fertilizer.securityPH, limit: 1 })
              .then(function (data) {
                if (data.content[0]) {
                  vm.sensorSecurityPH = data.content[0];
                }
              });
          }
        });
      }
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.parameters = fert.plain();
      });
    }

    function extractFertilizers(prog) {
      var subprogram;
      if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
        var subIndex = prog.xSubprogramCourse;
        subprogram = prog.subprograms[subIndex];
      } else {
        subprogram = prog;
      }

      var fertilizers = _.pickBy(subprogram, function (value, key) {
        return _.startsWith(key, 'fertilizer');
      });

      prog.ferilizerValues = _.values(fertilizers);
    }

    function prepareProgressBarA4(prog) {
      var total = Number(prog.preIrrigation + prog.subprograms[prog.xSubprogramCourse].value);
      prog.total = total;
      prog.parsedTotal = getParsed(prog.total - prog.xValue, prog);
      prog.stacked = [];
      if (!prog.postIrrigation > 0) {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPreIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPreIrrigation, prog),
          type: 'info',
        });
        prog.stacked.push({
          value: Number(prog.subprograms[prog.xSubprogramCourse].value - prog.xValue),
          parsedValue: getParsed(prog.subprograms[prog.xSubprogramCourse].value - prog.xValue, prog),
          type: 'info',
        });
      } else {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPreIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPreIrrigation, prog),
          type: 'info',
        });
        if (prog.xValue <= prog.postIrrigation) {
          prog.stacked.push({
            value: Number(prog.subprograms[prog.xSubprogramCourse].value - prog.postIrrigation),
            parsedValue: getParsed(prog.subprograms[prog.xSubprogramCourse].value - prog.postIrrigation, prog),
            type: 'info',
          });
          prog.stacked.push({
            value: prog.postIrrigation - prog.xValue,
            parsedValue: getParsed(prog.postIrrigation - prog.xValue, prog),
            type: 'info',
          });
        } else {
          prog.stacked.push({
            value: Number(prog.subprograms[prog.xSubprogramCourse].value - prog.xValue),
            parsedValue: getParsed(prog.subprograms[prog.xSubprogramCourse].value - prog.xValue, prog),
            type: 'info',
          });
        }
      }
    }

    function prepareProgressBar(prog) {
      var total = Number(prog.preIrrigation + prog.value);
      prog.total = total;
      prog.parsedTotal = getParsed(total, prog);
      prog.stacked = [];
      if (!prog.postIrrigation > 0) {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPrePostIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPrePostIrrigation, prog),
          type: 'info',
        });
        prog.stacked.push({
          value: Number(prog.value - prog.xValue),
          parsedValue: getParsed(prog.value - prog.xValue, prog),
          type: 'info',
        });
      } else {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPrePostIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPrePostIrrigation, prog),
          type: 'info',
        });
        if (prog.xValue <= prog.postIrrigation) {
          prog.stacked.push({
            value: Number(prog.value - prog.postIrrigation),
            parsedValue: getParsed(prog.value - prog.postIrrigation, prog),
            type: 'info',
          });
          prog.stacked.push({
            value: prog.postIrrigation - prog.xValue,
            parsedValue: getParsed(prog.postIrrigation - prog.xValue, prog),
            type: 'info',
          });
        } else {
          prog.stacked.push({
            value: Number(prog.value - prog.xValue),
            parsedValue: getParsed(prog.value - prog.xValue, prog),
            type: 'info',
          });
        }
      }
    }

    function formatFertilizerUnit(value, prog) {
      var unity;
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_4000:
          unity = prog.subprograms[prog.xSubprogramCourse].fertUnit;
          switch (unity) {
            case 0:
              return getTimeFromMin(value);
              break;
            case 1:
              return getTimeFromMin(value * 60); // Seconds to min.
              break;
            case 4:
              return value + ' l';
              break;
            case 8:
              return value + ' cl';
              break;
            case 32:
              return value + ' l/Ha';
              break;
            case 128:
              return value + ' dl';
              break;
          }
          break;
        case vm.UNITS.A_2500:
          unity = vm.currentUnit.fertilizer.fertUnits;
          switch (unity) {
            case 0: // hh:mm
              return getTimeFromMin(value / 60);
              break;
            case 1: // l
              return value / 100 + ' l';
              break;
            case 2: // l/Ha
              return value / 100 + ' l/Ha';
              break;
            case 3: // ' "
              return getTimeFromMin(value);
              break;
          }
          break;
        case vm.UNITS.A_BIT:
          switch (vm.fertUnit) {
            case 0: // hh:mm
              return getTimeFromMin(value / 60);
              break;
            case 1: // l
              switch (vm.fertDecimals) {
                case 0:
                  return value + ' l';
                  break;
                case 1:
                  return value / 10 + ' l';
                  break;
                case 2:
                  return value / 100 + ' l';
                  break;
              }
              break;
            case 2: // l/Ha
              switch (vm.fertDecimals) {
                case 0:
                  return value + ' l/Ha';
                  break;
                case 1:
                  return value / 10 + ' l/Ha';
                  break;
                case 2:
                  return value / 100 + ' l/Ha';
                  break;
              }
              break;
            case 3: // ' "
              return getTimeFromMin(value);
              break;
          }
          break;
      }

      return value;
    }

    function getFertF5Text55(fert) {
      switch (fert.xState) {
        case 0:
          return '-';
          break;
        case 1:
          return $filter('translate')('filters.wait');
          break;
        case 2:
          return vm.formatFert55(fert.xVolume, true);
          break;
        case 3:
          return $filter('translate')('general.pause');
          break;
      }
    }

    function formatFert55(val, f5) {
      var format = vm.fertilizer.fertUnits;
      var decimals = vm.fertilizer.fertDecimals;
      if (f5) {
        format = vm.fertilizer.fertUnitsF5;
        decimals = vm.fertilizer.fertDecimalsF5;
      }
      switch (format) {
        case 0: // hh:mm
          return getTimeFromMin(val / 60);
          break;
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
          break;
        case 2: // l
          switch (decimals) {
            case 0:
              return val + ' l';
              break;
            case 1:
              return val / 10 + ' l';
              break;
            case 2:
              return val / 100 + ' l';
              break;
          }
          break;
        case 3: // l/Ha
          switch (decimals) {
            case 0:
              return val + ' l/Ha';
              break;
            case 1:
              return val / 10 + ' l/Ha';
              break;
            case 2:
              return val / 100 + ' l/Ha';
              break;
          }
          break;
      }
    }

    function formatSignedIrr(value) {
      return value >> 8;
    }

    function formatSignedFert(value) {
      return value & 0x00ff;
    }

    function getParsed(value, prog) {
      var unity;
      switch (vm.currentUnit.type) {
        case vm.UNITS.A_4000:
          unity = prog.subprograms[prog.xSubprogramCourse].unit;
          if (value === null) value = prog.subprograms[prog.xSubprogramCourse].value;
          switch (unity) {
            case 0:
              return getTimeFromMin(value);
              break;
            case 1:
              return getTimeFromMin(value * 60); // Seconds to min.
              break;
            case 2:
              return value + ' m3';
              break;
            case 4:
              return value + ' l';
              break;
            case 16:
              return value + ' m3/Ha';
              break;
          }
          break;
        case vm.UNITS.A_2500:
          unity = prog.unit;
          switch (unity) {
            case 0: //hh:mm
              return getTimeFromMin(value / 60);
              break;
            case 1: //m3
              return value + ' m3';
              break;
            case 2: //m3/Ha
              return value + ' m3/Ha';
              break;
            case 4: // ' ''
              return getTimeFromMin(value);
              break;
            case 16: //hh:mm/Ha
              return getTimeFromMin(value / 60);
              break;
          }
          break;
        case vm.UNITS.A_BIT:
          unity = prog.unit;
          switch (unity) {
            case 0: //hh:mm
              return getTimeFromMin(value / 60);
              break;
            case 1: //m3
              return value / 1000 + ' m3';
              break;
            case 2: //m3/Ha
              return value / 1000 + ' m3/Ha';
              break;
            case 4: // ' ''
              return getTimeFromMin(value);
              break;
            case 16: //hh:mm/Ha
              return getTimeFromMin(value / 60);
              break;
          }
          break;
      }
    }

    function getTotalFert(value, prog) {
      var total = prog.subprograms[prog.xSubprogramCourse].value - prog.preIrrigation - prog.postIrrigation;
      var prop = formatSignedIrr(value);
      var fertProp = formatSignedFert(value);

      return (total / prop) * fertProp;
    }

    function getCalculatedFert(value, prog) {
      var total = prog.subprograms[prog.xSubprogramCourse].value - prog.preIrrigation - prog.postIrrigation;
      var irrigation = total - prog.xValue;
      var prop = formatSignedIrr(value);
      var fertProp = formatSignedFert(value);

      return (irrigation / prop) * fertProp;
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }

    function endAlarmCE() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case vm.UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params).then(() => {});
            break;
        }
        modalInstance.result.then(() => {});
      });
    }

    function endAlarmPH() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case vm.UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 16;
            manualFactory.genericManualAction(params.deviceId, params).then(() => {});
            break;
        }
        modalInstance.result.then(() => {});
      });
    }

    function save() {
      if (vm.formFertilizer && vm.formFertilizer.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('fertilizersFormSave', true);
        });
      }
    }

    function cancel($event) {
      if (vm.formFertilizer && vm.formFertilizer.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formFertilizer.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('vegga-overlay').dismiss();
          vm.tabIndex = 'general';
          $state.go('fertilizers', { unit: vm.currentUnit });
        });
      } else {
        document.querySelector('vegga-overlay').dismiss();
        loadPrograms();
      }
    }

    function changeTab(state) {
      vm.tabState = state;
      $scope.$broadcast('tabChange', vm.tabState);
    }

    $scope.$on('refresh', (event, args) => {
      if (args.unit) {
        vm.currentUnit = args.unit;
        loadPrograms();
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });

    $scope.$on('formUpdated', (e, args) => {
      vm.formFertilizer = args;
    });
  }
})();
