import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Navbar from "./navbar.component";
import { Observable } from "rxjs";
import { AppProps } from "single-spa";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  loadRootComponent(props: AppProps & { sidebarState$: Observable<boolean> }) {
    return new Promise((resolve) => resolve(() =>
      <Navbar {...props}/>))
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
