import {
  OrganizationFeaturesEnum,
  OrganizationPermissionEnum,
} from "@vegga-statics/enums";
import { environment } from "../../environments/environment";

export const irrigationManagerFeatures = [
  {
    eaUrl: 'ea-irrigation-manager/admin"',
    url: "/admin",
    value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_ADMIN_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/",
    url: "/",
    value:
      OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_DASHBOARD_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/farms",
    url: "/farms",
    value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_FARMS_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/solid",
    url: "/solid",
    value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_SOLIDS_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/stocks",
    url: "/stocks",
    value:
      OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/submit",
    url: "/submit",
    value:
      OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/results",
    url: "/results",
    value:
      OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_RESULTS_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/settings",
    url: "/settings",
    value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_CONFIG_ENABLED,
  },
  {
    eaUrl: "ea-irrigation-manager/clientAdmin",
    url: "/clientAdmin",
    value:
      OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT_ENABLED,
  },
];
export const irrigationManagerFlags = [
  {
    eaUrl: 'ea-irrigation-manager/admin"',
    url: "/admin",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN,
  },
  {
    eaUrl: "ea-irrigation-manager/",
    url: "/",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD,
  },
  {
    eaUrl: "ea-irrigation-manager/farms",
    url: "/farms",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FARMS,
  },
  {
    eaUrl: "ea-irrigation-manager/solid",
    url: "/solid",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SOLIDS,
  },
  {
    eaUrl: "ea-irrigation-manager/stocks",
    url: "/stocks",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS,
  },
  {
    eaUrl: "ea-irrigation-manager/submit",
    url: "/submit",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM,
  },
  {
    eaUrl: "ea-irrigation-manager/results",
    url: "/results",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_RESULTS,
  },
  {
    eaUrl: "ea-irrigation-manager/settings",
    url: "/settings",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_CONFIG,
  },
  {
    eaUrl: "ea-irrigation-manager/clientAdmin",
    url: "/clientAdmin",
    value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT,
  },
];

export const irrigationManagerPermissions = [
  {
    eaUrl: 'ea-irrigation-manager/admin"',
    url: "/admin",
    value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_ADMIN,
  },
  {
    eaUrl: "ea-irrigation-manager/",
    url: "/",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD,
  },
  {
    eaUrl: "ea-irrigation-manager/farms",
    url: "/farms",
    value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_FARMS,
  },
  {
    eaUrl: "ea-irrigation-manager/solid",
    url: "/solid",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_SOLIDS,
  },
  {
    eaUrl: "ea-irrigation-manager/stocks",
    url: "/stocks",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS,
  },
  {
    eaUrl: "ea-irrigation-manager/submit",
    url: "/submit",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM,
  },
  {
    eaUrl: "ea-irrigation-manager/results",
    url: "/results",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_RESULTS,
  },
  {
    eaUrl: "ea-irrigation-manager/settings",
    url: "/settings",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_CONFIG,
  },
  {
    eaUrl: "ea-irrigation-manager/clientAdmin",
    url: "/clientAdmin",
    value:
      OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT,
  },
];
