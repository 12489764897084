import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('smsController', smsController);

  smsController.$inject = ['$scope', '$state', 'commFactory', 'SMS', '$filter', '$confirm'];

  function smsController($scope, $state, commFactory, SMS, $filter, $confirm) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.UNITS = UNITS;
    activate();

    function activate() {
      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.sms = SMS;
        vm.save = save;
        vm.saveex = saveE;
        vm.cancel = cancel_edition;
        vm.comunications = { 0: 'Ninguno', 1: 'COM 1', 2: 'COM 2', 3: 'COM 3', 4: 'COM 4' };
        vm.actions = { 0: 'Marcha', 1: 'Paro' };
        vm.programs = _.range(0, 36);
        vm.changeState = changeState;
      } else {
        $state.go('units');
      }
    }

    function changeState(location) {
      if ((vm.form && vm.form.$dirty) || (vm.formO && vm.formO.$dirty)) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          cancel_edition(); //Emetem cancelació de canvis
          if (vm.form) {
            //Actualitzem estat del formulari a inicial
            vm.form.$setPristine();
          }
          if (vm.formO) {
            vm.formO.$setPristine();
          }
          vm.form = null;
          $state.go('communications.sms.' + location, { unit: vm.currentUnit }); //Canviem d'estat
        });
      } else {
        $state.go('communications.sms.' + location, { unit: vm.currentUnit });
      }
    }

    function save() {
      vm.sms.unittype = vm.currentUnit.type;
      vm.loading = true;
      vm.sms.updateExternal = false;
      commFactory.updateSMS(vm.currentUnit.id, vm.sms).then(
        (result) => {
          vm.sms = result;
          backup = angular.copy(vm.sms);
          vm.loading = false;
          if (vm.form) {
            vm.form.$setPristine();
          }
          if (vm.formO) {
            vm.formO.$setPristine();
          }
        },
        (error) => {}
      );
    }

    function saveE() {
      vm.sms.unittype = vm.currentUnit.type;
      vm.loading = true;
      vm.sms.updateExternal = true;
      commFactory.updateSMS(vm.currentUnit.id, vm.sms).then(
        (result) => {
          vm.sms = result;
          vm.sms.externalSMS = _.orderBy(vm.sms.externalSMS, 'id');
          backup = angular.copy(vm.sms);
          vm.loading = false;
          if (vm.form) {
            vm.form.$setPristine();
          }
          if (vm.formO) {
            vm.formO.$setPristine();
          }
        },
        (error) => {}
      );
    }

    function cancel_edition() {
      vm.linkBox = angular.copy(backup);
    }
    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        if (vm.form) {
          $scope.$emit('formUpdated', vm.form);
        }
      }
    );

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.formO,
      () => {
        if (vm.formO) {
          $scope.$emit('formUpdated', vm.formO);
        }
      }
    );

    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
