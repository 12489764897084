import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('equipoLayerController', equipoLayerController);

  equipoLayerController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'mapsFactory',
    'manualFactory',
    '$confirm',
  ];

  function equipoLayerController($log, $rootScope, $scope, $state, mapsFactory, manualFactory, $confirm) {
    var vm = this;
    var currentMarker;
    vm.equipo;
    let selectedUnit;
    activate();

    /*******************/

    function activate() {
      if (!$state.params.unit) {
        $state.go('^');
        return;
      }

      vm.equipoInfo = false;
      vm.addMarkerE = addMarker;
      vm.deleteEquipoMarker = deleteEquipoMarker;
      vm.closeEquipoInfo = closeEquipoInfo;
      vm.saveEquipo = saveEquipo;
      vm.returnType = returnType;
      vm.unitHour = unitHour;
      vm.manualStop = manualStop;
      vm.manualExitStop = manualExitStop;
      vm.manualOut = manualOut;
      vm.manualExitOut = manualExitOut;
      vm.checkStatus = checkStatus;
      if ($state.params.unit !== null) {
        vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
        vm.currentUnit = $state.params.unit;
        vm.shared = $state.params.map.shared;
        vm.sharingLvl = $state.params.map.sharingLvl;
        vm.areaInfo = false;
        vm.moduleInfo = false;
        vm.equipoInfo = true;
        vm.sensorInfo = false;
      }
    }

    function editArea() {
      $scope.$emit('editAreaFromSectorLayer');
    }

    function getEquipoStateClass(unit) {
      switch (unit.status) {
        case 'systemStop':
        case 'definitiveStopMalfunction':
          return 'stop';
        case 'outService':
          return 'out';
        case 'notconnected':
          return 'nok';
        case 'ok':
          if (unit.irrigation) {
            return 'irrig';
          } else {
            return 'noirrig';
          }
        default:
          return 'malfunction';
      }
    }

    function markerPrepare(e) {
      var className = getEquipoStateClass(vm.unit);
      e.layer.setIcon(createEquipoIcon(className));

      saveMarker(e.latlng).then((resp) => {
        e.layer.properties = {};
        e.layer.properties.id = resp.id;
        e.layer.properties.deviceid = resp.deviceId;
        $scope.$emit('completedSave');
        vm.equiposLayer.addLayer(e.layer);
        e.layer.toggleEdit();
        vm.map.off('editable:drawing:commit', markerPrepare);
      });
    }

    function saveMarker(latLng) {
      var params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 0;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      vm.map.editTools.startMarker({ zIndex: 9999 });
      vm.map.on('editable:drawing:commit', markerPrepare);
    }

    var createEquipoIcon = function (labelClass) {
      var content = '<div class="map-equipo-marker ' + labelClass + '"></div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function saveEquipo() {
      //console.log(currentMarker.properties);
      var params = {};
      params.id = currentMarker.properties.id;
      params.deviceId = currentMarker.properties.deviceid = vm.currentUnit.id;
      mapsFactory.updateMarker(vm.map.id, params).then((result) => {
        /*             closeEquipoInfo();
            loadEquiposLayer(); */
      });
    }

    function closeEquipoInfo() {
      $rootScope.$broadcast('closeEquipo');
      $state.go('^');
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
          break;
        case 3:
          return 'Agrónic 2500';
          break;
        case 4:
          return 'Agrónic BIT';
          break;
        case 5:
          return 'Agrónic 7000';
          break;
        case 6:
          return 'Agrónic 5500';
          break;
      }
    }

    function unitHour() {
      if (vm.currentUnit && vm.currentUnit.date) {
        return moment(vm.currentUnit.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
      } else {
        return '--:--';
      }
    }

    function deleteEquipoMarker() {
      $rootScope.$broadcast('deleteEquipo');
    }

    /* Manual functionality */

    function manualStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.yes`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopUnit(vm.currentUnit).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.yes`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.exitStopUnit(vm.currentUnit).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.yes`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.outUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.yes`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.exitOutUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }

    $scope.$on('remarkSectors', (event, args) => {
      if (selectedUnit !== undefined && selectedUnit !== null) {
        showOtherSectorFromUnit(selectedUnit.id);
      }
    });

    $scope.$on('reloadEquipo', (event, args) => {
      vm.currentUnit = args.unit;
    });

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });

    $scope.$on('editPaginatorChange', (ev, unit) => {
      vm.currentUnit = unit;
    });
  }
})();
