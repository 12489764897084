import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsMetersController', totalsMetersController);

  totalsMetersController.$inject = ['$scope', '$state', 'NgTableParams', 'unitFactory'];

  function totalsMetersController($scope, $state, NgTableParams, unitFactory) {
    var vm = this;
    vm.activeList;
    activate();

    function activate() {
      vm.UNITS = UNITS;

      vm.currentState = $state.includes;

      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.deleteTotals = deleteTotals;
        initGrid();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 99,
        },
        {
          counts: [],
          getData: function (params) {
            return unitFactory.totalsMeter(vm.currentUnit.id).then((data) => {
              vm.tableParams.total(data.length);
              return data;
            });
          },
        }
      );
    }

    function deleteTotals() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 4:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          initGrid();
        });
      });
    }

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        initGrid();
      }
    });
  }
})();
