(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('others45fertController', others45fertController)

        others45fertController.$inject = ['$rootScope', '$scope', '$state', 'sectorFactory','$confirm','$filter'];

    function others45fertController($rootScope, $scope, $state,  sectorFactory, $confirm,$filter) {

        var vm = this;
        var backup; 
        vm.installer;
        vm.save;
        vm.cancel;
        vm.sector = {};
        activate();

        function activate() {
            vm.currentState = $state.includes;
            if ($state.params.unit !== null) {
                vm.currentUnit = $state.params.unit;
                vm.save = save;
                vm.cancel = cancel_edition;
                vm.changeSector = changeSector;
                vm.changingSector = changingSector;
                vm.nextOrPreviousItem = nextOrPreviousItem;
                vm.changeFert = changeFert;
                loadSectors(vm.currentUnit.id);               
            } else {
                $state.go('units');
            }

        }

        function changeSector(e) {
            if (e.detail.value) {
                vm.index = +e.detail.value;
                vm.sectorSelected = (vm.index).toString();
                angular.copy(vm.sectors[vm.index - 1],vm.sector);
            }
        }

        function changeFert(f){
            if(f == 1){
                vm.sector.fert2 = 0;
                vm.sector.fert3 = 0;
                vm.sector.fert4 = 0;
            }else if(f == 2){
                vm.sector.fert1 = 0;
                vm.sector.fert3 = 0;
                vm.sector.fert4 = 0;
            }else if(f == 3){
                vm.sector.fert1 = 0;
                vm.sector.fert2 = 0;
                vm.sector.fert4 = 0;
            }else if(f == 4){
                vm.sector.fert1 = 0;
                vm.sector.fert2 = 0;
                vm.sector.fert3 = 0;
            }            
        }

        function changingSector($event) {
            if(vm.form && vm.form.$dirty){
                (typeof $event !== 'undefined') ? $event.preventDefault() : null;
                $confirm({text: $filter('translate')('sensors.cancelq')}).then( () => {
                    $scope.$broadcast('formCancel'); //Emetem cancelació de canvis                    
                    vm.form.$setPristine(); //Actualitzem estat del formulari a inicial                    
                    this.nextOrPreviousItem($event.detail.action, $event);
                })
            }
        }
    
        function nextOrPreviousItem(action, $event) {
            const paginator = document.querySelector('vegga-item-paginator');
            switch (action) {
                case "next-item":
                    paginator.nextItem();
                    break;
                case "previous-item":
                    paginator.previousItem();
                    break;
                default: 
                    changeSector($event);
                    break;
            }
        }

        function backup(){
            vm.sector_backup = {};  
            angular.copy(vm.sector, vm.sector_backup);
        }

        function loadSectors(id){
            sectorFactory.sector(id).then(function(data){
                 vm.sectors = data.plain(); 
                 vm.sectorSelected = vm.sectors[0].pk.id;
                 angular.copy(vm.sectors[0],vm.sector);
                 //vm.sector = JSON.parse(JSON.stringify(vm.sectors[0]));
                 backup();
            });        
        }

        function save() {
            vm.sector.unittype = vm.currentUnit.type;
            sectorFactory.update(vm.sector).then(response => {
                vm.sector = response.plain();
                angular.copy(vm.sector, vm.sectors[vm.index-1]);         
                $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
                vm.form.$setPristine();
                $rootScope.toast({
                    type:"success",
                    title:"Guardado correcto"
                });
                backup();        
            }, error => {
                $rootScope.toast({
                    type:"error",
                    title:"Error en el guardado"
                });
            })
        }
        
        function cancel_edition() {
            vm.form.$setPristine();
            angular.copy(vm.sector_backup, vm.sector);
        }

        /**
        * Emetem estat del formulari quan detecta canvis en l'objecte.
        */
        $scope.$watch(() => vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });

        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save(e, vm.form);
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });

    }
})();