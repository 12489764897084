(function () {
    'use strict';


    angular.module('agronicwebApp')

        .controller('others45configController', others45configController)

        others45configController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'filterFactory'];

    function others45configController($rootScope, $scope, $state,  configFactory, filterFactory) {

        var vm = this;
        var backup; 
        vm.installer;
        vm.save;
        vm.cancel;

        activate();

        function activate() {
            vm.currentState = $state.includes;
            if ($state.params.unit !== null) {
                vm.currentUnit = $state.params.unit;
                getInstaller();               
                vm.save = save;
                vm.cancel = cancel_edition;
            } else {
                $state.go('units');
            }

        }

        function backup(){
            vm.installer_backup = {};  
            angular.copy(vm.installer, vm.installer_backup);         
        }

        function getInstaller(){
            filterFactory.getInstaller(vm.currentUnit.id).then(function(data){            
                vm.installer = data.plain();
                backup();
            });
        }

        function save() {
            vm.installer.unittype = vm.currentUnit.type;
            filterFactory.updateInstaller(vm.currentUnit.id,vm.installer).then(res => {
                //vm.installer = data; 
                vm.form.$setPristine();
                $rootScope.toast({
                    type:"success",
                    title:"Guardado correcto"
                });
                backup();        
            }, error => {
                $rootScope.toast({
                    type:"error",
                    title:"Error en el guardado"
                });
            })
        }
        
        function cancel_edition() {
            vm.form.$setPristine();
            angular.copy(vm.installer_backup, vm.installer);
        }

        /**
        * Emetem estat del formulari quan detecta canvis en l'objecte.
        */
        $scope.$watch(() => vm.form, () => {
            $scope.$emit('formUpdated', vm.form);
        });

        /**
         * Event listener per detectar acció de save executada desde el parent controller.
         */
        $scope.$on('formSubmit', (e, args) => {
            save(e, vm.form);
        });
        /**
         * Event listener per detectar acció de cancel·lar executada desde el parent controller.
         */
        $scope.$on('formCancel', (e, args) => {
            //Restaurem estat del formulari a la versió previa.
            cancel_edition();
        });
    }
})();