import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersConfigA45Controller', filtersConfigA45Controller);

  filtersConfigA45Controller.$inject = ['$scope', '$state', 'sensorsFactory', 'filterFactory', '$rootScope'];

  function filtersConfigA45Controller($scope, $state, sensorsFactory, filterFactory, $rootScope) {
    var vm = this;
    vm.form;
    vm.calcNumFilter3;
    vm.analogs = [];
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.save = save;
        vm.cancel = cancel_edition;
        vm.calcNumFilter3func = calcNumFilter3func;
        vm.filter = $state.params.filter;
        vm.currentHeader = $state.params.headerId;
        vm.currentUnit = $state.params.unit;
        getInstaller();
        loadAnalogs();
        calcNumFilter3func();
        if (!vm.filter.backup) {
          backup();
        }
      } else {
        $state.go('units');
      }
    }

    function loadAnalogs() {
      sensorsFactory.analogsAll(vm.currentUnit.id).then(function (data) {
        vm.analogs = data;
      });
    }

    function calcNumFilter3func() {
      if (vm.filter.numFilters && vm.filter.numFilters > 0) {
        vm.calcNumFilter3 = vm.filter.numFilters - vm.filter.numFiltersGroup1 - vm.filter.numFiltersGroup2;
      } else vm.calcNumFilter3 = 0;
    }

    function backup() {
      vm.filter_backup = {};
      angular.copy(vm.filter, vm.filter_backup);
      vm.filter.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.filter_backup, vm.filter);
    }

    function getInstaller() {
      filterFactory.getInstaller(vm.currentUnit.id).then(function (data) {
        vm.installer = data.plain();
      });
    }

    function save() {
      vm.filter.modifiedForGeneral = false;
      vm.installer.unittype = vm.currentUnit.type;
      filterFactory.saveHeaderFilter(vm.currentUnit.id, vm.currentHeader, vm.filter).then((result) => {
        filterFactory.updateInstaller(vm.currentUnit.id, vm.installer).then((res) => {
          //vm.installer = data;
          vm.form.$setPristine();
          vm.filter = result.plain();
          backup();
        });
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
      $scope.$emit('headerGeneralFiltersUpdate', vm.installer.headerGeneralFilters);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
