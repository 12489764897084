import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('equipoMapController', equipoMapController);

  equipoMapController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'manualFactory',
    '$confirm',
    '$timeout',
    '$anchorScroll',
  ];

  function equipoMapController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    manualFactory,
    $confirm,
    $timeout,
    $anchorScroll
  ) {
    var vm = $scope.$parent.vm;
    var layerLoaded;
    var currentMarker;
    vm.equipo;
    let selectedUnit;
    activate();

    /*******************/

    function activate() {
      vm.addMarkerE = addMarker;
      vm.saveEquipo = saveEquipo;
      vm.returnType = returnType;
      vm.unitHour = unitHour;
      vm.manualStop = manualStop;
      vm.manualExitStop = manualExitStop;
      vm.manualOut = manualOut;
      vm.manualExitOut = manualExitOut;
      layerLoaded = false;
    }

    function loadEquiposLayer() {
      if (!layerLoaded && vm.map.id !== undefined) {
        mapsFactory.getMarkers(vm.map.id, 0).then((markers) => {
          layerLoaded = true;
          vm.unitsInMap = vm.units.filter(({ sync }) =>
            markers.find(({ deviceId }) => deviceId === (sync && sync.deviceId))
          );
          loadMarkersToLayer(markers.plain());
          vm.map.addLayer(vm.equiposLayer);
        });
      } else {
        vm.map.addLayer(vm.equiposLayer);
      }
    }

    function loadMarkersToLayer(markers) {
      vm.equiposLayer.clearLayers();
      _.forEach(markers, (markerInfo) => {
        var unit = _.filter(vm.units, { id: Number(markerInfo.deviceId) });
        if (!_.isEmpty(unit)) {
          var marker = L.marker(new L.LatLng(markerInfo.lat, markerInfo.lng));
          if (unit[0].status === 'ok') {
            checkIrrigation(unit[0]).then((res) => {
              var className = getEquipoStateClass(unit[0]);
              marker.setIcon(createEquipoIcon(className));
              marker.properties = {};
              marker.properties.id = markerInfo.id;
              marker.properties.deviceid = markerInfo.deviceId;
              vm.equiposLayer.addLayer(marker);
            });
          } else {
            var className = getEquipoStateClass(unit[0]);
            marker.setIcon(createEquipoIcon(className));
            marker.properties = {};
            marker.properties.id = markerInfo.id;
            marker.properties.deviceid = markerInfo.deviceId;

            vm.equiposLayer.addLayer(marker);
          }
          var stringPopup = '<div class="title-popup"><div class="img-logo">' + unit[0].name + '</div></div>';
          var mypopup = L.popup({ closeButton: false, className: 'popup-map-sector' }).setContent(stringPopup);
          marker.bindPopup(mypopup);
        }
      });
      vm.equiposLayer.on({ click: showEquipoInfo });
      vm.equiposLayer.on({ mouseover: changeStyleOver });
      vm.equiposLayer.on({ mouseout: changeStyleOut });
    }

    function changeStyleOver(event) {
      event.layer.openPopup();
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) !== event.layer.properties.deviceid) {
          vm.map.removeLayer(l);
        }
      });
    }

    function changeStyleOut(event) {
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) !== event.layer.properties.deviceid) {
          vm.map.addLayer(l);
        }
      });
    }

    function editArea() {
      $scope.$emit('editAreaFromSectorLayer');
    }

    function getEquipoStateClass(unit) {
      switch (unit.status) {
        case 'systemStop':
        case 'definitiveStopMalfunction':
          return 'stop';
        case 'outService':
          return 'out';
        case 'notconnected':
          return 'nok';
        case 'ok':
          if (unit.irrigation) {
            return 'irrig';
          } else {
            return 'noirrig';
          }
        default:
          return 'malfunction';
      }
    }

    function showEquipoInfo(e) {
      clearSectorSelection();
      var unit = _.filter(vm.units, { id: Number(e.layer.properties.deviceid) });
      if (!_.isEmpty(unit)) {
        vm.currentUnit = selectedUnit = unit[0];
        $timeout(() => {
          vm.map.invalidateSize();
          vm.map.flyTo(this.getBounds().getCenter());
        }, 200);
        currentMarker = e.layer;
        showOtherSectorFromUnit(vm.currentUnit.id);

        vm.overlay.show();
        $scope.$emit('editSectionChange', {
          type: 1,
          value: vm.currentUnit,
          elementsInMap: vm.unitsInMap,
        });
        $state.go(
          'maps.unit',
          { unit: vm.currentUnit, map: vm.map, elementsInMap: vm.unitsInMap },
          { reload: 'maps.unit' }
        );
      }
    }

    function showOtherSectorFromUnit(id) {
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) === id) {
          l.setStyle({ color: '#ffc72c', weight: 3 });
        }
      });
    }

    function clearSectorSelection() {
      vm.areasLayer.eachLayer(function (l) {
        l.setStyle({ color: 'white', weight: 1 });
      });
    }

    function saveMarker(latLng) {
      var params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 0;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      const event = vm.props;
      var className = getEquipoStateClass(vm.unit);
      event.layer.setIcon(createEquipoIcon(className));

      saveMarker(event.latlng).then((resp) => {
        event.layer.properties = {};
        event.layer.properties.id = resp.id;
        event.layer.properties.deviceid = resp.deviceId;
        reloadUnits();
        $scope.$emit('completedSave');
        vm.equiposLayer.addLayer(event.layer);
        event.layer.toggleEdit();
        vm.map.off('editable:drawing:commit');
      });
    }

    function initAddMarker() {
      vm.filter.comp.units = true;
      vm.filter.comp.active = true;
    }

    var createEquipoIcon = function (labelClass) {
      var content = '<div class="map-equipo-marker ' + labelClass + '"></div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function saveEquipo() {
      var params = {};
      params.id = currentMarker.properties.id;
      params.deviceId = currentMarker.properties.deviceid = vm.currentUnit.id;
      mapsFactory.updateMarker(vm.map.id, params).then((result) => {
        closeEquipoInfo();
        loadEquiposLayer();
      });
    }

    function closeEquipoInfo() {
      vm.areaInfo = false;
      $anchorScroll();
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
      clearSectorSelection();
    }

    function checkIrrigation(device) {
      var deferred = $q.defer();
      switch (device.type) {
        case 2: //A4000
        case 3: //A2500
        case 4: //ABIT
        case 5: //A7000
        case 6: //A5500
          if (device.status == 'ok') {
            if (device.sectors !== undefined) {
              var irrig = _.filter(device.sectors, (sector) => {
                return sector.xStatus & (1 === 1);
              });
              device.irrigation = irrig.length > 0;
              extractIrrigationSectors(device, irrig);
            } else {
              sectorFactory.allSectors(device.id).then(
                function (data) {
                  device.sectors = data.plain();
                  var irrig = _.filter(device.sectors, (sector) => {
                    return sector.xStatus & (1 === 1);
                  });
                  device.irrigation = irrig.length > 0;
                  extractIrrigationSectors(device, irrig);
                },
                function (error) {}
              );
            }
            progFactory.programs(device.id, device.type, true).then(function (data) {
              var activeList = progFactory.activePrograms();

              device.nprogr = activeList.length;
              return deferred.resolve('');
            });
          } else {
            return deferred.resolve('');
          }
          break;
      }
      return deferred.promise;
    }

    function extractIrrigationSectors(device, irrig) {
      if (irrig.length > 0) {
        var ids = _.map(irrig, 'id');
        device.irrigationSectors = _.values(ids).join(' - ');
      }
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
        case 3:
          return 'Agrónic 2500';
        case 4:
          return 'Agrónic BIT';
        case 5:
          return 'Agrónic 7000';
        case 6:
          return 'Agrónic 5500';
      }
    }

    function unitHour() {
      if (vm.currentUnit && vm.currentUnit && vm.currentUnit.date) {
        return moment(vm.currentUnit.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
      } else {
        return '--:--';
      }
    }

    function deleteEquipoMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then(() => {
        vm.equipoInfo = false;
        vm.equiposLayer.removeLayer(currentMarker);
        reloadUnits();
        closeEquipoInfo();
      });
    }

    /* Manual functionality */

    function manualStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.yes`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopUnit(vm.currentUnit).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.no`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.exitStopUnit(vm.currentUnit).then((response) => {});
            break;
          case 3:
            var params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.no`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.outUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.yes`),
        cancel: $filter('translate')(`general.no`),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.exitOutUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function reloadUnits() {
      layerLoaded = false;
      loadEquiposLayer();
    }

    $scope.$on('closeEquipo', () => {
      closeEquipoInfo();
    });
    $scope.$on('deleteEquipo', () => {
      vm.overlay.dismiss();
      deleteEquipoMarker();
    });

    $scope.$on('addEquipoMarker', (event, args) => {
      vm.props = args;
      $scope.$emit('groupElementsInMap', vm.unitsInMap);
      initAddMarker();
    });

    $scope.$on('loadEquiposLayer', () => {
      loadEquiposLayer();
    });

    $scope.$on('remarkSectors', () => {
      if (selectedUnit !== undefined && selectedUnit !== null) {
        showOtherSectorFromUnit(selectedUnit.id);
      }
    });
    $scope.$on('reloadMap', () => {
      layerLoaded = false;
      loadEquiposLayer();
      if ($state.current.parent === 'maps' && vm.currentUnit !== null && vm.currentUnit !== undefined) {
        let new_unit = _.filter(vm.units, { id: vm.currentUnit.id });
        if (new_unit.length > 0) {
          $rootScope.$broadcast('reloadEquipo', { unit: new_unit[0] });
        }
      }
    });
  }
})();
